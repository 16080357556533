import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
// import { goBack } from 'connected-react-router';
import { Empty, Skeleton } from 'antd';
import { useHistory } from 'react-router-dom';

import {
  CONTRACTOR_ID,
  SELECTED_STATUS,
  TESTID_ORDERS_DETAILS_DRAWER,
  TYPE_ORDER
} from 'constants/index';

import Drawer from 'components/common/drawer';
import useFetchOrderStatus from 'components/orders-view/drawers/details/use-fetch-order-status';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
// import WorkspaceVerifyModal from 'components/common/workspace-verify-modal';
import ContactChat from 'components/contacts-view/views/chats/contact-chat';

// import { getActiveWorkspace } from 'store/workspace';

import { useQueryParam } from 'hooks';

import Info from './info';
import ContractorPanel from './contractor-panel';
import StatusActions from './status-actions';
import SmallList from './small-list';

import styles from './details.module.scss';

const OrderDetailsDrawer = ({ visible }) => {
  // const dispatch = useDispatch();

  const [isAfterVisible, setIsAfterVisible] = useState(false);
  // const [visibleVerifyModal, setVisibleVerifyModal] = useState(false);

  // const workspace = useSelector(getActiveWorkspace);

  const history = useHistory();

  const orderStatusId = useQueryParam(SELECTED_STATUS);
  const contractorId = useQueryParam(CONTRACTOR_ID);

  const {
    orderStatus,
    hasOrderStatus,
    errorData,
    isLoadingPeriodicSpec
  } = useFetchOrderStatus({
    orderStatusId,
    allowFetch: isAfterVisible,
    isFromNotification: (history.location.state || {}).isFromNotification
  });
  const { contractor, title, id } = orderStatus || {};

  // const onCloseVerifyModal = useCallback(
  //   ({ isVerified } = {}) => {
  //     setVisibleVerifyModal(false);

  //     if (!isVerified) {
  //       dispatch(goBack());
  //     }
  //   },
  //   [dispatch]
  // );

  // useEffect(() => {
  //   if (contractor && contractor.employee && workspace.isNotVerified) {
  //     setVisibleVerifyModal(true);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [contractor]);

  // needed to check contractorId when CHANNEL_CHAT_NEW_MESSAGES event
  useEffect(() => {
    if (contractor && isAfterVisible && orderStatusId && !contractorId) {
      history.replace({
        ...history.location,
        search: `${history.location.search}&${CONTRACTOR_ID}=${contractor.id}`
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAfterVisible, contractor, orderStatusId]);

  return (
    <>
      <Drawer
        visible={visible}
        fullWidth
        isContent
        data-qa="qa-d5jmy1u1x9ry340"
        closable={false}
        documentTitleProps={{ title }}
        destroyOnClose
        afterVisibleChange={setIsAfterVisible}
        data-test-id={TESTID_ORDERS_DETAILS_DRAWER}
      >
        <div className={styles.root}>
          <SmallList selectedId={orderStatusId} />

          {!hasOrderStatus && !errorData && (
            <>
              <div className={styles.skeletonWrap}>
                <Skeleton active paragraph={{ rows: 5 }} />

                {[...Array(3).keys()].map(value => (
                  <Skeleton
                    key={`skeleton-three-${value}`}
                    active
                    avatar={{ size: 24, shape: 'circle' }}
                    title={{ width: '100%' }}
                    paragraph={false}
                  />
                ))}

                {[...Array(2).keys()].map(value => (
                  <Skeleton
                    key={`skeleton-two-${value}`}
                    active
                    paragraph={{ rows: 2 }}
                  />
                ))}
              </div>

              <div className={styles.chat} />
            </>
          )}

          {errorData && (
            <div className={styles.error}>
              <Empty
                image={<Icon component={errorData.iconComponent} />}
                imageStyle={{ height: 'auto' }}
                description={
                  <Typography.Title level={1}>
                    {errorData.title}
                  </Typography.Title>
                }
              />
            </div>
          )}

          {hasOrderStatus && (
            <>
              <div className={styles.infoWrap}>
                <Info
                  orderStatus={orderStatus}
                  isLoadingPeriodicSpec={isLoadingPeriodicSpec}
                />

                <StatusActions orderStatus={orderStatus} />
              </div>

              <div className={styles.chat}>
                <ContactChat
                  contact={contractor}
                  destination={{
                    entityType: TYPE_ORDER,
                    entityId: orderStatusId,
                    entity: orderStatus
                  }}
                  isEntityLoading={!contractor}
                  canView={contractor && contractor.permissions.canViewContact}
                />
              </div>

              {contractor && contractor.permissions.canViewContact && (
                <ContractorPanel orderStatusId={id} contractor={contractor} />
              )}
            </>
          )}
        </div>
      </Drawer>

      {/* <WorkspaceVerifyModal
        visible={visibleVerifyModal}
        description={
          <>
            Внимание! <br />
            Ваш контрагент зарегистрирован в Upservice. Проводить сделки в
            режиме онлайн могут только компании, которые прошли проверку. Чтобы
            сделать это — введите название компании и ее страну. Мы проверим ваш
            налоговый статус.
          </>
        }
        onClose={onCloseVerifyModal}
      /> */}
    </>
  );
};

OrderDetailsDrawer.propTypes = {
  visible: PropTypes.bool
};

OrderDetailsDrawer.defaultProps = {
  visible: false
};

export default OrderDetailsDrawer;

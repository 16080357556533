import React from 'react';
import PropTypes from 'prop-types';
import { Empty as AntdEmpty } from 'antd';

import { AssetIcon } from 'components/common/icons/multicolor';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

const { Paragraph, Title } = Typography;

export const Empty = ({
  title,
  description,
  videoSlug,
  isOpenDrawer,
  ...props
}) => (
  <AntdEmpty
    image={<Icon component={AssetIcon} />}
    style={{
      display: 'flex',
      flexDirection: 'column',
      justifyContent: 'center',
      margin: 8
    }}
    imageStyle={{ height: 'auto' }}
    description={
      <>
        {title && (
          <Title level={1} weight="bold" style={{ marginBottom: 16 }}>
            {title}
          </Title>
        )}

        <Paragraph style={{ margin: '0 auto 16px', maxWidth: 406 }}>
          {description}
        </Paragraph>
      </>
    }
    {...props}
  />
);

Empty.propTypes = {
  title: PropTypes.string,
  description: PropTypes.string.isRequired
};

Empty.defaultProps = {
  title: undefined
};

export default Empty;

import { useCallback, useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import {
  fetchOrderStatus,
  fetchOrderStatusAttachments,
  editOrderStatus
} from 'store/order-statuses';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const useEdit = ({ orderStatusId, onClose }) => {
  const dispatch = useDispatch();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isAfterVisible, setIsAfterVisible] = useState(false);
  const [values, setValues] = useState({});

  const hasValues = !isEmpty(values) && !isFetchLoading;

  const fetch = useCallback(async () => {
    try {
      setIsFetchLoading(true);

      const orderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: {
            relatedOrderStatuses: orderStatusId
          }
        })
      );

      setValues({
        ...orderStatus,
        description: orderStatus.content,
        fileList: documentList
      });
    } finally {
      setIsFetchLoading(false);
    }
  }, [dispatch, orderStatusId]);

  const onSubmit = async data => {
    try {
      setIsSubmitLoading(true);

      await dispatch(
        editOrderStatus({
          id: orderStatusId,
          values: data
        })
      );

      await dispatch(
        fetchOrderStatus({
          id: orderStatusId
        })
      );

      showNoticeMessage({ number: NOTICE_NUMBER.orderStatusUpdated });

      onClose();
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (isAfterVisible) {
      fetch();
    }

    return () => setValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAfterVisible]);

  return {
    isLoading: isSubmitLoading,
    values,
    hasValues,
    onSubmit,
    afterVisibleChange: setIsAfterVisible
  };
};

export default useEdit;

import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useLocation } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { NOTIFICATIONS } from 'constants/index';

import Drawer from 'components/common/drawer';
import Icon from 'components/common/icon';
import Button from 'components/common/button';
import NotificationsWithActions from 'components/dashboard-view/views/notifications/with-actions';
import NotificationList from 'components/dashboard-view/views/notifications/list';

import {
  getActiveWorkspace,
  getAllWorkspaces,
  getPersonalWorkspace,
  setActiveId
} from 'store/workspace';
import { getDrawerVisible, setDrawerVisible } from 'store/notifications';
import { getIsOnlyUserProfile, getUser } from 'store/user';

import { useRoutesService } from 'services/routes';

export const NotificationsView = () => {
  const dispatch = useDispatch();

  const visible = useSelector(getDrawerVisible);
  const user = useSelector(getUser);
  const workspaces = useSelector(getAllWorkspaces);
  const workspace = useSelector(getActiveWorkspace);
  const personalWorkspace = useSelector(getPersonalWorkspace);
  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);

  const location = useLocation();

  const [afterVisibleChange, setAfterVisibleChange] = useState(false);

  const routes = useRoutesService();

  const { t } = useTranslation('NotificationsBell');

  const activeWorkspace = workspace || personalWorkspace;

  const onClose = useCallback(() => dispatch(setDrawerVisible(false)), [
    dispatch
  ]);

  const goToSettings = useCallback(() => {
    dispatch(setActiveId({ workspaceId: activeWorkspace.id }));

    routes.toUserWorkspace({
      employeeId: workspaces[activeWorkspace.id].user.id,
      view: NOTIFICATIONS
    });

    onClose();
  }, [dispatch, onClose, routes, activeWorkspace.id, workspaces]);

  useEffect(() => {
    if (visible) {
      onClose();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [location]);

  return (
    <Drawer
      visible={visible}
      onClose={onClose}
      title={
        <>
          <Drawer.Title>{t('FeedHeading')}</Drawer.Title>

          {user.personalWorkspaceId !== activeWorkspace.id && (
            <Button
              type="link"
              style={{ padding: '0 10px', height: 'auto' }}
              onClick={goToSettings}
            >
              <Icon type="setting" size={20} />
            </Button>
          )}
        </>
      }
      destroyOnClose
      width={804}
      bodyStyle={{ padding: '24px 10px 24px 24px', gap: 20 }}
      afterVisibleChange={setAfterVisibleChange}
    >
      <NotificationsWithActions afterVisibleChange={afterVisibleChange} />

      {!isOnlyUserProfile && (
        <NotificationList
          afterVisibleChange={afterVisibleChange}
          view={NOTIFICATIONS}
        />
      )}
    </Drawer>
  );
};

export default NotificationsView;

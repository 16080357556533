import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import { Button, Form, Input } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import { DATE_FORMAT } from 'constants/calendar';

import Icon from 'components/common/icon';
import PhoneInput from 'components/common/controls/phone-input';
import DatePicker from 'components/common/date-picker';
import LocationInput from 'components/common/controls/location-input';

import {
  editContact,
  fetchStatusDuplicateContacts
} from 'store/contacts/actions';
import { editOrderStatusContractorSync } from 'store/order-statuses';

import { useRoutesService } from 'services/routes';
import { validateIsPhone, validateIsRequire } from 'utils/validators';

import styles from './editor-form.module.scss';

const INPUT = 'input';
const DATE_PICKER = 'datePicker';
const PHONE = 'phone';
const EMAIL = 'email';
const LOCATION = 'address';

export const FIELD_TYPES = {
  INPUT,
  DATE_PICKER,
  PHONE,
  EMAIL,
  LOCATION
};

const EditableForm = Form.create()(
  ({
    form,
    name,
    value,
    type,
    allowClear,
    isLoading,
    isMulti,
    onSubmit,
    onClear,
    onCancel
  }) => {
    const { t } = useTranslation('Contacts');

    const { getFieldDecorator } = form;

    const renderFieldType = () => {
      switch (type) {
        case FIELD_TYPES.EMAIL:
          return (
            <Input
              type="email"
              placeholder={t('EnterEmail')}
              data-qa="qa-3m0bxdrgtp18v9o"
            />
          );
        case FIELD_TYPES.PHONE:
          return <PhoneInput data-qa="qa-cmxm7papg37fi4p" />;
        case FIELD_TYPES.LOCATION:
          return <LocationInput />;
        case FIELD_TYPES.DATE_PICKER:
          return (
            <DatePicker
              showTimeSelect={false}
              placeholderText={t('BirthDate')}
              maxDate={new Date()}
              peekNextMonth
              showMonthDropdown
              showYearDropdown
              dropdownMode="select"
              wrapperClassname={styles.datePicker}
            />
          );
        case FIELD_TYPES.INPUT:
        default:
          return <Input placeholder="" />;
      }
    };

    const getRules = () => {
      switch (type) {
        case FIELD_TYPES.EMAIL:
          return {
            initialValue: value,
            rules: [validateIsRequire()]
          };
        case FIELD_TYPES.LOCATION:
          return {
            initialValue: value,
            rules: [validateIsRequire()]
          };
        case FIELD_TYPES.PHONE:
          return {
            initialValue: value,
            rules: [{ validator: validateIsPhone }]
          };
        case FIELD_TYPES.DATE_PICKER:
          return {
            initialValue: value
              ? moment(value, DATE_FORMAT).toDate()
              : new Date(),
            valuePropName: 'selected'
          };
        case FIELD_TYPES.INPUT:
        default:
          return {
            initialValue: value
          };
      }
    };

    const renderFields = () => (
      <div className={styles.flexColumn} data-qa="qa-0zfy8xe5rgwm950">
        <Form.Item>
          {getFieldDecorator('lastName', {
            initialValue: value.lastName
          })(
            <Input
              autoFocus
              placeholder={t('EnterLastName')}
              maxLength={35}
              data-qa="qa-m216n7me2bccqk4"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('firstName', {
            initialValue: value.firstName
          })(
            <Input
              placeholder={t('EnterName')}
              maxLength={35}
              data-qa="qa-z8uz5bvluvh8lfd"
            />
          )}
        </Form.Item>
        <Form.Item>
          {getFieldDecorator('middleName', {
            initialValue: value.middleName
          })(
            <Input
              placeholder={t('EnterMiddleName')}
              maxLength={35}
              data-qa="qa-es6wk0gpk5ubhqj"
            />
          )}
        </Form.Item>
      </div>
    );

    const renderField = () => (
      <Form.Item style={{ marginBottom: 0, flexGrow: 1 }}>
        {getFieldDecorator(name, getRules())(renderFieldType())}
      </Form.Item>
    );

    const formatedValues = values => {
      if (type === FIELD_TYPES.DATE_PICKER) {
        return {
          ...values,
          [name]: moment(values[name]).format(DATE_FORMAT)
        };
      }

      return values;
    };

    const onSave = event => {
      event.preventDefault();

      form.validateFields(async (err, values) => {
        if (!err) {
          onSubmit(formatedValues(values));
        }
      });
    };

    return (
      <Form
        className={styles.editableFields}
        data-qa="qa-djmtyqcki8uf22h"
        onSubmit={onSave}
        colon={false}
        hideRequiredMark
      >
        <div className={styles.formInner} data-qa="qa-c6e1ltpt4ry37pv">
          {isMulti ? renderFields() : renderField()}

          {allowClear && (
            <Button
              type="link"
              shape="circle"
              onClick={() => onClear(name)}
              data-qa="qa-4625oq2tp03kg4a"
            >
              <Icon
                type="close"
                size={20}
                style={{ justifyContent: 'flex-end' }}
              />
            </Button>
          )}
        </div>

        <div
          style={{ display: 'flex', justifyContent: 'space-between' }}
          data-qa="qa-7qleqc3pz6bjdqf"
        >
          <Button
            type="primary"
            data-qa="qa-0dkytie65cuh1wt"
            loading={isLoading}
            style={{ flexGrow: 1 }}
            htmlType="submit"
          >
            {t('SaveBtn')}
          </Button>

          <Button
            type="link"
            className={styles.btnCancel}
            data-qa="qa-usz2e89ebraz3oe"
            style={{ flexGrow: 1 }}
            onClick={() => onCancel(name)}
          >
            {t('CancelBtn')}
          </Button>
        </div>
      </Form>
    );
  }
);

EditableForm.propTypes = {
  name: PropTypes.string,
  value: PropTypes.any,
  allowClear: PropTypes.bool,
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  isMulti: PropTypes.bool,
  onCancel: PropTypes.func,
  onSubmit: PropTypes.func,
  onClear: PropTypes.func
};

EditableForm.defaultProps = {
  name: undefined,
  value: undefined,
  allowClear: false,
  type: FIELD_TYPES.INPUT,
  isLoading: false,
  isMulti: false,
  onCancel: () => {},
  onSubmit: () => {},
  onClear: () => {}
};

export const EditableFields = ({
  children,
  defaultEditable,
  allowEdit,
  isMulti,
  orderStatusId,
  contactId,
  clearEditable,
  ...fieldProps
}) => {
  const dispatch = useDispatch();
  const routes = useRoutesService();

  const [editable, setEditable] = useState(defaultEditable);
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Common');

  useEffect(() => {
    setEditable(defaultEditable);
  }, [defaultEditable]);

  const onSave = async values => {
    setIsLoading(true);

    try {
      await dispatch(editContact({ id: contactId, values }));

      if (orderStatusId) {
        dispatch(
          editOrderStatusContractorSync({
            id: orderStatusId,
            values
          })
        );
      }

      await dispatch(fetchStatusDuplicateContacts());

      setEditable(false);
      clearEditable();
    } catch (error) {
      const { status, data } = error.response || {};
      if (status === 409) {
        if (!data.isArchived) {
          routes.toContact({
            id: data.contactId,
            state: { isDuplicate: true }
          });
          setEditable(false);
          clearEditable();
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onClear = field => onSave({ [field]: null });

  const onCancel = async field => {
    if (defaultEditable && !fieldProps.value) {
      await onClear(field);
    }

    setEditable(false);
    clearEditable();
  };

  if (editable) {
    return (
      <EditableForm
        {...fieldProps}
        isMulti={isMulti}
        isLoading={isLoading}
        onCancel={onCancel}
        onClear={onClear}
        onSubmit={onSave}
        data-qa="qa-f6gwrhfgzyuapu1"
      />
    );
  }

  return (
    <div
      className={classnames(styles.editField, {
        [styles.editable]: editable,
        [styles.active]: allowEdit
      })}
      onDoubleClick={() => setEditable(allowEdit)}
      title={allowEdit ? t('DoubleClickToEdit') : ''}
    >
      {children}

      {allowEdit && (
        <Button
          type="link"
          shape="circle"
          className={styles.btnEdit}
          onClick={() => setEditable(true)}
        >
          <Icon type="edit" />
        </Button>
      )}
    </div>
  );
};

EditableFields.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  isMulti: PropTypes.bool,
  defaultEditable: PropTypes.bool,
  allowEdit: PropTypes.bool,
  clearEditable: PropTypes.func
};

EditableFields.defaultProps = {
  children: undefined,
  isMulti: false,
  defaultEditable: false,
  allowEdit: false,
  clearEditable: () => {}
};

export default EditableFields;

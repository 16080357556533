import React, { useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Divider, Skeleton, Tooltip } from 'antd';
import classnames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  ACCEPTED_STATE_ACTUAL,
  ACCEPTED_STATE_DECLINED,
  CANCELLED,
  COMPLETED,
  DATE_TIME_FORMAT,
  IN_PROGRESS,
  REVIEW,
  TYPE_ASSET,
  TYPE_ORDER_STATUS
} from 'constants/index';

import EventStatus from 'components/common/event-status';
import CopyLink from 'components/common/copy-link';
import Typography from 'components/common/typography';
import DetailsField from 'components/common/details-field';
import Markdown from 'components/common/markdown';
import Attachments from 'components/common/attachments';
import { TagList } from 'components/common/tags';
import {
  getFormattedDate,
  getOrderStatusStates
} from 'components/orders-view/utils';
import { AttachmentSkeleton } from 'components/common/skeletons';
import ChangingResponsibleModal from 'components/orders-view/modals/changing-responsible';
import ChangingSignatoryModal from 'components/orders-view/modals/changing-signatory';
import Actions from 'components/orders-view/actions';
import AssetList from 'components/common/assets/list';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Reminder from 'components/common/reminder';

import { getIsOrderStatusOutdated } from 'utils/get-is-outdated';
import useRoutesService from 'services/routes';

import IconId from '../icon-id';
import UserRole from '../user-role';
import InfoButtons from '../info-buttons';
import CancellationReason from '../cancellation-reason';
import Tasks from '../tasks';
import useActions from '../status-actions/use-actions';
import SchedulerTooltip from '../scheduler-tooltip';

import styles from './info.module.scss';

const FILES_COUNT_WITH_SCROLL = 4;

export const Info = ({
  orderStatus,
  isCalendarPreview,
  isLoadingPeriodicSpec
}) => {
  const { reopenAct, isResponsible, isLoading } = useActions({
    orderStatus
  });

  const routes = useRoutesService({ returnUrl: true });

  const { t } = useTranslation(['Order', 'FunnelSettings']);

  const declinedAct =
    (orderStatus.acts || []).length &&
    orderStatus.acts[orderStatus.acts.length - 1].state ===
      ACCEPTED_STATE_DECLINED
      ? orderStatus.acts[orderStatus.acts.length - 1]
      : null;

  const canShowScheduleInfo =
    orderStatus &&
    orderStatus.specification &&
    orderStatus.specification.scheduler &&
    orderStatus.specification.state === ACCEPTED_STATE_ACTUAL &&
    (orderStatus.state === IN_PROGRESS || orderStatus.state === REVIEW);

  const [
    visibleChangingResponsibleModal,
    setVisibleChangingRespinsibleModal
  ] = useState(false);
  const [
    visibleChangingSignatoryModal,
    setVisibleChangingSignatoryModal
  ] = useState(false);

  const {
    title,
    state,
    orderId,
    contractorId,
    contractor,
    responsibleId,
    responsible,
    signatoryId,
    signatory,
    createdAt,
    location,
    locationExact,
    startAt,
    deadlineAt,
    content,
    id,
    fileList, // it's file ids
    price,
    currency,
    kind,
    documentList, // all files
    permissions,
    relations = [],
    bookings = []
  } = orderStatus;

  const attachedFiles = (documentList || []).filter(file =>
    fileList.includes(file.fileId)
  );
  const isLoadingFileList = fileList.length ? !attachedFiles.length : false;

  const isOutdated = getIsOrderStatusOutdated(orderStatus);

  const isCancelled = state === CANCELLED;
  const isComleted = state === COMPLETED;

  const {
    canChangeSignatory,
    canChangeResponsible,
    canAssignRelation
  } = permissions;
  const allowChangeResponsible =
    canChangeResponsible && !isComleted && !isCancelled;
  const allowChangeSignatory =
    canChangeSignatory && !isComleted && !isCancelled;

  // The online order automatically created for the second party does not have a signatory
  useEffect(() => {
    if (!signatoryId && !isCancelled) {
      setVisibleChangingSignatoryModal(true);
    }
  }, [isCancelled, signatoryId]);

  return (
    <>
      <div className={styles.root}>
        <div className={styles.header}>
          <div className={styles.top}>
            <IconId orderId={orderId} contractor={contractor} />

            <CopyLink
              link={window.location.href}
              label={t('CopyOrderLinkTip')}
              className={styles.copyLink}
            />

            <EventStatus
              activeStatus={state}
              statuses={getOrderStatusStates(kind)}
            />

            {!isCalendarPreview && (
              <Actions
                orderStatus={orderStatus}
                btnClassName={styles.actions}
              />
            )}
          </div>

          {isCancelled && <CancellationReason orderStatusId={orderStatus.id} />}
        </div>

        <div className={styles.info}>
          <Typography.Title
            level={3}
            color={isOutdated ? 'red' : 'black'}
            className={styles.title}
          >
            {title}
          </Typography.Title>

          {!isCalendarPreview && <InfoButtons orderStatus={orderStatus} />}

          <div className={styles.fieldsWrap}>
            <DetailsField iconType="calendar" title={t('OrderCreatedDate')}>
              {getFormattedDate(createdAt)}
            </DetailsField>

            {location && (
              <DetailsField iconType="environment" title={t('OrderAddress')}>
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${location}`}
                  target="_blank"
                  rel="noreferrer"
                >{`${location} ${locationExact || ''}`}</a>
              </DetailsField>
            )}

            {startAt && (
              <DetailsField iconType="calendar" title={t('OrderStartDate')}>
                {getFormattedDate(startAt)}
              </DetailsField>
            )}

            <DetailsField iconType="calendar" title={t('OrderDueDate')}>
              <Typography.Text color={isOutdated ? 'red' : 'black'}>
                {getFormattedDate(deadlineAt)}
              </Typography.Text>
            </DetailsField>

            <Reminder
              entityId={orderStatus.id}
              entityType={TYPE_ORDER_STATUS}
              textReminder={orderStatus.title}
            />

            {[...Array(2).keys()].map(key => (
              <Skeleton
                key={`scheduler-skeleton-${key}`}
                active
                paragraph={false}
                loading={isLoadingPeriodicSpec}
              />
            ))}

            {canShowScheduleInfo && !isLoadingPeriodicSpec && (
              <div className={styles.schedulerInfo}>
                <Icon
                  type="retweet"
                  size={16}
                  color="black-55"
                  className={styles.icon}
                />

                <div className={styles.actInfo}>
                  <Tooltip
                    title={() => (
                      <SchedulerTooltip
                        specification={orderStatus.specification}
                        state={orderStatus.state}
                      />
                    )}
                    mouseEnterDelay={0.5}
                  >
                    <Button type="link" className={styles.btn}>
                      {t('PeriodActDesc', {
                        date:
                          orderStatus.state !== REVIEW
                            ? moment(
                                orderStatus.specification.scheduler.nextDate
                              ).format(DATE_TIME_FORMAT)
                            : ''
                      })}
                    </Button>
                  </Tooltip>
                </div>
              </div>
            )}

            {declinedAct &&
              !isLoadingPeriodicSpec &&
              orderStatus.specification &&
              orderStatus.specification.scheduler &&
              isResponsible &&
              orderStatus.state === IN_PROGRESS &&
              !orderStatus.isLocked && (
                <Button
                  onClick={() =>
                    reopenAct({
                      id: orderStatus.id,
                      contractId: orderStatus.contract,
                      actId: declinedAct.id
                    })
                  }
                  type="secondary"
                  loading={isLoading}
                >
                  {t('ReissueRejectedAct')}
                </Button>
              )}
          </div>

          {content && <Markdown className={styles.content}>{content}</Markdown>}

          {isLoadingFileList ? (
            <div
              className={classnames(styles.attachments, {
                [styles.withScroll]: fileList.length >= FILES_COUNT_WITH_SCROLL
              })}
            >
              {fileList.map(() => (
                <AttachmentSkeleton />
              ))}
            </div>
          ) : (
            <Attachments
              fileList={attachedFiles}
              actionsDeps={{
                sendCopyToComment: false,
                orderId,
                statusId: id
              }}
              className={classnames(styles.attachments, {
                [styles.withScroll]: fileList.length >= FILES_COUNT_WITH_SCROLL
              })}
              showDownloadAllButton
            />
          )}

          {!!price && (
            <div className={styles.priceWrap}>
              <Typography.Title
                level={2}
                weight="bold"
                className={styles.price}
              >
                {price} {currency}
              </Typography.Title>
            </div>
          )}

          <Divider style={{ margin: '0 0 12px' }} />

          <div className={styles.users}>
            {!isLoadingPeriodicSpec && (
              <UserRole
                label={t('Contractor')}
                user={contractor}
                isContractor
                link={routes.toContact({ id: contractorId })}
              />
            )}

            <UserRole
              label={t('OrderResponsible')}
              user={responsible}
              link={routes.toEmployee(responsibleId)}
              allowChange={allowChangeResponsible}
              onClickChanging={() => setVisibleChangingRespinsibleModal(true)}
            />

            {signatoryId && (
              <UserRole
                label={t('Signer')}
                labelTooltip={t('SignersTip', { ns: 'FunnelSettings' })}
                user={signatory}
                link={routes.toEmployee(signatoryId)}
                allowChange={allowChangeSignatory}
                onClickChanging={() => setVisibleChangingSignatoryModal(true)}
              />
            )}
          </div>

          {/* <Estimate
            id={orderStatus.id}
            orderStatus={orderStatus}
            workLog={orderStatus.workLog}
            workLogTotal={orderStatus.workLogTotal}
            canUpdateOrderWorklog={
              orderStatus.permissions.canUpdateOrderWorklog
            }
          /> */}

          {!isCalendarPreview && (
            <>
              <Divider style={{ margin: '12px 0 ' }} />

              <Typography.Title level={3} className={styles.title}>
                {t('TagsHeading')}
              </Typography.Title>

              <TagList
                entityType={TYPE_ORDER_STATUS}
                entityId={id}
                needFetchTags
              />

              <Divider style={{ margin: '12px 0' }} />

              <Tasks
                orderStatus={orderStatus}
                canCreateTask={canAssignRelation}
              />

              <Divider style={{ margin: '12px 0' }} />

              <AssetList
                assets={relations.filter(r => r.type === TYPE_ASSET)}
                entityData={{
                  id,
                  title,
                  type: TYPE_ORDER_STATUS,
                  allowEditDates: false,
                  allowBooking: true,
                  dateStart: startAt,
                  dateEnd: deadlineAt
                }}
                bookings={bookings}
              />
            </>
          )}
        </div>
      </div>

      <ChangingResponsibleModal
        visible={visibleChangingResponsibleModal}
        orderStatusId={orderStatus.id}
        defaultValue={responsible}
        onClose={() => setVisibleChangingRespinsibleModal(false)}
      />

      <ChangingSignatoryModal
        visible={visibleChangingSignatoryModal}
        orderStatusId={orderStatus.id}
        defaultValue={signatory}
        onClose={() => setVisibleChangingSignatoryModal(false)}
      />
    </>
  );
};

Info.propTypes = {
  orderStatus: PropTypes.shape({}).isRequired,
  isCalendarPreview: PropTypes.bool
};

Info.defaultProps = {
  isCalendarPreview: false
};

export default Info;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import RejectionModal from 'components/common/modals/rejection';

import {
  cancelOrderStatusRequest,
  fetchOrderStatus,
  fetchOrderStatusAllActs
} from 'store/order-statuses';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const OrderCancelActModal = ({
  visible,
  orderStatus,
  onClose,
  resetReordering
}) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ reason, fileList }) => {
      const { id, request, contract } = orderStatus;

      await dispatch(
        cancelOrderStatusRequest({
          id,
          requestId: request.id,
          reason,
          fileList
        })
      );

      await dispatch(fetchOrderStatus({ id }));

      await dispatch(
        fetchOrderStatusAllActs({
          id,
          contractId: contract
        })
      );

      showNoticeMessage({
        number: NOTICE_NUMBER.orderStatusCancelAct
      });
    },
    [dispatch, orderStatus]
  );

  const closeModal = () => {
    onClose();
    resetReordering();
  };

  return (
    <RejectionModal
      visible={visible}
      onSubmit={onSubmit}
      onClose={closeModal}
      callback={onClose}
      dataTestId="orders-cancel-act-modal"
    />
  );
};

OrderCancelActModal.propTypes = {
  visible: PropTypes.bool,
  orderStatus: PropTypes.shape({
    id: PropTypes.string,
    request: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  onClose: PropTypes.func.isRequired,
  resetReordering: PropTypes.func
};

OrderCancelActModal.defaultProps = {
  orderStatus: {},
  visible: false,
  resetReordering: () => {}
};

export default OrderCancelActModal;

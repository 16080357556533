import React, { memo, useLayoutEffect } from 'react';
import marked from 'marked';
import classnames from 'classnames';
import hljs from 'highlight.js/lib/core';
import 'highlight.js/styles/atom-one-light.css';
import javascript from 'highlight.js/lib/languages/javascript';
import python from 'highlight.js/lib/languages/python';
import { unescape } from 'lodash';

import themeVariables from 'theme';
import sanitizeHTML from 'utils/sanitize-html';

import { MENTION_REGEX } from './helpers';

import styles from './markdown.module.scss';

hljs.registerLanguage('javascript', javascript);
hljs.registerLanguage('python', python);

const renderer = new marked.Renderer();

renderer.link = (href, title, text) => {
  const hasProtocol = /^(?:\w+:)?\/\//.test(href);
  const modifiedHref = !hasProtocol ? `https://${href}` : href;

  const mention = `@[${text}](${href})`;
  const match = mention.match(MENTION_REGEX);

  if (match && !hasProtocol) {
    return `<a target="_blank" class="mention-link">${`@${text}`}</a>`;
  }

  const titleAttr = title ? `title="${title}"` : '';

  return `<a target="_blank" ${titleAttr} href="${modifiedHref}">${text}</a>`;
};

renderer.codespan = code => {
  const hljsCode = hljs.highlightAuto(unescape(code));

  return `<span class="code-hljs">${hljsCode.value}</span>`;
};

renderer.hr = () =>
  `<hr color="${themeVariables.color['black-85']}" size="1px" />`;

renderer.paragraph = content => {
  if (content.includes('@<a')) {
    // eslint-disable-next-line no-param-reassign
    content = content.replace(/@<a/g, '<a');
  }

  return `<div style="white-space: break-spaces;">${content.replace(
    /\u2028/g,
    ' '
  )}</div>`;
};

renderer.blockquote = content =>
  `<div style="
    margin: 12px 0 12px 10px;
    border-left: 4px solid ${themeVariables.color['black-85']};
    padding-left: 6px;"
  >
    ${content.replace(/\u2028/g, ' ')}
  </div>`;

renderer.listitem = content => {
  if (content.includes('@<a')) {
    // eslint-disable-next-line no-param-reassign
    content = content.replace(/@<a/g, '<a');
  }

  return `<li style="text-align: left">${content.replace(/\u2028/g, ' ')}</li>`;
};

export const getMarkdownHtml = ({ children, santizeProps, props }) => {
  const contentWithBr = children.replace(
    /\n(?=\n)/g,
    '\n\n<hr style="visibility:hidden; margin: 4px" />\n'
  );

  const rawMarkup = marked(contentWithBr, {
    renderer,
    ...props
  });

  return sanitizeHTML(rawMarkup, santizeProps);
};

export const Markdown = memo(
  ({ children, className, santizeProps, ...props }) => {
    useLayoutEffect(() => {
      document.querySelectorAll('code:not(.hljs)').forEach(block => {
        hljs.highlightBlock(block);
      });
    }, []);

    // eslint-disable-next-line react/no-danger
    return (
      <span
        // eslint-disable-next-line react/no-danger
        dangerouslySetInnerHTML={{
          __html: getMarkdownHtml({ children, santizeProps, props })
        }}
        className={classnames(className, styles.root)}
      />
    );
  }
);

export default Markdown;

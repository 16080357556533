import { createAction } from 'redux-actions';
import api, { apiV2 } from 'api';

import {
  getClearOrderingKey,
  getOrderingDirectionByKey
} from 'components/common/actions/utils';

import { fetchTagsByEntity } from 'store/tags';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId, getHasUser, getUserEmployee } from '../workspace';
import { getRouterUrlSubmenu } from '../router/selectors';
import {
  getItemsPerPage,
  getFilterEndDateRangeOrders,
  getFilterCategoriesOrders,
  getFilterEmployeesOrders,
  getFilterCounterparts,
  getFilterCounterpartWorkspaces,
  getFilterSearchOrders,
  getIsCustomerFilter,
  getFilterSubStatus,
  getFilterIsOutdated,
  getFilterOrderingOrders,
  getFilterPaid,
  getFilterControllers,
  getFilterStatus,
  getFilterIsFavorite,
  getFilterOrders,
  getOrderStatusesEntries,
  getOrderStatusesItemsPerPage,
  getOrderStatusesFilter
} from './selectors';
import {
  ITEMS_PER_PAGE,
  STATUS_COMPLETED,
  ALL,
  TYPE_ORDER,
  TYPE_ORDER_STATUS,
  ORDER_STATUS_ORDERING_OPTIONS,
  DEADLINE_AT,
  ORDER_STATUS_STATES
} from '../../constants';
import mapValue from '../../utils/map-value';
import getFileIds from '../../hooks/common/use-file-upload/get-file-ids';
import transformDateEnd from '../../utils/transform-date-end';

// Пока что используем только COMPLETED,
// Но подстатусы еше есть так же и в DECLINED
const getSubStatusByStatus = (status, subStatus) => {
  if (
    (status || []).length === 1 &&
    (status || []).includes(STATUS_COMPLETED)
  ) {
    return subStatus;
  }

  return undefined;
};

export const getOrders = ({
  access = 'full',
  employee,
  status,
  isEmployeeView,
  getState,
  dispatch
}) => {
  const state = getState();
  const currentStatus = isEmployeeView
    ? status
    : getFilterStatus(state).map(mapValue);

  let subStatus = getFilterSubStatus(state).value;
  subStatus = getSubStatusByStatus(currentStatus, subStatus);

  const workspaceId = getWorkspaceId(state);
  const itemsPerPage = getItemsPerPage(state);
  const offset = getOrderStatusesEntries(state).length;
  const search = getFilterSearchOrders(state);

  const {
    asset = [],
    contact: contactFilter,
    createDateRange = {},
    startDateRange = {},
    completedDateRange = {},
    members = [],
    tag,
    order,
    task,
    channelChat
    // kind
  } = getFilterOrders(state);

  const contact = (contactFilter || []).map(mapValue);
  const categories = (getFilterCategoriesOrders(state) || []).map(mapValue);

  const endDateRange = (getFilterEndDateRangeOrders(state) || {}).value;

  const employees = employee
    ? [employee]
    : (getFilterEmployeesOrders(state) || []).map(mapValue);
  const counterparts = (getFilterCounterparts(state) || []).map(mapValue);
  const controllers = (getFilterControllers(state) || []).map(mapValue);
  const counterpartWorkspaces = (
    getFilterCounterpartWorkspaces(state) || []
  ).map(mapValue);
  const isCustomer = getIsCustomerFilter(state);
  const isOutdated = getFilterIsOutdated(state);
  const paid = getFilterPaid(state).map(mapValue);
  const ordering = getFilterOrderingOrders(state).key;
  const isFavorite = getFilterIsFavorite(state);

  const hasTags = !!tag.ids.length;
  const tagsCondition = tag.condition.value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

  return api.orderStatuses
    .fetch(
      workspaceId,
      contact,
      currentStatus,
      offset,
      itemsPerPage,
      search,
      categories,
      employees,
      endDateRange,
      counterparts,
      counterpartWorkspaces,
      isCustomer,
      access,
      subStatus,
      controllers,
      isOutdated,
      ordering,
      paid,
      undefined, // kind
      isFavorite,
      asset.map(mapValue),
      createDateRange.value || {},
      startDateRange.value || {},
      completedDateRange.value || {},
      members.map(mapValue),
      tagsCondition,
      tagsIds,
      order.map(mapValue),
      task.map(mapValue),
      channelChat.map(mapValue)
    )
    .then(res => {
      const orderStatusIds = res.data.results.map(
        orderStatus => orderStatus.id
      );

      if (orderStatusIds) {
        dispatch(
          fetchTagsByEntity({
            entityType: TYPE_ORDER,
            entityIds: orderStatusIds
          })
        );
      }

      return {
        ...res.data,
        results: res.data.results,
        totalItems: res.data.count,
        filter: status,
        search
      };
    });
};

export const fetchOrders = createActionThunk(
  'order-statuses/fetch-orders',
  getOrders
);

// Используется для внутреннего использования, не для reducer
export const fetchOrdersLocal = createActionThunk(
  'order-statuses/fetch-local',
  ({
    search,
    status,
    employee,
    offset,
    access,
    limit = ITEMS_PER_PAGE,
    categories = [],
    endDateRange = [],
    counterparts = [],
    counterpartWorkspaces = [],
    isCustomer = null,
    contact,
    getState
  }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orderStatuses
      .fetch(
        workspaceId,
        contact,
        status,
        offset,
        limit,
        search,
        categories,
        employee,
        endDateRange,
        counterparts,
        counterpartWorkspaces,
        isCustomer,
        access
      )
      .then(({ data }) => ({
        ...data,
        entries: data.results,
        totalItems: data.count,
        filter: status,
        search
      }));
  }
);

export const fetchOrder = createActionThunk(
  'order-statuses/fetch-order',
  ({ statusId, show400, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orderStatuses.fetchOne(statusId, workspaceId, show400);
  }
);

export const fetchOrderLocal = createActionThunk(
  'order-statuses/fetch-order-local',
  ({ statusId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orderStatuses.fetchOne(statusId, workspaceId);
  }
);

export const fetchOneByOrderId = createActionThunk(
  'order-statuses/fetch-one-by-order-id',
  ({ orderId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orderStatuses.fetchOneByOrderId({ workspaceId, orderId });
  }
);

export const fetchOrderStatusesFilter = createActionThunk(
  'order-statuses/fetch-filter',
  ({ filterBy, status, search, offset, access, getState }) => {
    const state = getState();
    const statusItem = status === `orders/${ALL}` ? null : status;
    const workspaceId = getWorkspaceId(state);
    const limit = getItemsPerPage(state);

    const categories = (getFilterCategoriesOrders(state) || []).map(mapValue);
    const endDateRange = getFilterEndDateRangeOrders(state).value;
    const employees = (getFilterEmployeesOrders(state) || []).map(mapValue);
    const isCustomer = getIsCustomerFilter(state);

    return api.orderStatuses
      .fetchFilter(
        workspaceId,
        filterBy,
        statusItem,
        offset,
        limit,
        search,
        categories,
        employees,
        endDateRange,
        isCustomer,
        access
      )
      .then(({ data }) => ({
        ...data,
        entries: data.results,
        totalItems: data.count
      }));
  }
);

// для создания и копирования заказа
export const onCreateOrder = ({ order, type, getState }) => {
  const state = getState();
  const filter = getRouterUrlSubmenu(state);
  const isCustomer = getHasUser(state)(order.customer);

  return api.orders
    .create(order)
    .then(res => ({ ...res.data, filter, isCustomer, type }));
};

export const createOrder = createActionThunk(
  'order-statuses/create-order',
  onCreateOrder
);

export const editOrder = createActionThunk(
  'order-statuses/edit-order',
  ({ orderStatus, statusId, isTaskModalShowed = null, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const updatedStatus = {
      ...orderStatus,
      id: statusId || orderStatus.id,
      isTaskModalShowed,
      order: {
        ...(orderStatus.order || {}),
        category: undefined,
        categoryNameFirst: undefined,
        categoryNameSecond: undefined,
        dateEnd:
          (orderStatus.order || {}).dateEnd &&
          transformDateEnd(
            (orderStatus.order || {}).dateEnd,
            (orderStatus.order || {}).dateStart
          )
      }
    };

    return api.orders
      .updateFields(updatedStatus, workspaceId)
      .then(({ data }) => data);
  }
);

export const archiveOrder = createActionThunk(
  'order-statuses/delete-order',
  ({ order, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orders.archive(order.order.id, workspaceId);
  }
);

export const remove = createActionThunk(
  'order-statuses/remove',
  ({ orderStatusId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses.remove({ orderStatusId, workspaceId });
  }
);

export const handleFavoriteOrder = createActionThunk(
  'order-statuses/handle-favorite',
  ({ order, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .handleFavorite(order, workspaceId)
      .then(({ data }) => data);
  }
);

export const createFeedbacks = createActionThunk(
  'order-statuses/create-feedback',
  ({ statusId, orderId, toEmployee, rate, content, fileList, getState }) => {
    const workspaceId = getWorkspaceId(getState());
    const fileListIds = getFileIds(fileList);

    return api.dealFeedbacks
      .createFeedback(
        workspaceId,
        orderId,
        toEmployee,
        rate,
        content,
        fileListIds
      )
      .then(() => ({ statusId }));
  }
);

export const createDeal = createActionThunk(
  'order-statuses/deal-set',
  ({ contractorStatusId, statusId, contractor, negotiation, getState }) => {
    // statusId - используется для фильтрации чатов
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .createDeal(workspaceId, contractorStatusId, negotiation)
      .then(({ data }) => ({
        ...data,
        chatId: contractorStatusId,
        statusId,
        contractor
      }));
  }
);

export const fillNegotiation = createActionThunk(
  'order-statuses/fill-negotiation',
  ({ data }) =>
    api.actions.fillNegotiation(data).then(({ data: resData }) => resData)
);

export const addNegotiation = createActionThunk(
  'order-statuses/add-negotiation',
  ({ data, id, isFirstNegotiate, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .addNegotiation(workspaceId, id, data)
      .then(({ data: response }) => ({ ...response, isFirstNegotiate }));
  }
);

export const fetchSchedulerConfig = createActionThunk(
  'order-statuses/fetch-scheduler-config',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .fetchSchedulerConfig(workspaceId, id)
      .then(({ data }) => ({
        config: data,
        statusId: id
      }));
  }
);

export const addSchedulerAct = createActionThunk(
  'order-statuses/add-scheduler-act',
  ({ config, id, withoutAccept, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .addSchedulerAct(workspaceId, id, config)
      .then(({ data }) => ({ data, withoutAccept }));
  }
);

export const changeSchedulerActLocal = createActionThunk(
  'order-statuses/change-scheduler-act',
  ({ statusId, actId, data, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses.changeSchedulerAct(
      workspaceId,
      statusId,
      actId,
      data
    );
  }
);

export const deleteSchedulerActLocal = createActionThunk(
  'order-statuses/delete-scheduler-act',
  ({ statusId, actId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses.deleteSchedulerAct(workspaceId, statusId, actId);
  }
);

export const addUsersNegotiation = createActionThunk(
  'order-statuses/add-users-negotiation',
  ({ data, id, isFirstNegotiate, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .addUsersNegotiation(workspaceId, id, data)
      .then(({ data: response }) => ({ ...response, isFirstNegotiate }));
  }
);

export const previewSystemSpecification = createActionThunk(
  'order-statuses/preview-sytem-specification',
  ({ specification, orderStatusId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses.previewSystemSpecification({
      workspaceId,
      specification,
      orderStatusId
    });
  }
);

export const previewAct = createActionThunk(
  'order-statuses/preview-act',
  ({ id, act, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .previewAct({
        workspaceId,
        id,
        act
      })
      .then(({ data }) => data);
  }
);

export const changeAct = createActionThunk(
  'orders/order-statuses/change-act',
  ({ id, contractId, actId, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .changeAct({
        workspaceId,
        id,
        contractId,
        actId,
        values
      })
      .then(({ data }) => data);
  }
);
export const fetchPaymentlogList = createActionThunk(
  'order-statuses/fetch-paymentlog-list',
  ({ chatId, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.chatStatuses
      .fetchPaymentlogList(workspaceId, chatId)
      .then(({ data }) => data.results);
  }
);

export const createPaymentlog = createActionThunk(
  'order-statuses/create-paymentlog',
  ({ statusId, chatId, data, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.chatStatuses
      .createPaymentlog(workspaceId, chatId, data)
      .then(({ data: res }) => ({ ...res, statusId }));
  }
);

export const updatePaymentlog = createActionThunk(
  'order-statuses/update-paymentlog',
  ({ statusId, diff, chatId, id, data, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.chatStatuses
      .updatePaymentlog(workspaceId, chatId, id, data)
      .then(({ data: res }) => ({ ...res, diff, statusId }));
  }
);

export const deletePaymentlog = createActionThunk(
  'order-statuses/delete-paymentlog',
  ({ chatId, statusId, value, id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.chatStatuses
      .deletePaymentlog(workspaceId, chatId, id)
      .then(() => ({ value, statusId }));
  }
);

// конвертация заказа из онлайн в оффлайн

export const customerNotify = createActionThunk(
  'order-statuses/customer-notify',
  ({ id, phone, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses.customerNotify(workspaceId, id, phone);
  }
);

export const convertExtended = createActionThunk(
  'order-statuses/convert-extended',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses.convertExtended(workspaceId, id);
  }
);

export const createWorklog = createActionThunk(
  'order-statuses/create-worklog',
  ({ id, value, description, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .createWorklog(workspaceId, id, { value, description })
      .then(({ data }) => ({ ...data, id }));
  }
);

export const checkIsCanSmsSubmit = createActionThunk(
  'order-statuses/check-is-can-sms-submit',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .getIsCanSubmitSms(workspaceId, id)
      .then(({ data }) => data);
  }
);

export const submitNotifySms = createActionThunk(
  'order-statuses/submit-notify-sms',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .submitNotifySms(workspaceId, id)
      .then(({ data }) => ({ ...data, id }));
  }
);

export const fetchOrderInfo = createActionThunk(
  'order-statuses/fetch-order-info',
  ({ id }) =>
    api.orderStatuses.getOrderInfo(id).then(({ data }) => ({ ...data, id }))
);

export const fetchWorklogList = createActionThunk(
  'order-statuses/fetch-worklog-list',
  ({ id, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .fetchWorklogList(workspaceId, id)
      .then(({ data }) => ({ ...data, id }));
  }
);

export const deleteWorklog = createActionThunk(
  'order-statuses/delete-worklog',
  ({ id, worklogId, value, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .deleteWorklog(workspaceId, id, worklogId)
      .then(() => ({ orderStatusId: id, worklogId, value }));
  }
);

export const updateWorklog = createActionThunk(
  'order-statuses/update-worklog',
  ({ id, worklogId, value, description, diff, getState }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.orderStatuses
      .updateWorklog(workspaceId, id, worklogId, { value, description })
      .then(({ data }) => ({ ...data, orderStatusId: id, diff }));
  }
);

export const createTemplate = createActionThunk(
  'orders/create-template',
  ({ order, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orders
      .createTemplate({
        ...order,
        workspaceId: order.workspaceId || workspaceId
      })
      .then(({ data }) => data);
  }
);

export const fetchTemplatesLocal = createActionThunk(
  'orders/create-template',
  ({ offset, limit, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orders
      .fetchTemplates({ workspaceId, offset, limit })
      .then(({ data }) => data);
  }
);

export const updateTemplate = createActionThunk(
  'orders/update-template',
  ({ id, order, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orders
      .updateTemplate({ workspaceId, id, order })
      .then(({ data }) => data);
  }
);

export const deleteTemplate = createActionThunk(
  'orders/delete-template',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.orders.deleteTemplate({ workspaceId, id });
  }
);

// CONTROLLERS

export const fetchControllers = createActionThunk(
  'order-statuses/fetch-controllers',
  ({ getState, offset, limit }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ordersControllers
      .fetch({ workspaceId, offset, limit })
      .then(({ data }) => data.results);
  }
);

export const addController = createActionThunk(
  'order-statuses/add-controller',
  ({ getState, employee }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ordersControllers
      .create(workspaceId, { employee })
      .then(({ data }) => data);
  }
);

export const removeController = createActionThunk(
  'order-statuses/remove-controller',
  ({ getState, employees }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.ordersControllers
      .remove(workspaceId, employees)
      .then(({ data }) => data);
  }
);

export const changeOrderStatusAttachments = createAction(
  'order-statuses/change-order-status-attachments'
);

export const setCurrentPage = createAction('order-statuses/set-current-page');
export const clearFilter = createAction('order-statuses/clear-filter');

// V2

export const createOrderStatus = createActionThunk(
  'orders/order-statuses/create',
  ({ values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const employee = getUserEmployee(state);

    return apiV2.orderStatuses
      .create({
        workspaceId,
        values: {
          ...values,
          relations: values.relations || [],
          responsibleId: employee.id
        }
      })
      .then(({ data }) => data);
  }
);

export const editOrderStatus = createActionThunk(
  'orders/order-statuses/edit',
  ({ id, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .edit({
        workspaceId,
        id,
        values
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatus = createActionThunk(
  'orders/order-statuses/fetch-one',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchOne({
        workspaceId,
        id
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusesByIds = createActionThunk(
  'orders/order-statuses/system/fetch',
  ({ ids, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchByIds({
        workspaceId,
        ids
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusLocal = createActionThunk(
  'orders/order-statuses/fetch-one-local',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchOne({
        workspaceId,
        id
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusesContractors = createActionThunk(
  'order-statuses/fetch-contractors',
  ({ ids, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts
      .fetchWithoutCancelling({
        workspaceId,
        ids
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusesResponsibles = createActionThunk(
  'order-statuses/fetch-responsibles',
  ({ ids, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees
      .fetchAll({
        workspaceId,
        isActive: null,
        id: ids
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatuses = createActionThunk(
  'orders/order-statuses/fetch',
  ({ statuses, page, axiosCancelTokenSource, dispatch, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);
    const itemsPerPage = getOrderStatusesItemsPerPage(state);
    const {
      kind,
      ordering,
      search,
      searchKind,
      responsibleIds: responsibleIdsFilter,
      signatoryIds,
      memberIds,
      tag,
      createdAtRange,
      startAtRange,
      deadlineAtRange,
      completedAtRange,
      relations,
      contractorIds: contractorIdsFilter
    } = getOrderStatusesFilter(state);

    const { orderStatus = [], task = [], contact = [], asset = [] } = relations;

    const hasTags = !!tag.ids.length;
    const tagsCondition = tag.condition.value.toUpperCase();
    const tagIds = hasTags ? tag.ids.map(mapValue) : undefined;

    const createdAt = createdAtRange.value || [];
    const startAt = startAtRange.value || [];
    const deadlineAt = deadlineAtRange.value || [];
    const completedAt = completedAtRange.value || [];

    const params = {
      statuses,
      size: itemsPerPage,
      page,
      kind,
      ordering: getClearOrderingKey(ordering.key),
      direction: getOrderingDirectionByKey(ordering.key),
      search,
      searchKind: searchKind.value,
      responsibleIds: responsibleIdsFilter.map(mapValue),
      signatoryIds: signatoryIds.map(mapValue),
      memberIds: memberIds.map(mapValue),
      contractorIds: contractorIdsFilter.map(mapValue),
      tagsCondition,
      tagIds,
      createdAtGte: createdAt[0],
      createdAtLte: createdAt[1],
      startAtGte: startAt[0],
      startAtLte: startAt[1],
      deadlineAtGte: deadlineAt[0],
      deadlineAtLte: deadlineAt[1],
      completedAtGte: completedAt[0],
      completedAtLte: completedAt[1],
      orderStatusIds: orderStatus.map(mapValue),
      taskIds: task.map(mapValue),
      contactIds: contact.map(mapValue),
      assetIds: asset.map(mapValue)
    };

    return apiV2.orderStatuses
      .fetch({
        workspaceId,
        params,
        axiosCancelTokenSource
      })
      .then(({ data }) => {
        if (data._embedded) {
          const {
            orderStatusIds,
            contractorIds,
            responsibleIds
          } = data._embedded.orderStatuses.reduce(
            (acc, curr) => {
              acc.orderStatusIds.push(curr.id);
              acc.contractorIds.push(curr.contractorId);
              acc.responsibleIds.push(curr.responsibleId);

              return acc;
            },
            { orderStatusIds: [], contractorIds: [], responsibleIds: [] }
          );

          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_ORDER_STATUS,
              entityIds: orderStatusIds
            })
          );

          dispatch(
            fetchOrderStatusesContractors({
              ids: contractorIds,
              orderStatusIds
            })
          );

          dispatch(
            fetchOrderStatusesResponsibles({
              ids: responsibleIds,
              orderStatusIds
            })
          );
        }

        return data;
      });
  }
);

export const fetchOrderStatusesLocal = createActionThunk(
  'orders/order-statuses/fetch-local',
  ({ statuses, limit = ITEMS_PER_PAGE, offset = 0, search, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const params = {
      statuses: statuses || Array.from(ORDER_STATUS_STATES).map(([key]) => key),
      size: limit,
      page: Math.ceil(offset / limit),
      ordering: ORDER_STATUS_ORDERING_OPTIONS.find(o => o.key === DEADLINE_AT)
        .key,
      direction: 'ASC',
      search,
      searchKind: ALL.toUpperCase()
    };

    return apiV2.orderStatuses
      .fetch({
        workspaceId,
        params
      })
      .then(({ data }) => ({
        ...data,
        entries: data._embedded ? data._embedded.orderStatuses : [],
        totalItems: data.page.totalElements
      }));
  }
);

export const fetchOrderStatusContractor = createActionThunk(
  'orders/order-statuses/fetch-contractor',
  ({ contractorId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.contacts.fetchOne(contractorId, workspaceId);
  }
);

export const fetchOrderStatusResponsible = createActionThunk(
  'orders/order-statuses/fetch-responsible',
  ({ responsibleId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees
      .fetch(responsibleId, workspaceId)
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusSignatory = createActionThunk(
  'orders/order-statuses/fetch-signatory',
  ({ signatoryId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.employees
      .fetch(signatoryId, workspaceId)
      .then(({ data }) => data);
  }
);

export const acceptOrderStatusLead = createActionThunk(
  'orders/order-statuses/accept',
  ({ id, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .accept({
        workspaceId,
        id,
        values
      })
      .then(({ data }) => data);
  }
);

export const changeOrderStatus = createActionThunk(
  'orders/order-statuses/change',
  ({ id, values, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .change({
        workspaceId,
        id,
        values
      })
      .then(({ data }) => data);
  }
);

export const reopenOrderStatus = createActionThunk(
  'orders/order-statuses/reopen',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .reopen({
        workspaceId,
        id
      })
      .then(({ data }) => data);
  }
);

export const cancelOrderStatus = createActionThunk(
  'orders/order-statuses/reject',
  ({ id, reason, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .cancel({
        workspaceId,
        id,
        reason
      })
      .then(({ data }) => data);
  }
);

export const acceptOrderStatusRequest = createActionThunk(
  'orders/order-statuses/requests/accept',
  ({ id, requestId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .acceptRequest({
        workspaceId,
        id,
        requestId
      })
      .then(({ data }) => data);
  }
);

export const rejectOrderStatusRequest = createActionThunk(
  'orders/order-statuses/requests/reject',
  ({ id, requestId, reason, fileList, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .rejectRequest({
        workspaceId,
        id,
        requestId,
        reason,
        fileList
      })
      .then(({ data }) => data);
  }
);

export const cancelOrderStatusRequest = createActionThunk(
  'orders/order-statuses/requests/cancel',
  ({ id, requestId, reason, fileList, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .cancelRequest({
        workspaceId,
        id,
        requestId,
        reason,
        fileList
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusAttachments = createActionThunk(
  'order-statuses/fetch-attachments',
  ({ params, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const { relatedOrderStatuses, fileIds, ...restParams } = params;

    return api.attachments
      .fetch({
        workspaceId,
        relatedOrderStatuses,
        fileIds,
        ...restParams
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusRequest = createActionThunk(
  'orders/order-statuses/requests/fetch-one',
  ({ id, requestId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchRequest({
        workspaceId,
        id,
        requestId
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusAllRequests = createActionThunk(
  'orders/order-statuses/requests/fetch-all',
  ({ id, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchAllRequests({
        workspaceId,
        id
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusContract = createActionThunk(
  'orders/order-statuses/contracts/fetch',
  ({ id, contractId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchContract({
        workspaceId,
        id,
        contractId
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusSpecification = createActionThunk(
  'orders/order-statuses/contracts/specifications/fetch-one',
  ({ id, contractId, specificationId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchSpecification({
        workspaceId,
        id,
        contractId,
        specificationId
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusAllSpecifications = createActionThunk(
  'orders/order-statuses/contracts/specifications/fetch-all',
  ({ id, contractId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchAllSpecifications({
        workspaceId,
        id,
        contractId
      })
      .then(({ data }) => data);
  }
);

export const completeOrderStatus = createActionThunk(
  'orders/order-statuses/complete',
  ({ id, documentList, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .complete({
        workspaceId,
        id,
        documentList
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusAct = createActionThunk(
  'orders/order-statuses/contracts/acts/fetch-one',
  ({ id, contractId, actId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchAct({
        workspaceId,
        id,
        contractId,
        actId
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusAllActsLocal = createActionThunk(
  'orders/order-statuses/contracts/acts/fetch-all-local',
  ({ id, contractId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchAllActs({
        workspaceId,
        id,
        contractId
      })
      .then(({ data }) => data);
  }
);

export const fetchOrderStatusAllActs = createActionThunk(
  'orders/order-statuses/contracts/acts/fetch-all',
  ({ id, contractId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchAllActs({
        workspaceId,
        id,
        contractId
      })
      .then(({ data }) => data);
  }
);

export const changeOrderStatusResponsible = createActionThunk(
  'orders/order-statuses/change/resposible',
  ({ id, responsibleId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .changeResponsible({
        workspaceId,
        id,
        responsibleId
      })
      .then(({ data }) => data);
  }
);

export const changeOrderStatusSignatory = createActionThunk(
  'orders/order-statuses/change/resposible',
  ({ id, signatoryId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .changeSignatory({
        workspaceId,
        id,
        signatoryId
      })
      .then(({ data }) => data);
  }
);

export const changeMembers = createActionThunk(
  'orders/order-statuses/members/change',
  ({ getState, id, isDelete, employees }) => {
    const workspaceId = getWorkspaceId(getState());

    return apiV2.orderStatuses.changeMembers({
      workspaceId,
      id,
      employees,
      isDelete
    });
  }
);

// RELATIONS
export const fetchOrderStatusRelations = createActionThunk(
  'orders/order-statuses/relations/fetch',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchRelations({ workspaceId, id })
      .then(({ data }) => data.results);
  }
);

export const changeOrderStatusRelations = createActionThunk(
  'orders/order-statuses/relations/change',
  ({ getState, id, relations }) => {
    const workspaceId = getWorkspaceId(getState());

    return apiV2.orderStatuses.changeRelations({ workspaceId, id, relations });
  }
);

export const fetchSchedulerOrderNextDateLocal = createActionThunk(
  'tasks/fetch-scheduled-order-next-date-local',
  ({ getState, startAt, interval, frequency, until }) => {
    const state = getState();

    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .fetchSchedulerOrderNextDate({
        workspaceId,
        startAt,
        interval,
        frequency,
        until
      })
      .then(({ data }) => data);
  }
);

export const reopenOrderStatusAct = createActionThunk(
  'orders/order-statuses/reopen',
  ({ id, contractId, actId, getState }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return apiV2.orderStatuses
      .reopenAct({
        workspaceId,
        id,
        contractId,
        actId
      })
      .then(({ data }) => data);
  }
);

export const setOrderStatusesIsAllLoading = createAction(
  'order-statuses/set-is-all-loading'
);

export const setCollapsedOrderStatusContractorPanel = createAction(
  'order-statuses/set-collapsed-contractor-panel'
);

export const setOpenedOrderStatusSmallList = createAction(
  'order-statuses/set-opened-small-list'
);

export const clearSelectedOrderStatus = createAction(
  'order-statuses/clear-selected'
);

export const clearOrderStatusesEntries = createAction(
  'order-statuses/clear-entries'
);

export const reorderOrderStatusesEntries = createAction(
  'order-statuses/reorder-entries'
);

export const resetReorderingOrderStatusesEntries = createAction(
  'order-statuses/reset-reorder-entries'
);

export const editOrderStatusContractorSync = createAction(
  'order-statuses/edit-contractor'
);

export const removeOrderStatusFromEntries = createAction(
  'order-statuses/remove-from-entries'
);

// FILTERS

export const setOrderStatusesFilterKind = createAction(
  'order-statuses/set-filter/kind'
);

export const setOrderStatusesOrdering = createAction(
  'order-statuses/set-ordering'
);

export const setOrderStatusesFilterResponsibleIds = createAction(
  'order-statuses/set-filter/resposibleIds'
);

export const setOrderStatusesFilterSignatoryIds = createAction(
  'order-statuses/set-filter/signatoryIds'
);

export const setOrderStatusesFilterMemberIds = createAction(
  'order-statuses/set-filter/memberIds'
);

export const setOrderStatusesFilterTag = createAction(
  'order-statuses/set-filter/tag'
);

export const setOrderStatusesFilterCreatedAtRange = createAction(
  'order-statuses/set-filter/createdAtRange'
);

export const setOrderStatusesFilterStartAtRange = createAction(
  'order-statuses/set-filter/startAtRange'
);

export const setOrderStatusesFilterDeadlineAtRange = createAction(
  'order-statuses/set-filter/deadlineAtRange'
);

export const setOrderStatusesFilterCompletedAtRange = createAction(
  'order-statuses/set-filter/completedAtRange'
);

export const setOrderStatusesFilterSearch = createAction(
  'order-statuses/set-filter/search'
);

export const setOrderStatusesFilterRelations = createAction(
  'order-statuses/set-filter/relations'
);

export const setOrderStatusesFilterCompanies = createAction(
  'order-statuses/set-filter/companies'
);

export const setOrderStatusesFilterContractorIds = createAction(
  'order-statuses/set-filter/contractorIds'
);

export const setOrderStatusesFilterSearchKind = createAction(
  'order-statuses/set-filter/searchKind'
);

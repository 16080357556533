import React, { useCallback, useMemo } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Translation, useTranslation } from 'react-i18next';

import { ORDER_STATUS_ORDERING_OPTIONS } from 'constants/index';

import Actions from 'components/common/actions';
import TotalItems from 'components/common/total-items';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import {
  getOrderStatusesOrdering,
  setOrderStatusesOrdering,
  getOrderStatusesPageData
} from 'store/order-statuses';

export const ActionsOrdering = props => {
  const dispatch = useDispatch();

  const ordering = useSelector(getOrderStatusesOrdering);
  const pageData = useSelector(getOrderStatusesPageData);

  const { t } = useTranslation('Common');

  const totalItems = useMemo(
    () =>
      Object.values(pageData).reduce(
        (acc, curr) => acc + curr.totalElements,
        0
      ),
    [pageData]
  );

  const handleOrdering = useCallback(
    (key, sort = '') => {
      const action = ORDER_STATUS_ORDERING_OPTIONS.find(a => a.key === key);

      dispatch(setOrderStatusesOrdering({ ...action, key: `${sort}${key}` }));
    },
    [dispatch]
  );

  return (
    <div style={{ display: 'flex', flexGrow: 2 }}>
      <Actions
        actions={ORDER_STATUS_ORDERING_OPTIONS}
        orderingKey={ordering.key}
        dropdownProps={{ placement: 'bottomLeft' }}
        onClick={handleOrdering}
        data-qa="qa-a0wwqcw87ijx9f7"
        {...props}
      >
        <div style={{ display: 'flex', marginRight: 16 }}>
          <Actions.Sort />

          <div
            style={{ display: 'flex', alignItems: 'center', cursor: 'pointer' }}
            data-qa="qa-qti4l8qkmtlbckt"
          >
            <Typography.Text style={{ marginRight: 4 }}>
              <Translation ns={ordering.ns}>
                {translate => translate(ordering.title)}
              </Translation>
            </Typography.Text>

            <Icon type="arrow" size={20} color="black-55" />
          </div>
        </div>
      </Actions>

      <TotalItems
        count={totalItems}
        plural={[
          t('SingularFormOrder'),
          t('GenitiveFormOrder'),
          t('PluralFormOrder')
        ]}
      />
    </div>
  );
};

export default ActionsOrdering;

import { useEffect, useCallback, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { useWebsocketOperatorContext } from 'providers';
import { useHistory } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { IN_PROGRESS, REVIEW, TYPE_CONTACT } from 'constants/index';

import { Error404Icon, NoAccessIcon } from 'components/common/icons';

import {
  clearSelectedOrderStatus,
  fetchOrderStatus,
  fetchOrderStatusContractor,
  fetchOrderStatusSignatory,
  fetchOrderStatusResponsible,
  fetchOrderStatusAttachments,
  getSelectedOrderStatus,
  fetchOrderStatusRelations,
  fetchOrderStatusContract,
  fetchOrderStatusAllActs,
  fetchOrderStatusSpecification
} from 'store/order-statuses';
import { fetchAssetOrderStatusBookingData } from 'store/calendar';
import { clearEntityChats } from 'store/operator';

const NOT_ACCEPTABLE_ID_ERROR = 'error.not_acceptable_id';

export const useFetchOrderStatus = ({
  orderStatusId,
  allowFetch = false,
  isFromNotification = false // need for refetch orderStatus if details card opened and we click on notification
}) => {
  const dispatch = useDispatch();

  const orderStatus = useSelector(getSelectedOrderStatus);

  const [isLoadingOrderStatus, setIsLoadingOrderStatus] = useState(false);

  const [isLoadingPeriodicSpec, setIsLoadingPeriodicSpec] = useState(false);

  const [errorData, setErrorData] = useState(null);

  const socket = useWebsocketOperatorContext();

  const history = useHistory();

  const { t } = useTranslation('ScreenErrors');

  const { state, updatedAt } = orderStatus || {};

  const hasOrderStatus = !!orderStatus && !isLoadingOrderStatus;

  const allowLoadActsAndSpecification =
    orderStatus &&
    !isLoadingOrderStatus &&
    orderStatus.contract &&
    (state === REVIEW || state === IN_PROGRESS);

  const onFetchOrderStatus = useCallback(async () => {
    try {
      setIsLoadingOrderStatus(true);

      await dispatch(fetchOrderStatus({ id: orderStatusId }));

      setIsLoadingOrderStatus(false); // if place this in finally block, then after NOT_ACCEPTABLE_ID_ERROR is triggered, the order will not be loaded completely
    } catch ({ response }) {
      if (response.status === 404) {
        setErrorData({
          iconComponent: Error404Icon,
          title: t('OrderNotFoundHeading')
        });

        setIsLoadingOrderStatus(false);
      }

      if (response.status === 403) {
        setErrorData({
          iconComponent: NoAccessIcon,
          title: t('NoOrderAccessHeading')
        });

        setIsLoadingOrderStatus(false);
      }

      if (
        response.status === 406 &&
        response.data.code === NOT_ACCEPTABLE_ID_ERROR
      ) {
        history.replace({
          ...history.location,
          search: history.location.search.replace(
            orderStatusId,
            response.data.message
          )
        });
      }
    }
  }, [dispatch, history, orderStatusId, t]);

  const onFetchContractor = useCallback(
    async contractorId => {
      const contractor = await dispatch(
        fetchOrderStatusContractor({ contractorId })
      );
      socket.joinRooms([contractor]);
    },
    [dispatch, socket]
  );

  const onFetchResponsible = useCallback(
    responsibleId => dispatch(fetchOrderStatusResponsible({ responsibleId })),
    [dispatch]
  );

  const onFetchSignatory = useCallback(
    signatoryId => dispatch(fetchOrderStatusSignatory({ signatoryId })),
    [dispatch]
  );

  const onFetchRelations = useCallback(
    id => dispatch(fetchOrderStatusRelations({ id })),
    [dispatch]
  );

  const onFetchAssetBooking = useCallback(
    id => dispatch(fetchAssetOrderStatusBookingData({ id })),
    [dispatch]
  );

  const onFetchDocumentList = useCallback(
    async relatedOrderStatusId =>
      dispatch(
        fetchOrderStatusAttachments({
          params: {
            relatedOrderStatuses: relatedOrderStatusId
          }
        })
      ),
    [dispatch]
  );

  const onFetchAllActs = useCallback(
    async contractId =>
      dispatch(
        fetchOrderStatusAllActs({
          contractId,
          id: orderStatusId
        })
      ),
    [dispatch, orderStatusId]
  );

  const onFetchSpecification = useCallback(
    async ({ contractId, specificationId }) =>
      dispatch(
        fetchOrderStatusSpecification({
          id: orderStatusId,
          contractId,
          specificationId
        })
      ),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, orderStatusId]
  );

  const onFetchOrderStatusActsAndSpec = useCallback(
    async contractId => {
      try {
        setIsLoadingPeriodicSpec(true);

        const contract = await dispatch(
          fetchOrderStatusContract({ id: orderStatusId, contractId })
        );

        await onFetchAllActs(contractId);
        await onFetchSpecification({
          contractId,
          specificationId: contract.specificationId
        });
      } finally {
        setIsLoadingPeriodicSpec(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, orderStatus]
  );

  const clearData = useCallback(
    contractorId => {
      dispatch(clearSelectedOrderStatus());
      dispatch(
        clearEntityChats({ entityType: TYPE_CONTACT, entityId: contractorId })
      );
      setErrorData(null);
    },
    [dispatch]
  );

  useEffect(() => {
    if (allowFetch && orderStatusId) {
      onFetchOrderStatus();
    }

    return () => clearData((orderStatus || {}).contractorId);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatusId, allowFetch, isFromNotification]);

  useEffect(() => {
    if (allowLoadActsAndSpecification && orderStatusId) {
      onFetchOrderStatusActsAndSpec(orderStatus.contract);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOrderStatus]);

  useEffect(() => {
    if (orderStatus && !isLoadingOrderStatus) {
      onFetchContractor(orderStatus.contractorId);
      onFetchResponsible(orderStatus.responsibleId);

      if (orderStatus.signatoryId) {
        onFetchSignatory(orderStatus.signatoryId);
      }

      onFetchRelations(orderStatus.id);
      onFetchAssetBooking(orderStatus.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLoadingOrderStatus]);

  useEffect(() => {
    if (orderStatus && !isLoadingOrderStatus) {
      onFetchDocumentList(orderStatus.id);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [state, updatedAt, isLoadingOrderStatus]);

  return {
    hasOrderStatus,
    orderStatus,
    errorData,
    isLoadingPeriodicSpec
  };
};

export default useFetchOrderStatus;

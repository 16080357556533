import React from 'react';
import { useDispatch } from "react-redux";

import { fetchPositionsDepartment } from "store/team/departments";
import CustomSelect from "../custom-select";

const mapValue = position => ({
    value: position.title,
    label: position.title,
});

const PositionSelect = ({ isMulti, onChange, value, valueText, label, ...props }) => {
    const dispatch = useDispatch();

    const fetchData = params =>
        dispatch(fetchPositionsDepartment(params))
            .then(({ count, results }) => ({
                totalItems: count,
                entries: results.map(mapValue),
            }));

    return (
        <CustomSelect
            fetchData={fetchData}
            onChange={onChange}
            value={value}
            isClearable
            isSearchable
            isMulti
            valueText={valueText}
            label={label}
            closeMenuOnSelect={false}
            isAsync
            {...props}
        />
    );
};

export default PositionSelect;
import React, { useState } from 'react';
import { Link } from 'react-router-dom';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  TYPE_TASK,
  TYPE_CONTACT,
  TYPE_ASSET,
  TYPE_ORDER_STATUS
} from 'constants/index';

import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import ContactAvatar from 'components/common/avatar/contact';

import useModalsService from 'services/modals';
import useRoutesService from 'services/routes';

import CustomSelect from '../custom-select';
import { OrderSelect } from '../order-select';
import ContactSelect from '../contact-select';
import AssetsSelect from '../assets-select';
import TaskSelect from '../task-select';
import { transformValueToRelation } from './utils';

import styles from './relations-select.module.scss';

const OPTIONS = [
  {
    value: TYPE_ORDER_STATUS,
    label: 'Order',
    ns: 'TaskLinks'
  },
  {
    value: TYPE_TASK,
    label: 'Task',
    ns: 'TaskLinks'
  },
  {
    value: TYPE_CONTACT,
    label: 'Contact',
    ns: 'TaskLinks'
  },
  {
    value: TYPE_ASSET,
    label: 'Asset',
    ns: 'TaskLinks'
  }
];

const renderTitle = value => {
  if (value.relationType === TYPE_ASSET) {
    return (
      <div style={{ display: 'flex', flexDirection: 'column' }}>
        <Typography.Text color="brand">{`${
          value.title
        } ${value.inventoryNumber || ''}`}</Typography.Text>

        {value.fullPath && (
          <Typography.Text size="small" color="black-55">
            {value.fullPath}
          </Typography.Text>
        )}
      </div>
    );
  }

  if (value.relationType === TYPE_CONTACT) {
    return value.title;
  }

  return `ID ${
    value.relationType === TYPE_ORDER_STATUS ? value.orderId : value.relationId
  } ${value.title}`;
};

const SelectedOption = ({ value, onDelete }) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const getUrl = () => {
    if (value.relationType === TYPE_CONTACT) {
      return routes.toContact({ id: value.relationId });
    }

    if (value.relationType === TYPE_TASK) {
      return `${routes.toTasks()}${modals.tasks.showDetails({
        id: value.relationId
      })}`;
    }

    if (value.relationType === TYPE_ORDER_STATUS) {
      return `${routes.toOrderStatuses()}${modals.orders.showDetails({
        orderStatusId: value.relationId
      })}`;
    }

    if (value.relationType === TYPE_ASSET) {
      return `${routes.toAssets()}${modals.assets.showDetails({
        id: value.relationId
      })}`;
    }

    return undefined;
  };

  return (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        justifyContent: 'space-between'
      }}
    >
      <Link
        to={getUrl()}
        target="_blank"
        style={{
          display: 'flex',
          alignItems: 'center',
          maxWidth: '90%'
        }}
      >
        {value.relationType === TYPE_CONTACT && (
          <ContactAvatar
            size={24}
            contact={{
              id: value.relationId,
              firstName: value.firstName,
              lastName: value.lastName,
              employee: value.employee,
              avatarFile: { url: value.icon.url }
            }}
            style={{ marginRight: 4 }}
          />
        )}

        <Typography.Text
          color="brand"
          style={{ marginRight: 8, flexShrink: 0, width: '100%' }}
        >
          {renderTitle(value)}
        </Typography.Text>
      </Link>

      <Button
        type="text"
        onClick={onDelete}
        style={{ padding: 0, height: 'auto' }}
      >
        <Icon size={16} type="close" color="black-55" />
      </Button>
    </div>
  );
};

export const RelationsSelect = ({
  value: selectValue = [],
  onChange,
  defaultType,
  hiddenTypes,
  allowToSelectOne,
  ...props
}) => {
  const filtredOptions = OPTIONS.filter(
    o => hiddenTypes.indexOf(o.value) === -1
  );

  const getInitialLocalValue = () => {
    const result = {};

    selectValue.forEach(r => {
      result[r.relationType] = [
        ...(result[r.relationType] || []),
        { value: r.relationId, label: { id: r.relationId, ...r } }
      ];
    });

    return result;
  };

  const initalLocalValue = getInitialLocalValue();

  const [localValue, setLocalValue] = useState(initalLocalValue);

  const [currentType, setCurrentType] = useState(
    filtredOptions.find(o => o.value === defaultType)
  );

  const { t } = useTranslation('Common');

  const onDelete = ({ relationId, relationType }) => {
    setLocalValue(prev => ({
      ...prev,
      [relationType]: localValue[relationType].filter(
        v => v.value !== relationId
      )
    }));
    onChange(selectValue.filter(r => r.relationId !== relationId));
  };

  const getCurrentSelect = () => {
    if (!currentType) {
      return null;
    }

    const commonProps = {
      value: localValue[currentType.value],
      onChange: v => {
        const value = Array.isArray(v) ? v : [v];

        setLocalValue(prev => ({ ...prev, [currentType.value]: value }));
        onChange([
          ...selectValue.filter(rel => rel.relationType !== currentType.value),
          ...transformValueToRelation(value, currentType.value)
        ]);
      },
      isMulti: !allowToSelectOne,
      isHideSelectedValue: true,
      closeMenuOnSelect: allowToSelectOne,
      isDisabled: allowToSelectOne && selectValue.length,
      style: { margin: '16px 0' }
    };

    if (currentType.value === TYPE_TASK) {
      return <TaskSelect valueText={t('ChooseTask')} {...commonProps} />;
    }

    if (currentType.value === TYPE_ORDER_STATUS) {
      return <OrderSelect valueText={t('ChooseOrder')} {...commonProps} />;
    }

    if (currentType.value === TYPE_CONTACT) {
      return <ContactSelect valueText={t('ChooseContact')} {...commonProps} />;
    }

    if (currentType.value === TYPE_ASSET) {
      return <AssetsSelect valueText={t('ChooseAsset')} {...commonProps} />;
    }

    return null;
  };

  return (
    <>
      <CustomSelect
        value={currentType}
        onChange={setCurrentType}
        valueText={t('ChooseLinkType')}
        options={filtredOptions}
        isDisabled={allowToSelectOne && selectValue.length}
        rootClassName={styles.root}
        {...props}
      />

      {getCurrentSelect()}

      <div
        style={{
          display: 'grid',
          gap: 8,
          gridTemplateColumns: 'minmax(0, 1fr)'
        }}
      >
        {selectValue.map(v => (
          <SelectedOption value={v} onDelete={() => onDelete(v)} />
        ))}
      </div>
    </>
  );
};

RelationsSelect.defaultProps = {
  hiddenTypes: PropTypes.array,
  allowToSelectOne: PropTypes.bool
};

RelationsSelect.defaultProps = {
  hiddenTypes: [],
  allowToSelectOne: false
};

export default RelationsSelect;

import React from 'react';

import {
  TYPE_PROJECT,
  TYPE_DEPARTMENT,
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_ORDER,
  TYPE_CONTACT
} from 'constants/index';

import Typography from 'components/common/typography';

import { getFullName } from 'utils/get-fio';

import styles from './mention.module.scss';

// TODO: Return when merging logs or conversations is done. Do a search on this comment to get everything back
export const DEFAULT_MAX_MENTION_ITEMS_ALL_EMPLOYEES = 1; // 2
export const DEFAULT_MAX_MENTION_ITEMS_EMPLOYEES = 4; // 3
export const DEFAULT_MAX_MENTION_ITEMS_DEPARTMENTS = 2;
export const DEFAULT_MAX_MENTION_ITEMS_PROJECTS = 2;

export const MAX_MENTION_ITEMS_COUNT =
  DEFAULT_MAX_MENTION_ITEMS_ALL_EMPLOYEES +
  DEFAULT_MAX_MENTION_ITEMS_EMPLOYEES +
  DEFAULT_MAX_MENTION_ITEMS_DEPARTMENTS +
  DEFAULT_MAX_MENTION_ITEMS_PROJECTS;

export const MENTION_TYPE_ALL = 'all';
export const MENTION_TYPE_EMPLOYEE = '';

export const EMPLOYEE_MENTION_TYPES = [
  MENTION_TYPE_ALL,
  MENTION_TYPE_EMPLOYEE,
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_ORDER,
  TYPE_CONTACT
];

export const MENTION_MEMBERS_KEYS_BY_ENTITY_TYPE = {
  [TYPE_TASK]: 'EveryoneTask',
  [TYPE_ORDER]: 'EveryoneOrder',
  [TYPE_CONTACT]: 'EveryoneContact',
  [TYPE_ASSET]: 'EveryoneList'
};

const transfromEntity = ({
  entity,
  mentionType,
  title,
  description,
  mentionText
}) => ({
  id: entity.id,
  mentionType,
  title,
  description,
  mentionText,
  entity
});

export const transformEmployeeToMentionItem = employee => {
  const fullName = getFullName(employee);

  return transfromEntity({
    entity: employee,
    mentionType: MENTION_TYPE_EMPLOYEE,
    mentionText: fullName,
    title: fullName,
    description: employee.position
  });
};

export const transformProjectToMentionItem = project =>
  transfromEntity({
    entity: project,
    mentionType: TYPE_PROJECT,
    mentionText: project.title,
    title: project.title
  });

export const transformDepartmentToMentionItem = department =>
  transfromEntity({
    entity: department,
    mentionType: TYPE_DEPARTMENT,
    mentionText: department.name,
    title: department.name
  });

const getTitleByMentionType = ({ mentionType, t }) => {
  if (EMPLOYEE_MENTION_TYPES.includes(mentionType)) {
    return t('Employees');
  }

  if (mentionType === TYPE_DEPARTMENT) {
    return t('Departments');
  }

  if (mentionType === TYPE_PROJECT) {
    return t('Projects');
  }

  return null;
};

export const renderItemsTitle = ({ results, index, t }) => {
  const current = results[index];
  const previous = results[index - 1];

  if (
    !previous ||
    (current.mentionType !== previous.mentionType &&
      (!EMPLOYEE_MENTION_TYPES.includes(current.mentionType) ||
        !EMPLOYEE_MENTION_TYPES.includes(previous.mentionType)))
  ) {
    return (
      <Typography.Text color="black-65" className={styles.mentionTypeTitle}>
        {getTitleByMentionType({ mentionType: current.mentionType, t })}
      </Typography.Text>
    );
  }

  return null;
};

const TRIGGERS = ['@'].join('');

const PUNCTUATION =
  '\\.,\\+\\*\\?\\$\\@\\|#{}\\(\\)\\^\\-\\[\\]\\\\/!%\'"~=<>_:;';
const NAME = `\\b[A-Z][^\\s${PUNCTUATION}]`;

const DocumentMentionsRegex = {
  NAME,
  PUNCTUATION
};

const CapitalizedNameMentionsRegex = new RegExp(
  `(^|[^#])((?:${DocumentMentionsRegex.NAME}{${1},})$)`
);

const PUNC = DocumentMentionsRegex.PUNCTUATION;

const VALID_CHARS = `[^${TRIGGERS}${PUNC}\\s]`;

// eslint-disable-next-line no-useless-concat
const VALID_JOINS = `${'(?:' + '\\.[ |$]|' + ' |' + '['}${PUNC}]|` + `)`;

const LENGTH_LIMIT = 75;

const AtSignMentionsRegex = new RegExp(
  // eslint-disable-next-line no-useless-concat
  `${'(^|\\s|\\()(' + '['}${TRIGGERS}]` +
    `((?:${VALID_CHARS}${VALID_JOINS}){0,${LENGTH_LIMIT}})` +
    `)$`
);

const ALIAS_LENGTH_LIMIT = 50;

const AtSignMentionsRegexAliasRegex = new RegExp(
  // eslint-disable-next-line no-useless-concat
  `${'(^|\\s|\\()(' + '['}${TRIGGERS}]` +
    `((?:${VALID_CHARS}){0,${ALIAS_LENGTH_LIMIT}})` +
    `)$`
);

function checkForCapitalizedNameMentions(text, minMatchLength) {
  const match = CapitalizedNameMentionsRegex.exec(text);

  if (match !== null) {
    const maybeLeadingWhitespace = match[1];
    const matchingString = match[2];

    if (matchingString != null && matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: matchingString
      };
    }
  }
  return null;
}

function checkForAtSignMentions(text, minMatchLength) {
  let match = AtSignMentionsRegex.exec(text);

  if (match === null) {
    match = AtSignMentionsRegexAliasRegex.exec(text);
  }

  if (match !== null) {
    const maybeLeadingWhitespace = match[0];
    const matchingString = match[3];

    if (matchingString.length >= minMatchLength) {
      return {
        leadOffset: match.index + maybeLeadingWhitespace.length,
        matchingString,
        replaceableString: match[2]
      };
    }
  }
  return null;
}

export function getPossibleQueryMatch(text) {
  const match = checkForAtSignMentions(text, 0);

  return match === null ? checkForCapitalizedNameMentions(text, 3) : match;
}

// TODO: Return when merging logs or conversations is done. Do a search on this comment to get everything back
const MENTION_HEIGHT = 522; // 508

export const getMentionStyle = ({ anchorRef }) => {
  const style = {
    height: MENTION_HEIGHT
  };

  const { offsetTop } = anchorRef;
  const { innerHeight } = window;
  const offsetBottom = innerHeight - offsetTop;

  if (style.height <= offsetTop) {
    style.bottom = 20;

    return style;
  }

  if (style.height >= offsetTop && style.height <= offsetBottom) {
    style.top = 20;

    return style;
  }

  const isTop = offsetTop > offsetBottom;

  style.height = isTop ? offsetTop : offsetBottom;

  if (isTop) {
    style.bottom = 20;
  } else {
    style.top = 20;
  }

  return style;
};

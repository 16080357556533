import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import { FormContactSelect } from 'components/common/hook-form';
import Button from 'components/common/button';

import styles from './merging-contacts.module.scss';

const ChoiceContactStep = ({ contactId, onSubmit }) => {
  const methods = useForm();

  const { t } = useTranslation(['СombineContacts', 'Errors']);

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(({ contact }) =>
          onSubmit(contact.label)
        )}
      >
        <Typography.Paragraph>
          {t('СombineContactsChooseDesc')}
        </Typography.Paragraph>

        <FormContactSelect
          name="contact"
          label={t('Email')}
          params={{
            exclude: [contactId],
            isArchived: null
          }}
          rules={{
            required: t('RequiredField', { ns: 'Errors' })
          }}
        />

        <Button
          type="primary"
          width="expanded"
          htmlType="submit"
          className={styles.button}
        >
          {t('NextBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

ChoiceContactStep.propTypes = {
  contactId: PropTypes.number.isRequired,
  onSubmit: PropTypes.func.isRequired
};

export default ChoiceContactStep;

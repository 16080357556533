import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import { FormRelationsSelect } from 'components/common/hook-form';
import Button from 'components/common/button';
import FormUpload from 'components/common/hook-form/upload';

import styles from './add-file.module.scss';

const UploadForm = ({ isLoading, onSubmit }) => {
  const methods = useForm();

  const { t } = useTranslation(['UploadFile', 'Errors']);

  const handleSubmit = ({ fileList, relation }) => {
    const transformFileList = fileList.map(f => ({
      id: f.response.id,
      mimeType: f.type,
      title: f.name
    }));

    return onSubmit(transformFileList, relation);
  };

  return (
    <div className={styles.uploadForm}>
      <Typography.Paragraph>{t('UploadFileDesc')}</Typography.Paragraph>

      <FormProvider {...methods}>
        <form
          className={styles.form}
          onSubmit={methods.handleSubmit(handleSubmit)}
        >
          <FormRelationsSelect
            name="relation"
            label={t('Link')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            allowToSelectOne
            itemProps={{
              className: styles.relations
            }}
          />

          <FormUpload
            name="fileList"
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            multiple
            itemProps={{
              className: styles.upload
            }}
            attachmetsProps={{
              className: styles.attachment
            }}
          />

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            width="expanded"
            loading={isLoading}
            className={styles.submit}
          >
            {t('AddBtn')}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

UploadForm.propTypes = {
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

UploadForm.defaultProps = {
  isLoading: false
};

export default UploadForm;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import TotalItems from 'components/common/total-items';
import DebounceInput from 'components/common/controls/debounce-input';

import styles from './menu.module.scss';

export const Menu = ({ totalItems, className, search, setSearch }) => {
  const { t } = useTranslation('Common');

  return (
    <div className={classnames(styles.flexWrap, className)}>
      <TotalItems
        count={totalItems}
        plural={[
          t('SingularFormFile'),
          t('GenitiveFormFile'),
          t('PluralFormFile')
        ]}
      />

      <DebounceInput
        className={styles.search}
        value={search}
        setValue={setSearch}
        isSetValueByPressEnter
        placeholder={t('SearchWithEnter')}
      />
    </div>
  );
};

Menu.propTypes = {
  totalItems: PropTypes.number,
  className: PropTypes.string,
  search: PropTypes.string,
  setSearch: PropTypes.func
};

Menu.defaultProps = {
  totalItems: 0,
  className: undefined,
  search: '',
  setSearch: () => {}
};

export default Menu;

import React from 'react';
import { Tooltip } from 'antd';
import PropTyes from 'prop-types';
import { Link } from 'react-router-dom';

import useRoutesService from 'services/routes';
import getFIO, { getFullName } from 'utils/get-fio';

import Avatar from '../index';

const EmployeeAvatar = ({ employee, size, tooltip, isLink, ...props }) => {
  const routes = useRoutesService({ returnUrl: true });

  const { className, content, isHide } = tooltip;
  const { id, avatarFile, isActive = true } = employee;

  const TooltipTitle = () => (
    <div className={className}>
      {getFullName(employee)}
      {content}
    </div>
  );

  return (
    <Tooltip title={isHide ? undefined : TooltipTitle}>
      {isLink && isActive ? (
        <Link to={routes.toEmployee(id)}>
          <Avatar src={(avatarFile || {}).url} size={size} {...props}>
            {getFIO(employee)}
          </Avatar>
        </Link>
      ) : (
        <Avatar
          src={(avatarFile || {}).url}
          size={size}
          isActive={isActive}
          {...props}
        >
          {getFIO(employee)}
        </Avatar>
      )}
    </Tooltip>
  );
};

EmployeeAvatar.propTypes = {
  employee: PropTyes.shape({
    id: PropTyes.number,
    avatarFile: PropTyes.object,
    firstName: PropTyes.string,
    lastName: PropTyes.string,
    isActive: PropTyes.bool
  }),
  size: PropTyes.number,
  tooltip: PropTyes.shape({
    className: PropTyes.string,
    content: PropTyes.oneOfType([
      PropTyes.string,
      PropTyes.node,
      PropTyes.func
    ]),
    isHide: PropTyes.bool
  }),
  isLink: PropTyes.bool
};

EmployeeAvatar.defaultProps = {
  employee: {},
  size: 24,
  tooltip: {},
  isLink: true
};

export default EmployeeAvatar;

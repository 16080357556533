import createTheme, { variable } from './utils';

const SPACE_UNIT = 8;

// https://github.com/ant-design/ant-design/blob/master/components/style/themes/default.less
const antd = {
  'screen-xxl': '1400px',
  'screen-xl': '1200px',
  'screen-lg': '992px',
  'screen-md': '768px',
  'screen-sm': '576px',

  'height-base': '36px',
  'height-lg': '40px',
  'height-sm': '24px',

  'padding-lg': variable('size.24px'), // containers
  'padding-md': variable('size.16px'), // small containers and buttons
  'padding-sm': variable('size.12px'), // Form controls and items
  'padding-xs': variable('size.8px'), // small items
  'padding-xss': variable('size.4px'), // more small

  'margin-lg': variable('size.24px'), // containers
  'margin-md': variable('size.16px'), // small containers and buttons
  'margin-sm': variable('size.12px'), // Form controls and items
  'margin-xs': variable('size.8px'), // small items
  'margin-xss': variable('size.4px'), // more small

  'select-dropdown-height': variable('height-base'),
  'select-single-item-height-lg': variable('height-lg'),
  'select-multiple-item-height': `calc(${variable('height-lg')} - ${variable(
    'input-padding-vertical-base'
  ) * 2})`,
  'select-multiple-item-height-lg': variable('height-lg'),

  white: variable('color.white'),
  black: variable('color.black'),
  'primary-color': variable('color.brand'),
  'text-color': variable('color.black-15'),
  'error-color': variable('color.red'),
  'label-color': variable('color.black-35'),
  'divider-color': variable('color.black-85'),
  'processing-color': variable('primary-color'),
  'progress-text-font-size': '12px',
  'progress-circle-text-font-size': '12px',

  'font-family': `'Open Sans', sans-serif`,
  'font-size-base': '14px',

  'layout-body-background': variable('color.black-98'),
  'layout-header-background': variable('color.white'),
  'layout-header-height': '52px',
  'layout-header-padding': '0 30px',
  'layout-sider-background': variable('color.black-15'),

  'menu-bg': 'transparent',
  'menu-item-color': variable('color.white'),
  'menu-item-active-bg': variable('primary-color'),
  'menu-item-active-color': variable('color.white'),
  'menu-item-active-border-width': '0',

  'radio-size': '18px',
  'radio-border-width': '1.5px',
  'radio-dot-size': '10px',
  'radio-dot-color': variable('color.brand'),
  'radio-dot-disabled-color': variable('color.black-75'),

  'radio-button-bg': variable('color.white'),
  'radio-button-active-color': variable('color.white'),

  'checkbox-size': '18px',

  'border-radius-base': '4px',
  'border-color-base': variable('color.black-85'),

  'avatar-bg': variable('primary-color'),
  'avatar-color': variable('color.white'),
  'avatar-size-base': '40px',

  'tooltip-arrow-width': '8px',
  'tooltip-bg': `rgba(${variable('color.black')}, 0.7)`,
  'tooltip-distance': variable('tooltip-arrow-width'),
  'tooltip-color': variable('color.white'),
  'tooltip-max-width': '330px',

  'shadow-color': 'rgba(0, 0, 0, 0.1)',
  'shadow-1-down': `0 2px 8px ${variable('shadow-color')}`,
  'shadow-2': `0 4px 12px ${variable('shadow-color')}`,
  'shadow-3': `0px 2px 30px ${variable('shadow-color')}`,
  'box-shadow-base': `0 4px 12px ${variable('shadow-color')}`,

  'table-row-hover-bg': variable('color.brand-95'),

  'tabs-horizontal-margin': '0',
  'tabs-highlight-color': variable('color.black'),

  'control-padding-horizontal': variable('padding-sm'),
  'control-padding-horizontal-sm': variable('padding-xs'),

  'input-height-base': variable('height-base'),
  'input-height-lg': variable('height-lg'),
  'input-height-sm': variable('height-sm'),
  'input-placeholder-color': variable('color.black-55'),
  'input-border-color': variable('color.black-65'),

  'btn-font-weight': '600',
  'btn-border-radius-base': variable('border-radius-base'),
  'btn-font-size-lg': '16px',
  'btn-padding-horizontal-base': `${SPACE_UNIT * 3}px`,
  'btn-padding-horizontal-lg': variable('btn-padding-horizontal-base'),
  'btn-height-base': variable('height-base'),
  'btn-height-lg': variable('height-lg'),
  'btn-height-sm': variable('height-sm'),
  'btn-danger-bg': variable('color.red-70'),
  'btn-danger-border': variable('btn-danger-bg'),

  'link-color': variable('color.brand'),
  'link-hover-color': variable('color.brand-30'),
  'link-active-color': variable('color.brand-30'),

  'heading-1-size': '24px',
  'heading-2-size': '20px',
  'heading-3-size': '16px',
  'heading-4-size': '14px',
  'heading-5-size': '12px',

  'typography-title-margin-bottom': variable('heading-1-size'),

  'modal-header-title-font-size': variable('heading-2-size'),
  'modal-header-title-line-height': '1.4',

  'card-radius': variable('border-radius-base')
};

const common = {
  'gradient-base': `linear-gradient(to right, ${variable(
    'primary-color'
  )} 0%, ${variable('color.green')} 100%)`,
  'bottom-banner-height': '40px',
  'zindex-header': '901',
  'zindex-sider': '900'
};

const theme = createTheme({
  'space-unit': `${SPACE_UNIT}px`,
  color: {
    // Primary
    brand: '#16969C',
    'brand-5': '#031516',
    'brand-10': '#062B2D',
    'brand-15': '#0A4043',
    'brand-20': '#0D5659',
    'brand-25': '#106B70',
    'brand-30': '#138186',
    'brand-40': '#19ACB2',
    'brand-45': '#1DC1C9',
    'brand-50': '#20D7DF',
    'brand-90': '#D2F7F9',
    'brand-95': '#E9FBFC',
    // Black
    black: '#0C0D0D',
    'black-15': '#252627',
    'black-25': '#3E3F42',
    'black-35': '#57595C',
    'black-45': '#6F7276',
    'black-55': '#898C90',
    'black-65': '#A3A5A8',
    'black-75': '#BDBFC1',
    'black-85': '#D8D9DA',
    'black-95': '#F2F2F3',
    'black-98': '#FAFAFA',
    // White
    white: '#FFFFFF',
    // Red
    red: '#F5120A',
    'red-20': '#610604',
    'red-25': '#7A0905',
    'red-30': '#930B06',
    'red-35': '#AB0C07',
    'red-40': '#C40E08',
    'red-45': '#DC1009',
    'red-55': '#F62A23',
    'red-60': '#F7413B',
    'red-70': '#F9716C',
    'red-80': '#FBA09D',
    'red-90': '#FDD0CE',
    // Indigo
    indigo: '#3F51B5',
    'indigo-dark': '#1A224C',
    'indigo-light': '#CED3EE',
    // Magenta
    magenta: '#C32373',
    'magenta-dark': '#561033',
    'magenta-semi': '#E77EB1',
    'magenta-light': '#F7D4E5',
    // Danube
    danube: '#0389B6',
    'danube-dark': '#012632',
    'danube-light': '#CDF2FE',
    // Yellow
    yellow: '#FDA402',
    'yellow-dark': '#654201',
    'yellow-semi': '#FFC966',
    'yellow-light': '#FFEDCC',
    // Green
    green: '#35BF5B',
    'green-dark': '#165026',
    'green-semi': '#87DE9F',
    'green-light': '#D7F4DF',
    // Orange
    orange: '#F63732',
    'orange-dark': '#930B06',
    'orange-light': '#FDD0CE',
    // Azure
    azure: '#1AB2FF',
    'azure-dark': '#004E75',
    'azure-semi': '#80D4FF',
    'azure-light': '#E1F5FE',
    // Pink
    pink: '#B37FEB',
    'pink-dark': '#4A1584',
    'pink-light': '#E5D3F8'
  },
  size: {
    '4px': '4px',
    '8px': '8px',
    '12px': '12px',
    '16px': '16px',
    '20px': '20px',
    '24px': '24px',
    '28px': '28px',
    '32px': '32px'
  },
  fonts: {},
  shadows: {},
  screen: {},
  common: {},
  ...antd,
  ...common
});

export default theme;

import React, { useContext, useEffect, useMemo, useRef } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';
import { message } from 'antd';

import {
  TYPE_CONTACT,
  ONLINE_CHAT,
  WHATSAPP,
  MESSAGE_DURATION,
  EMAIL
} from 'constants/index';

import { MarkdownEditorContext } from 'components/common/new-editor';
import Emoji from 'components/common/new-editor/components/emoji';
import Mention from 'components/common/new-editor/components/mention';
import ShowTopToolbarButton from 'components/common/new-editor/components/show-top-toolbar';
// import WriteFirstMessage from 'components/common/new-editor/components/write-first-message';
import ToChatSelect from 'components/common/new-editor/components/to-chat-select';
import Submit from 'components/common/new-editor/components/submit';
import UploadFilesMessage from 'components/common/new-editor/components/upload-files-message';
import { useToolbarControls } from 'components/common/new-editor/plugins/hooks/use-toolbar-controls';
import MessageTemplates from 'components/common/new-editor/components/message-templates';
import Typography from 'components/common/typography';

import getFileListSize from 'utils/get-file-list-size';

import CreateFile from '../../components/create-file';
import Private from '../../components/private';

import styles from '../../plugins/toolbar.module.scss';

const MAX_SIZE_WHATSAPP_FILE = 100 * 1024 * 1024;
const MAX_SIZE_EMAIL_FILE_LIST = 25 * 1024 * 1024;

export const MessageToolbar = ({
  fileList,
  setFileList,
  value,
  // openFirstWriteDrawer,
  // allowToSendFirstMessage,
  valueChat,
  onChangeChat,
  options,
  isDisabledChat,
  employeeId,
  entity,
  sendMessage,
  isLoadingSending,
  isDisabledSubmit,
  entityType,
  allowCreateFile,
  privateData
}) => {
  const { t } = useTranslation('Errors');

  const toolbarRef = useRef(null);

  const [editor] = useLexicalComposerContext();

  const {
    addMention,
    addEmoji,
    openMessageTemplatesDrawer,
    onChangePrivate
  } = useToolbarControls({
    editor,
    fileList,
    value,
    setIsPrivate: privateData.onChange,
    isPrivate: privateData.value,
    onSubmit: sendMessage,
    descriptionValue: value.description
  });

  const { showToolbar, setShowToolbar } = useContext(MarkdownEditorContext);

  const isWhatsAppChat = valueChat.label.channelKind === WHATSAPP;
  const isEmailChat = valueChat.label.channelKind === EMAIL;

  const onlineChat = entity.chats.find(c => c.channelKind === ONLINE_CHAT);

  const isMaxSizeEmailFileListReached =
    isEmailChat && getFileListSize(fileList) > MAX_SIZE_EMAIL_FILE_LIST;

  const handleMaxSizeUploadFile = () => {
    fileList.forEach(file => {
      if (
        file.fileSize > MAX_SIZE_WHATSAPP_FILE ||
        file.size > MAX_SIZE_WHATSAPP_FILE
      ) {
        // if the file is not downloaded from the device, but from the file table
        if (file.fileSize) {
          message.error(
            t('FileSizeMustNotExceed', {
              mb: MAX_SIZE_WHATSAPP_FILE / 1024 / 1024
            }),
            MESSAGE_DURATION
          );
        }

        setFileList(prevList => prevList.filter(item => item.id !== file.id));
      }
    });
  };

  const handleMaxSizeFileList = maxSize => {
    const fileListSize = getFileListSize(fileList);

    if (fileListSize < maxSize) {
      return;
    }

    message.error(
      t('FileListSizeMustNotExceed', {
        mb: maxSize / 1024 / 1024
      }),
      MESSAGE_DURATION
    );

    setFileList(prevList => prevList.filter(item => !item.isCurrentlyAttach));
  };

  const maxLengthErrorMessage = useMemo(() => {
    if (value.description.length > 938 && isWhatsAppChat) {
      return t('InputMaxLengthSymbols', { length: 938 });
    }

    return null;
  }, [value.description.length, isWhatsAppChat, t]);

  useEffect(() => {
    if (isWhatsAppChat) {
      handleMaxSizeUploadFile();
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList, valueChat]);

  useEffect(() => {
    if (isEmailChat) {
      handleMaxSizeFileList(MAX_SIZE_EMAIL_FILE_LIST);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileList]);

  useEffect(() => {
    if (isMaxSizeEmailFileListReached) {
      message.error(
        t('FileListSizeMustNotExceed', {
          mb: MAX_SIZE_EMAIL_FILE_LIST / 1024 / 1024
        }),
        MESSAGE_DURATION
      );
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [valueChat]);

  return (
    <>
      {maxLengthErrorMessage && (
        <Typography.Text color="red" className={styles.error}>
          {maxLengthErrorMessage}
        </Typography.Text>
      )}

      <div
        ref={toolbarRef}
        className={classnames(styles.bottomToolbar, styles.messageToolbar)}
      >
        <UploadFilesMessage
          fileList={fileList}
          onChange={setFileList}
          maxSizeFile={isWhatsAppChat ? MAX_SIZE_WHATSAPP_FILE : undefined}
          maxSizeFileList={isEmailChat ? MAX_SIZE_EMAIL_FILE_LIST : undefined}
        />

        {allowCreateFile && (
          <CreateFile
            fileList={fileList}
            onChange={setFileList}
            createData={{
              relation: {
                relationId: entity.id,
                relationType: entityType,
                onlineChat
              }
            }}
          />
        )}

        <Mention onClick={addMention} />

        <Emoji onSelect={addEmoji} />

        <MessageTemplates onClick={openMessageTemplatesDrawer} />

        <ShowTopToolbarButton
          onClick={() => setShowToolbar(!showToolbar)}
          isActive={showToolbar}
        />

        {/* {allowToSendFirstMessage && ( */}
        {/*  <WriteFirstMessage onClick={openFirstWriteDrawer} /> */}
        {/* )} */}

        {privateData.allow && (
          <Private
            isDisabled={privateData.isDisabled}
            tooltip={privateData.tooltip}
            value={privateData.value}
            onChange={onChangePrivate}
          />
        )}

        <div className={styles.right}>
          {entityType === TYPE_CONTACT && (
            <ToChatSelect
              value={valueChat}
              onChange={onChangeChat}
              options={options}
              isDisabled={isDisabledChat}
              employeeId={employeeId}
              contact={entity}
            />
          )}

          <Submit
            onSubmit={sendMessage}
            isLoading={isLoadingSending}
            isDisabled={
              isDisabledSubmit ||
              maxLengthErrorMessage ||
              isMaxSizeEmailFileListReached
            }
          />
        </div>
      </div>
    </>
  );
};

export default MessageToolbar;

MessageToolbar.propTypes = {
  fileList: PropTypes.array,
  setFileList: PropTypes.func,
  value: PropTypes.object,
  allowCreateFile: PropTypes.bool
};

MessageToolbar.defaultProps = {
  fileList: [],
  setFileList: () => {},
  value: {},
  allowCreateFile: false
};

import { useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWorkspaceId } from 'store/workspace';
import {
  fetchAttachments,
  getCurrentPage,
  getFilterAttachments,
  getIsAttachmentsLoading
} from 'store/attachments';

export const useDataProvider = ({ isTrash, ...params }) => {
  const dispatch = useDispatch();

  const isLoading = useSelector(getIsAttachmentsLoading);
  const workspaceId = useSelector(getWorkspaceId);
  const filter = useSelector(getFilterAttachments);
  const currentPage = useSelector(getCurrentPage);

  const fetchData = () => dispatch(fetchAttachments({ ...params, isTrash }));

  useEffect(() => {
    if (!isLoading) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [workspaceId, currentPage, filter]);

  return null;
};

export default useDataProvider;

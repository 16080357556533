import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CREATOR_DRAWER_WIDTH } from 'constants/index';

import Drawer from 'components/common/drawer';
import MainForm from 'components/orders-view/forms/main';
import { SkeletonEntityCreation } from 'components/common/skeletons';

import useEdit from './use-edit';

export const OrderEditDrawer = ({ visible, data, onClose }) => {
  const { t } = useTranslation('AddOrder');

  const {
    isLoading,
    values,
    hasValues,
    onSubmit,
    afterVisibleChange
  } = useEdit({ orderStatusId: data.id, onClose });

  return (
    <Drawer
      title={<Drawer.Title>{t('EditOrderHeading')}</Drawer.Title>}
      visible={visible}
      width={CREATOR_DRAWER_WIDTH}
      onClose={onClose}
      maskClosable={false}
      bodyStyle={{ padding: 0 }}
      afterVisibleChange={afterVisibleChange}
    >
      {!hasValues ? (
        <SkeletonEntityCreation />
      ) : (
        <MainForm
          defaultValues={values}
          isEditor
          onSubmit={onSubmit}
          isLoading={isLoading}
        />
      )}
    </Drawer>
  );
};

OrderEditDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

OrderEditDrawer.defaultProps = {
  data: {}
};

export default OrderEditDrawer;

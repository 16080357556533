import React, { Fragment } from 'react';
import PropTypes from 'prop-types';

import OrderContractModal from '../modals/contract';
import OrderRequestRejectionModal from '../modals/request-rejection';
import OrderCancellationModal from '../modals/cancellation';
import OrderCompletionModal from '../modals/completion';
import OrderAgreementModal from '../modals/agreement';
import OrderCancelActModal from '../modals/cancel-act';

const StateChangingModalsProvider = ({
  orderStatus,
  resetReordering,
  modalData,
  children
}) => {
  const {
    acceptanceContractModal,
    changingContractModal,
    requestRejectionModal,
    cancellationModal,
    completionModal,
    agreementModal,
    cancelActModal
  } = modalData;

  return (
    <Fragment>
      {children}

      <OrderAgreementModal
        visible={agreementModal.visible}
        orderStatus={orderStatus}
        isLoading={agreementModal.isLoading}
        onAccept={agreementModal.onAccept}
        onReject={agreementModal.onReject}
        onClose={agreementModal.onClose}
        resetDraggable={agreementModal.resetDraggable}
        resetReordering={resetReordering}
      />

      <OrderContractModal
        visible={acceptanceContractModal.visible}
        orderStatus={orderStatus}
        previousData={acceptanceContractModal.previousData}
        onSubmit={acceptanceContractModal.onSubmit}
        onClose={acceptanceContractModal.onClose}
        resetReordering={resetReordering}
      />

      <OrderContractModal
        visible={changingContractModal.visible}
        orderStatus={orderStatus}
        isChanging
        previousData={changingContractModal.previousData}
        onSubmit={changingContractModal.onSubmit}
        onClose={changingContractModal.onClose}
        resetReordering={resetReordering}
      />

      <OrderRequestRejectionModal
        visible={requestRejectionModal.visible}
        orderStatus={orderStatus}
        onClose={requestRejectionModal.onClose}
        resetReordering={resetReordering}
      />

      <OrderCancellationModal
        visible={cancellationModal.visible}
        orderStatusId={orderStatus.id}
        onClose={cancellationModal.onClose}
        resetReordering={resetReordering}
      />

      <OrderCompletionModal
        visible={completionModal.visible}
        orderStatus={orderStatus}
        onClose={completionModal.onClose}
        resetReordering={resetReordering}
      />

      <OrderCancelActModal
        onClose={cancelActModal.onClose}
        orderStatus={orderStatus}
        visible={cancelActModal.visible}
        resetReordering={resetReordering}
      />
    </Fragment>
  );
};

StateChangingModalsProvider.propTypes = {
  orderStatus: PropTypes.shape({}),
  resetReordering: PropTypes.func,
  modalData: PropTypes.shape({
    acceptanceContractModal: PropTypes.shape({
      visible: PropTypes.bool,
      onSubmit: PropTypes.func.isRequired,
      previousData: PropTypes.shape({}),
      onClose: PropTypes.func
    }),
    changingContractModal: PropTypes.shape({
      visible: PropTypes.bool,
      onSubmit: PropTypes.func.isRequired,
      previousData: PropTypes.shape({}),
      onClose: PropTypes.func
    }),
    requestRejectionModal: PropTypes.shape({
      visible: PropTypes.bool,
      onClose: PropTypes.func
    }),
    cancellationModal: PropTypes.shape({
      visible: PropTypes.bool,
      onClose: PropTypes.func
    }),
    completionModal: PropTypes.shape({
      visible: PropTypes.bool,
      onClose: PropTypes.func
    })
  }).isRequired
};

StateChangingModalsProvider.defaultProps = {
  orderStatus: {},
  resetReordering: () => {}
};

export default StateChangingModalsProvider;

import React, { useEffect, useState } from 'react';
import { Form, Button, Radio } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { INVITE_DATA } from 'constants/index';

import VatNumber from 'components/common/controls/vat-number';
import { LayoutAuth } from 'components/common/layout/template';
import Typography from 'components/common/typography';
import { Questions } from 'components/auth-view/common/questions';
import Icon from 'components/common/icon';
import UnverifiedWorkspace from 'components/common/controls/unverified-workspace';

import useAnalytics from 'hooks/analytics/use-analytics';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { useLocalStorage } from 'hooks/common';
import useAnalyticsParams from 'hooks/analytics/use-analytics-params';
import { validateMaxLength, validateMinLength } from 'utils/validators';

import styles from './styles.module.scss';

const CreateWorkspaceForm = ({
  data,
  form,
  className,
  isLoading,
  isCreateWorkspace,
  onSubmit
}) => {
  const amplitude = useAmplitude();

  const [inviteData] = useLocalStorage(INVITE_DATA, {});

  const [vatNumber, setVatNumber] = useState(inviteData);

  const { reachGoal, trackPageView } = useAnalytics(true);
  const { params } = useAnalyticsParams();

  const { t } = useTranslation(['AccountAlmostReady', 'Errors']);

  useEffect(trackPageView, []);

  const handleSubmit = async event => {
    event.preventDefault();

    form.validateFields((err, values) => {
      if (!err) {
        const result = values.isNotVerified
          ? {
              title: values.unverifiedWorkspace.title,
              country: values.unverifiedWorkspace.country.value,
              isNotVerified: values.isNotVerified,
              vatNumber: null
            }
          : vatNumber;

        onSubmit({ vatNumber: result });

        if (isJoinWorkspace) {
          amplitude.joinRequestSentEvent({
            id: vatNumber.id,
            analyticsProperty: params
          });
        }

        reachGoal('reg_step4-1', 'registration');
      }
    });
  };

  // Существует два варианта воркспейса.
  // 1. Id - существующая компания, зарегистрированная в системе
  // 2. title - компания не загегистрированная в системе
  const isJoinWorkspace = !!(vatNumber && vatNumber.id);
  const hasWorkspace = !!(vatNumber && vatNumber.title);

  const isStatusLiquidation = vatNumber && vatNumber.isStatusLiquidation;

  const isNotVerified =
    form.getFieldValue('isNotVerified') === undefined
      ? true
      : form.getFieldValue('isNotVerified');

  const unverifiedWorkspaceError = form.getFieldError('unverifiedWorkspace');
  const unverifiedWorkspaceValue = form.getFieldValue('unverifiedWorkspace');

  const isDisabledSubmit =
    (!isNotVerified && (!hasWorkspace || isStatusLiquidation)) ||
    (isNotVerified &&
      (unverifiedWorkspaceError || !(unverifiedWorkspaceValue || {}).title));

  return (
    <>
      <Form
        className={classnames(styles.form, className, {
          [styles.isCreateWorkspace]: isCreateWorkspace
        })}
        data-qa="qa-ox30h1x1vkkonay"
        onSubmit={handleSubmit}
        colon={false}
      >
        {!isCreateWorkspace && (
          <Typography.Title
            level={1}
            className={styles.title}
            data-qa="qa-buaujnej0jkrg5j"
          >
            {t('AccountAlmostReadyHeading')}
          </Typography.Title>
        )}

        <Form.Item className={styles.fromItem}>
          {form.getFieldDecorator('isNotVerified', {
            initialValue: true
          })(
            <Radio.Group className={styles.radioGruop}>
              <Radio value className={styles.radio}>
                {t('UnverifiedCompany')}
              </Radio>

              <Radio value={false} className={styles.radio}>
                {t('VerifyCompany')}
                <Icon
                  type="verified"
                  color="yellow"
                  style={{ marginLeft: 4 }}
                />
              </Radio>
            </Radio.Group>
          )}
        </Form.Item>

        <div
          className={classnames({
            [styles.flexForm]: isCreateWorkspace
          })}
          data-qa="qa-a8hhk0c5czimlue"
        >
          {isNotVerified ? (
            <Form.Item className={styles.fromItem}>
              {form.getFieldDecorator('unverifiedWorkspace', {
                initialValue: {
                  country: null,
                  title: undefined
                },
                rules: [
                  {
                    transform: v => v.title
                  },
                  {
                    validator: (_, value, callback) => {
                      if (value === undefined || !!value) {
                        return callback();
                      }

                      return callback(t('Required Field', { ns: 'Errors' }));
                    }
                  },
                  validateMinLength(2),
                  validateMaxLength(250)
                ]
              })(
                <UnverifiedWorkspace
                  countrySelectProps={{
                    defaultCountry: inviteData.country
                      ? { id: inviteData.country }
                      : { code: data.countryCode }
                  }}
                  inputProps={{
                    placeholder: t('NameYourCompanyProfile')
                  }}
                />
              )}
            </Form.Item>
          ) : (
            <VatNumber
              inputGroupClassName={styles.inputGroup}
              data-qa="qa-s400x0p9z6ko9ub"
              countrySelectClassName={styles.countrySelect}
              companySelectClassName={styles.companySelect}
              companyWrapClassName={styles.companyWrap}
              isRegistration
              // defaultValue={inviteData.vatNumber || undefined}
              defaultCountry={
                inviteData.country
                  ? { id: inviteData.country }
                  : { code: data.countryCode }
              }
              value={vatNumber}
              inviteData={inviteData}
              onChange={setVatNumber}
            />
          )}

          <div
            className={classnames(styles.btnWrap)}
            data-qa="qa-4kkhpyi8xho3158"
          >
            <Button
              htmlType="submit"
              type="primary"
              size="large"
              disabled={isDisabledSubmit}
              className={styles.btnSubmit}
              data-qa="qa-2kuapc5cx0fo3o4"
              loading={isLoading}
            >
              {t(isJoinWorkspace && !isNotVerified ? 'JoinBtn' : 'NextBtn')}
            </Button>
          </div>
        </div>
      </Form>

      <LayoutAuth.Footer>
        <Questions isNotVerified={isNotVerified} isRegistration />
      </LayoutAuth.Footer>
    </>
  );
};

CreateWorkspaceForm.propTypes = {
  data: PropTypes.shape({
    lastName: PropTypes.string,
    firstName: PropTypes.string,
    phone: PropTypes.string
  }),
  className: PropTypes.string,
  isLoading: PropTypes.bool,
  isCreateWorkspace: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

CreateWorkspaceForm.defaultProps = {
  data: {},
  className: undefined,
  isLoading: false,
  isCreateWorkspace: false
};

export default Form.create()(CreateWorkspaceForm);

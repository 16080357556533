import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';

import DocumentList from './list';

const DocumentsDrawer = ({ visible, orderStatus, onClose }) => {
  const [isAfterVisible, setIsAfterVisible] = useState(false);

  const { t } = useTranslation('OrderDocuments');

  return (
    <Drawer
      title={<Drawer.Title>{t('OrderDocumentsHeading')}</Drawer.Title>}
      bodyStyle={{ padding: 0 }}
      visible={visible}
      destroyOnClose
      onClose={onClose}
      afterVisibleChange={setIsAfterVisible}
    >
      <DocumentList orderStatus={orderStatus} isAfterVisible={isAfterVisible} />
    </Drawer>
  );
};

DocumentsDrawer.propTypes = {
  visible: PropTypes.bool,
  orderStatus: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired
};

DocumentsDrawer.defaultProps = {
  visible: false,
  orderStatus: {}
};

export default DocumentsDrawer;

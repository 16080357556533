import { useWebsocketOperatorContext } from 'providers';
import React, { useCallback, useMemo, useState } from 'react';
import { useDispatch } from 'react-redux';

import { fetchMergedContact, mergeContacts } from 'store/contacts';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import useRoutesService from 'services/routes';

import ChoiceContactStep from './choice-contact-step';
import ChoiceVariantStep from './choice-variant-step';

const CHOICE_CONTACT_STEP = 'choice_contact';
const CHOICE_VARIANT_STEP = 'choice_variant';

const useMergingContacts = ({ contact, onClose }) => {
  const dispatch = useDispatch();

  const routes = useRoutesService();
  const socket = useWebsocketOperatorContext();

  const [step, setStep] = useState(CHOICE_CONTACT_STEP);
  const [selectedContact, setSelectedContact] = useState(null);
  const [contacts, setContacts] = useState([]);
  const [isLoading, setIsLoading] = useState(false);

  const selectContact = useCallback(
    value => {
      setSelectedContact(value);
      setContacts([value, contact]);
      setStep(CHOICE_VARIANT_STEP);
    },
    [contact]
  );

  const handleClose = useCallback(() => {
    onClose();
    setStep(CHOICE_CONTACT_STEP);
    setSelectedContact(null);
    setContacts([]);
  }, [onClose]);

  const merge = useCallback(async () => {
    try {
      setIsLoading(true);
      const mergingContacts = {
        fromContact: contacts.find(c => c.id !== selectedContact.id).id,
        toContact: selectedContact.id
      };

      await dispatch(mergeContacts(mergingContacts));
      const { newContact } = await dispatch(
        fetchMergedContact({ initialContact: contact.id, ...mergingContacts })
      );
      socket.joinRooms([newContact]);

      routes.toContact({ id: newContact.id, isReplace: true });
      handleClose();
      showNoticeMessage({ number: NOTICE_NUMBER.contactsMerged });
    } finally {
      setIsLoading(false);
    }
  }, [
    contact.id,
    contacts,
    dispatch,
    handleClose,
    routes,
    selectedContact,
    socket
  ]);

  const component = useMemo(
    () => ({
      [CHOICE_CONTACT_STEP]: (
        <ChoiceContactStep contactId={contact.id} onSubmit={selectContact} />
      ),
      [CHOICE_VARIANT_STEP]: (
        <ChoiceVariantStep
          isLoading={isLoading}
          contacts={contacts}
          selectedContact={selectedContact}
          setSelectedContact={setSelectedContact}
          onSubmit={merge}
        />
      )
    }),
    [contact, contacts, isLoading, merge, selectContact, selectedContact]
  );

  return {
    step: component[step],
    handleClose
  };
};

export default useMergingContacts;

import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Translation } from 'react-i18next';

import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import Button from 'components/common/button';
import Icon from 'components/common/icon';

import { useModalsService } from 'services/modals';

import styles from './create-task-btn.module.scss';

const CreatRelatedTaskBtn = ({
  initialValue,
  className,
  isDisabled,
  type,
  size,
  width,
  createWithUrl,
  name,
  callback,
  children
}) => {
  const modals = useModalsService();

  const [visibleDrawer, setVisibleDrawer] = useState(false);

  const handlerCreateTask = () =>
    modals.tasks.showCreator({
      initialData: initialValue
    });

  const isTypeLink = type === 'link';

  const onClick = async () => {
    await callback();

    return createWithUrl ? handlerCreateTask() : setVisibleDrawer(true);
  };

  return (
    <>
      <Button
        className={classnames({ [styles.btnLink]: isTypeLink }, className)}
        data-qa="qa-k1ztf0uujthnmm5"
        type={type}
        onClick={onClick}
        disabled={
          isDisabled !== undefined
            ? isDisabled
            : !Object.keys(initialValue).length
        }
        size={size}
        width={width}
      >
        {children || (
          <>
            {isTypeLink && (
              <Icon type="snippets" size={16} className={styles.icon} />
            )}
            {name}
          </>
        )}
      </Button>

      <TaskCreatorDrawer
        visible={visibleDrawer}
        onClose={() => setVisibleDrawer(false)}
        value={initialValue}
      />
    </>
  );
};

CreatRelatedTaskBtn.propTypes = {
  initialValue: PropTypes.object.isRequired,
  className: PropTypes.string,
  isDisabled: PropTypes.bool,
  type: PropTypes.string,
  size: PropTypes.string,
  createWithUrl: PropTypes.bool,
  name: PropTypes.string,
  callback: PropTypes.func
};

CreatRelatedTaskBtn.defaultProps = {
  className: undefined,
  isDisabled: undefined,
  type: 'primary',
  size: 'large',
  createWithUrl: true,
  name: <Translation ns="Common">{t => t('AddTask')}</Translation>,
  callback: () => {}
};

export default CreatRelatedTaskBtn;

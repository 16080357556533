import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { VIDEOS } from 'constants/videos';

import EstimateModal from 'components/common/estimate';
import VideoBtn from 'components/common/video-btn';

import { createWorklog } from 'store/tasks';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';

export const EditorWorklogModal = ({ handleSubmit, task, ...props }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();
  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('TrackTimeModal');

  const onSubmit = async ({ value }) => {
    try {
      setIsLoading(true);
      await dispatch(createWorklog({ id: task.id, value }));

      amplitude.changeWorklog({ value, task });

      handleSubmit();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <EstimateModal
      title={t('TrackTimeModalHeading')}
      estimateFormProps={{
        onlySave: true,
        isLoading,
        onSubmit
      }}
      {...props}
    >
      <VideoBtn slug={VIDEOS.workWithTimeLog} style={{ marginTop: 20 }} />
    </EstimateModal>
  );
};

export default EditorWorklogModal;

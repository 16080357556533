import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { CONTACT } from 'constants/index';
import { LINK_TYPE } from 'constants/router';

import Avatar from 'components/common/avatar';
import Typography from 'components/common/typography';
import ContactAvatar from 'components/common/avatar/contact';

import { getFIO, getFullName } from 'utils/get-fio';
import useRoutesService from 'services/routes';

import Option from '../option';

import styles from './user-option.module.scss';

export const UserOption = ({
  option,
  className,
  withWorkspace,
  withAllName,
  children,
  hidePosition,
  selectType,
  isLinkToElement,
  optionId,
  hasRelatedProject,
  ellipsis,
  isInInput,
  showEmail,
  ...props
}) => {
  const { t } = useTranslation('Common');

  const {
    workspaceTitle,
    position,
    email,
    phone,
    isOnline,
    avatarFile,
    isProjectParticipant,
    isActive
  } = option;

  const workspace = workspaceTitle || position;
  const routes = useRoutesService({ returnUrl: true });
  const name =
    typeof option === 'string'
      ? option
      : getFullName({ ...option, allName: withAllName }) ||
        email ||
        phone ||
        children;

  const urlBySelectOption = {
    [LINK_TYPE.CONTACT]: routes.toContact({ id: optionId })
  };

  const title = withWorkspace && workspace ? `${name} (${workspace})` : name;

  const UserInfo = () => (
    <div
      style={{
        display: 'flex',
        alignItems: 'center',
        marginBottom: hidePosition ? 0 : 4
      }}
    >
      {selectType === CONTACT && !isOnline ? (
        <ContactAvatar size={24} contact={option} style={{ marginRight: 8 }} />
      ) : (
        <Avatar
          src={(avatarFile || {}).url}
          size={24}
          style={{ marginRight: 8, flexShrink: 0 }}
          isActive={isActive}
        >
          {getFIO({ ...option, lastName: name })}
        </Avatar>
      )}

      <Typography.Text
        className={isLinkToElement ? styles.textPrimary : ''}
        ellipsis={isInInput || ellipsis}
      >
        {title}
      </Typography.Text>

      {hasRelatedProject && isProjectParticipant && (
        <Typography.Text color="black-55" style={{ marginLeft: 8 }}>
          {t('ProjectMember')}
        </Typography.Text>
      )}
    </div>
  );

  return (
    <Option
      className={classnames(className, styles.root)}
      contentClassName={styles.content}
      title={title}
      ellipsis={isInInput || ellipsis}
      {...props}
    >
      {isLinkToElement ? (
        <Link to={urlBySelectOption[selectType]}>
          <UserInfo />
        </Link>
      ) : (
        <UserInfo />
      )}

      {position && !hidePosition && (
        <Typography.Text ellipsis size="small">
          {position}
        </Typography.Text>
      )}

      {email && showEmail && (
        <Typography.Text ellipsis size="small">
          {email}
        </Typography.Text>
      )}
    </Option>
  );
};

UserOption.propTypes = {
  option: PropTypes.shape({
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    phone: PropTypes.string
  }).isRequired,
  withWorkspace: PropTypes.bool,
  hidePosition: PropTypes.bool,
  withAllName: PropTypes.bool,
  isLinkToElement: PropTypes.bool,
  selectType: PropTypes.string,
  optionId: PropTypes.number,
  hasRelatedProject: PropTypes.bool,
  ellipsis: PropTypes.bool,
  showEmail: PropTypes.bool
};

UserOption.defaultProps = {
  withWorkspace: false,
  hidePosition: false,
  withAllName: true,
  isLinkToElement: false,
  selectType: '',
  optionId: null,
  hasRelatedProject: false,
  ellipsis: true,
  showEmail: false
};

export default UserOption;

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Tooltip, Skeleton } from 'antd';
import { Link } from 'react-router-dom';
import classnames from 'classnames';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import Avatar from 'components/common/avatar';
import Typography from 'components/common/typography';
import CollapsePanel from 'components/common/collapse-panel';
import BackBtn from 'components/common/back-btn';
import EventStatus from 'components/common/event-status';
import { getOrderStatusStates } from 'components/orders-view/utils';
import ContactAvatar from 'components/common/avatar/contact';

import {
  getOrderStatusesEntries,
  getOrderStatusesIsAllLoading
} from 'store/order-statuses';

import getFIO, { getFullName } from 'utils/get-fio';
import useModalsService from 'services/modals';
import useRoutesService from 'services/routes';

import IconId from '../icon-id';
import useSmallListOpened from './use-small-list-opened';
import useCollapsedContractorPanel from '../contractor-panel/use-collapsed-contractor-panel';

import styles from './small-list.module.scss';

const Card = ({ orderStatus, selectedId }) => {
  const modals = useModalsService({ returnUrl: true });

  const { t } = useTranslation('Order');

  const {
    id,
    orderId,
    contractorId,
    contractor,
    responsible,
    title,
    state,
    kind
  } = orderStatus;

  const isActive = useMemo(() => selectedId === id, [id, selectedId]);

  const getLink = useCallback(
    orderStatusId => modals.orders.showDetails({ orderStatusId, contractorId }),
    [contractorId, modals.orders]
  );

  const renderUser = useCallback(({ user, role, isContractor }) => {
    if (!user) {
      return (
        <Skeleton
          active
          avatar={{ shape: 'circle', size: 20 }}
          title={false}
          paragraph={false}
          className={styles.skeletonUser}
        />
      );
    }

    const getUserWorkspaceTitle = () => {
      if (!isContractor) {
        return null;
      }

      return user.company.title;
    };

    const workspaceTitle = getUserWorkspaceTitle();

    return (
      <Tooltip
        title={
          <>
            <Typography.Paragraph
              color="white"
              size="small"
              weight="semibold"
              className={styles.tooltipParagraph}
            >
              {getFullName(user)}
            </Typography.Paragraph>

            {workspaceTitle && (
              <Typography.Paragraph
                color="white"
                size="small"
                weight="semibold"
                className={styles.tooltipParagraph}
              >
                {workspaceTitle}
              </Typography.Paragraph>
            )}

            <Typography.Paragraph
              color="white"
              size="small"
              weight="semibold"
              className={styles.tooltipParagraph}
            >
              {role}
            </Typography.Paragraph>
          </>
        }
        mouseEnterDelay={0.5}
      >
        {isContractor ? (
          <ContactAvatar size={20} contact={user} />
        ) : (
          <Avatar size={20} src={(user.avatarFile || {}).url}>
            {getFIO(user)}
          </Avatar>
        )}
      </Tooltip>
    );
  }, []);

  return (
    <Link
      className={classnames(styles.card, {
        [styles.active]: isActive
      })}
      data-qa="qa-5gqp3gtbyb04xu1"
      to={getLink(id)}
    >
      <div className={styles.head} data-qa="qa-4lnub3vxlg0yonb">
        <IconId orderId={orderId} contractor={contractor} />

        <EventStatus
          activeStatus={state}
          statuses={getOrderStatusStates(kind)}
          className={styles.status}
        />
      </div>

      <Typography.Paragraph
        ellipsis={{ rows: 2 }}
        weight="semibold"
        className={styles.title}
      >
        <Tooltip title={title} placement="top" mouseEnterDelay={0.5}>
          {title}
        </Tooltip>
      </Typography.Paragraph>

      <div className={styles.users}>
        {renderUser({
          user: contractor,
          isContractor: true,
          role: t('Contractor')
        })}

        {renderUser({
          user: responsible,
          isContractor: false,
          role: t('OrderResponsible')
        })}
      </div>
    </Link>
  );
};

const SkeletonCard = () => (
  <Skeleton
    active
    avatar={{ shape: 'square', size: 16 }}
    title={{
      className: styles.skeletonTitle
    }}
    paragraph={{
      className: styles.skeletonParagraph
    }}
    className={styles.card}
  />
);

export const SmallList = ({ selectedId }) => {
  const orderStatuses = useSelector(getOrderStatusesEntries);
  const isAllLoading = useSelector(getOrderStatusesIsAllLoading);

  const routes = useRoutesService();

  const [, setCollsedContractor] = useCollapsedContractorPanel();
  const openedCallback = useCallback(
    opened => {
      if (opened) {
        setCollsedContractor(true);
      }
    },
    [setCollsedContractor]
  );

  const [opened, setOpened] = useSmallListOpened(openedCallback);

  const { t } = useTranslation(['Order', 'Common']);

  return (
    <CollapsePanel
      contentClassName={styles.smallListContent}
      head={
        <BackBtn
          onClick={routes.toOrderStatuses}
          className={styles.backBtn}
          data-qa="qa-3lupvjgdlbmt1rm"
        >
          {t('BackBtn', { ns: 'Common' })}
        </BackBtn>
      }
      offsetHead={{
        right: 4,
        top: 24
      }}
      headClassName={styles.smallListHead}
      labels={{
        back: t('BackBtn', { ns: 'Common' }),
        close: t('CollapseOrderListTip'),
        open: t('ExpandOrderListTip')
      }}
      data-qa="qa-jjb51hs60ysblqy"
      sizes={{
        minWidth: 260,
        maxWidth: 260,
        width: 260,
        collapseWidth: 48
      }}
      outerState={{
        opened,
        setOpened
      }}
    >
      <div className={styles.root}>
        {!isAllLoading &&
          orderStatuses.map(orderStatus => (
            <Card
              key={`small-list-card-${orderStatus.id}`}
              orderStatus={orderStatus}
              selectedId={selectedId}
            />
          ))}

        {isAllLoading &&
          [...Array(10).keys()].map(key => (
            <SkeletonCard key={`small-list-skelton-card-${key}`} />
          ))}
      </div>
    </CollapsePanel>
  );
};

SmallList.propTypes = {
  selectedId: PropTypes.string.isRequired
};

export default SmallList;

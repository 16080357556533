import React from 'react';
import isUrl from 'is-url';
import { get, map } from 'lodash';
import { Typography, Icon } from 'antd';
import classnames from 'classnames';

import {
  TYPE_TASK,
  TYPE_ORDER,
  TYPE_CONTACT,
  TYPE_ASSET,
  TYPE_ORDER_STATUS
} from 'constants/index';

import Markdown from '../markdown';
import { IdeaIcon } from '../icons';
import { getFullName } from '../../../utils/get-fio';
import { convertLinkToMarkdown } from '../markdown/helpers';

import styles from './converters.module.scss';

const { Text } = Typography;

export const MENTION = 'mention';
export const MENTION_AT = '@';
export const LINK = 'link';
export const contentTypes = {
  link: LINK,
  mention: MENTION_AT,
  mentionText: MENTION,
  text: 'text'
};

const { text, link, mention } = contentTypes;

const contentTypesList = Object.values(contentTypes);

export const checkContentType = content => {
  let result = text;

  if (isUrl(content)) result = link;
  if (content[content.length - 1] === MENTION_AT) result = mention;

  return result;
};

const getFirstContentType = current =>
  contentTypesList.filter(key => {
    const currentTypes = Object.keys(current);
    const keyIndex = currentTypes.indexOf(key);

    if (keyIndex > -1) return key;
    return false;
  })[0];

const prepareMessage = (message, mentions) => {
  let result = [];

  if (mentions.length) {
    const usersList = mentions
      .map(mentionItem => `(${MENTION_AT}${mentionItem[MENTION_AT].login})`)
      .join('|');
    const array = message.split(new RegExp(usersList, 'g'));
    result = [...array];
  } else {
    result.push(message);
  }

  result = result.filter(item => !!item);

  result.forEach((string, index) => {
    mentions.forEach(mentionItem => {
      const { id, login } = mentionItem[MENTION_AT];
      const userString = `${MENTION_AT}${login}`;
      if (userString === string) {
        result[index] = { [MENTION_AT]: { id, login } };
      }
    });
  });

  const finalArr = [];

  result.forEach((item, index) => {
    if (typeof item !== 'string') {
      finalArr.push(item);
      return;
    }
    const splittedString = item.split(' ').filter(string => !!string);
    finalArr.splice(index, 1, ...splittedString);
  });

  return finalArr;
};

export const convertMessageToArr = (message, mentions = []) => {
  if (!(message && message.length)) return [];

  const sourceArray = prepareMessage(message, mentions);

  return sourceArray.map(item => {
    if (typeof item !== 'string') return item;
    const contentType = checkContentType(item);
    switch (contentType) {
      case link:
        return { link: item };
      default:
        return { text: item };
    }
  });
};

const isConvertible = data => data && Array.isArray(data) && data.length;

export const convertMessageToString = messageInArr => {
  if (Array.isArray(messageInArr) && !messageInArr.length) return '';

  if (!isConvertible(messageInArr)) return messageInArr;

  const filteredArr = messageInArr.reduce(
    (acc, current) => acc.concat([current]),
    []
  );

  return filteredArr.reduce((acc, curr) => {
    const contentType = getFirstContentType(curr);

    switch (contentType) {
      case link:
        return acc.concat(` [${curr.link}](${curr.link})`);
      case text:
        return acc.length > 0 ? acc.concat(` ${curr.text}`) : curr.text;
      case mention:
        return acc.concat(` @${curr['@'].login}`);
      default:
        return acc;
    }
  }, '');
};

export const convertMessageToNode = messageInArr => {
  if (!isConvertible(messageInArr)) return messageInArr;

  const messageNode = messageInArr.reduce((acc, curr) => {
    const contentType = getFirstContentType(curr);

    const str = curr[text];

    switch (contentType) {
      case text:
        return [acc, str].join(' ').replace(/^ +| +$/gm, '');
      case link:
        return [acc, convertLinkToMarkdown(curr)]
          .join(' ')
          .replace(/^ +| +$/gm, '');
      default:
        return acc;
    }
  }, '');

  return <Markdown>{messageNode}</Markdown>;
};

// [{ text: '' }, { @: '' }, { text: '' }]
export const hasContent = (content = []) => {
  if ((content || []).length > 1) {
    return true;
  }

  if (content.length === 1) {
    return content[0].text !== '';
  }

  return false;
};

const convertSlateData = dataArr =>
  dataArr.map(item => {
    const { type } = item;
    if (type) {
      switch (type) {
        case link:
          return {
            [link]: item.url,
            text: convertMessageToString(item.children)
          };
        case MENTION:
          // eslint-disable-next-line no-case-declarations
          const { character } = item || {};
          if (!character) break;
          // eslint-disable-next-line no-case-declarations
          const { id, firstName, lastName } = character;
          return {
            [mention]: { id, login: getFullName({ lastName, firstName }) }
          };
        default:
          break;
      }
    }

    if (item.text !== undefined) return { text: item.text };

    return undefined;
  });

export const adoptMessageObject = messageArr =>
  messageArr
    .map(({ children }, index) => {
      if (messageArr.length - 1 !== index) {
        return [...convertSlateData(children), { text: '\n' }];
      }

      return convertSlateData(children);
    })
    .reduce((acc, curr) => acc.concat(curr));

export const hasDescription = (description = []) =>
  !!description.find(({ children }) =>
    children.find(c =>
      Object.keys(c).find(key => typeof c[key] === 'string' && !!c[key].trim())
    )
  );

export const renderDescriptions = description => {
  if (!description) {
    return null;
  }

  // проверка на стринг нужна для прежде созданных идей
  if (typeof description === 'string') {
    return <Markdown>{description}</Markdown>;
  }

  return description.map(
    (item, i) => 'text' in item && <Markdown key={i}>{item.text}</Markdown>
  );
};

export const convertLog = (content, routes, modals) =>
  content
    .map(item => {
      if (item.id) {
        switch (item.type) {
          case TYPE_TASK:
            return convertLinkToMarkdown({
              link: `${routes.toTasks()}${modals.tasks.showDetails({
                id: item.id
              })}`,
              text: item.text
            });
          case TYPE_ORDER:
          case TYPE_ORDER_STATUS:
            return convertLinkToMarkdown({
              link: `${routes.toOrderStatuses()}${modals.orders.showDetails({
                orderStatusId: item.id
              })}`,
              text: item.text
            });
          case TYPE_CONTACT:
            return convertLinkToMarkdown({
              link: routes.toContact({ id: item.id }),
              text: item.text
            });
          case TYPE_ASSET:
            return convertLinkToMarkdown({
              link: `${routes.toAssets()}${modals.assets.showDetails({
                id: item.id
              })}`,
              text: item.text
            });
          default:
            return item.text;
        }
      }

      return item.text;
    })
    .join(' ');

// used in convertToNode
export const convertLogToObject = (content, routes, modals) => {
  const eventTypes = {
    task: TYPE_TASK,
    order: TYPE_ORDER,
    orderStatus: 'order-status',
    contact: TYPE_CONTACT,
    asset: TYPE_ASSET,
    channelChat: 'channel-chat',
    idea: 'idea',
    changes: 'changes',
    employee: 'employee'
  };

  const eventContent = {
    id: '',
    icon: '',
    onClick: () => {},
    name: '',
    text: '',
    textName: ''
  };

  const eventData = content.reduce((result, item) => {
    Object.keys(item).forEach(key => {
      if (key === 'text' && result[key]) {
        // "text" может присутствовать в двух местах, переименовываем чтобы не перезаписалось
        result.textName = item[key];
        return result;
      }
      result[key] = item[key];
    });

    return result;
  }, {});

  switch (eventData.type) {
    case eventTypes.task:
      eventContent.id = eventData.id;
      eventContent.name = eventData.name;
      eventContent.title = eventData.title;

      eventContent.text = eventData.text;
      eventContent.textName = eventData.textName;
      eventContent.onClick = () =>
        modals.tasks.showDetails({ id: eventData.id });
      break;
    case eventTypes.order:
    case eventTypes.orderStatus:
      eventContent.id = eventData.orderId || eventData.id;
      eventContent.name = eventData.name;
      eventContent.title = eventData.title;

      eventContent.text = eventData.text;
      eventContent.textName = eventData.textName;
      eventContent.onClick = () => {
        modals.orders.showDetails({
          orderStatusId: eventData.id
        });
      };
      break;
    case eventTypes.contact:
      eventContent.text = eventData.text;
      eventContent.textName = eventData.textName;
      eventContent.onClick = () => routes.toContact({ id: eventData.id });
      break;
    case eventTypes.asset:
      eventContent.text = eventData.text;
      eventContent.textName = eventData.textName;
      eventContent.onClick = () =>
        modals.assets.showDetails({ id: eventData.id });
      break;
    case eventTypes.channelChat:
      eventContent.text = eventData.text;
      eventContent.textName = eventData.textName;
      break;
    case eventTypes.idea:
      eventContent.text = eventData.text;
      eventContent.id = eventData.id;
      eventContent.icon = (
        <Icon component={IdeaIcon} className={styles.ideaIcon} />
      );
      eventContent.onClick = () =>
        modals.ideas.showIdeaDetails(eventData.ideaId);
      eventContent.name = eventData.ideaName;
      break;
    case eventTypes.changes:
      eventContent.text = eventData.text;
      break;
    case eventTypes.employee:
      eventContent.text = eventData.text;
      eventContent.name = eventData.name;
      break;
    default:
      eventContent.text = eventData.text;
      eventContent.name = eventData.textName;
  }

  return eventContent;
};

// used in contact company history
export const convertToNode = (content, routes, modals) => {
  const eventContent = convertLogToObject(content, routes, modals);
  const hasOnClick = eventContent.onClick instanceof Function;
  const formattedText = eventContent.text.replace(/(-|1\.) /g, '\n$1 ');

  return (
    <>
      <Text className={styles.eventText} data-qa="qa-zb3grdjg1du5212">
        {renderDescriptions(formattedText)}{' '}
      </Text>
      <div
        className={classnames(styles.event, {
          [styles.link]: hasOnClick
        })}
        data-qa="qa-v6urw8wa5ty2xh1"
        onClick={hasOnClick ? () => eventContent.onClick() : undefined}
      >
        {eventContent.icon && (
          <div className={styles.eventIcon} data-qa="qa-me3wtf983qo7ueb">
            {eventContent.icon}
          </div>
        )}

        {eventContent.textName ? (
          <Text className={styles.id} data-qa="qa-nunu2g5ir05wzab">
            {eventContent.textName}
          </Text>
        ) : (
          <>
            {eventContent.id && (
              <Text className={styles.id} data-qa="qa-nunu2g5ir05wzab">
                ID {eventContent.id} {eventContent.title || ''}
              </Text>
            )}

            {eventContent.name && (
              <Markdown
                className={styles.eventText}
                data-qa="qa-1gbg7lnsf6qbgbx"
              >
                {eventContent.name}
              </Markdown>
            )}
          </>
        )}
      </div>
    </>
  );
};

export const removeEmptyLink = data => {
  const newData = [...data].map(item => ({
    ...item,
    children: map(item.children, child => {
      if (child.type === LINK && !get(child, 'children[0].text.length', 0)) {
        return { text: '' };
      }

      return child;
    })
  }));

  return newData;
};

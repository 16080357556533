import React, { useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Button from 'components/common/button';

import Card from './card';

import styles from '../merging-contacts.module.scss';

const ChoiceVariantStep = ({
  contacts,
  selectedContact,
  isLoading,
  setSelectedContact,
  onSubmit
}) => {
  const { t } = useTranslation('СombineContacts');

  const hasAllOnlineContacts = useMemo(
    () => contacts.every(contact => !!contact.employee),
    [contacts]
  );

  const onlineContact = useMemo(
    () => contacts.find(contact => !!contact.employee),
    [contacts]
  );

  const hasOneOnlineContact = useMemo(
    () => !hasAllOnlineContacts && onlineContact,
    [hasAllOnlineContacts, onlineContact]
  );

  const description = useMemo(() => {
    if (hasAllOnlineContacts) {
      return t('СombineContactsRegisteredDesc');
    }

    if (hasOneOnlineContact) {
      return t('СombineContactsDifferentDesc');
    }

    return t('СombineContactsNotRegisteredDesc');
  }, [hasAllOnlineContacts, hasOneOnlineContact, t]);

  useEffect(() => {
    if (hasAllOnlineContacts) {
      setSelectedContact({});
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasAllOnlineContacts]);

  useEffect(() => {
    if (hasOneOnlineContact) {
      setSelectedContact(onlineContact);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hasOneOnlineContact]);

  return (
    <div>
      <Typography.Paragraph>{description}</Typography.Paragraph>

      <div className={styles.cards}>
        {contacts.map(contact => (
          <Card
            key={`merging-contact-card-${contact.id}`}
            contact={contact}
            isDisabled={hasAllOnlineContacts || hasOneOnlineContact}
            isSelected={contact.id === selectedContact.id}
            select={setSelectedContact}
          />
        ))}
      </div>

      {!hasAllOnlineContacts && (
        <Button
          type="primary"
          width="expanded"
          loading={isLoading}
          className={styles.button}
          onClick={onSubmit}
        >
          {t('SaveBtn')}
        </Button>
      )}
    </div>
  );
};

ChoiceVariantStep.propTypes = {
  contacts: PropTypes.arrayOf(PropTypes.shape({})).isRequired,
  selectedContact: PropTypes.shape({}),
  setSelectedContact: PropTypes.func.isRequired,
  isLoading: PropTypes.bool.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ChoiceVariantStep.defaultProps = {
  selectedContact: {}
};

export default ChoiceVariantStep;

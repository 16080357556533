import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  ASSIGNMENTS_CHANGE_DRAWER,
  CLICK_ORDER_ATTACHMENT_EVENT,
  RELATIONS_DRAWER
} from 'constants/index';

import EntityInfoButtons from 'components/common/entity-info-buttons';
// import PaymentsDrawer from 'components/orders-view/drawers/payments';

import {
  changeMembers,
  fetchOrderStatus,
  removeOrderStatusFromEntries,
  changeOrderStatusRelations,
  fetchOrderStatusRelations
} from 'store/order-statuses';
import { setVisibleDrawer } from 'store/drawers';
import { fetchEmployeesLocal } from 'store/team/employees';

import { NOTICE_NUMBER } from 'services/notice';
import useAmplitude from 'hooks/amplitude/use-amplitude';
import useRoutesService from 'services/routes';

import DocumentsDrawer from '../../documents';

import styles from './info-buttons.module.scss';

const InfoButtons = ({ orderStatus }) => {
  const dispatch = useDispatch();

  const amplitdue = useAmplitude();
  const routes = useRoutesService();

  const [visibleDocuments, setVisibleDocuments] = useState(false);
  // const [visiblePayments, setVisiblePayments] = useState(false);

  const { t } = useTranslation(['Order', 'OrderCoexecutors', 'OrderLinks']);

  const {
    id,
    documentList = [],
    members,
    permissions,
    relations = []
  } = orderStatus;

  const { canAssignMember, canAssignRelation } = permissions;

  const fetchMembers = useCallback(async () => {
    if (!members.length) {
      return [];
    }

    const { entries } = await dispatch(
      fetchEmployeesLocal({ id: members, limit: 10000, excludeBench: false })
    );
    return entries;
  }, [dispatch, members]);

  const editMembers = async ({ added, deleted }) => {
    if (deleted.length) {
      await dispatch(
        changeMembers({
          id,
          employees: deleted,
          isDelete: true
        })
      );
    }

    if (added.length) {
      await dispatch(
        changeMembers({
          id,
          employees: added,
          isDelete: false
        })
      );
    }

    try {
      await dispatch(fetchOrderStatus({ id }));
    } catch ({ response }) {
      if (response.status === 403 || response.status === 404) {
        dispatch(removeOrderStatusFromEntries(id));
        routes.toOrderStatuses({ isReplace: true });
      }
    }
  };

  const fetchRelaltions = useCallback(
    () => dispatch(fetchOrderStatusRelations({ id })),
    [dispatch, id]
  );

  const editRelations = useCallback(
    async ({ added, deleted }) => {
      const result = [
        ...deleted.map(d => ({ ...d, isDelete: true })),
        ...added.map(d => ({ ...d, isDelete: false }))
      ];

      if (result.length) {
        await dispatch(
          changeOrderStatusRelations({
            id,
            relations: result
          })
        );

        await dispatch(fetchOrderStatusRelations({ id }));
      }
    },
    [dispatch, id]
  );

  const showAssignmentsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: ASSIGNMENTS_CHANGE_DRAWER,
        data: {
          title: t('OrderCoxecutorsHeading', { ns: 'OrderCoexecutors' }),
          description: t('OrderCoexecutorsDesc', { ns: 'OrderCoexecutors' }),
          employeeListTitle: t('OrderCoexecutorsListText', {
            ns: 'OrderCoexecutors'
          }),
          submitText: t('SaveBtn', { ns: 'OrderCoexecutors' }),
          selectProps: {
            label: t('Coexecutors', { ns: 'OrderCoexecutors' })
          },
          noticeNumber: NOTICE_NUMBER.memberListSaved,
          permissions: {
            allowAdd: canAssignMember,
            allowDelete: canAssignMember
          },
          fetch: fetchMembers,
          submit: editMembers
        }
      })
    );

  const showRelationsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: RELATIONS_DRAWER,
        data: {
          title: t('OrderLinksHeading', { ns: 'OrderLinks' }),
          allowEdit: canAssignRelation,
          description: t('OrderLinksDesc', { ns: 'OrderLinks' }),
          fetch: fetchRelaltions,
          submit: editRelations
        }
      })
    );

  const buttons = [
    {
      icon: 'big-team',
      text: t('CoexecutorsBtn'),
      allow: true,
      count: members.length,
      onClick: showAssignmentsDrawer
    },
    {
      icon: 'copy',
      text: t('DocumentsBtn'),
      allow: true,
      count: documentList.length,
      onClick: () => {
        setVisibleDocuments(true);
        amplitdue.logEvent({ event: CLICK_ORDER_ATTACHMENT_EVENT });
      }
    },
    {
      icon: 'link',
      text: t('LinksBtn'),
      allow: true,
      count: relations.length,
      onClick: showRelationsDrawer
    }
  ]
    .filter(b => b.allow)
    .map(({ allow, ...button }) => button);

  return (
    <>
      <EntityInfoButtons buttons={buttons} className={styles.root} />

      <DocumentsDrawer
        visible={visibleDocuments}
        orderStatus={orderStatus}
        onClose={() => setVisibleDocuments(false)}
      />

      {/* <PaymentsDrawer
        visible={visiblePayments}
        orderStatus={orderStatus}
        onClose={() => setVisiblePayments(false)}
      /> */}
    </>
  );
};

InfoButtons.propTypes = {
  orderStatus: PropTypes.shape({
    statusId: PropTypes.number,
    chatId: PropTypes.number
  }).isRequired
  // scrollAndShowAssetTooltip: PropTypes.func
};

InfoButtons.defaultProps = {
  // scrollAndShowAssetTooltip: () => {}
};

export default InfoButtons;

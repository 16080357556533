import React, { useEffect, useState } from 'react';
import moment from 'moment';
import { Table, Empty } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import uniq from 'lodash/uniq';
import { useTranslation } from 'react-i18next';

import {
  DATE_TIME_FORMAT,
  TYPE_ATTACHMENT,
  TYPE_ORDER_STATUS
} from 'constants/index';

import { MyDriveIcon } from 'components/common/icons';
import Icon from 'components/common/icon';
import UserCardSmall from 'components/common/user-card-small';
import VideoBtn from 'components/common/video-btn';
import Typography from 'components/common/typography';
import AttachmentActions from 'components/attachments-view/actions';
import { TagList } from 'components/common/tags';

import { fetchOrderStatusesByIds } from 'store/order-statuses';

import formatBytes from 'utils/format-bytes';

import Entities from './components/entities';
import FileTitle from '../file-title';
import Content from './components/content';

import styles from './table.module.scss';

export const AttachmentsTable = ({
  data,
  pagination,
  setSorting,
  isLoading,
  isTrash,
  className,
  scroll,
  locale,
  rowSelection,
  videoBtnSlug,
  actionsDeps,
  onCloseAttachmentModal,
  fileLinkTarget,
  filters,
  ...props
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('MyDrive');

  const [orderStatuses, setOrderStatuses] = useState(null);

  const { sorting, search } = filters;

  const onChangeSorting = (_, __, sorter) =>
    setSorting({
      sortField: (sorter || {}).columnKey,
      sortDirection: (sorter || {}).order
    });

  const getSortOrderByFileld = field =>
    (sorting.sortField === field && sorting.sortDirection) || false;

  const hasData = data.length > 0;

  const fetchOrderStatusIds = async () => {
    const orderStatusIds = data.reduce(
      (acc, curr) => [
        ...acc,
        ...curr.entities.filter(e => e.type === TYPE_ORDER_STATUS)
      ],
      []
    );

    if (orderStatusIds.length) {
      const { _embedded } = await dispatch(
        fetchOrderStatusesByIds({
          ids: uniq(orderStatusIds.map(os => os.objectId))
        })
      );

      if (_embedded) {
        const { orderStatuses: fetchedOrderStatuses } = _embedded;
        setOrderStatuses(fetchedOrderStatuses);
      }
    }
  };

  useEffect(() => {
    if (hasData) {
      setOrderStatuses(null);
      fetchOrderStatusIds();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [data]);

  const columns = [
    {
      key: 'title',
      title: t('Title'),
      width: 240,
      sortOrder: getSortOrderByFileld('title'),
      sortDirections: ['descend', 'ascend'],
      sorter: hasData,
      render: file => (
        <div className={styles.title}>
          <Icon
            type="attachment"
            size={20}
            style={{ marginRight: 12 }}
            color="brand"
          />

          <FileTitle
            isDisabled={isTrash}
            target={fileLinkTarget}
            file={file}
            actionsDeps={actionsDeps}
          />
        </div>
      )
    },
    {
      key: 'content',
      title: t('Content'),
      allow: !!search && !!(data[0] || {}).highlight,
      width: 240,
      render: ({ highlight }) =>
        highlight && (highlight.content || []).length ? (
          <Content content={highlight.content} />
        ) : (
          '-'
        )
    },
    {
      key: 'entities',
      title: t('Link'),
      dataIndex: 'entities',
      render: entities => (
        <Entities items={entities} orderStatuses={orderStatuses} />
      )
    },
    {
      key: 'tags',
      title: t('Tags'),
      render: file => (
        <TagList
          entityType={TYPE_ATTACHMENT}
          entityId={file.id}
          displayedCount={1}
          shortAddButton
          allowAddTag={!isTrash}
          allowDeleteTag={!isTrash}
        />
      )
    },
    {
      key: 'creator',
      title: t('Author'),
      dataIndex: 'creator',
      sortOrder: getSortOrderByFileld('creator'),
      sortDirections: ['descend', 'ascend'],
      sorter: hasData,
      render: creator => (
        <UserCardSmall asLink className={styles.creator} {...creator} />
      )
    },
    {
      key: 'created_at',
      title: t('Added'),
      width: 150,
      dataIndex: 'createdAt',
      sortOrder: getSortOrderByFileld('created_at'),
      sortDirections: ['descend', 'ascend'],
      sorter: hasData,
      render: createdAt => (
        <Typography.Text>
          {moment(createdAt).format(DATE_TIME_FORMAT)}
        </Typography.Text>
      )
    },
    {
      key: 'file_size',
      title: t('Size'),
      dataIndex: 'fileSize',
      width: 100,
      sorter: hasData,
      sortOrder: getSortOrderByFileld('file_size'),
      sortDirections: ['descend', 'ascend'],
      render: fileSize => (
        <Typography.Text>{formatBytes(fileSize)}</Typography.Text>
      )
    },
    {
      key: 'actions',
      title: '',
      width: 60,
      allow: true,
      render: file => (
        <AttachmentActions
          file={file}
          actionsDeps={{ ...actionsDeps, allowDeleteFrom: false }}
          onCloseAttachmentModal={onCloseAttachmentModal}
          getPopupContainer={null}
          btnClassName={styles.actionsBtn}
        />
      ),
      fixed: 'right'
    }
  ];

  const getAllowedColumns = () =>
    columns.filter(({ allow }) => allow !== false);

  return (
    <Table
      columns={getAllowedColumns()}
      rowKey="id"
      dataSource={data}
      pagination={{
        hideOnSinglePage: true,
        pageSize: 50,
        ...pagination
      }}
      scroll={{ y: 500, x: 1100, ...scroll }}
      locale={{
        emptyText: isLoading ? (
          `${t('Loading', { ns: 'Common' })}...`
        ) : (
          <Empty
            image={<Icon component={MyDriveIcon} />}
            imageStyle={{ height: 'auto', marginBottom: 10 }}
            style={{ marginTop: 14, marginBottom: 24 }}
            description={
              <>
                <Typography.Paragraph>
                  {t('FilesNotFound')}
                </Typography.Paragraph>

                {!!videoBtnSlug && (
                  <VideoBtn
                    slug={videoBtnSlug}
                    style={{ margin: '15px auto 0' }}
                  />
                )}
              </>
            }
          />
        ),
        sortTitle: t('SortAlphabetically'),
        ...locale
      }}
      loading={isLoading}
      className={classnames(styles.table, className, {
        [styles.isOpacity]: isTrash
      })}
      rowSelection={hasData ? rowSelection : null}
      onChange={onChangeSorting}
      {...props}
    />
  );
};

AttachmentsTable.propTypes = {
  allowActions: PropTypes.bool,
  filters: PropTypes.shape({
    sorting: PropTypes.shape({
      sortField: PropTypes.string,
      sortDirection: PropTypes.string
    })
  }),
  setSorting: PropTypes.func,
  isLoading: PropTypes.bool,
  isOpacity: PropTypes.bool,
  pagination: PropTypes.object,
  className: PropTypes.string,
  getActions: PropTypes.func,
  scroll: PropTypes.object,
  locale: PropTypes.object,
  rowSelection: PropTypes.object,
  actionsDeps: PropTypes.object,
  data: PropTypes.any,
  fileLinkTarget: PropTypes.string
};

AttachmentsTable.defaultProps = {
  allowActions: true,
  filters: {
    sorting: {
      sortField: '',
      sortDirection: ''
    }
  },
  setSorting: () => {},
  getActions: () => {},
  isLoading: false,
  pagination: {},
  scroll: {},
  locale: {},
  rowSelection: undefined,
  isOpacity: false,
  className: undefined,
  actionsDeps: {},
  data: [],
  fileLinkTarget: '_self'
};

export default AttachmentsTable;

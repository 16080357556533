import React, { useState, useCallback, useEffect } from 'react';
import { useForm, FormProvider } from 'react-hook-form';
import { useLocation } from 'react-router-dom';
import { useDispatch, useSelector } from 'react-redux';
import { Divider, Alert } from 'antd';
import moment from 'moment';
import PropTypes from 'prop-types';
import omit from 'lodash/omit';
import { getCountryCallingCode } from 'libphonenumber-js/min';
import { useWebsocketOperatorContext } from 'providers';
import { useTranslation } from 'react-i18next';

import {
  DATE_FORMAT,
  IS_COMPANY,
  IS_INDIVIDUAL,
  LEGAT_COUNTRY_ALPHA_2_CODES,
  VIDEOS,
  TYPE_TAGS,
  TYPE_CONTACT,
  ONLINE_CHAT,
  DIALOG_TYPE_COMMENT
} from 'constants/index';

// import WorkspaceVerifyModal from 'components/common/workspace-verify-modal';
import ActionsDropdown from 'components/common/actions';
import VideoBtn from 'components/common/video-btn';
import Drawer from 'components/common/drawer';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import {
  ADD_CONTACT,
  FeedbackModal,
  useFeedbackModal
} from 'components/common/feedback-modal';
import {
  FormRadio,
  FormCompanySelect,
  FormInput,
  FormPhoneInput,
  FormTextarea,
  FormDatePicker,
  FormTagsSelect,
  withoutBubbling,
  FormLocationInput
} from 'components/common/hook-form';
import { validatePhone } from 'components/common/hook-form/validators';
import { useUpdateTags } from 'components/common/tags';

import { createContact, fetchStatusDuplicateContacts } from 'store/contacts';
import { changeRelations } from 'store/assets';
import { getActiveWorkspace } from 'store/workspace';

import getIsDemoModeActive from 'utils/get-is-demo-mode-active';
import { capitalize } from 'utils/capitalize';
import useRoutesService from 'services/routes';
import { useAmplitude } from 'hooks/amplitude/use-amplitude';

import RestoreContactModal from './restore-contact-modal';
import EditableField from './editable-field';
import MultipleField from './multiple-field';
import SendInviteModal from '../../send-invite-modal';
import { getCompanyData } from '../../company-select';

const TYPE_DATE_OF_BIRTH = 'birthday';
const TYPE_LOCATION = 'address';

const CONTACT_TYPE = 'contactType';
const COMPANY = 'company';

const PHONE = 'phone';
const EMAIL = 'email';

const Form = ({ defaultValues, isLoading, onSubmit }) => {
  const workspace = useSelector(getActiveWorkspace);

  const methods = useForm({
    defaultValues: {
      ...defaultValues,
      [CONTACT_TYPE]: defaultValues.isIndividual ? IS_INDIVIDUAL : IS_COMPANY,
      [COMPANY]: defaultValues.company || {
        country: {
          value: workspace.country.id,
          label: workspace.country
        }
      },
      [PHONE]: [{ value: { number: defaultValues.phone } }],
      [EMAIL]: [{ value: defaultValues.email }]
    }
  });

  const [activeFields, setActiveFields] = useState([]);

  const { t } = useTranslation(['AddContact', 'Errors']);

  const contactType = methods.watch(CONTACT_TYPE);

  const phones = methods.watch(PHONE);
  const emails = methods.watch(EMAIL);
  const company = methods.watch(COMPANY);

  const alerts = {
    [IS_COMPANY]: {
      message: t('CompanyRepresentativeWarning'),
      type: 'warning'
    },
    [IS_INDIVIDUAL]: {
      message: t('IndividualWarning'),
      type: 'warning'
    }
  };

  const fields = [
    {
      type: TYPE_DATE_OF_BIRTH,
      title: 'DateOfBirth',
      ns: 'AddContact',
      icon: 'calendar',
      allow: true,
      onClick: () => {
        onAddField(TYPE_DATE_OF_BIRTH);
      },
      component: (
        <EditableField onDelete={() => onDeleteField(TYPE_DATE_OF_BIRTH)}>
          <FormDatePicker
            name={TYPE_DATE_OF_BIRTH}
            label={t('DateOfBirth')}
            showTimeSelect={false}
            placeholderText={t('DateOfBirth')}
            peekNextMonth
            showMonthDropdown
            showYearDropdown
            dropdownMode="select"
            maxDate={new Date()}
          />
        </EditableField>
      )
    },
    {
      type: TYPE_TAGS,
      title: 'Tags',
      ns: 'AddContact',
      icon: 'tags',
      allow: true,
      onClick: () => onAddField(TYPE_TAGS),
      component: (
        <EditableField onDelete={() => onDeleteField(TYPE_TAGS)}>
          <FormTagsSelect
            label={t('Tags')}
            name={TYPE_TAGS}
            itemProps={{
              style: { flex: 'auto' },
              'data-pf': 'contact_creator_tags_select' // TODO: repace to constants
            }}
          />
        </EditableField>
      )
    },
    {
      type: TYPE_LOCATION,
      title: 'Address',
      ns: 'AddContact',
      icon: 'environment',
      allow: true,
      onClick: () => onAddField(TYPE_LOCATION),
      component: (
        <EditableField onDelete={() => onDeleteField(TYPE_LOCATION)}>
          <FormLocationInput
            itemProps={{
              style: { flex: 'auto' }
            }}
            label={t('MainAddress')}
            name={TYPE_LOCATION}
            placeholder={t('EnterAddress')}
          />
        </EditableField>
      )
    }
  ];

  const onDeleteField = typeField => {
    const filteredFields = activeFields.filter(field => field !== typeField);
    setActiveFields(filteredFields);
  };

  const onAddField = typeField => setActiveFields([...activeFields, typeField]);

  const renderActiveFields = () =>
    activeFields.map(f => {
      const action = fields.find(item => item.type === f);

      return action.component;
    });

  const filteredFields = fields.filter(
    field => !activeFields.includes(field.type)
  );

  const checkIsPhoneEmpty = (number, dialCode) => {
    const isEmpty =
      !number ||
      !dialCode ||
      number.replace('+', '').replace(dialCode, '') === '';

    return isEmpty;
  };

  const validateIsPhone = ({ number, dialCode }) => {
    const isEmpty = checkIsPhoneEmpty(number, dialCode);

    if (
      phones.find(
        ({ value }) => !checkIsPhoneEmpty(value.number, value.dialCode)
      )
    ) {
      return isEmpty || validatePhone(number, { isOnlyNumber: true });
    }

    if (isEmpty && !emails.find(e => !!e.value)) {
      return t('EnterAPhoneNumberOrEmail', { ns: 'Errors' });
    }

    return true;
  };

  const getBirthdayValue = birthday =>
    birthday && moment(birthday).format(DATE_FORMAT);

  const transformValuesByMultiFields = (names, data) => {
    let index = 0;
    const transformedValues = {};

    names.forEach(name => {
      index = 0;

      data[name].forEach(({ value }) => {
        const condition =
          name === EMAIL
            ? !!value
            : !checkIsPhoneEmpty(value.number, value.dialCode);

        if (condition) {
          transformedValues[index === 0 ? name : `${name}${index}`] =
            name === EMAIL ? value : value.number;

          index += 1;
        }
      });
    });

    return transformedValues;
  };

  const handleSubmit = ({
    phone,
    email,
    company: companyValue,
    address,
    position,
    ...values
  }) =>
    onSubmit(
      omit(
        {
          ...values,
          address: address || undefined,
          position: position || undefined,
          ...transformValuesByMultiFields([PHONE, EMAIL], { phone, email }),
          [TYPE_DATE_OF_BIRTH]: getBirthdayValue(values[TYPE_DATE_OF_BIRTH]),
          companyIsIndividual: values[CONTACT_TYPE] === IS_INDIVIDUAL,
          ...getCompanyData(companyValue)
        },
        [CONTACT_TYPE]
      ),
      phone.filter(
        ({ value }) =>
          value.number &&
          value.number.length > 1 &&
          value.number.replace('+', '') !== value.dialCode
      )
    );

  useEffect(() => {
    const firstPhone = phones[0].value;

    if (firstPhone.number) {
      const callingCode = getCountryCallingCode(
        company.country.label.alpha2Code
      );
      methods.setValue('phone.0.value', {
        number: (firstPhone.number || '').replace(
          firstPhone.dialCode,
          callingCode
        ),
        countryCode: company.country.label.alpha2Code.toLowerCase(),
        dialCode: callingCode
      });
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [company.country]);

  return (
    <FormProvider {...methods}>
      <form
        style={{
          display: 'flex',
          flexDirection: 'column',
          flex: '0 0 100%'
        }}
        data-qa="qa-6vj7old0oxfqqjd"
        onSubmit={event =>
          withoutBubbling(event, () => methods.handleSubmit(handleSubmit))
        }
      >
        <VideoBtn slug={VIDEOS.addClient} style={{ marginBottom: 16 }} />

        <FormRadio
          name={CONTACT_TYPE}
          options={[
            {
              label: 'CompanyRepresentativeRadio',
              ns: 'AddContact',
              value: IS_COMPANY
            },
            { label: 'IndividualRadio', ns: 'AddContact', value: IS_INDIVIDUAL }
          ]}
          itemProps={{
            'data-qa': 'qa-n6a7bp7o6ej7igc'
          }}
        />

        <Alert
          type={alerts[contactType].type}
          message={alerts[contactType].message}
          style={{ marginBottom: 16 }}
        />

        {contactType === IS_COMPANY && (
          <FormCompanySelect
            name={COMPANY}
            itemProps={{ 'data-qa': 'qa-sqvqduix3rnnmr1' }}
            countryCodeByPhone={phones[0].value.countryCode}
          />
        )}

        <div
          style={{
            display: 'grid',
            gridTemplateColumns: 'repeat(3, 1fr)',
            gap: 16
          }}
          data-qa="qa-g8zwwugkcgyzb8v"
        >
          <FormInput
            name="lastName"
            label={t('LastName')}
            placeholder={t('EnterLastName')}
            maxLength={35}
            itemProps={{ 'data-qa': 'qa-68s3a8x8o8dimwc' }}
            data-qa="qa-hjaeqeuf8iyd8eg"
          />

          <FormInput
            name="firstName"
            label={t('FirstName')}
            placeholder={t('EnterFirstName')}
            maxLength={35}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            itemProps={{ 'data-qa': 'qa-8659m64gkab9djy' }}
            data-qa="qa-8o7gz6nik7fl678"
          />

          <FormInput
            name="middleName"
            label={t('MiddleName')}
            placeholder={t('EnterMiddleName')}
            maxLength={35}
            itemProps={{ 'data-qa': 'qa-os0kanr6axykoxa' }}
            data-qa="qa-iyuybefafq03782"
          />
        </div>

        {contactType === IS_COMPANY && (
          <FormInput
            name="position"
            label={t('Position')}
            placeholder={t('EnterPosition')}
            itemProps={{ 'data-qa': 'qa-gz0651vlbs9cy3k' }}
            data-qa="qa-022i0ss8xyx1prf"
          />
        )}

        <MultipleField
          control={methods.control}
          name={PHONE}
          label={t('CorporatePhone')}
          isOnlyNumber={false}
          isDefaultOnChange
          itemProps={{
            style: {
              width: '100%'
            }
          }}
          newValue={{
            number: undefined
          }}
          rules={{
            validate: validateIsPhone
          }}
        >
          <FormPhoneInput />
        </MultipleField>

        <MultipleField
          control={methods.control}
          name={EMAIL}
          label={t('CorporateEmail')}
          type={EMAIL}
          placeholder={t('EnterEmail')}
          itemProps={{
            style: {
              width: '100%'
            }
          }}
          newValue=""
        >
          <FormInput />
        </MultipleField>

        <div data-qa="qa-f0zl4z11rlvg1up">{renderActiveFields()} </div>

        <FormTextarea
          name="comment"
          placeholder={t('YourComment')}
          data-qa="qa-kjh2bc88mppfjqg"
          maxLength={2000}
          itemProps={{
            style: {
              marginBottom: 0
            }
          }}
          autoSize={{
            minRows: 3
          }}
          style={{
            resize: 'none'
          }}
        />

        <Divider
          style={{
            width: 'calc(100% + 48px)',
            margin: '24px -24px'
          }}
          data-qa="qa-y41cc9kga70uh2u"
        />

        <ActionsDropdown
          actions={filteredFields}
          placement="bottomLeft"
          btnStyle={{
            alignSelf: 'flex-start'
          }}
        >
          <Button
            type="link"
            style={{
              display: 'flex',
              alignItems: 'center',
              padding: 0,
              fontSize: 16,
              marginBottom: 24
            }}
            data-qa="qa-6eojzz6il3ax34f"
          >
            <Icon type="plus" /> {t('ShowAllFieldsBtn')}
          </Button>
        </ActionsDropdown>

        <Button
          type="primary"
          size="large"
          width="expanded"
          htmlType="submit"
          loading={isLoading}
          style={{
            flexShrink: 0,
            margin: 'auto 0 0 auto'
          }}
        >
          {t('SaveBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  defaultValues: PropTypes.object,
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

Form.defaultProps = {
  defaultValues: {},
  isLoading: false
};

export const ContactCreatorDrawer = ({
  values,
  isFromDialog,
  onClose,
  onCreate,
  isDetailsAfterSubmit,
  ...props
}) => {
  const dispatch = useDispatch();
  const updateTags = useUpdateTags();
  const amplitude = useAmplitude();
  const routes = useRoutesService({ returnUrl: true });
  const routesWithoutUrl = useRoutesService();

  const [
    visibleFeedbackModal,
    onCloseFeedbackModal,
    checkCanShowFeedbackModal
  ] = useFeedbackModal();

  const socket = useWebsocketOperatorContext();

  const [isLoading, setIsLoading] = useState(false);

  const [visibleSmsModal, setVisibleSmsModal] = useState(undefined);
  const [visibleRestoreModal, setVisibleRestoreModal] = useState(false);
  // const [verifyModalData, setVerifyModalData] = useState(undefined);

  const [contactId, setContactId] = useState(undefined);
  const { state = {} } = useLocation();

  const { t } = useTranslation('AddContact');

  const onShowContactInfo = id => routesWithoutUrl.toContact({ id });

  const checkPhonesWithCode = useCallback((phonesWithCode, data) => {
    const phones = [];

    phonesWithCode.forEach(({ value }) => {
      if (
        LEGAT_COUNTRY_ALPHA_2_CODES.includes(value.countryCode.toUpperCase())
      ) {
        phones.push(value.number);
      }
    });

    if (phones.length && !data.employee && !getIsDemoModeActive()) {
      setVisibleSmsModal({ phones, ...data });
    }
  }, []);

  const handleSubmit = async ({ tags, ...contact }, phonesWithCode) => {
    setIsLoading(true);

    try {
      const data = await dispatch(
        createContact({
          contact: {
            ...contact,
            firstName: capitalize(contact.firstName),
            lastName: capitalize(contact.lastName),
            middleName: capitalize(contact.middleName)
          }
        })
      );

      await dispatch(fetchStatusDuplicateContacts());

      await updateTags({
        entityType: TYPE_CONTACT,
        entityId: data.id,
        newTags: tags
      });

      if (contact.comment) {
        const onlineChat = data.chats.find(c => c.channelKind === ONLINE_CHAT);

        socket.sendMessage({
          entityId: data.id,
          entityType: TYPE_CONTACT,
          subject: undefined,
          channelKind: ONLINE_CHAT,
          fileList: [],
          kind: DIALOG_TYPE_COMMENT,
          roomUuid: onlineChat.uuid,
          channelUuid: onlineChat.channelUuid,
          text: [{ text: contact.comment }]
        });
      }

      amplitude.addContactEvent(data);

      if (contact.assetId) {
        await dispatch(
          changeRelations({
            id: contact.assetId,
            relations: [
              {
                relationIds: [data.id],
                relType: TYPE_CONTACT,
                isDelete: false
              }
            ]
          })
        );
      }

      if (onCreate) {
        onCreate(data);
      }

      onClose();

      if (isDetailsAfterSubmit || state.isContactPageSubmit) {
        onShowContactInfo(data.id);

        setTimeout(() => {
          checkCanShowFeedbackModal();
        }, 5000);
      }

      checkPhonesWithCode(phonesWithCode, data);
    } catch (error) {
      const { status, data } = error.response || {};
      // if (data.isInNotVerified) {
      //   return setVerifyModalData({ contact, phonesWithCode });
      // }

      if (status === 409) {
        if (data.isArchived) {
          setContactId(data.contactId);
          setVisibleRestoreModal(true);
        } else {
          routesWithoutUrl.toContact({
            id: data.contactId,
            state: { isDuplicate: true }
          });
        }
      }
    } finally {
      setIsLoading(false);
    }
  };

  const onCloseRestoreModal = () => setVisibleRestoreModal(false);

  return (
    <>
      <Drawer
        title={<Drawer.Title>{t('AddContactHeading')}</Drawer.Title>}
        data="qa-7wt4neazjwtnucj"
        onClose={onClose}
        destroyOnClose
        {...props}
      >
        <Form
          isLoading={isLoading}
          onSubmit={handleSubmit}
          defaultValues={values}
        />
      </Drawer>

      {/* NOT USED NOW ??? */}
      <RestoreContactModal
        visible={visibleRestoreModal}
        link={routes.toContact({ id: contactId })}
        onClose={onCloseRestoreModal}
      />

      <SendInviteModal
        visible={visibleSmsModal !== undefined && !isFromDialog}
        contact={visibleSmsModal}
        onClose={() => setVisibleSmsModal(undefined)}
      />

      <FeedbackModal
        visible={visibleFeedbackModal}
        eventName={ADD_CONTACT}
        onClose={onCloseFeedbackModal}
      />

      {/* <WorkspaceVerifyModal
        visible={verifyModalData !== undefined}
        description={
          <>
            Внимание! <br />
            Ваш контрагент зарегистрирован в Upservice. Проводить сделки в
            режиме онлайн могут только компании, которые прошли проверку. Чтобы
            сделать это — введите название компании и ее страну. Мы проверим ваш
            налоговый статус.
          </>
        }
        onClose={() => setVerifyModalData(undefined)}
        callback={() =>
          handleSubmit(verifyModalData.contact, verifyModalData.phonesWithCode)
        }
      /> */}
    </>
  );
};

ContactCreatorDrawer.propTypes = {
  isFromDialog: PropTypes.bool,
  isDetailsAfterSubmit: PropTypes.bool,
  onClose: PropTypes.func,
  onCreate: PropTypes.func
};

ContactCreatorDrawer.defaultProps = {
  isFromDialog: false,
  isDetailsAfterSubmit: false,
  onClose: () => {},
  onCreate: undefined
};

export default ContactCreatorDrawer;

import React, { useState, useEffect, forwardRef, useCallback } from 'react';
import PlacesAutocomplete from 'react-places-autocomplete';
import { Input, Spin, Typography } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import styles from './location-input.module.scss';

const { Paragraph } = Typography;

const ZERO_RESULTS = 'ZERO_RESULTS';

export const boundsByBelarus =
  window.google &&
  new window.google.maps.LatLngBounds(
    new window.google.maps.LatLng(51.262035, 23.178322),
    new window.google.maps.LatLng(56.171728, 32.776533)
  );

export const LocationInput = forwardRef(
  ({ onChange, value, ...props }, ref) => {
    const [address, setAddress] = useState('');
    const [isSelected, setIsSelected] = useState(false);
    const [isNotFound, setIsNotFound] = useState(false);

    const { t } = useTranslation('Common');

    const handleChange = useCallback(
      newAdress => {
        onChange(newAdress);
        setAddress(newAdress);
        setIsSelected(false);
        setIsNotFound(false);
      },
      [onChange]
    );

    const onSelect = useCallback(
      newAdress => {
        onChange(newAdress);
        setAddress(newAdress);
        setIsSelected(!!newAdress.length);
      },
      [onChange]
    );

    const onBlur = useCallback(() => {
      if (!isSelected) {
        onSelect('');
      }
    }, [isSelected, onSelect]);

    // const renderNotFound = () => {
    //   setTimeout(() => )
    // }

    useEffect(() => {
      setIsSelected(!!(value || '').length);
      setAddress(value || '');
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    return (
      <PlacesAutocomplete
        value={address}
        onChange={handleChange}
        onSelect={onSelect}
        onError={status => {
          if (status === ZERO_RESULTS) {
            setIsNotFound(true);
          }
        }}
        searchOptions={{
          bounds: boundsByBelarus,
          types: ['address']
        }}
        debounce={300}
        ref={ref}
      >
        {({ getInputProps, getSuggestionItemProps, suggestions, loading }) => (
          <div className={styles.root} data-qa="qa-2jtsu1veg6r3vmr">
            <Input
              {...getInputProps({
                placeholder: t('EnterAddress'),
                ...props,
                onBlur
              })}
            />

            <div className={styles.dropdown} data-qa="qa-zap886da9wzilfi">
              {loading && <Spin style={{ margin: 10 }} />}

              {!loading &&
                !(suggestions || []).length &&
                !isSelected &&
                !!(value || '').length &&
                isNotFound && (
                  <Paragraph className={styles.item}>
                    {t('AddressNotFound')}
                  </Paragraph>
                )}

              {suggestions.map(suggestion => (
                <Paragraph
                  key={suggestion.description}
                  {...getSuggestionItemProps(suggestion, {
                    ellipsis: { rows: 1 },
                    className: classnames(styles.item, {
                      [styles.active]: suggestion.active
                    }),
                    title: suggestion.description
                  })}
                >
                  {suggestion.description}
                </Paragraph>
              ))}
            </div>
          </div>
        )}
      </PlacesAutocomplete>
    );
  }
);

LocationInput.propTypes = {
  onChange: PropTypes.func,
  value: PropTypes.string
};

LocationInput.defaultProps = {
  value: '',
  onChange: () => {}
};

export default LocationInput;

import qs from 'qs';

import { CONTACTS } from 'constants/api';

import makeCancelableRequest from 'utils/make-cancalable-request';

export default api => ({
  fetch: makeCancelableRequest(
    api,
    (
      fetch,
      {
        workspaceId,
        responsible,
        status,
        channel,
        channelKind,
        contactCompanyResponsible,
        workspace,
        company,
        employee,
        isOffline,
        isArchived,
        isIndividual,
        createdDateRange = [],
        ordering,
        search,
        limit,
        offset,
        tagsCondition,
        tagsIds,
        task,
        orderStatus,
        asset,
        channelChat,
        contact,
        ids,
        exclude,
        searchKind,
        lite,
        isDeferred
      }
    ) =>
      fetch('get', `/v1/${workspaceId}/${lite ? 'contacts_lite' : CONTACTS}/`, {
        params: {
          workspace,
          channel,
          channel_kind: channelKind,
          contact_company_responsible: contactCompanyResponsible,
          company,
          status,
          employee,
          responsible,
          is_offline: isOffline,
          is_archived: isArchived,
          is_individual: isIndividual,
          created_at_gte: createdDateRange[0],
          created_at_lte: createdDateRange[1],
          search,
          ordering,
          limit,
          offset,
          tags_condition: tagsCondition,
          tags_ids: tagsIds,
          task,
          order_status: orderStatus,
          asset,
          channel_chat: channelChat,
          contact,
          id: ids,
          exclude,
          search_kind: searchKind,
          is_deferred: isDeferred
        },
        paramsSerializer(params) {
          return qs.stringify(params, { arrayFormat: 'comma' });
        }
      })
  ),

  fetchWithoutCancelling: ({
    workspaceId,
    responsible,
    status,
    channel,
    channelKind,
    contactCompanyResponsible,
    workspace,
    company,
    employee,
    isOffline,
    isArchived,
    isIndividual,
    createdDateRange = [],
    ordering,
    search,
    limit,
    offset,
    tagsCondition,
    tagsIds,
    task,
    order,
    asset,
    channelChat,
    contact,
    ids,
    lite
  }) =>
    api.get(`/v1/${workspaceId}/${lite ? 'contacts_lite' : CONTACTS}/`, {
      params: {
        workspace,
        channel,
        channel_kind: channelKind,
        contact_company_responsible: contactCompanyResponsible,
        company,
        status,
        employee,
        responsible,
        is_offline: isOffline,
        is_archived: isArchived,
        is_individual: isIndividual,
        created_at_gte: createdDateRange[0],
        created_at_lte: createdDateRange[1],
        search,
        ordering,
        limit,
        offset,
        tags_condition: tagsCondition,
        tags_ids: tagsIds,
        task,
        order,
        asset,
        channel_chat: channelChat,
        contact,
        id: ids
      },
      paramsSerializer(params) {
        return qs.stringify(params, { arrayFormat: 'comma' });
      }
    }),

  fetchRecommended: workspaceId =>
    api
      .get(`/v1/${workspaceId}/${CONTACTS}/recommended/`)
      .then(({ data }) => data),

  fetchOne: (id, workspaceId) =>
    api
      .get(`/v1/${workspaceId}/${CONTACTS}/${id}/`, { show400: false })
      .then(({ data }) => data),

  create: (workspaceId, contact) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/`, contact),

  edit: (workspaceId, contact) =>
    api.patch(`/v1/${workspaceId}/${CONTACTS}/${contact.id}/`, contact),

  archive: (workspaceId, id) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/archive/`),

  remove: (workspaceId, id) =>
    api.delete(`/v1/${workspaceId}/${CONTACTS}/${id}/`),

  removeRecommended: (workspaceId, id) =>
    api.delete(`/v1/${workspaceId}/${CONTACTS}/recommended/${id}/`),

  sendSmsInvite: (workspaceId, id, phone) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/sms-invitation/`, { phone }),

  sendEmailInvite: (workspaceId, id, email) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/email-invitation/`, {
      email
    }),

  fetchLinkInvite: (workspaceId, id) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/link-invitation/`),

  fetchHistory: ({ workspaceId, id, offset, kind, excludeKind }) =>
    api
      .get(`/v1/${workspaceId}/${CONTACTS}/${id}/log/`, {
        params: { offset, kind, exclude_kind: excludeKind }
      })
      .then(({ data }) => data),

  sendComment: (workspaceId, id, comment) =>
    api
      .post(`/v1/${workspaceId}/${CONTACTS}/${id}/log/comments/`, {
        ...comment
      })
      .then(({ data }) => data),

  restore: (workspaceId, id) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/restore/`),

  retrieveByChat: (workspaceId, roomUuid) =>
    api.get(`/v1/${workspaceId}/${CONTACTS}/retrieve-by-chat/`, {
      params: { uuid: roomUuid },
      show400: false
    }),

  // MEMBERS (ACCESS SETTINGS)
  fetchMembers: ({ limit = 1000, offset = 0, id, workspaceId }) =>
    api.get(`/v1/${workspaceId}/${CONTACTS}/${id}/members/`, {
      params: {
        limit,
        offset
      }
    }),

  members: ({ employees, id, isDelete, workspaceId }) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/members/`, {
      employees,
      delete: isDelete
    }),

  // RELATIONS
  changeRelations: ({ workspaceId, id, relations }) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/relations/bulk_update/`, {
      relations
    }),

  fetchRelations: (workspaceId, id, offset = 0, limit = 1000) =>
    api.get(`/v1/${workspaceId}/${CONTACTS}/${id}/relations/`, {
      params: {
        limit,
        offset
      }
    }),

  // MERGE
  merge: ({ workspaceId, fromContact, toContact }) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/merge/`, {
      fromContact,
      toContact
    }),

  toggleNotify: ({ workspaceId, id, notify }) =>
    api.patch(`/v1/${workspaceId}/${CONTACTS}/${id}/notify/`, { notify }),

  createEmailChat: ({ workspaceId, id, values }) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/email-channels/`, values),

  createWhatsAppChat: ({ workspaceId, id, values }) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${id}/whatsapp-channels/`, values),

  uploadImportContacts: ({ workspaceId, values }) =>
    api.post(`/v1/${workspaceId}/contacts-import/upload/`, values),

  fetchResultsImportContacts: ({ id, workspaceId }) =>
    api.get(`/v1/${workspaceId}/contacts-import/${id}/results/`),

  completeImportContacts: ({ id, workspaceId }) =>
    api.delete(`/v1/${workspaceId}/contacts-import/${id}/complete/`),

  fetchStatusImportContacts: ({ workspaceId }) =>
    api.get(`/v1/${workspaceId}/contacts-import/check_status/`),

  fetchStatusDuplicateContacts: ({ workspaceId }) =>
    api.get(`/v1/${workspaceId}/contacts-duplicate/check-status/`),

  fetchResultsDuplicateContacts: ({ workspaceId }) =>
    api.get(`/v1/${workspaceId}/contacts-duplicate/`),

  mergeDuplicateContact: ({ workspaceId, payload }) =>
    api.post(`/v1/${workspaceId}/contacts-duplicate/resolve/`, payload),

  markNotDuplicateContact: ({ workspaceId, payload }) =>
    api.patch(`/v1/${workspaceId}/contacts-duplicate/resolve/`, payload),

  createDefer: ({ workspaceId, contactId, payload }) =>
    api.patch(`/v1/${workspaceId}/${CONTACTS}/${contactId}/defer/`, payload),

  removeDeferral: ({ workspaceId, contactId }) =>
    api.post(`/v1/${workspaceId}/${CONTACTS}/${contactId}/undefer/`)
});

import React, { useEffect, useState } from 'react';
import { Button, Divider } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AttachmentsTable from 'components/attachments-view/views/all/components/table';
import Filters from 'components/attachments-view/views/all/components/filters/filters';
import FilterPane from 'components/common/filter-pane';
import Menu from 'components/attachments-view/views/all/components/menu';

import { initialFilter } from 'store/attachments/reducer';

import useFilterIndicator from 'hooks/common/use-filter-indicator';

import useFilters from './use-filters';
import useData from './use-data';

import styles from './table.module.scss';

export const Table = ({
  onChange,
  multiple,
  actionsDeps,
  onCloseAttachmentModal,
  filterPaneClassName,
  attachmentsTableClassName,
  isChangeAfterSelect
}) => {
  const [selectedItems, setSelectedItems] = useState([]);

  const { t } = useTranslation('UploadFile');

  const {
    filters,
    setSearch,
    setOrders,
    setProjects,
    setChannelChat,
    setTasks,
    setContacts,
    onClear,
    setSorting,
    setCreators,
    setCreatedAt,
    setAsset,
    setTag,
    setCurrentPage
  } = useFilters();

  const { data } = useData({ filters });

  const actions = {
    setOrders,
    setProjects,
    setTasks,
    setContacts,
    setCreators,
    setCreatedAt,
    setAsset,
    setTag,
    setChannelChat
  };

  const rowSelection = {
    type: multiple ? 'checkbox' : 'radio',
    columnWidth: 50,
    onSelect: (record, selected) => {
      if (selected) {
        return setSelectedItems(prev => [...prev, record]);
      }

      return setSelectedItems(prev =>
        prev.filter(selectedFile => selectedFile.id !== record.id)
      );
    },
    onSelectAll: (selected, _, changeRows) => {
      if (selected) {
        return setSelectedItems(prev => [...prev, ...changeRows]);
      }

      return setSelectedItems(prev =>
        prev.filter(
          selectedFile =>
            !changeRows.map(({ id }) => id).includes(selectedFile.id)
        )
      );
    }
  };

  const onSelect = () => onChange(selectedItems, true);

  const excludedKeys = ['search', 'sorting', 'ideas'];
  const excludeKeys = key => excludedKeys.includes(key);
  const filterIndicator = useFilterIndicator(
    initialFilter,
    filters,
    excludeKeys
  );

  useEffect(() => {
    if (isChangeAfterSelect) {
      onSelect();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [selectedItems.length]);

  return (
    <FilterPane
      name="attachments-modal"
      onReset={onClear}
      bodyStyle={{ padding: 15 }}
      indicator={filterIndicator}
      className={filterPaneClassName}
    >
      <Filters
        filters={filters}
        actions={actions}
        totalItems={data.totalItems}
      />

      <div
        style={{
          height: 'auto',
          flex: '1 1 auto',
          display: 'flex',
          flexDirection: 'column',
          paddingLeft: 15
        }}
      >
        <Menu
          hideCreateButton
          totalItems={data.totalItems}
          search={filters.search}
          className={styles.menu}
          setSearch={setSearch}
        />

        <AttachmentsTable
          rowSelection={rowSelection}
          filters={filters}
          data={data.entries}
          pagination={{
            current: filters.currentPage,
            total: data.totalItems,
            onChange: setCurrentPage
          }}
          scroll={{ y: 300 }}
          setSorting={setSorting}
          isLoading={data.isLoading}
          actionsDeps={actionsDeps}
          onCloseAttachmentModal={onCloseAttachmentModal}
          className={attachmentsTableClassName}
          fileLinkTarget="_blank"
        />

        <Divider style={{ margin: isChangeAfterSelect ? 0 : 'auto 0 24px' }} />

        {!isChangeAfterSelect && (
          <Button
            type="primary"
            size="large"
            style={{ marginLeft: 'auto' }}
            disabled={!data.entries.length || !selectedItems.length}
            onClick={onSelect}
          >
            {t('AttachBtn')}
          </Button>
        )}
      </div>
    </FilterPane>
  );
};

Table.propTypes = {
  onChange: PropTypes.func,
  multiple: PropTypes.bool
};

Table.defaultProps = {
  onChange: () => {},
  multiple: true
};

export default Table;

import { combineReducers } from 'redux';
import { connectRouter } from 'connected-react-router';

import workspace from './workspace';
import billing from './billing';
import chats from './chats';
import comments from './comments';
import user, { logout } from './user';
import orderStatuses from './order-statuses';
import notifications from './notifications';
import categories from './categories';
import cities from './cities';
import contacts from './contacts';
import contactsCompanies from './contacts-companies';
import team from './team';
import socialCredentials from './social-credentials';
import routerUrl from './router';
import guides from './guides';
import tasks from './tasks';
import projects from './projects';
import ideas from './ideas';
import attachments from './attachments';
import channels from './channels';
import dialogues from './dialogues';
import drawers from './drawers';
import devices from './devices';
import assets from './assets';
import calendar from './calendar';
import ui from './ui';
import tags from './tags';
import release from './release';
import reports from './reports';
import operator from './operator';
import messageTemplates from './message-templates';
import reminders from './reminders';
import gpt from './gpt';

const reducers = history =>
  combineReducers({
    router: connectRouter(history),
    routerUrl,
    drawers,
    workspace,
    billing,
    user,
    tasks,
    projects,
    orderStatuses,
    ideas,
    contacts,
    contactsCompanies,
    team,
    notifications,
    chats,
    comments,
    categories,
    cities,
    guides,
    socialCredentials,
    attachments,
    dialogues,
    channels,
    devices,
    assets,
    calendar,
    ui,
    tags,
    release,
    reports,
    operator,
    messageTemplates,
    reminders,
    gpt
  });

const rootReducer = history => (state, action) => {
  // clear store if user logout (tabKey is needed for dialog message sound)
  if (action.type === logout.toString()) {
    // eslint-disable-next-line no-param-reassign
    state = {
      routerUrl: { tabKey: state.routerUrl.tabKey },
      ui: { language: state.ui.language }
    };
  }
  return reducers(history)(state, action);
};

export default rootReducer;

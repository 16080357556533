import { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import isEmpty from 'lodash/isEmpty';

import { LEADS, TYPE_ORDER_STATUS } from 'constants/index';

import { useUpdateTags } from 'components/common/tags';

import { getTagsByEntity } from 'store/tags';
import {
  clearOrderStatusesEntries,
  createOrderStatus,
  fetchOrderStatus,
  fetchOrderStatuses,
  fetchOrderStatusContractor,
  fetchOrderStatusSignatory,
  fetchOrderStatusAttachments,
  clearSelectedOrderStatus
} from 'store/order-statuses';

import useModalsService from 'services/modals';

const useCopy = ({ orderStatusId, onClose }) => {
  const dispatch = useDispatch();

  const [isSubmitLoading, setIsSubmitLoading] = useState(false);
  const [isFetchLoading, setIsFetchLoading] = useState(false);
  const [isAfterVisible, setIsAfterVisible] = useState(false);
  const [values, setValues] = useState({});

  const tags = useSelector(state =>
    getTagsByEntity(state)({
      entityId: orderStatusId,
      entityType: TYPE_ORDER_STATUS
    })
  );

  const modals = useModalsService();
  const updateTags = useUpdateTags();

  const hasValues = !isEmpty(values) && !isFetchLoading;

  const fetch = useCallback(async () => {
    try {
      setIsFetchLoading(true);

      const orderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );
      const contractor = await dispatch(
        fetchOrderStatusContractor({ contractorId: orderStatus.contractorId })
      );
      const signatory = await dispatch(
        fetchOrderStatusSignatory({ signatoryId: orderStatus.signatoryId })
      );
      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: {
            relatedOrderStatuses: orderStatusId
          }
        })
      );

      setValues({
        ...orderStatus,
        contractor,
        signatory,
        description: orderStatus.content,
        fileList: documentList,
        startAt: null,
        deadlineAt: null,
        tags
      });
    } finally {
      setIsFetchLoading(false);
    }
  }, [dispatch, orderStatusId, tags]);

  const onSubmit = async ({ tags: newTags, ...newValues }) => {
    try {
      setIsSubmitLoading(true);

      const { identifier } = await dispatch(
        createOrderStatus({ values: { ...newValues, kind: values.kind } })
      );

      await updateTags({
        entityType: TYPE_ORDER_STATUS,
        entityId: identifier,
        newTags
      });

      onClose();
      dispatch(clearSelectedOrderStatus());

      modals.orders.showDetails({ orderStatusId: identifier });

      dispatch(clearOrderStatusesEntries(LEADS));
      dispatch(fetchOrderStatuses({ statuses: LEADS }));
    } finally {
      setIsSubmitLoading(false);
    }
  };

  useEffect(() => {
    if (isAfterVisible) {
      fetch();
    }

    return () => setValues({});
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAfterVisible]);

  return {
    isLoading: isSubmitLoading,
    values,
    hasValues,
    onSubmit,
    afterVisibleChange: setIsAfterVisible
  };
};

export default useCopy;

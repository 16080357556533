export const DOCX = 'docx';
export const XLSX = 'xlsx';
export const PPTX = 'pptx';
export const ZIP = 'zip';
export const CSV = 'csv';
export const MSI = 'msi';

export const IMAGE_TYPE = 'image/';
export const VIDEO_TYPE = 'video/';
export const AUDIO_TYPE = 'audio/';
export const PDF_TYPE = 'application/pdf';
export const LINK_TYPE = 'application/link';
export const XLSX_TYPE =
  'application/vnd.openxmlformats-officedocument.spreadsheetml.sheet';
export const CSV_TYPE = 'text/csv';
export const DOCX_TYPE =
  'application/vnd.openxmlformats-officedocument.wordprocessingml.document';
export const PPTX_TYPE =
  'application/vnd.openxmlformats-officedocument.presentationml.presentation';
export const XLS_TYPE = 'application/vnd.ms-excel';
export const EXE_TYPE = 'application/x-msdownload';
export const TIFF_TYPE = 'image/tiff';

export const DOCS_FILE_TYPES = [
  'application/msword',
  DOCX_TYPE,
  'application/vnd.oasis.opendocument.text',
  'text/html',
  'application/rtf',
  'text/plain'
];

export const SHEETS_FILE_TYPES = [
  XLSX_TYPE,
  'application/vnd.oasis.opendocument.spreadsheet',
  CSV_TYPE,
  'text/rtf'
];

export const SLIDES_FILE_TYPES = [
  'application/vnd.openxmlformats-officedocument.presentationml.presentation',
  'application/octet-stream'
];

export const MEDIA_FILE_TYPES = [IMAGE_TYPE, VIDEO_TYPE, AUDIO_TYPE, PDF_TYPE];
const EXCLUDE_MEDIA_FILE_TYPES = [TIFF_TYPE];

export const EDITABLE_FILE_TYPES = [
  ...DOCS_FILE_TYPES,
  ...SHEETS_FILE_TYPES,
  ...SLIDES_FILE_TYPES
];

export const getIsMediaFile = ({ mimeType, type, contentType }) => {
  const resultType = mimeType || type || contentType || '';

  return (
    MEDIA_FILE_TYPES.find(t => resultType.includes(t)) &&
    !EXCLUDE_MEDIA_FILE_TYPES.find(t => resultType.includes(t))
  );
};

export const getIsEditableFile = ({ mimeType, type, contentType }) =>
  EDITABLE_FILE_TYPES.find(t =>
    (mimeType || type || contentType || '').includes(t)
  );

export const getIsImage = ({ mimeType, type }) =>
  (mimeType || type).includes(IMAGE_TYPE);
export const getIsVideo = ({ mimeType, type }) =>
  (mimeType || type).includes(VIDEO_TYPE);
export const getIsAudio = ({ mimeType, type }) =>
  (mimeType || type).includes(AUDIO_TYPE);
export const getIsLink = ({ mimeType, type, contentType }) =>
  (mimeType || type || contentType || '').includes(LINK_TYPE);

export const getIsPDF = ({ mimeType, type }) =>
  (mimeType || type).includes(PDF_TYPE);
export const getIsXLS = ({ mimeType, type }) =>
  (mimeType || type).includes(XLS_TYPE);

export const getIsZip = ({ title }) => title.includes(`.${ZIP}`);

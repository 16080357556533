import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CREATOR_DRAWER_WIDTH } from 'constants/index';

import Drawer from 'components/common/drawer';
import MainForm from 'components/orders-view/forms/main';
import { SkeletonEntityCreation } from 'components/common/skeletons';

import useCopy from './use-copy';

export const OrderCopyDrawer = ({ visible, data, onClose }) => {
  const { t } = useTranslation('AddOrder');

  const {
    isLoading,
    values,
    hasValues,
    onSubmit,
    afterVisibleChange
  } = useCopy({ orderStatusId: data.id, onClose });

  return (
    <Drawer
      title={<Drawer.Title>{t('CopyOrderHeading')}</Drawer.Title>}
      data-qa="qa-ep2f4svh0gplhjj"
      visible={visible}
      width={CREATOR_DRAWER_WIDTH}
      onClose={onClose}
      maskClosable={false}
      bodyStyle={{ padding: 0 }}
      afterVisibleChange={afterVisibleChange}
    >
      {!hasValues ? (
        <SkeletonEntityCreation />
      ) : (
        <MainForm
          defaultValues={values}
          isCopy
          isLoading={isLoading}
          onSubmit={onSubmit}
        />
      )}
    </Drawer>
  );
};

OrderCopyDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  data: PropTypes.shape({
    id: PropTypes.string
  }),
  onClose: PropTypes.func.isRequired
};

OrderCopyDrawer.defaultProps = {
  data: {}
};

export default OrderCopyDrawer;

import React, { useState, useEffect } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Drawer from 'components/common/drawer';
import Attachments from 'components/common/attachments';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import {
  fetchAgreementSheet,
  fetchAgreementSheetFile
} from 'store/attachments';

import getUrlFileByArrayBinary from 'hooks/common/use-file-upload/get-url-file-by-binaty';
import downloadFile from 'hooks/common/use-file-upload/download-file';
import { getFullName } from 'utils/get-fio';

import Table from './table';

export const AgreementSheetDrawer = ({
  visible,
  sheetId,
  task,
  onClose,
  ...props
}) => {
  const [afterChangeVisible, setAfterChangeVisible] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const [isFileLoading, setIsFileLoading] = useState(false);
  const [data, setData] = useState({});

  const dispatch = useDispatch();

  const { t } = useTranslation('ApprovingList');

  const { attachments, steps } = data;

  const fetchData = async () => {
    try {
      setIsLoading(true);

      const sheetData = await dispatch(fetchAgreementSheet({ id: sheetId }));
      setData(sheetData);
    } finally {
      setIsLoading(false);
    }
  };

  const onDownloadFile = async () => {
    try {
      setIsFileLoading(true);

      const file = await dispatch(fetchAgreementSheetFile({ id: sheetId }));

      const url = getUrlFileByArrayBinary(file);
      downloadFile({ url, fileName: file.filename });
    } finally {
      setIsFileLoading(false);
    }
  };

  useEffect(() => {
    if (afterChangeVisible && sheetId) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [sheetId, afterChangeVisible]);

  return (
    <Drawer
      title={<Drawer.Title>{t('ApprovingListHeading')}</Drawer.Title>}
      width={800}
      visible={visible}
      afterVisibleChange={setAfterChangeVisible}
      destroyOnClose
      onClose={onClose}
      {...props}
    >
      {!!(attachments || []).length && (
        <Spin spinning={isLoading}>
          <Typography.Title level={3} style={{ marginBottom: 16 }}>
            {t('ApprovingDoc')}
          </Typography.Title>

          <div style={{ marginBottom: 24 }}>
            <Attachments fileList={attachments} />
          </div>
        </Spin>
      )}

      {task && (
        <Typography.Paragraph style={{ marginBottom: 24 }}>{`${t(
          'ApprovingInitiator'
        )} ${getFullName(task.author)}`}</Typography.Paragraph>
      )}

      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          justifyContent: 'space-between',
          alignItems: 'flex-end',
          height: 'calc(100% - 44px)'
        }}
      >
        <Table isLoading={isLoading} dataSource={steps} />

        <Button
          type="primary"
          size="large"
          loading={isFileLoading}
          style={{
            flexShrink: 0
          }}
          onClick={onDownloadFile}
        >
          {t('DownloadApprovingListBtn')}
        </Button>
      </div>
    </Drawer>
  );
};

AgreementSheetDrawer.propTypes = {
  visible: PropTypes.bool.isRequired,
  sheetId: PropTypes.number,
  task: PropTypes.shape({
    author: PropTypes.object
  }),
  onClose: PropTypes.func.isRequired
};

AgreementSheetDrawer.defaultProps = {
  sheetId: null,
  task: undefined
};

export default AgreementSheetDrawer;

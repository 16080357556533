import React, { useCallback } from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import { useTheme } from 'components/common/theme';
import Typography from 'components/common/typography';

import Option from '../option';

import styles from './tag-option.module.scss';

export const HEX_ALPHA = '1A'; // 90%

export const TagOption = ({
  className,
  option: optionValue,
  isTag,
  isOption,
  isClearable,
  ...props
}) => {
  const { variables } = useTheme();

  const option =
    typeof optionValue === 'string'
      ? { id: optionValue, name: optionValue }
      : optionValue;

  const color = option.color || variables.color.green;
  const getRgbaColor = useCallback(() => `${color}${HEX_ALPHA}`, [color]);

  const onClick = event => {
    if (isOption) {
      return null;
    }

    return event.preventDefault();
  };

  return (
    <div onClick={onClick} style={{ display: 'flex', maxWidth: '100%' }}>
      <Option
        className={classnames(className, styles.root, {
          [styles.isTag]: !isOption,
          [styles.isClearable]: isClearable,
          [styles.ellipsisOnHover]: option.name.length > 3
        })}
        title={option.name}
        contentClassName={styles.content}
        style={{ backgroundColor: getRgbaColor() }}
        isClearable={isClearable}
        {...props}
      >
        <Typography.Text
          ellipsis
          key={option.id}
          size="small"
          style={{ color }}
        >
          {option.name}
        </Typography.Text>
      </Option>
    </div>
  );
};

TagOption.propTypes = {
  option: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    name: PropTypes.string,
    color: PropTypes.string
  }).isRequired,
  isOption: PropTypes.bool,
  className: PropTypes.string
};

TagOption.defaultProps = {
  isOption: false,
  className: undefined
};

export default TagOption;

import React from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { ASSIGNMENTS_CHANGE_DRAWER, RELATIONS_DRAWER } from 'constants/index';

import EntityInfoButtons from 'components/common/entity-info-buttons';

import { setVisibleDrawer } from 'store/drawers';
import {
  changeMembers,
  changeRelations,
  fetchMembers,
  fetchRelations
} from 'store/contacts';

import { NOTICE_NUMBER } from 'services/notice';

const InfoButtons = ({ contact }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation(['Contacts', 'ContactLinks', 'ContactMembers']);

  const { canShareContact, canUpdateContact } = contact.permissions || {};

  const editRelations = async ({ added, deleted }) => {
    const result = [
      ...deleted.map(d => ({ ...d, isDelete: true })),
      ...added.map(d => ({ ...d, isDelete: false }))
    ];

    if (result.length) {
      await dispatch(
        changeRelations({
          id: contact.id,
          relations: result
        })
      );
    }
  };

  const showRelationsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: RELATIONS_DRAWER,
        data: {
          title: t('ContactLinksHeading', { ns: 'ContactLinks' }),
          description: t('ContactLinksDesc', { ns: 'ContactLinks' }),
          allowEdit: canUpdateContact,
          fetch: () => dispatch(fetchRelations({ id: contact.id })),
          submit: editRelations
        }
      })
    );

  const editMembers = async ({ added, deleted }) => {
    if (deleted.length) {
      await dispatch(
        changeMembers({
          id: contact.id,
          employees: deleted,
          isDelete: true
        })
      );
    }

    if (added.length) {
      await dispatch(
        changeMembers({
          id: contact.id,
          employees: added,
          isDelete: false
        })
      );
    }
  };

  const showAssignmentsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: ASSIGNMENTS_CHANGE_DRAWER,
        data: {
          title: t('ContactMembersHeading', { ns: 'ContactMembers' }),
          description: t('ContactMembersDesc', { ns: 'ContactMembers' }),
          employeeListTitle: t('ContactMembersListText', {
            ns: 'ContactMembers'
          }),
          submitText: t('SaveBtn', { ns: 'ContactMembers' }),
          noticeNumber: NOTICE_NUMBER.membersSaved,
          selectProps: {
            label: t('Employee', { ns: 'ContactMembers' })
          },
          permissions: {
            allowAdd: canShareContact,
            allowDelete: canShareContact
          },
          fetch: () =>
            dispatch(
              fetchMembers({
                id: contact.id
              })
            ),
          submit: editMembers
        }
      })
    );

  const buttons = [
    {
      icon: 'big-team',
      text: t('ContactMembersBtn'),
      onClick: showAssignmentsDrawer
    },
    {
      icon: 'link',
      text: t('ContactLinksBtn'),
      onClick: showRelationsDrawer
    }
  ].filter(b => !b.hidden);

  return <EntityInfoButtons buttons={buttons} />;
};

export default InfoButtons;

import React, { Fragment, useEffect, useRef } from 'react';
import { useDispatch, useSelector } from 'react-redux';

import { setDefaultState as clearAssetsState } from 'store/assets';
import { clearEmployeeCalendarFilter } from 'store/calendar';
import { setIsNeedToReconnect } from 'store/operator';
import { getWorkspaceId } from 'store/workspace';

export const ChangeWorkspaceProvider = () => {
  const dispatch = useDispatch();

  const workspaceId = useSelector(getWorkspaceId);

  const isFirstRender = useRef(true);

  // TODO: add all states
  useEffect(() => {
    if (!isFirstRender.current) {
      dispatch(clearAssetsState());
      dispatch(clearEmployeeCalendarFilter());
      dispatch(setIsNeedToReconnect(true));
    } else {
      isFirstRender.current = false;
    }
  }, [dispatch, workspaceId]);

  return <Fragment />;
};

export default ChangeWorkspaceProvider;

import React, { useState } from 'react';
import { Button, Form, Typography } from 'antd';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Translation, useTranslation } from 'react-i18next';

import { EmployeeSelect } from 'components/common/controls/custom-select';
import Modal from 'components/common/modal';

import { editOrderStatusContractorSync } from 'store/order-statuses';
import { editContact } from 'store/contacts/actions';
import { editContactCompanies } from 'store/contacts-companies/actions';

import styles from './styles.module.scss';

const { Text } = Typography;

const EditorForm = Form.create()(
  ({
    form,
    responsible,
    contactId,
    orderStatusId,
    isCompaniesContact,
    onCallback,
    btnText
  }) => {
    const dispatch = useDispatch();
    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation(['ChangeContactResponsible', 'Errors']);

    const { getFieldDecorator } = form;

    const onSave = event => {
      event.preventDefault();

      form.validateFields(async (err, values) => {
        if (!err) {
          setIsLoading(true);

          const edit = isCompaniesContact ? editContactCompanies : editContact;

          try {
            const data = await dispatch(
              edit({
                id: contactId,
                values: { responsible: values.responsible.value }
              })
            );

            if (orderStatusId) {
              dispatch(
                editOrderStatusContractorSync({
                  id: orderStatusId,
                  values: {
                    responsible: data.responsible
                  }
                })
              );
            }

            onCallback(data);
          } finally {
            setIsLoading(false);
          }
        }
      });
    };

    return (
      <Form
        colon={false}
        hideRequiredMark
        onSubmit={onSave}
        className={styles.form}
        data-qa="qa-puawo9houhbpeym"
      >
        <Form.Item label={t('ContactResponsible')}>
          {getFieldDecorator('responsible', {
            initialValue: responsible && {
              value: responsible.id,
              label: responsible
            },
            rules: [
              {
                required: true,
                message: t('RequiredField', { ns: 'Errors' })
              }
            ]
          })(<EmployeeSelect data-qa="qa-70do1829c9wbr9o" />)}
        </Form.Item>

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          className={styles.btn}
          data-qa="qa-jh0gpwvrbafw0y3"
        >
          {btnText}
        </Button>
      </Form>
    );
  }
);

EditorForm.propTypes = {
  responsible: PropTypes.object,
  contactId: PropTypes.number,
  isCompaniesContact: PropTypes.bool,
  onCallback: PropTypes.func,
  btnText: PropTypes.string
};

EditorForm.defaultProps = {
  responsible: undefined,
  contactId: undefined,
  isCompaniesContact: false,
  onCallback: () => {},
  btnText: (
    <Translation ns="ChangeContactResponsible">{t => t('SaveBtn')}</Translation>
  )
};

export const EditorResponsibleModal = ({
  onClose,
  contactId,
  responsible,
  isCompaniesContact,
  title,
  orderStatusId,
  btnText,
  ...props
}) => (
  <Modal
    width={540}
    title={
      <Text className={styles.title} data-qa="qa-ctc8en03pews2sm">
        {title}
      </Text>
    }
    onClose={onClose}
    contentClassName={styles.modal}
    data-qa="qa-4d79ue7zre4c43m"
    {...props}
  >
    <EditorForm
      responsible={responsible}
      orderStatusId={orderStatusId}
      contactId={contactId}
      onCallback={onClose}
      isCompaniesContact={isCompaniesContact}
      btnText={btnText}
      data-qa="qa-gezphp0ycm1v0l2"
    />
  </Modal>
);

EditorResponsibleModal.propTypes = {
  title: PropTypes.string.isRequired
};

export default EditorResponsibleModal;

import React from 'react';
import pl from 'plural-ru';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  SCHEDULER_ORDER_MONTHLY,
  SCHEDULER_ORDER_WEEKLY,
  SCHEDULER_ORDER_QUARTERLY
} from 'constants/orders';

import Typography from 'components/common/typography';

const FrequencyLabel = ({ count, frequency }) => {
  const { t } = useTranslation('Common');

  const wordForms = {
    [SCHEDULER_ORDER_WEEKLY]: pl(
      count,
      t('SingularFormWeek'),
      t('GenitiveFormWeek'),
      t('PluralFormWeek')
    ),
    [SCHEDULER_ORDER_MONTHLY]: pl(
      count,
      t('SingularFormMonth'),
      t('GenitiveFornMonth'),
      t('PluralFormMonth')
    ),
    [SCHEDULER_ORDER_QUARTERLY]: pl(
      count,
      t('SingularFormQuarter'),
      t('GenitiveFormQuarter'),
      t('PluralFormQuarter')
    )
  };

  const wordForm = wordForms[frequency];

  return <Typography.Text>{wordForm}</Typography.Text>;
};

FrequencyLabel.propTypes = {
  count: PropTypes.number.isRequired,
  frequency: PropTypes.string.isRequired
};

FrequencyLabel.defaultProps = {};

export default FrequencyLabel;

import React, { useCallback, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import EmployeeModal from 'components/common/modals/employee';

import {
  changeOrderStatusSignatory,
  fetchOrderStatus,
  fetchOrderStatusSignatory,
  removeOrderStatusFromEntries
} from 'store/order-statuses';

import useRoutesService from 'services/routes';

const ChangingSignatoryModal = ({
  visible,
  orderStatusId,
  defaultValue,
  onClose
}) => {
  const dispatch = useDispatch();

  const routes = useRoutesService();

  const { t } = useTranslation('OrderRoleAction');

  const modalData = useMemo(() => {
    // if the order was created on the online contact
    if (!defaultValue) {
      return {
        title: t('AssignSignerHeading'),
        description: t('AssignSignerDesc'),
        onClose: event => {
          onClose();

          if (event) {
            routes.toOrderStatuses();
          }
        }
      };
    }

    return {
      title: t('ChangeSignerHeading'),
      description: <Alert type="warning" message={t('ChangeSignerWarning')} />,
      onClose
    };
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  const onSubmit = useCallback(
    async employee => {
      try {
        await dispatch(
          changeOrderStatusSignatory({
            id: orderStatusId,
            signatoryId: employee.value
          })
        );
      } catch {
        return;
      }

      try {
        await dispatch(fetchOrderStatus({ id: orderStatusId }));
        await dispatch(
          fetchOrderStatusSignatory({ signatoryId: employee.value })
        );
      } catch ({ response }) {
        if (response.status === 403 || response.status === 404) {
          dispatch(removeOrderStatusFromEntries(orderStatusId));
          routes.toOrderStatuses({ isReplace: true });
        }
      }
    },
    [dispatch, orderStatusId, routes]
  );

  return (
    <EmployeeModal
      title={modalData.title}
      btnText={t('SubmitBtn')}
      description={modalData.description}
      selectProps={{
        label: t('Signer'),
        allowSetYourself: false,
        params: {
          workspaceControllers: true
        },
        renderContentTop: () => {}
      }}
      defaultValue={defaultValue}
      visible={visible}
      onSubmit={onSubmit}
      onClose={modalData.onClose}
    />
  );
};

ChangingSignatoryModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderStatusId: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string
  })
};

ChangingSignatoryModal.defaultProps = {
  defaultValue: null
};

export default ChangingSignatoryModal;

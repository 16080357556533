import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { Translation, useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Button from 'components/common/button';
import FormNewEditor from 'components/common/hook-form/markdown';

import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';

import styles from './reason.module.scss';

const Form = ({ isLoading, onSubmit }) => {
  const { t } = useTranslation(['ReasonModal', 'Errors']);

  const methods = useForm({
    defaultValues: {
      reason: {
        description: undefined,
        fileList: []
      }
    }
  });

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormNewEditor
          name="reason"
          placeholder={t('YourComment')}
          toolbarHidden
          showItems={{
            upload: false,
            emoji: true,
            mention: true,
            topToolbar: true
          }}
          rules={{
            validate: value => {
              if (!value.description) {
                return t('RequiredField', { ns: 'Errors' });
              }

              if (value.description.length < 4) {
                return t('ValidateMinLength', { ns: 'Errors', value: 4 });
              }

              if (value.description.length > 2500) {
                return t('ValidateMaxLength', { ns: 'Errors', value: 2500 });
              }

              return true;
            }
          }}
        />

        <Button
          type="primary"
          width="expanded"
          htmlType="submit"
          loading={isLoading}
          className={styles.button}
        >
          {t('SendBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

const RejectionModal = ({ visible, title, onSubmit, onClose, callback }) => {
  const [isLoading, setIsLoading] = useState(false);

  const handleSubmit = async ({ reason }) => {
    try {
      setIsLoading(true);

      const { description, fileList } = reason;

      await onSubmit({ reason: description, fileList: getFileIdsV2(fileList) });
      callback();
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Modal
      title={title}
      width={644}
      visible={visible}
      destroyOnClose
      onClose={onClose}
      contentClassName={styles.root}
      centered
    >
      <Form isLoading={isLoading} onSubmit={handleSubmit} />
    </Modal>
  );
};

RejectionModal.propTypes = {
  visible: PropTypes.bool,
  title: PropTypes.string,
  onSubmit: PropTypes.func.isRequired,
  onClose: PropTypes.func.isRequired,
  callback: PropTypes.func
};

RejectionModal.defaultProps = {
  visible: false,
  title: (
    <Translation ns="ReasonModal">
      {t => t('DeclineTaskReasonHeading')}
    </Translation>
  ),
  callback: () => {}
};

export default RejectionModal;

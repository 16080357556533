import React, { useState, useCallback } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  CONTACT_STATUS_ARCHIVED,
  CONTACT_STATUS_VERIFIED,
  INCOMING,
  OUTGOING,
  DEFER_DIALOG_MODAL,
  TYPE_CONTACT
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import Archive from 'components/common/icons/multicolor/archive';
import ModalDeleteConfirm from 'components/common/modal-delete-confirm';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import OrderCreatorModal from 'components/orders-view/modals/creator';
import { useModalContext } from 'components/app/modals-provider';

import {
  archiveContact,
  editContact,
  removeDeferral,
  restoreContact
} from 'store/contacts';
import { getUserEmployee } from 'store/workspace';
import { editContactCompanies } from 'store/contacts-companies';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import MergingContactsModal from '../modals/merging-contacts';

import styles from './actions.module.scss';

export const Actions = ({ contact, isDetailsView, ...props }) => {
  const dispatch = useDispatch();
  const employee = useSelector(getUserEmployee);

  const { showModal } = useModalContext();

  const [visibleMergingModal, setVisibleMergingModal] = useState(false);
  const [visibleOrderCreatorModal, setVisibleOrderCreatorModal] = useState({
    visible: false,
    defaultKind: ''
  });
  const [visibleTaskDrawer, setVisibleTaskDrawer] = useState(false);

  const [visibleArchiveModal, setVisibleArchiveModal] = useState(false);
  const [isLoadingArchive, setLoadingArchive] = useState(false);

  const { t } = useTranslation(['ArchiveContact', 'Contacts']);

  const allowCreateOrder =
    (contact.permissions || {}).canCreateOrder &&
    contact.status === CONTACT_STATUS_VERIFIED;

  const onArchiveContact = async () => {
    setLoadingArchive(true);

    if (!contact.responsible) {
      dispatch(
        editContact({ values: { responsible: employee.id }, id: contact.id })
      );
    }

    if (!contact.company.responsible) {
      dispatch(
        editContactCompanies({
          id: contact.company.id,
          values: { responsible: employee.id }
        })
      );
    }

    try {
      await dispatch(archiveContact({ id: contact.id, isDetailsView }));

      setVisibleArchiveModal(false);
      showNoticeMessage({ number: NOTICE_NUMBER.contactStatusChanged });
    } finally {
      setLoadingArchive(false);
    }
  };

  const onRestoreContact = async () => {
    await dispatch(restoreContact({ id: contact.id }));

    showNoticeMessage({ number: NOTICE_NUMBER.contactStatusChanged });
  };

  const onRemoveDefer = async () => {
    await dispatch(removeDeferral({ contactId: contact.id }));

    showNoticeMessage({
      customContent: t('TurnDialogSnoozeOffToast', { ns: 'Contacts' })
    });
  };

  // const remove = async () => {
  //   try {
  //     setLoadingRemoval(true);
  //     await dispatch(removeContact({ id: contact.id }));

  //     setVisibleRemovalModal(false);

  //     message.success('Контакт успешно удален!', MESSAGE_DURATION);
  //   } finally {
  //     setLoadingRemoval(false);
  //   }
  // };

  const getActions = useCallback(
    () => [
      {
        title: 'AddOrderAction',
        ns: 'Contacts',
        onClick: () =>
          setVisibleOrderCreatorModal({ visible: true, defaultKind: OUTGOING }),
        allow: allowCreateOrder
      },
      {
        title: 'AddPurchaseAction',
        ns: 'Contacts',
        onClick: () =>
          setVisibleOrderCreatorModal({ visible: true, defaultKind: INCOMING }),
        allow: allowCreateOrder
      },
      {
        title: 'AddTaskAction',
        ns: 'Contacts',
        onClick: () => setVisibleTaskDrawer(true),
        allow: (contact.permissions || {}).canCreateTask
      },
      {
        title: 'SnoozeContactAction',
        ns: 'Contacts',
        onClick: () =>
          showModal(DEFER_DIALOG_MODAL, {
            contactId: contact.id
          }),
        allow:
          (contact.permissions || {}).canDeferContact &&
          !contact.isDeferred &&
          contact.status !== CONTACT_STATUS_ARCHIVED
      },
      {
        title: 'TurnOffSnoozeContactAction',
        ns: 'Contacts',
        onClick: onRemoveDefer,
        allow:
          (contact.permissions || {}).canDeferContact &&
          contact.isDeferred &&
          contact.status !== CONTACT_STATUS_ARCHIVED
      },
      {
        title: 'СombineContactsAction',
        ns: 'Contacts',
        onClick: () => setVisibleMergingModal(true),
        allow: true
      },
      {
        title: 'ArchiveContactAction',
        ns: 'Contacts',
        onClick: () => setVisibleArchiveModal(true),
        allow:
          contact.status !== CONTACT_STATUS_ARCHIVED &&
          (contact.permissions || {}).canArchiveContact,
        className: styles.delete
      },
      {
        title: 'BackFromArchiveContactAction',
        ns: 'Contacts',
        onClick: onRestoreContact,
        allow:
          contact.status === CONTACT_STATUS_ARCHIVED &&
          ((contact.permissions || {}).canArchiveContact ||
            (contact.permissions || {}).canFullRestoreContact)
      }
      // {
      //   title: 'Удалить контакт',
      //   onClick: () => setVisibleRemovalModal(true),
      //   allow:
      //     contact.isArchived && (contact.permissions || {}).canRemoveContact,
      //   className: styles.delete
      // }
    ],
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [contact]
  );

  return (
    <>
      <ActionsDropdown
        actions={getActions()}
        {...props}
        data-qa="qa-yzukumggniqr6ox"
      />

      <MergingContactsModal
        visible={visibleMergingModal}
        contact={contact}
        onClose={() => setVisibleMergingModal(false)}
      />

      {/* <ModalDeleteConfirm */}
      {/*  visible={visibleRemovalModal} */}
      {/*  title={`Вы точно хотите удалить ${getFullName(contact)}?`} */}
      {/*  description="Восстановить данные будет невозможно." */}
      {/*  data-qa="qa-m0uw8a2zswg4hbl" */}
      {/*  isLoading={isLoadingRemoval} */}
      {/*  onConfirm={remove} */}
      {/*  onClose={() => setVisibleRemovalModal(false)} */}
      {/* /> */}

      <ModalDeleteConfirm
        visible={visibleArchiveModal}
        iconModal={Archive}
        title={
          contact.isDeferred
            ? t('ArchiveSnoozedContactHeading')
            : t('ArchiveContactHeading')
        }
        description={
          contact.isDeferred ? t('ArchiveSnoozedContactDesc') : undefined
        }
        data-qa="qa-m0uw8a2zswg4hbl"
        isLoading={isLoadingArchive}
        onConfirm={onArchiveContact}
        cancelBtnText={t('NoBtn')}
        deleteBtnText={t('YesBtn')}
        onClose={() => setVisibleArchiveModal(false)}
      />

      <OrderCreatorModal
        visible={visibleOrderCreatorModal.visible}
        onClose={() =>
          setVisibleOrderCreatorModal({ visible: false, defaultKind: '' })
        }
        data={{
          defaultKind: visibleOrderCreatorModal.defaultKind,
          defaultValues: { contractor: contact }
        }}
      />

      <TaskCreatorDrawer
        visible={visibleTaskDrawer}
        onClose={() => setVisibleTaskDrawer(false)}
        value={{
          relations: transformValueToRelation(
            [{ value: contact.id, label: contact }],
            TYPE_CONTACT
          ),
          responsible: contact.responsible ? [contact.responsible] : []
        }}
      />
    </>
  );
};

export default Actions;

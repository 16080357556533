import React from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import { Tooltip } from 'antd';
import classnames from 'classnames';

import { CALENDAR, TYPE_ASSET } from 'constants/index';

import Typography from 'components/common/typography';
import EmployeeAvatar from 'components/common/avatar/employee';
import Actions from 'components/assets-view/actions';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import { TagList } from 'components/common/tags';

import useModalsService from 'services/modals';
import useRoutesService from 'services/routes';

import styles from './card.module.scss';

const Card = ({ asset, tagListOptions, style, ActionsComponent }) => {
  const modals = useModalsService({ returnUrl: true });
  const routes = useRoutesService();

  return (
    <div className={styles.root} style={style}>
      <Link
        to={modals.assets.showDetails({ id: asset.id })}
        className={classnames(styles.flex, styles.link)}
      >
        <Tooltip
          title={
            <>
              {asset.title}
              <br />
              {asset.fullPath}
            </>
          }
          mouseEnterDelay={0.5}
        >
          <Typography.Text ellipsis>{asset.title}</Typography.Text>

          {asset.fullPath && (
            <Typography.Text color="black-55" size="small" ellipsis>
              {asset.fullPath}
            </Typography.Text>
          )}
        </Tooltip>
      </Link>

      <div className={styles.flex}>
        <TagList
          entityType={TYPE_ASSET}
          entityId={asset.id}
          displayedCount={2}
          className={styles.tags}
          {...tagListOptions}
        />

        {asset.inventoryNumber && (
          <Typography.Text
            size="small"
            color="black-55"
            style={{ marginRight: 16, whiteSpace: 'nowrap' }}
          >
            {asset.inventoryNumber}
          </Typography.Text>
        )}

        {asset.responsible && (
          <div style={{ marginRight: 16 }}>
            <EmployeeAvatar employee={asset.responsible} />
          </div>
        )}

        <Button
          type="link"
          className={styles.calendar}
          onClick={() => routes.toAssets({ view: CALENDAR, asset })}
        >
          <Icon type="schedule" />
        </Button>

        {ActionsComponent ? (
          <ActionsComponent asset={asset} />
        ) : (
          <Actions
            isFromDetails={false}
            asset={asset}
            style={{ marginLeft: 16 }}
          />
        )}
      </div>
    </div>
  );
};

Card.propTypes = {
  tagListOptions: PropTypes.object
};

Card.defaultProps = {
  tagListOptions: {}
};

export default Card;

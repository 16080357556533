import React from 'react';
import { useFormContext, Controller, useWatch } from 'react-hook-form';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import AttachmentsControl from 'components/common/attachments-control';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Attachments from 'components/common/attachments';

import FormItem from '../form-item';

export const FormAttachDocuments = ({
  rules,
  name,
  buttonProps,
  label,
  itemProps
}) => {
  const { t } = useTranslation('Common');

  const { control, formState, setValue } = useFormContext();

  const fileList = useWatch({ name, control });

  const onRenameFile = ({ id, name: fileName }) => {
    const newFileList = fileList.reduce((acc, curr) => {
      if (curr.response && curr.response.id === id) {
        return [...acc, { ...curr, name: fileName }];
      }

      return [...acc, curr];
    }, []);

    setValue(name, newFileList);
  };

  return (
    <>
      <FormItem name={name} {...itemProps} errors={formState.errors}>
        <Controller
          control={control}
          rules={rules}
          render={({ field }) => (
            <AttachmentsControl
              fileList={field.value}
              onChange={field.onChange}
            >
              <Button
                size="large"
                type="secondary"
                style={{ width: '100%' }}
                {...buttonProps}
              >
                <Icon type="paper-clip" />
                {label || t('AttachDocsBtn')}
              </Button>
            </AttachmentsControl>
          )}
        />
      </FormItem>

      <Attachments
        attachmentProps={{
          fileLinkTarget: '_blank',
          onDelete: file => {
            const key = file.id ? 'id' : 'uid';

            setValue(
              name,
              fileList.filter(({ [key]: id }) => id !== file[key])
            );
          },
          onRename: onRenameFile
        }}
        fileList={fileList}
        style={{ marginBottom: 16 }}
      />
    </>
  );
};

FormAttachDocuments.defaultProps = {
  rules: PropTypes.any,
  label: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  itemProps: PropTypes.object
};

FormAttachDocuments.defaultProps = {
  rules: {},
  label: undefined,
  itemProps: {}
};

export default FormAttachDocuments;

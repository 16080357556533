import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';

import { TYPE_DEPARTMENT } from 'constants/index';

import Avatar from 'components/common/avatar';
import Typography from 'components/common/typography';
import EmployeeAvatar from 'components/common/avatar/employee';
import Icon from 'components/common/icon';
import { FUNNY_AVATARS } from 'components/common/avatar/contact';

import { MENTION_TYPE_ALL, MENTION_TYPE_EMPLOYEE } from './utils';

import styles from './mention.module.scss';

const MentionAvatar = ({ item }) => {
  const { id, entity, mentionType, isMembers } = item;

  if (mentionType === MENTION_TYPE_EMPLOYEE) {
    return (
      <EmployeeAvatar
        isLink={false}
        tooltip={{ isHide: true }}
        employee={entity}
      />
    );
  }

  if (mentionType === MENTION_TYPE_ALL) {
    return <Icon color="black-55" type="big-team" />;
  }

  if (isMembers) {
    return <Icon color="black-55" type="team" />;
  }

  if (mentionType === TYPE_DEPARTMENT) {
    return <Avatar size={24} src={FUNNY_AVATARS[id % 10]} />;
  }

  return null;
};

export const MentionItem = forwardRef(
  ({ item, setSelected, className }, ref) => {
    const { title, description } = item;

    return (
      <div
        ref={ref}
        className={className}
        tabIndex="-1"
        onClick={() => setSelected(item)}
        onKeyPress={() => setSelected(item)}
      >
        <div className={styles.mentionItemHeader}>
          <MentionAvatar item={item} />

          <Typography.Text ellipsis>{title}</Typography.Text>
        </div>

        {description && (
          <Typography.Text ellipsis size="small">
            {description}
          </Typography.Text>
        )}
      </div>
    );
  }
);

MentionItem.propTypes = {
  item: PropTypes.shape({
    firstName: PropTypes.string,
    lastName: PropTypes.string,
    email: PropTypes.string,
    avatarFile: PropTypes.object
  }).isRequired,
  setSelected: PropTypes.func
};

MentionItem.defaultProps = {
  setSelected: () => {}
};

export default MentionItem;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';

import useMergingContacts from './use-merging-contacts';

import styles from './merging-contacts.module.scss';

const MergingContactsModal = ({ visible, contact, onClose }) => {
  const { t } = useTranslation('СombineContacts');

  const { step, handleClose } = useMergingContacts({ contact, onClose });

  return (
    <Modal
      width={600}
      title={t('СombineContactsHeading')}
      destroyOnClose
      centered
      contentClassName={styles.modal}
      visible={visible}
      onClose={handleClose}
    >
      {step}
    </Modal>
  );
};

// TODO
MergingContactsModal.propTypes = {
  visible: PropTypes.bool,
  contact: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired
};

MergingContactsModal.defaultProps = {
  visible: false,
  contact: {}
};

export default MergingContactsModal;

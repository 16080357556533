import React, { useState } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';
import Typography from 'components/common/typography';
import { getFormattedDate } from 'components/orders-view/utils';

import styles from './status-actions.module.scss';

const Condition = ({ data }) => {
  const [isExpanded, setIsExpanded] = useState(true);

  const { title, deadlineAt, price, currency } = data;

  const { t } = useTranslation('Order');

  return (
    <div className={styles.condition}>
      <Button
        type="link"
        className={styles.button}
        onClick={() => setIsExpanded(!isExpanded)}
      >
        {t(isExpanded ? 'CollapseConditionsBtn' : 'ExpandConditionsBtn')}
      </Button>

      <div
        className={classnames(styles.content, {
          [styles.expanded]: isExpanded
        })}
      >
        <Typography.Text>{`${t('ServiceNameDesc')} ${title}`}</Typography.Text>

        <Typography.Text>{`${t('OrderDueDateDesc')} ${getFormattedDate(
          deadlineAt
        )}`}</Typography.Text>

        <Typography.Text>{`${t('FinalCostDesc')} ${price ||
          0} ${currency}`}</Typography.Text>
      </div>
    </div>
  );
};

Condition.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.string,
    deadlineAt: PropTypes.string,
    price: PropTypes.number,
    currency: PropTypes.string
  }).isRequired
};

export default Condition;

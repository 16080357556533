import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import Attachments from 'components/common/attachments';

import { deleteFile } from 'hooks/common/use-file-upload/get-file-ids';

import styles from './controls.module.scss';

const ControlsAttachments = ({
  fileList,
  onChange,
  actionsDeps,
  className,
  attachmentProps,
  ...props
}) => {
  const removeFile = useCallback(file => onChange(deleteFile(fileList, file)), [
    fileList,
    onChange
  ]);

  const renameFile = useCallback(
    ({ id: fileId, name }) => {
      const newFileList = fileList.reduce((acc, curr) => {
        if (curr.response && curr.response.id === fileId) {
          return [...acc, { ...curr, name }];
        }

        return [...acc, curr];
      }, []);

      onChange(newFileList);
    },
    [fileList, onChange]
  );

  return (
    <Attachments
      fileList={fileList}
      className={classnames(styles.attachments, className)}
      data-qa="qa-zea14ytg1788fw3"
      attachmentProps={{
        size: 'small',
        ...attachmentProps,
        onDelete: removeFile,
        onRename: renameFile
      }}
      actionsDeps={actionsDeps}
      {...props}
    />
  );
};

ControlsAttachments.propTypes = {
  fileList: PropTypes.arrayOf(PropTypes.shape({})),
  attachmentProps: PropTypes.object,
  onChange: PropTypes.func,
  actionsDeps: PropTypes.shape({
    statusId: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    contactId: PropTypes.number,
    taskId: PropTypes.number,
    orderId: PropTypes.number,
    sendCopyToComment: PropTypes.bool,
    commentId: PropTypes.number,
    allowDeleteFrom: PropTypes.bool,
    isPrivate: PropTypes.bool
  }),
  className: PropTypes.string
};

ControlsAttachments.defaultProps = {
  fileList: [],
  attachmentProps: {},
  onChange: () => {},
  actionsDeps: {},
  className: undefined
};

export default ControlsAttachments;

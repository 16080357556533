import React, { useEffect } from 'react';
import { FormProvider, useForm } from 'react-hook-form';
import PropTypes from 'prop-types';
import { GoogleReCaptcha, useGoogleReCaptcha } from 'react-google-recaptcha-v3';
import { notification } from 'antd';
import classnames from 'classnames';

import Typography from 'components/common/typography';
import Button from 'components/common/button';
import {
  FormCheckbox,
  FormInput,
  FormPhoneInput,
  FormTextarea,
  validateMaxLength,
  validateMinLength,
  validatePhone
} from 'components/common/hook-form';
import { LogoIcon } from 'components/common/icons';
import Icon from 'components/common/icon';
import { renderDescriptions } from 'components/common/comments/converters';
import FormAttachFile from 'components/common/hook-form/attach-file';

import addImagePrefixToBase64 from 'utils/add-image-prefix-to-base64';

import { getLocalizedText } from '../website-form-channel/utils';

import styles from './website-form.module.scss';

const MAX_SIZE_UPLOAD_FILE = 200 * 1024 * 1024;

const WebsiteForm = ({
  values,
  onSubmit,
  isPreview,
  isLoading,
  onChangeFile
}) => {
  const { executeRecaptcha } = useGoogleReCaptcha();
  const { language } = values;

  const methods = useForm({
    defaultValues: {
      isAgreePolicy: !!isPreview,
      file: {}
    }
  });

  const fileWatch = methods.watch('file');

  const renderFormInput = ({ name, placeholder, required }) => {
    const formInputProps = {
      name,
      placeholder: getLocalizedText(name, language) || placeholder,
      rules: {
        required: required && getLocalizedText('fieldRequired', language)
      },
      disabled: isPreview
    };

    if (name === 'message') {
      return (
        <FormTextarea
          {...formInputProps}
          rules={{
            required: required && getLocalizedText('fieldRequired', language),
            maxLength: validateMaxLength(4000)
          }}
        />
      );
    }

    if (name === 'phone') {
      return (
        <FormPhoneInput
          {...formInputProps}
          rules={{
            required:
              required && getLocalizedText('phoneFieldRequired', language),
            validate: v =>
              required &&
              validatePhone(
                v,
                { isOnlyNumber: true },
                getLocalizedText('phoneFieldRequired', language)
              )
          }}
          itemProps={{ className: styles.phone }}
        />
      );
    }

    if (name === 'email') {
      return <FormInput {...formInputProps} type="email" />;
    }

    if (name === 'file') {
      return (
        <FormAttachFile
          itemProps={{
            className: styles.attachFile
          }}
          name="file"
          label={getLocalizedText('file', language)}
          buttonProps={{
            type: 'link',
            width: undefined,
            className: classnames(styles.attachBtn, {
              [styles.attachBtnDisabled]: isPreview
            }),
            style: { color: values.buttonColor }
          }}
          rules={{
            validate: value =>
              required && !Object.values(value).length
                ? getLocalizedText('fieldRequired', language)
                : true
          }}
          maxSize={MAX_SIZE_UPLOAD_FILE}
          disabled={isPreview}
          isImage={false}
          isMultiple
        />
      );
    }

    return (
      <FormTextarea
        key={name}
        {...formInputProps}
        rules={{
          required: required && getLocalizedText('fieldRequired', language),
          maxLength: validateMaxLength(1000)
        }}
        className={styles.textarea}
        autosize={{ minRows: 1.2, maxRows: 5 }}
      />
    );
  };

  const renderFormInputs = () => (values.fields || []).map(renderFormInput);

  const renderLogo = () => {
    const { isLogo, pageLogo } = values;

    if (isLogo || (!isPreview && pageLogo)) {
      if (!pageLogo && isPreview) {
        return <Icon component={LogoIcon} className={styles.logo} />;
      }

      const logoSrc = isPreview
        ? pageLogo
        : pageLogo && addImagePrefixToBase64(pageLogo);

      return <img alt="logo" src={logoSrc} className={styles.customLogo} />;
    }

    return null;
  };

  const dynamicStyleCheckbox = {
    '--background-button': values.buttonColor
  };

  const transformSubmittedValues = data => {
    const excludedKeys = ['isAgreePolicy', 'file'];

    const extraFields = Object.keys(data)
      .filter(key => !excludedKeys.includes(key))
      .reduce((obj, key) => {
        obj[key] = data[key];

        return obj;
      }, {});

    const { name, email, phone } = data;

    return {
      name,
      email: email || undefined,
      phone: phone || undefined,
      text: extraFields
    };
  };

  useEffect(() => {
    onChangeFile(fileWatch.fileList);

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [fileWatch]);

  return (
    <>
      {renderLogo()}

      <div className={styles.root} style={{ backgroundColor: values.color }}>
        <Typography.Title className={styles.title}>
          {values.title}
        </Typography.Title>

        {values.description && (
          <Typography.Text className={styles.description}>
            {renderDescriptions(values.description)}
          </Typography.Text>
        )}

        <FormProvider {...methods}>
          <form
            onSubmit={methods.handleSubmit(async data => {
              const token = await executeRecaptcha('submit');

              if (token) {
                onSubmit(transformSubmittedValues(data));
              } else {
                notification.error({
                  message: getLocalizedText('tryUpdatePage', language)
                });
              }
            })}
          >
            <FormInput
              name="name"
              placeholder={getLocalizedText('name', language)}
              disabled={isPreview}
              rules={{
                required: getLocalizedText('fieldRequired', language),
                maxLength: validateMaxLength(35),
                minLength: validateMinLength(2)
              }}
            />

            {renderFormInputs()}

            <div className={styles.privacy}>
              <FormCheckbox
                name="isAgreePolicy"
                rules={{
                  required: getLocalizedText('fieldRequired', language)
                }}
                style={dynamicStyleCheckbox}
                className={styles.checkbox}
              >
                <Typography.Text color="black-55" className={styles.text}>
                  {getLocalizedText('privacyPolicy', language)}
                  <a
                    href={getLocalizedText('privacyPolicyLink', language)}
                    target="_blank"
                    rel="noopener noreferrer"
                    className={styles.link}
                    style={{ color: values.buttonColor }}
                  >
                    {getLocalizedText('privacyPolicyTextLink', language)}
                  </a>
                </Typography.Text>
              </FormCheckbox>

              <Button
                htmlType="submit"
                disabled={isPreview}
                className={styles.submitBtn}
                width="expanded"
                style={{ background: values.buttonColor }}
                loading={isLoading}
              >
                <Typography.Text className={styles.btnText} ellipsis>
                  {values.buttonText}
                </Typography.Text>
              </Button>
            </div>
          </form>
        </FormProvider>
      </div>

      <Typography.Paragraph
        color="black-65"
        className={styles.footer}
        size="small"
      >
        {getLocalizedText('businessFormText', language)}
        <a
          style={{ color: values.buttonColor }}
          href="https://upservice.com/?utm_source=upservice-form-to-site"
          target="_blank"
          rel="noopener noreferrer"
        >
          Upservice
        </a>
      </Typography.Paragraph>

      {!isPreview && <GoogleReCaptcha action="submit" />}
    </>
  );
};

WebsiteForm.propTypes = {
  values: PropTypes.object,
  onSubmit: PropTypes.func,
  isPreview: PropTypes.bool,
  isLoading: PropTypes.bool,
  onChangeFile: PropTypes.func
};

WebsiteForm.defaultProps = {
  values: {},
  onSubmit: () => {},
  isPreview: false,
  isLoading: false,
  onChangeFile: () => {}
};

export default WebsiteForm;

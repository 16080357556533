import React from 'react';
import { useDispatch, useSelector } from 'react-redux';

import {
  MODAL,
  CREATE_COMPANY,
  CHANNELS_CREATOR_DRAWER,
  CHANNELS_EDITOR_DRAWER,
  ORDER_CREATOR_DRAWER,
  ORDER_COPY_DRAWER,
  ASSIGNMENTS_CHANGE_DRAWER,
  RELATIONS_DRAWER,
  CONTACT_CREATOR_DRAWER,
  CONTRACT_DRAWER,
  ACT_EDITOR_DRAWER,
  ORDER_EDIT_DRAWER,
  WRITE_FIRST_DRAWER,
  SAVE_TO_DISK_DRAWER,
  INVITE_EMPLOYEE_DRAWER,
  INVITE_EMPLOYEE,
  CONTACTS_IMPORT_DRAWER,
  CONTACTS_IMPORT_RESULTS_DRAWER,
  MESSAGE_TEMPLATES_DRAWER,
  CREATE_ORDER,
  ASSET_CREATOR_DRAWER,
  TASK_CREATOR_DRAWER,
  CREATE_DEPARTMENT_DRAWER,
  MOVE_DEPARTMENT_DRAWER
} from 'constants/index';

import CreateWorkspaceDrawer from 'components/common/create-workspace-drawer';
import useVisibleOrderDetails from 'components/orders-view/hooks/use-visible-order-details';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import TaskDetailDrawer from 'components/tasks-view/view/drawers/details';
import TaskEditorDrawer from 'components/tasks-view/view/drawers/editor';
import AssetDetailsDrawer from 'components/assets-view/drawers/details';
import useAssetDetailsVisible from 'components/assets-view/hooks/use-asset-details-visible';
import ChannelsCreatorDrawer from 'components/contacts-view/views/integrations/drawers/creator';
import ChannelsEditorDrawer from 'components/contacts-view/views/integrations/drawers/editor';
import OrderCreatorDrawer from 'components/orders-view/drawers/creator';
import OrderCopyDrawer from 'components/orders-view/drawers/copy';
import {
  AssignmentsDrawer,
  RelationsDrawer,
  SaveToDiskDrawer
} from 'components/common/drawers';
import OrderDetailsDrawer from 'components/orders-view/drawers/details';
import ContactCreatorDrawer from 'components/contacts-view/drawers/creator';
import OrderContractDrawer from 'components/orders-view/drawers/contract';
import OrderActEditorDrawer from 'components/orders-view/drawers/act';
import OrderEditDrawer from 'components/orders-view/drawers/edit';
import WriteFirstDrawer from 'components/contacts-view/drawers/write-first';
import InviteEmployeeDrawer from 'components/team-view/employees-view/drawers/invite-employee';
import ContactsImportDrawer from 'components/contacts-view/drawers/contacts-import';
import ContactsImportResultsDrawer from 'components/contacts-view/drawers/contacts-import-results';
import MessageTemplatesDrawer from 'components/common/drawers/message-templates';
import AssetCreatorDrawer from 'components/assets-view/drawers/creator';
import CreateDepartmentDrawer from 'components/team-view/orgstructure-view/drawers/create-department';
import MovingDepartmentDrawer from 'components/team-view/orgstructure-view/drawers/moving-department';

import { getDrawer, setVisibleDrawer } from 'store/drawers';
import { getIsOnlyUserProfile } from 'store/user';
import { getAllWorkspaces } from 'store/workspace';

import { useTaskCreator, useTaskDetailModal, useTaskEditor } from 'hooks';
import { useQueryParam } from 'hooks/common';
import useModalsService from 'services/modals';

export const DrawersProvider = () => {
  const dispatch = useDispatch();

  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);
  const workspaces = useSelector(getAllWorkspaces);

  const contactCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTACT_CREATOR_DRAWER })
  );
  const contactsImportDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTACTS_IMPORT_DRAWER })
  );
  const contactsImportResultsDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTACTS_IMPORT_RESULTS_DRAWER })
  );

  const orderCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ORDER_CREATOR_DRAWER })
  );
  const orderCopyDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ORDER_COPY_DRAWER })
  );
  const orderEditDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ORDER_EDIT_DRAWER })
  );

  const channelsCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CHANNELS_CREATOR_DRAWER })
  );
  const channelsEditorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CHANNELS_EDITOR_DRAWER })
  );
  const assignmentsChangeDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ASSIGNMENTS_CHANGE_DRAWER })
  );
  const relationsDrawer = useSelector(state =>
    getDrawer(state)({ drawer: RELATIONS_DRAWER })
  );
  const contractDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CONTRACT_DRAWER })
  );
  const orderActEditorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ACT_EDITOR_DRAWER })
  );
  const contactWriteFirstDrawer = useSelector(state =>
    getDrawer(state)({ drawer: WRITE_FIRST_DRAWER })
  );
  const saveToDiskDrawer = useSelector(state =>
    getDrawer(state)({ drawer: SAVE_TO_DISK_DRAWER })
  );
  const inviteEmployeeDrawer = useSelector(state =>
    getDrawer(state)({ drawer: INVITE_EMPLOYEE_DRAWER })
  );
  const messageTemplatesDrawer = useSelector(state =>
    getDrawer(state)({ drawer: MESSAGE_TEMPLATES_DRAWER })
  );
  const assetCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: ASSET_CREATOR_DRAWER })
  );
  const taskCreatorDrawer = useSelector(state =>
    getDrawer(state)({ drawer: TASK_CREATOR_DRAWER })
  );
  const createDepartmentDrawer = useSelector(state =>
    getDrawer(state)({ drawer: CREATE_DEPARTMENT_DRAWER })
  );
  const moveDepartmentDrawer = useSelector(state =>
    getDrawer(state)({ drawer: MOVE_DEPARTMENT_DRAWER })
  );

  const handleCloseDrawer = drawer => dispatch(setVisibleDrawer({ drawer }));

  const modals = useModalsService();
  const modal = useQueryParam(MODAL);

  const showCreateWorkspace = modal === CREATE_COMPANY;
  const showInviteEmployee = modal === INVITE_EMPLOYEE;
  const showOrderCreator = modal === CREATE_ORDER;

  const ordersDetailsVisible = useVisibleOrderDetails(false);

  const [visibleCreator] = useTaskCreator(false);
  const [visibleTaskDetail] = useTaskDetailModal(false);
  const [visibleEditor] = useTaskEditor(false);

  const assetDetailsVisible = useAssetDetailsVisible(false);

  const onCloseDrawer = () => modals.close();
  const onBack = () => modals.back();

  return (
    <>
      <CreateWorkspaceDrawer visible={showCreateWorkspace} onClose={onBack} />

      {!isOnlyUserProfile && !!Object.keys(workspaces).length && (
        <>
          {/* Создние задачи */}
          <TaskCreatorDrawer
            visible={!!taskCreatorDrawer || visibleCreator}
            value={taskCreatorDrawer}
            onClose={() => {
              if (visibleCreator) {
                return onCloseDrawer();
              }

              return handleCloseDrawer(TASK_CREATOR_DRAWER);
            }}
          />

          {/* Просмотр задачи */}
          <TaskDetailDrawer
            visible={visibleTaskDetail}
            onClose={onCloseDrawer}
          />

          {/* Редактирование задачи */}
          <TaskEditorDrawer visible={visibleEditor} onClose={onBack} />

          {/* CONTACTS */}
          <ContactCreatorDrawer
            visible={!!contactCreatorDrawer}
            values={contactCreatorDrawer}
            isDetailsAfterSubmit={
              (contactCreatorDrawer || {}).isDetailsAfterSubmit
            }
            onClose={() => handleCloseDrawer(CONTACT_CREATOR_DRAWER)}
          />

          <ContactsImportDrawer
            visible={!!contactsImportDrawer}
            data={contactsImportDrawer}
            onClose={() => handleCloseDrawer(CONTACTS_IMPORT_DRAWER)}
          />

          <ContactsImportResultsDrawer
            visible={!!contactsImportResultsDrawer}
            data={contactsImportResultsDrawer}
            onClose={() => handleCloseDrawer(CONTACTS_IMPORT_RESULTS_DRAWER)}
          />

          {/* WRITE FIRST DRAWER */}
          <WriteFirstDrawer
            visible={!!contactWriteFirstDrawer}
            data={contactWriteFirstDrawer}
            onClose={() => handleCloseDrawer(WRITE_FIRST_DRAWER)}
          />

          {/* MESSAGE TEMPLATES DRAWER */}
          <MessageTemplatesDrawer
            visible={!!messageTemplatesDrawer}
            data={messageTemplatesDrawer}
            onClose={() => handleCloseDrawer(MESSAGE_TEMPLATES_DRAWER)}
          />

          {/* ORDERS */}
          <OrderCreatorDrawer
            visible={!!orderCreatorDrawer || showOrderCreator}
            data={orderCreatorDrawer}
            onClose={() => {
              if (showOrderCreator) {
                return onCloseDrawer();
              }

              return handleCloseDrawer(ORDER_CREATOR_DRAWER);
            }}
          />

          <OrderCopyDrawer
            visible={!!orderCopyDrawer}
            data={orderCopyDrawer}
            onClose={() => handleCloseDrawer(ORDER_COPY_DRAWER)}
          />

          <OrderEditDrawer
            visible={!!orderEditDrawer}
            data={orderEditDrawer}
            onClose={() => handleCloseDrawer(ORDER_EDIT_DRAWER)}
          />

          <OrderDetailsDrawer visible={ordersDetailsVisible} />

          {/* CHANNELS */}
          <ChannelsCreatorDrawer
            visible={!!channelsCreatorDrawer}
            data={channelsCreatorDrawer}
            onClose={() => handleCloseDrawer(CHANNELS_CREATOR_DRAWER)}
          />

          <ChannelsEditorDrawer
            visible={!!channelsEditorDrawer}
            onClose={() => handleCloseDrawer(CHANNELS_EDITOR_DRAWER)}
          />

          {/* ASSETS */}
          <AssetCreatorDrawer
            visible={!!assetCreatorDrawer}
            values={assetCreatorDrawer}
            onClose={() => handleCloseDrawer(ASSET_CREATOR_DRAWER)}
          />

          <AssetDetailsDrawer
            visible={assetDetailsVisible}
            onClose={onCloseDrawer}
          />

          {/* ASSIGNMENTS - used to change members in tasks, orders and setting access in contacts, assets */}
          <AssignmentsDrawer
            visible={!!assignmentsChangeDrawer}
            data={assignmentsChangeDrawer}
            onClose={() => handleCloseDrawer(ASSIGNMENTS_CHANGE_DRAWER)}
          />

          {/* RELATIONS */}
          <RelationsDrawer
            visible={!!relationsDrawer}
            data={relationsDrawer}
            onClose={() => handleCloseDrawer(RELATIONS_DRAWER)}
          />

          {/* ORDER CONTRACT */}
          <OrderContractDrawer
            visible={!!contractDrawer}
            data={contractDrawer}
            onClose={() => handleCloseDrawer(CONTRACT_DRAWER)}
          />

          {/* ORDER CHANGE ACT DRAWER */}
          <OrderActEditorDrawer
            visible={!!orderActEditorDrawer}
            data={orderActEditorDrawer}
            onClose={() => handleCloseDrawer(ACT_EDITOR_DRAWER)}
          />

          {/* SAVE TO DISK */}
          <SaveToDiskDrawer
            visible={!!saveToDiskDrawer}
            data={saveToDiskDrawer}
            onClose={() => handleCloseDrawer(SAVE_TO_DISK_DRAWER)}
          />

          <InviteEmployeeDrawer
            visible={!!inviteEmployeeDrawer || showInviteEmployee}
            data={inviteEmployeeDrawer}
            onClose={() => {
              if (showInviteEmployee) {
                return onCloseDrawer();
              }

              return handleCloseDrawer(INVITE_EMPLOYEE_DRAWER);
            }}
          />

          <CreateDepartmentDrawer
            visible={createDepartmentDrawer}
            onClose={() => handleCloseDrawer(CREATE_DEPARTMENT_DRAWER)}
            data={createDepartmentDrawer}
          />

          <MovingDepartmentDrawer
            visible={moveDepartmentDrawer}
            onClose={() => handleCloseDrawer(MOVE_DEPARTMENT_DRAWER)}
            data={moveDepartmentDrawer}
          />
        </>
      )}
    </>
  );
};

export default DrawersProvider;

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  CONTACT_STATUS_ARCHIVED,
  CONTACT_STATUS_UNASSEMLED,
  CONTACT_STATUS_VERIFIED,
  EMAIL
} from 'constants/index';

import { Button } from 'components/common/button';
import ChatControls from 'components/common/chat/controls';
import { getIsCustomKindByValue } from 'components/common/new-editor/components/to-chat-select/utils';
import MessageEditor from 'components/common/new-editor/editors/message-editor';
import { ContactSelect } from 'components/common/controls/custom-select';
import emailsMapValues from 'components/common/controls/custom-select/contact-select/emails-map-values';

import Parent from '../message/parent';

import styles from './controls.module.scss';

const { Attachments, Subject, AiAssistantPrompts, Typing } = ChatControls;

const Controls = ({
  entity,
  parentData,
  controls,
  typingList,
  rootClassName
}) => {
  const { t } = useTranslation('Contacts');

  const {
    fileList,
    value,
    hasValue,
    toChat,
    toChatOptions,
    subject,
    allowOnlyComment,
    isUploadingFiles,
    isLoadingSending,
    setSubject,
    setFileList,
    onChange,
    setToChat,
    sendMessage,
    onRestoreContact,
    openFirstWriteDrawer,
    allowToSendFirstMessage,
    employeeId,
    visibleCopyEmail,
    setVisibleCopyEmail,
    copyEmails,
    setCopyEmails,
    entityType,
    allowCreateFile,
    privateData,
    setMentionedEmployees,
    hasAiAssistantMention,
    destination
  } = controls;

  const { label, value: chatUuid } = toChat;
  const isCustomKind = getIsCustomKindByValue(chatUuid);

  const hasSubjectField = label.channelKind === EMAIL && !isCustomKind;

  return entity.status !== CONTACT_STATUS_ARCHIVED ? (
    <>
      <Typing typingList={typingList} />

      <AiAssistantPrompts
        hasAiAssistantMention={hasAiAssistantMention}
        entityType={destination.entityType}
        onClick={prompt => sendMessage({ prompt })}
      />

      {parentData.message && (
        <Parent
          data={parentData.message}
          onDelete={parentData.onDelete}
          onClick={parentData.onClick}
        />
      )}

      <div
        className={classnames(
          styles.root,
          {
            [styles[label.channelKind]]: chatUuid
          },
          rootClassName
        )}
      >
        <Attachments
          fileList={fileList}
          className={styles.attachments}
          onChange={setFileList}
        />

        <div className={styles.padding}>
          {label.channelKind === EMAIL && visibleCopyEmail && (
            <ContactSelect
              isMulti
              label={undefined}
              valueText={t('CC')}
              value={copyEmails}
              className={styles.emailsSelect}
              onChange={email => setCopyEmails(email)}
              customMapValues={emailsMapValues}
              closeMenuOnSelect={false}
              showEmail
              params={{
                status: [CONTACT_STATUS_VERIFIED, CONTACT_STATUS_UNASSEMLED]
              }}
            />
          )}

          <div
            className={classnames(styles.editorWrap, {
              [styles.noTopBorderRadius]: visibleCopyEmail
            })}
          >
            {label.channelKind === EMAIL && !isCustomKind && (
              <Subject
                value={subject}
                onChange={setSubject}
                onOpenCopy={() => setVisibleCopyEmail(prev => !prev)}
                isCopy={visibleCopyEmail}
                isDisabled={!!parentData.message}
                className={
                  visibleCopyEmail ? styles.noTopBorderRadius : undefined
                }
              />
            )}

            <MessageEditor
              onChange={onChange}
              setFileList={setFileList}
              fileList={fileList}
              value={value}
              openFirstWriteDrawer={openFirstWriteDrawer}
              allowToSendFirstMessage={allowToSendFirstMessage}
              valueChat={toChat}
              onChangeChat={setToChat}
              options={toChatOptions}
              isDisabledChat={allowOnlyComment}
              employeeId={employeeId}
              entity={entity}
              sendMessage={sendMessage}
              isLoadingSending={isLoadingSending}
              isDisabledSubmit={!hasValue || isUploadingFiles}
              editorContainerClassName={
                hasSubjectField ? styles.noTopBorderRadius : undefined
              }
              entityType={entityType}
              allowCreateFile={allowCreateFile}
              privateData={privateData}
              setMentionedEmployees={setMentionedEmployees}
              destination={destination}
            />
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className={styles.wrapRestoreContact}>
      <Button
        type="secondary"
        onClick={onRestoreContact}
        className={styles.restoreContactBtn}
      >
        {t('BackFromArchiveContactAction')}
      </Button>
    </div>
  );
};

Controls.propTypes = {
  parentData: PropTypes.shape({
    data: PropTypes.object,
    onDelete: PropTypes.func,
    onClick: PropTypes.func
  }),
  rootClassName: PropTypes.string
};

Controls.defaultProps = {
  parentData: {},
  rootClassName: undefined
};

export default Controls;

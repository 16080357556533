import { useEffect, useState } from 'react';

import { ITEMS_PER_PAGE, TYPE_CONTACT } from 'constants/index';

import useChat from '../use-chat';

const useContactChat = ({ contact = {}, destination }) => {
  const [offsetData, setOffsetData] = useState({});

  const {
    renderedMessages,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    renderedControls,
    entityChat,
    isJoined,
    hasMore,
    fetchMessages
  } = useChat({
    entity: contact,
    destination,
    entityType: TYPE_CONTACT,
    needLeave: false
  });

  const currentOffset = offsetData[contact.id] || 0;

  const loadMore = async ({ offset = currentOffset } = {}) => {
    await fetchMessages({ offset, limit: ITEMS_PER_PAGE, withSource: false });
    setOffsetData(prev => ({
      ...prev,
      [contact.id]: (prev[contact.id] || 0) + ITEMS_PER_PAGE
    }));
  };

  useEffect(() => {
    if (
      contact &&
      contact.chats &&
      (entityChat.isNeedInitalFetchMessages === undefined ||
        entityChat.isNeedInitalFetchMessages)
    ) {
      loadMore({ offset: 0 });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [entityChat]);

  return {
    renderedMessages,
    renderedControls,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    isJoined,
    hasMore,
    loadMore
  };
};

export default useContactChat;

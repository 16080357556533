import React, { useState, useEffect } from 'react';
import classnames from 'classnames';
import { Divider, Tooltip } from 'antd';
import PropTypes from 'prop-types';
import moment from 'moment';
import { useDispatch } from 'react-redux';
import { Translation, useTranslation } from 'react-i18next';

import {
  // CONTACT_STATUS_UNASSEMLED,
  // CONTACT_STATUS_VERIFIED,
  DATE_FORMAT,
  DATE_TIME_FORMAT,
  NO_NAME,
  // OUTGOING,
  TYPE_CONTACT
} from 'constants/index';

import VerifiedPhone from 'components/common/verified-phone';
import Button from 'components/common/button';
import Avatar from 'components/common/avatar';
import { TagList } from 'components/common/tags';
// import CreateOrderBtn from 'components/common/create-order-btn';
// import CreatRelatedTaskBtn from 'components/common/create-task-btn';
// import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';
import Actions from 'components/contacts-view/actions';
import Typography from 'components/common/typography';
import Switch from 'components/common/controls/switch';
import ContactAvatar from 'components/common/avatar/contact';
import Reminder from 'components/common/reminder';

import { toggleNotify } from 'store/contacts';

import getFIO, { getFullName } from 'utils/get-fio';
import useRoutesService from 'services/routes';

import EditableFields, { FIELD_TYPES } from './editable-field';
import EditorContractorModal from './editor-contractor-modal';
import EditorResponsibleModal from '../../drawers/editor-responsible';
import InviteToRegister from '../../invite-to-register';
import InfoButtons from '../info-buttons';

import styles from './editor-form.module.scss';

const { Text, Paragraph } = Typography;

export const ContactEditorForm = ({
  className,
  contact: _contact,
  orderStatusId
}) => {
  const dispatch = useDispatch();
  const routes = useRoutesService();
  const [contact, setContact] = useState(_contact);
  const [editableField, setEditableField] = useState();
  const [visibleEditorContractor, setVisibleEditorContractor] = useState(false);
  const [responsible, setResponsible] = useState();

  const { t } = useTranslation([
    'Contacts',
    'ChangeContactResponsible',
    'Common'
  ]);

  const onChangeToggleNotify = async checked => {
    await dispatch(toggleNotify({ notify: checked, id: contact.id }));
  };

  const toWorkspaceContact = () => {
    if (!(contact.company || {}).id) {
      return;
    }

    routes.toContractor(contact.company.id);
  };

  useEffect(() => {
    setContact(_contact);
  }, [_contact]);

  useEffect(() => {
    setEditableField(null);
  }, [contact.id]);

  if (!contact) {
    return null;
  }

  const {
    canEditContact, // use for contact fields
    canUpdateContact // use for tags, responsible set/change
  } = contact.permissions || {};

  const isOfflineContact = !contact.employee;
  const canEditOfflineContact = canEditContact && isOfflineContact;
  const isNotIndividualContact =
    (contact.company || {}).workspace &&
    !((contact.company || {}).workspace || {}).isIndividual;

  // const allowCreateOrder = contact.status === CONTACT_STATUS_VERIFIED;

  // const getCreateOrderTitle = () => {
  //   if (contact.status === CONTACT_STATUS_UNASSEMLED) {
  //     return 'Чтобы создать заказ, заполните данные о контакте, нажав на Добавить возле email или телефона. Или запросите контакты, если вам их еще не предоставили';
  //   }
  //
  //   if (!allowCreateOrder) {
  //     return 'Чтобы создать заказ, верните контакт из архива';
  //   }
  //   return undefined;
  // };

  const filtrFieldsByField = field =>
    Object.keys(contact).filter(
      key => key.includes(field) && contact[key] !== null
    );

  const renderFields = field => {
    if (!filtrFieldsByField(field).length) {
      return <Text>-</Text>;
    }

    return filtrFieldsByField(field).map((key, index) => (
      <EditableFields
        orderStatusId={orderStatusId}
        key={key}
        name={key}
        contactId={contact.id}
        value={contact[key]}
        type={FIELD_TYPES[field.toUpperCase()]}
        defaultEditable={editableField === key}
        clearEditable={() => setEditableField(null)}
        allowClear={index > 0}
        allowEdit={canEditOfflineContact}
      >
        <Text className={styles.value}>{contact[key]}</Text>

        {index === 0 && field === 'phone' && (
          <VerifiedPhone
            isVerified={contact.isPhoneVerified}
            iconStyle={{ margin: '0 auto 0 4px' }}
          />
        )}
      </EditableFields>
    ));
  };

  const onAddField = field => {
    const countField = filtrFieldsByField(field).length;
    const fieldName = `${field}${countField === 0 ? '' : countField}`;

    setEditableField(fieldName);
    setContact({ ...contact, [fieldName]: undefined });
  };

  return (
    <>
      <div
        className={classnames(styles.root, className)}
        data-qa="qa-hj5kcbobkqwhlmo"
      >
        <div className={styles.nameWrap} data-qa="qa-efu14d6uott3l9e">
          <div style={{ marginRight: 12, flexShrink: 0 }}>
            <VerifiedPhone
              isVerified={contact.isPhoneVerified}
              badgeProps={{
                offset: [-5, 32]
              }}
            >
              <Tooltip
                title={
                  contact.isDeferred
                    ? `${t('SnoozedTip')} ${moment(contact.deferUntil).format(
                        DATE_TIME_FORMAT
                      )}`
                    : undefined
                }
              >
                <ContactAvatar contact={contact} size={36} />
              </Tooltip>
            </VerifiedPhone>
          </div>

          <EditableFields
            orderStatusId={orderStatusId}
            isMulti
            contactId={contact.id}
            value={contact}
            allowEdit={canEditOfflineContact}
          >
            <Text className={styles.name} data-qa="qa-tz5qzaajhp7bvnd">
              {getFullName({ ...contact, allName: true }) || (
                <Translation ns={NO_NAME.NS}>
                  {translate =>
                    `${translate(NO_NAME.FIRST_NAME)} ${NO_NAME.LAST_NAME}`
                  }
                </Translation>
              )}
            </Text>
          </EditableFields>

          {!orderStatusId && (
            <Actions contact={contact} btnClassName={styles.actionsBtn} />
          )}
        </div>

        <div>
          {isOfflineContact && (
            <div className={styles.inviteToRegister}>
              <Text color="black-55" data-qa="qa-rdffiz4dgdq6lnv">
                {t('ContactNotRegistered')}
              </Text>

              <InviteToRegister contact={contact} />
            </div>
          )}

          <div className={styles.reminder}>
            <Reminder
              entityType={TYPE_CONTACT}
              entityId={contact.id}
              textReminder={`${contact.firstName} ${
                contact.lastName ? ` ${contact.lastName}` : ''
              }`}
            />
          </div>

          <Divider className={styles.divider} />
        </div>

        {/* {!orderStatusId && ( */}
        {/*  <div className={styles.creatorBtnsWrap}> */}
        {/*    <Tooltip mouseEnterDelay={0.5} title={getCreateOrderTitle()}> */}
        {/*      <div> */}
        {/*        <CreateOrderBtn */}
        {/*          withCreatorModal */}
        {/*          type="secondary" */}
        {/*          size="large" */}
        {/*          data={{ */}
        {/*            defaultKind: OUTGOING, */}
        {/*            defaultValues: { contractor: contact } */}
        {/*          }} */}
        {/*          disabled={!allowCreateOrder} */}
        {/*          style={{ */}
        {/*            pointerEvents: allowCreateOrder ? 'auto' : 'none' */}
        {/*          }} */}
        {/*        > */}
        {/*          Добавить заказ */}
        {/*        </CreateOrderBtn> */}
        {/*      </div> */}
        {/*    </Tooltip> */}

        {/*    <CreatRelatedTaskBtn */}
        {/*      createWithUrl={false} */}
        {/*      type="secondary" */}
        {/*      initialValue={{ */}
        {/*        relations: transformValueToRelation( */}
        {/*          [{ value: contact.id, label: contact }], */}
        {/*          TYPE_CONTACT */}
        {/*        ), */}
        {/*        responsible: contact.responsible ? [contact.responsible] : [] */}
        {/*      }} */}
        {/*    > */}
        {/*      Добавить задачу */}
        {/*    </CreatRelatedTaskBtn> */}
        {/*  </div> */}
        {/* )} */}

        {/* <Divider className={styles.divider} /> */}

        <InfoButtons contact={contact} />

        <Divider className={styles.divider} />

        <div className={styles.item} data-qa="qa-23phkyh1452lkbz">
          <Text
            className={styles.label}
            data-qa="qa-g1ux081jqqbrjk9"
            style={{ height: 24 }}
          >
            <span>{t('ContactResponsibleFull')}</span>

            {canUpdateContact && (
              <Button
                type="link"
                size="small"
                className={styles.link}
                data-qa="qa-y0t1k8sf3l4n8lr"
                onClick={() => setResponsible(contact.responsible)}
              >
                {t(
                  contact.responsible ? 'ChangeContactResponsible' : 'AssignBtn'
                )}
              </Button>
            )}
          </Text>

          <Text
            className={styles.value}
            data-qa="qa-6r8atv24btanu3j"
            style={{ display: 'flex', alignItems: 'center' }}
          >
            {contact.responsible ? (
              <>
                <Avatar
                  src={(contact.responsible.avatarFile || {}).url}
                  size={25}
                  style={{ marginRight: 8, flexShrink: 0 }}
                >
                  {getFIO(contact.responsible)}
                </Avatar>
                <Text style={{ marginRight: 'auto' }}>
                  {getFullName(contact.responsible) || '-'}
                </Text>{' '}
              </>
            ) : (
              <>
                <div className={styles.circle} />
                <Text>{t('NotAssigned')}</Text>
              </>
            )}
          </Text>
        </div>

        <Divider className={styles.divider} />

        <div className={styles.item}>
          <div className={styles.tagsHeader}>
            <Text className={styles.label}>{t('TagsHeading')}</Text>
          </div>

          <TagList
            entityType={TYPE_CONTACT}
            entityId={contact.id}
            needFetchTags
            allowAddTag={canUpdateContact}
            allowDeleteTag={canUpdateContact}
          />
        </div>

        <div className={styles.item} data-qa="qa-zmguapz17o6dxqu">
          <Text className={styles.label} data-qa="qa-m7hswlxv8gcmdxy">
            <span>{t('CompanyHeading')}</span>

            {canEditOfflineContact && (
              <Button
                type="link"
                size="small"
                className={styles.link}
                data-qa="qa-doubwzugituk05u"
                onClick={() => setVisibleEditorContractor(true)}
              >
                {t('ChangeContractorBtn')}
              </Button>
            )}
          </Text>

          <Button
            type="link"
            style={{ marginTop: -5 }}
            className={classnames(styles.value, {
              [styles.default]: !(contact.company || {}).id
            })}
            data-qa="qa-mk4t8k7anbhu8bm"
            onClick={toWorkspaceContact}
          >
            <Paragraph
              ellipsis={{ rows: 1 }}
              color="inherit"
              style={{ marginBottom: 0, fontWeight: 400, fontSize: 16 }}
              title={(contact.workspace || {}).title}
              className={styles.link}
              data-qa="qa-uchul2qc9n7hgp2"
            >
              <Tooltip
                title={
                  (contact.company || {}).title ||
                  t('Individual', { ns: 'Common' })
                }
                mouseEnterDelay={0.5}
                placement="left"
              >
                {(contact.company || {}).title ||
                  t('Individual', { ns: 'Common' })}
              </Tooltip>
            </Paragraph>
          </Button>
        </div>

        {isNotIndividualContact && (
          <div className={styles.item} data-qa="qa-vmzjmuvph6s5pkt">
            <Text className={styles.label} data-qa="qa-fwk94eqzhhem150">
              {t('PositionHeading')}
            </Text>

            <EditableFields
              orderStatusId={orderStatusId}
              name="position"
              contactId={contact.id}
              value={contact.position}
              allowEdit={canEditOfflineContact}
            >
              <Text className={styles.value} data-qa="qa-w09manmc1hd3ugt">
                {contact.position || '-'}
              </Text>
            </EditableFields>
          </div>
        )}

        <div className={styles.item} data-qa="qa-huwmfeg38qdf2jf">
          <Text className={styles.label} data-qa="qa-bxbv3zb1003o69a">
            <span>{t('PhoneNumberHeading')}</span>

            {canEditOfflineContact && (
              <Button
                type="link"
                size="small"
                className={styles.link}
                data-qa="qa-pp9xlcjhl5i7i53"
                disabled={!!editableField}
                onClick={() => onAddField('phone')}
              >
                {t('AddPhoneNumberBtn')}
              </Button>
            )}
          </Text>
          {renderFields('phone')}
        </div>

        <div className={styles.item} data-qa="qa-m4faiv6iocelmhv">
          <Text className={styles.label} data-qa="qa-3necl6sb9ct6lwf">
            <span>{t('Email')}</span>

            {canEditOfflineContact && (
              <Button
                type="link"
                size="small"
                className={styles.link}
                data-qa="qa-dha309ff6kfi0ut"
                disabled={!!editableField}
                onClick={() => onAddField('email')}
              >
                {t('AddEmailBtn')}
              </Button>
            )}
          </Text>
          {renderFields('email')}
        </div>

        <div className={styles.item} data-qa="qa-o8s0pop34jzjodh">
          <Text className={styles.label} data-qa="qa-qz6jptwj93bpm46">
            {t('Address')}
          </Text>

          <EditableFields
            orderStatusId={orderStatusId}
            name="address"
            contactId={contact.id}
            data-qa="qa-jho96ahfamwpvpm"
            value={contact.address}
            type={FIELD_TYPES.LOCATION}
            allowEdit={canEditOfflineContact}
          >
            <Text className={styles.value} data-qa="qa-hhokjja3vqzaq1z">
              {contact.address ? (
                <a
                  href={`https://www.google.com/maps/search/?api=1&query=${contact.address}`}
                  target="_blank"
                  rel="noreferrer"
                >
                  {contact.address}
                </a>
              ) : (
                '-'
              )}
            </Text>
          </EditableFields>
        </div>

        <div className={styles.item} data-qa="qa-1axu7mli3xdhqd1">
          <Text className={styles.label} data-qa="qa-ixivq006ku8hh45">
            {t('BirthDate')}
          </Text>
          <EditableFields
            orderStatusId={orderStatusId}
            name="birthday"
            data-qa="qa-8vzp4xj33xquop5"
            value={contact.birthday}
            contactId={contact.id}
            type={FIELD_TYPES.DATE_PICKER}
            allowEdit={canEditContact}
          >
            <Text className={styles.value} data-qa="qa-jd3bb9mis74gblu">
              {contact.birthday || '-'}
            </Text>
          </EditableFields>
        </div>

        <div className={styles.item} data-qa="qa-jd3bb9mis74gblu">
          <Text className={styles.label} data-qa="qa-qfw3l0sqqr22szi">
            {t('ContactCreationDate')}
          </Text>
          <Text className={styles.value} data-qa="qa-hdjyxk6jh3yx6g6">
            {moment(contact.createdAt).format(DATE_FORMAT) || '-'}
          </Text>
        </div>

        {!orderStatusId && (
          <Switch
            value={contact.notify}
            label={t('ReceiveNotificationsToggle')}
            onChange={onChangeToggleNotify}
          />
        )}
      </div>

      <EditorContractorModal
        orderStatusId={orderStatusId}
        contactId={contact.id}
        visible={visibleEditorContractor}
        responsible={(contact.responsible || {}).id}
        isIndividualContact={
          ((contact.company || {}).workspace || {}).isIndividual
        }
        onClose={() => setVisibleEditorContractor(false)}
      />

      <EditorResponsibleModal
        title={t('ChangeContactResponsibleHeading', {
          ns: 'ChangeContactResponsible'
        })}
        orderStatusId={orderStatusId}
        visible={responsible !== undefined}
        responsible={responsible}
        contactId={(contact || {}).id}
        onClose={() => setResponsible(undefined)}
        btnText={t('SaveBtn', { ns: 'ChangeContactResponsible' })}
      />
    </>
  );
};

ContactEditorForm.propTypes = {
  className: PropTypes.string,
  contact: PropTypes.shape({
    id: PropTypes.number,
    contact: PropTypes.object
  })
};

ContactEditorForm.defaultProps = {
  className: undefined,
  contact: {}
};

export default ContactEditorForm;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { useRouteMatch } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { TYPE_ASSET } from 'constants/index';

import MainForm from 'components/assets-view/forms/main';
import Drawer from 'components/common/drawer';
import { useUpdateTags } from 'components/common/tags';

import { create } from 'store/assets';

import useModalsService from 'services/modals';

const AssetCreatorDrawer = ({
  values,
  visible,
  showDetailsAfterSubmit,
  callback,
  onClose
}) => {
  const { t } = useTranslation('AddAsset');

  const dispatch = useDispatch();
  const modals = useModalsService();
  const updateTags = useUpdateTags();
  const match = useRouteMatch('/:id/assets/categories/:categoryId');
  const { categoryId } = (match || {}).params || {};

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = async ({ tags, ...asset }) => {
    try {
      setIsLoading(true);

      const createdAsset = await dispatch(
        create({ asset, categoryId: +categoryId })
      );

      if (callback) {
        await callback(createdAsset);
      }

      await updateTags({
        entityType: TYPE_ASSET,
        entityId: createdAsset.id,
        newTags: tags
      });

      onClose();

      if (showDetailsAfterSubmit) {
        modals.assets.showDetails({ id: createdAsset.id });
      }
    } finally {
      setIsLoading(false);
    }
  };

  return (
    <Drawer
      title={<Drawer.Title>{t('AddAssetHeading')}</Drawer.Title>}
      width={591}
      visible={visible}
      destroyOnClose
      onClose={onClose}
      maskClosable={false}
    >
      <MainForm values={values} isLoading={isLoading} onSubmit={onSubmit} />
    </Drawer>
  );
};

AssetCreatorDrawer.propTypes = {
  values: PropTypes.shape({
    title: PropTypes.string,
    descriprion: PropTypes.oneOfType([PropTypes.string, PropTypes.array]),
    fileList: PropTypes.array,
    inventoryNumber: PropTypes.string,
    responsible: PropTypes.object,
    relations: PropTypes.array
  }),
  visible: PropTypes.bool,
  showDetailsAfterSubmit: PropTypes.bool,
  callback: PropTypes.func,
  onClose: PropTypes.func
};

AssetCreatorDrawer.defaultProps = {
  values: {},
  visible: false,
  showDetailsAfterSubmit: true,
  callback: undefined,
  onClose: () => {}
};

export default AssetCreatorDrawer;

import React, {
  createRef,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState
} from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Dropdown, Skeleton, Spin, Tooltip } from 'antd';
import classnames from 'classnames';
import { FormProvider, useForm } from 'react-hook-form';
import InfiniteScroll from 'react-infinite-scroller';
import { useTranslation } from 'react-i18next';

import {
  TYPE_ORDER,
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_CHANNEL_CHAT,
  TYPE_ORDER_TEMPLATE,
  TYPE_TASK_TEMPLATE,
  TYPE_ATTACHMENT,
  TYPE_PROJECT,
  TYPE_ORDER_STATUS
} from 'constants/index';

import Typography from 'components/common/typography';
import DebounceInput from 'components/common/controls/debounce-input';
import TagOption from 'components/common/controls/custom-select/custom-select/tag-option';
import FormRadioColor from 'components/common/hook-form/radio/color';
import { useTheme } from 'components/common/theme';
import Icon from 'components/common/icon';

import { addTag, createTag, fetchTagsLocal } from 'store/tags';

import styles from './add-button.module.scss';

const SELECT_TAG_STEP = 'select-tag-step';
const SELECT_COLOR_STEP = 'select-color-step';

const useKeyPress = (targetKey, targetCode) => {
  const [keyPressed, setKeyPressed] = useState(false);

  const downHandler = ({ key, code }) => {
    if (key === targetKey || targetCode === code) {
      setKeyPressed(true);
    }
  };

  const upHandler = ({ key, code }) => {
    if (key === targetKey || targetCode === code) {
      setKeyPressed(false);
    }
  };

  useEffect(() => {
    window.addEventListener('keydown', downHandler);
    window.addEventListener('keyup', upHandler);

    return () => {
      window.removeEventListener('keydown', downHandler);
      window.removeEventListener('keyup', upHandler);
    };
  });

  return keyPressed;
};

export const AddTagButton = ({
  entityType,
  entityId,
  attachedTags,
  shortAddButton,
  setCreatableTag,
  addCallback
}) => {
  const dispatch = useDispatch();

  const inputRef = useRef();
  const dropdownRef = useRef();

  const downPress = useKeyPress('ArrowDown');
  const upPress = useKeyPress('ArrowUp');
  const leftPress = useKeyPress('ArrowLeft');
  const rightPress = useKeyPress('ArrowRight');
  const enterPress = useKeyPress('Enter');
  const escapePress = useKeyPress('Escape');
  // const keySPress = useKeyPress(undefined, 'KeyS');

  const { variables } = useTheme();

  const methods = useForm({
    defaultValues: {
      color: variables.color.green
    }
  });

  const colorField = methods.watch('color');

  const [isLoading, setIsLoading] = useState(false);
  const [dropdownVisible, setDropdownVisible] = useState(false);
  const [tags, setTags] = useState([]);
  const [totalItems, setTotalItems] = useState(0);
  const [search, setSearch] = useState('');
  const [cursor, setCursor] = useState(0);
  const [hovered, setHovered] = useState(undefined);
  const [step, setStep] = useState(SELECT_TAG_STEP);
  const [isKeyboardMode, setIsKeyboardMode] = useState(false);

  const { t: translate } = useTranslation('Common');

  const allowCreateBtn = useMemo(
    () =>
      !isLoading &&
      search &&
      !tags.find(
        t => t.name.toLowerCase().trim() === search.toLowerCase().trim()
      ) &&
      totalItems <= tags.length,
    [isLoading, search, tags, totalItems]
  );

  const isShortButton = useMemo(() => shortAddButton && !!attachedTags.length, [
    attachedTags.length,
    shortAddButton
  ]);

  const hasMore = useMemo(() => !isLoading && totalItems > tags.length, [
    isLoading,
    tags.length,
    totalItems
  ]);

  const filteredTags = useMemo(
    () =>
      tags.reduce((acc, curr) => {
        if (attachedTags.find(t => t.id === curr.id)) {
          return acc;
        }

        return [...acc, curr];
      }, []),
    [attachedTags, tags]
  );

  const length = useMemo(() => filteredTags.length + 1 + allowCreateBtn, [
    allowCreateBtn,
    filteredTags.length
  ]);

  const refs = useMemo(
    () =>
      filteredTags.reduce((acc, curr) => {
        acc[curr.id] = createRef();
        return acc;
      }, {}),
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [length]
  );

  const changeCursor = value => {
    if (value === 0) {
      inputRef.current.focus();
    } else {
      inputRef.current.blur();
      dropdownRef.current.focus();
    }

    setCursor(value);
  };

  const changeHovered = value => {
    if (upPress || downPress) {
      return null;
    }

    setIsKeyboardMode(false);
    return setHovered(value);
  };

  const add = useCallback(
    async tag => {
      await dispatch(addTag({ tag: { ...tag, entityType, entityId } }));

      setSearch('');

      addCallback();
    },
    [addCallback, dispatch, entityId, entityType]
  );

  const create = useCallback(
    async color => {
      try {
        if (step === SELECT_TAG_STEP) {
          setCreatableTag({ name: search, color: variables.color.green });
          return setStep(SELECT_COLOR_STEP);
        }

        setIsLoading(true);

        const createdTag = await dispatch(
          createTag({
            tag: {
              name: search,
              color: color || colorField
            }
          })
        );

        await add(createdTag);

        setCreatableTag();
        return setDropdownVisible(false);
      } finally {
        setIsLoading(false);
      }
    },
    [
      add,
      colorField,
      dispatch,
      search,
      setCreatableTag,
      step,
      variables.color.green
    ]
  );

  const onPressEnter = useCallback(async () => {
    if (cursor === 0) {
      if (!search) {
        return null;
      }

      if (allowCreateBtn) {
        return create();
      }

      const searchedTag = filteredTags.find(t => t.name === search);

      if (searchedTag) {
        await add(searchedTag);
        return null;
      }

      return null;
    }

    if (cursor === length - 1 && allowCreateBtn) {
      return create();
    }

    if (filteredTags[cursor - 1]) {
      await add(filteredTags[cursor - 1]);
    }

    return null;
  }, [add, allowCreateBtn, create, cursor, filteredTags, length, search]);

  const onClickColor = color => {
    if (
      leftPress ||
      rightPress ||
      upPress ||
      downPress ||
      color !== colorField
    ) {
      return null;
    }

    return create(color);
  };

  const fetch = async ({ offset }) => {
    try {
      setIsLoading(true);

      const fetchedTags = await dispatch(
        fetchTagsLocal({
          search,
          offset: offset === undefined ? tags.length : offset
        })
      );

      setTags(prev => [...prev, ...fetchedTags.data]);
      setTotalItems(fetchedTags.total);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (dropdownVisible) {
      setTotalItems(0);
      setTags([]);
      fetch({ offset: 0 });
      setCursor(0);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [search, dropdownVisible]);

  useEffect(() => {
    if (
      length &&
      downPress &&
      dropdownVisible &&
      !isLoading &&
      step === SELECT_TAG_STEP
    ) {
      changeCursor(cursor < length - 1 ? cursor + 1 : cursor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [downPress]);

  useEffect(() => {
    if (
      length &&
      upPress &&
      dropdownVisible &&
      !isLoading &&
      step === SELECT_TAG_STEP
    ) {
      changeCursor(cursor > 0 ? cursor - 1 : cursor);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress]);

  useEffect(() => {
    if (inputRef.current) {
      inputRef.current.focus();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible, inputRef.current]);

  useEffect(() => {
    if (length && enterPress && dropdownVisible && !isLoading) {
      onPressEnter();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [enterPress]);

  useEffect(() => {
    if (length && hovered && !isKeyboardMode) {
      if (hovered === 'create') {
        setCursor(length - 1);
      } else {
        setCursor(filteredTags.indexOf(hovered) + 1);
      }
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [hovered]);

  useEffect(() => {
    if (
      (upPress || downPress) &&
      cursor !== 0 &&
      cursor !== length - allowCreateBtn &&
      isKeyboardMode
    ) {
      refs[filteredTags[cursor - 1].id].current.scrollIntoView({
        block: 'center'
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [cursor]);

  useEffect(() => {
    if ((upPress || downPress) && dropdownVisible) {
      setIsKeyboardMode(true);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [upPress, downPress]);

  useEffect(() => {
    if (escapePress && dropdownVisible) {
      setDropdownVisible(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [escapePress]);

  // useEffect(() => {
  //   if (
  //     keySPress &&
  //     dropdownVisible &&
  //     inputRef.current &&
  //     document.activeElement !== inputRef.current
  //   ) {
  //     changeCursor(0);
  //     setSearch(search);
  //   }
  //   // eslint-disable-next-line react-hooks/exhaustive-deps
  // }, [keySPress]);

  useEffect(() => {
    if (dropdownVisible) {
      setCreatableTag({ name: search, color: colorField });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [colorField]);

  useEffect(() => {
    if (!dropdownVisible) {
      if (step === SELECT_COLOR_STEP && !isLoading) {
        create();
      }

      setSearch('');
      setCursor(0);
      setStep(SELECT_TAG_STEP);
      setTags([]);
      setTotalItems(0);
      setHovered(undefined);
      methods.reset();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [dropdownVisible]);

  const component = {
    [SELECT_TAG_STEP]: (
      <>
        <DebounceInput
          autoFocus
          value={search}
          setValue={setSearch}
          className={styles.search}
          ref={inputRef}
          maxLength={25}
          placeholder={translate('Search')}
          onMouseEnter={() => setCursor(0)}
        />

        <div className={styles.tags}>
          <InfiniteScroll
            useWindow={false}
            initialLoad={false}
            hasMore={hasMore}
            loadMore={fetch}
          >
            {filteredTags.map((t, i) => (
              <div
                key={t.id}
                ref={refs[t.id]}
                className={classnames(styles.option, {
                  [styles.active]: i + 1 === cursor
                })}
                onMouseEnter={() => changeHovered(t)}
                onMouseLeave={() => changeHovered(undefined)}
                onClick={() => add(t)}
              >
                <TagOption option={t} />
              </div>
            ))}

            {isLoading && (
              <Skeleton
                title={false}
                active
                paragraph={{ rows: 4, width: '100%' }}
                className={styles.skeleton}
              />
            )}
          </InfiniteScroll>
        </div>

        {allowCreateBtn && (
          <Typography.Paragraph
            color="black-55"
            className={classnames(styles.create, {
              [styles.active]: cursor === length - 1,
              [styles.withTags]: !!filteredTags.length
            })}
            onMouseEnter={() => changeHovered('create')}
            onMouseLeave={() => changeHovered(undefined)}
            onClick={create}
          >
            {translate('Create')}{' '}
            <TagOption option={search} className={styles.creatableTag} />
          </Typography.Paragraph>
        )}
      </>
    ),
    [SELECT_COLOR_STEP]: (
      <Spin spinning={isLoading}>
        <FormProvider {...methods}>
          <form>
            <FormRadioColor
              name="color"
              itemProps={{
                className: styles.colorItem
              }}
              autoFocus
              onClick={onClickColor}
            />
          </form>
        </FormProvider>
      </Spin>
    )
  };

  const dropdownOverlay = (
    <div
      className={styles.dropdownOverlay}
      // eslint-disable-next-line jsx-a11y/no-noninteractive-tabindex
      tabIndex="0"
      ref={dropdownRef}
      onClick={event => {
        event.stopPropagation();
        event.nativeEvent.stopPropagation();
      }}
    >
      {component[step]}
    </div>
  );

  return (
    <Dropdown
      overlay={dropdownOverlay}
      trigger={['click']}
      visible={dropdownVisible}
      onVisibleChange={setDropdownVisible}
    >
      <Tooltip
        title={isShortButton ? translate('AddTag') : undefined}
        mouseEnterDelay={0.5}
      >
        <Typography.Text
          size="small"
          color="brand"
          className={classnames(styles.add, { [styles.short]: isShortButton })}
          onClick={e => e.stopPropagation()}
        >
          {isShortButton ? <Icon type="plus" size={16} /> : translate('AddTag')}
        </Typography.Text>
      </Tooltip>
    </Dropdown>
  );
};

AddTagButton.propTypes = {
  entityType: PropTypes.oneOf([
    TYPE_ORDER,
    TYPE_TASK,
    TYPE_ASSET,
    TYPE_CONTACT,
    TYPE_CHANNEL_CHAT,
    TYPE_ORDER_TEMPLATE,
    TYPE_TASK_TEMPLATE,
    TYPE_ATTACHMENT,
    TYPE_PROJECT,
    TYPE_ORDER_STATUS
  ]).isRequired,
  entityId: PropTypes.oneOfType([PropTypes.string, PropTypes.number])
    .isRequired,
  attachedTags: PropTypes.array,
  shortAddButton: PropTypes.bool,
  setCreatableTag: PropTypes.func,
  addCallback: PropTypes.func
};

AddTagButton.defaultProps = {
  attachedTags: [],
  shortAddButton: false,
  setCreatableTag: () => {},
  addCallback: () => {}
};

export default AddTagButton;

import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import BackBtn from 'components/common/back-btn';
import { FormInput, FormRelationsSelect } from 'components/common/hook-form';
import Button from 'components/common/button';

import styles from './settings.module.scss';

const Settings = ({ type, isLoading, onBack, onSubmit }) => {
  const methods = useForm();

  const { t } = useTranslation(['AddFile', 'Errors']);

  return (
    <div className={styles.root}>
      <BackBtn className={styles.back} onClick={onBack}>
        {t('BackBtn')}
      </BackBtn>

      <Typography.Paragraph>{t('AddFileDesc')}</Typography.Paragraph>

      <FormProvider {...methods}>
        <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
          <FormInput
            name="title"
            label={t('FileTitle')}
            placeholder={t('EnterTitle')}
            addonAfter={`.${type}`}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              maxLength: {
                value: 250,
                message: t('InputMaxLengthSymbols', {
                  ns: 'Errors',
                  length: '250'
                })
              }
            }}
          />

          <FormRelationsSelect
            name="relation"
            label={t('Link')}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            allowToSelectOne
          />

          <Button
            type="primary"
            htmlType="submit"
            size="large"
            width="expanded"
            loading={isLoading}
            className={styles.submit}
          >
            {t('NextBtn')}
          </Button>
        </form>
      </FormProvider>
    </div>
  );
};

Settings.propTypes = {
  type: PropTypes.string,
  isLoading: PropTypes.bool,
  onBack: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

Settings.defaultProps = {
  type: null,
  isLoading: false
};

export default Settings;

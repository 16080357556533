import { CancelToken } from 'axios';

export const OLD_REQUEST_CANCELED = 'old-request-canceled';

export const makeCancelableRequest = (api, fn) => {
  let cancel;

  return (...args) => {
    if (typeof cancel === 'function') {
      cancel(OLD_REQUEST_CANCELED);
    }

    const fetch = (method, url, config) =>
      api[method](url, {
        ...config,
        // eslint-disable-next-line
        cancelToken: new CancelToken(c => (cancel = c))
      });

    return fn(fetch, ...args);
  };
};

export default makeCancelableRequest;

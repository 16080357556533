import React, { useState } from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm } from 'react-hook-form';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import {
  TESTID_ORDERS_CREATOR_MODAL_CONTRACTOR_SELECT,
  TESTID_ORDERS_CREATOR_MODAL_DEADLINE_AT_PICKER,
  TESTID_ORDERS_CREATOR_MODAL_PRICE_INPUT,
  TESTID_ORDERS_CREATOR_MODAL_SHOW_FULL_FORM_BUTTON,
  TESTID_ORDERS_CREATOR_MODAL_SIGNATORY_SELECT,
  TESTID_ORDERS_CREATOR_MODAL_SUBMIT_BUTTON,
  TESTID_ORDERS_CREATOR_MODAL_TITLE_INPUT,
  CONTACT_STATUS_VERIFIED
} from 'constants/index';

import {
  FormCurrencySelect,
  FormDatePicker,
  FormEmployeeSelect,
  FormInput,
  FormInputNumber,
  LabelWithTooltip
} from 'components/common/hook-form';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import FormNewEditor from 'components/common/hook-form/markdown';

import { getUserEmployee } from 'store/workspace';

import FormCounterpartSelect from '../main/components/counterpart-select';
import { transformFormSubmittedValues } from '../utils';

import styles from './short.module.scss';

const ShortForm = ({ isLoading, defaultValues, showMainForm, onSubmit }) => {
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation(['AddOrder', 'Errors', 'Order']);

  const methods = useForm({
    defaultValues: {
      content: {
        description: undefined,
        fileList: []
      },
      deadlineAt: undefined,
      currency: {
        label: employee.currency,
        value: employee.currency
      },
      contractor: defaultValues.contractor
        ? {
            label: {
              ...defaultValues.contractor,
              workspaceTitle:
                defaultValues.contractor.workspaceTitle ||
                (defaultValues.contractor.company || {}).title
            },
            value: defaultValues.contractor.id
          }
        : null
    }
  });

  const [isShowDescription, setIsShowDescription] = useState(false);

  const onShowMainForm = () => {
    const values = methods.getValues();

    const transformedValues = {
      ...values,
      ...values.content,
      currency: employee.currency,
      signatory: (values.signatory || {}).label,
      contractor: (values.contractor || {}).label
    };

    showMainForm(transformedValues);
  };

  return (
    <FormProvider {...methods}>
      <form
        onSubmit={methods.handleSubmit(values =>
          onSubmit(
            transformFormSubmittedValues({ values, isShortForm: true, t })
          )
        )}
      >
        <FormInput
          label={t('ServiceName')}
          name="title"
          placeholder={t('EnterName')}
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
          data-testid={TESTID_ORDERS_CREATOR_MODAL_TITLE_INPUT}
        />

        {isShowDescription ? (
          <FormNewEditor
            label={t('OrderDesc')}
            name="content"
            placeholder={t('OrderDescPlchldr')}
            resizeInput
          />
        ) : (
          <Button
            type="link"
            className={styles.showDescriptionBtn}
            onClick={() => setIsShowDescription(true)}
          >
            <Icon type="plus" /> <span>{t('AddDescAndDocsBtn')}</span>
          </Button>
        )}

        <div className={styles.infoWrap}>
          <FormDatePicker
            label={t('OrderDueDate')}
            placeholderText={t('ChooseDate')}
            name="deadlineAt"
            rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
            wrapperClassname={styles.datePicker}
            dataTestId={TESTID_ORDERS_CREATOR_MODAL_DEADLINE_AT_PICKER}
          />

          <FormInputNumber
            label={t('OrderCost')}
            name="price"
            placeholder="000"
            data-testid={TESTID_ORDERS_CREATOR_MODAL_PRICE_INPUT}
          />

          <FormCurrencySelect name="currency" label={t('Currency')} />
        </div>

        <div className={styles.usersWrap}>
          <FormCounterpartSelect
            label={t('Contractor')}
            name="contractor"
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            params={{
              status: CONTACT_STATUS_VERIFIED
            }}
            dataTestId={TESTID_ORDERS_CREATOR_MODAL_CONTRACTOR_SELECT}
          />

          <FormEmployeeSelect
            name="signatory"
            label={
              <LabelWithTooltip
                label={t('Signer')}
                tooltip={t('SignerFieldTip')}
                style={{ maxWidth: 255 }}
              />
            }
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            allowSetYourself={false}
            params={{
              workspaceControllers: true
            }}
            renderContentTop={() => {}}
            dataTestId={TESTID_ORDERS_CREATOR_MODAL_SIGNATORY_SELECT}
          />
        </div>

        <div className={styles.submitWrap}>
          <Button
            type="link"
            className={styles.showMainFormBtn}
            onClick={onShowMainForm}
            data-testid={TESTID_ORDERS_CREATOR_MODAL_SHOW_FULL_FORM_BUTTON}
          >
            {t('OpenFullOrderFormBtn')}
          </Button>

          <Button
            type="primary"
            htmlType="submit"
            className={styles.submitBtn}
            loading={isLoading}
            data-testid={TESTID_ORDERS_CREATOR_MODAL_SUBMIT_BUTTON}
          >
            {t('SaveBtn')}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

ShortForm.propTypes = {
  isLoading: PropTypes.bool,
  defaultValues: PropTypes.shape({}),
  showMainForm: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired
};

ShortForm.defaultProps = {
  isLoading: false,
  defaultValues: {}
};

export default ShortForm;

import { createAction } from 'redux-actions';
import api from 'api';

import { ITEMS_PER_PAGE, TYPE_ASSET } from 'constants/index';

import { fetchTagsByEntity } from 'store/tags';

import mapValue from 'utils/map-value';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId } from '../workspace';
import { getAssetsFilter } from '.';

const transformFetchParams = ({
  responsible = [],
  creator = [],
  range = {},
  tag = {},
  relations = {},
  category = [],
  ids,
  ...params
}) => {
  const hasTags = !!(tag.ids || []).length;
  const tagsCondition = (tag.condition || {}).value;
  const tagsIds = hasTags ? tag.ids.map(mapValue) : undefined;

  const { orderStatus = [], task = [], contact = [], asset = [] } = relations;

  return {
    responsible: responsible.map(mapValue),
    creator: creator.map(mapValue),
    range: range.value || [],
    tagsCondition,
    tagsIds,
    orderStatus: orderStatus.map(mapValue),
    task: task.map(mapValue),
    contact: contact.map(mapValue),
    asset: asset.map(mapValue),
    category: Array.isArray(category) ? category.map(mapValue) : category,
    id: ids,
    ...params
  };
};

export const fetchAssets = createActionThunk(
  'assets/fetch',
  ({ getState, dispatch, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const filter = getAssetsFilter(state);

    const resultParams = transformFetchParams({
      ...filter,
      ...params,
      limit: ITEMS_PER_PAGE
    });

    return api.assets
      .fetch({ workspaceId, ...resultParams })
      .then(({ data }) => {
        const assetIds = data.results.map(asset => asset.id);

        if (assetIds) {
          dispatch(
            fetchTagsByEntity({
              entityType: TYPE_ASSET,
              entityIds: assetIds
            })
          );
        }

        return data;
      });
  }
);

export const fetchCategories = createActionThunk(
  'assets/fetch-categories',
  ({ getState, parent = 0, id, manager, limit = 10000, search }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets
      .fetchCategories({ workspaceId, parent, id, manager, limit, search })
      .then(({ data }) => data.results);
  }
);

export const fetchCategoriesLocal = createActionThunk(
  'assets/fetch-categories-local',
  ({ getState, search, limit = 10000 }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets
      .fetchCategories({ workspaceId, limit, search })
      .then(({ data }) => data.results);
  }
);

export const fetchOneCategory = createActionThunk(
  'assets/fetch-one-category',
  ({ getState, id }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets
      .fetchOneCategory({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const fetchAssetsLocal = createActionThunk(
  'assets/fetch-local',
  ({ getState, withoutCancelling, params = {} }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = transformFetchParams({
      limit: ITEMS_PER_PAGE,
      ...params
    });

    const fetch = withoutCancelling
      ? api.assets.fetchWithoutCancelling
      : api.assets.fetch;

    return fetch({ workspaceId, ...resultParams }).then(({ data }) => data);
  }
);

export const fetchOne = createActionThunk(
  'assets/fetch-one',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets.fetchOne({ workspaceId, id }).then(({ data }) => data);
  }
);

export const fetchOneLocal = createActionThunk(
  'assets/fetch-one-local',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets.fetchOne({ workspaceId, id }).then(({ data }) => data);
  }
);

export const create = createActionThunk(
  'assets/create',
  ({ getState, asset }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets.create({ workspaceId, asset }).then(({ data }) => data);
  }
);

export const update = createActionThunk(
  'assets/update',
  ({ getState, id, asset }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .update({ workspaceId, id, asset })
      .then(({ data }) => data);
  }
);

export const partialUpdate = createActionThunk(
  'assets/partial-update',
  ({ getState, id, asset }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .partialUpdate({ workspaceId, id, asset })
      .then(({ data }) => ({ id, ...data }));
  }
);

export const remove = createActionThunk('assets/delete', ({ getState, id }) => {
  const state = getState();
  const workspaceId = getWorkspaceId(state);

  return api.assets.delete({ workspaceId, id }).then(() => id);
});

// LOG & COMMENTS
export const fetchLog = createActionThunk(
  'assets/fetch-log',
  ({ getState, id, isFetchAfterChanges, params }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .fetchLog({ workspaceId, id, params })
      .then(({ data }) => ({ id, isFetchAfterChanges, ...data }));
  }
);

export const sendComment = createActionThunk(
  'assets/send-comment',
  ({ getState, id, comment }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .sendComment({ workspaceId, id, comment })
      .then(({ data }) => data);
  }
);

// MANAGERS
export const fetchManagers = createActionThunk(
  'assets/fetch-managers',
  ({ getState, params = {} }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    const resultParams = {
      ...params,
      limit: params.limit || 1000
    };

    return api.assets
      .fetchManagers({ workspaceId, params: resultParams })
      .then(({ data }) => data);
  }
);

export const fetchManagersLocal = createActionThunk(
  'assets/fetch-managers-local',
  ({ getState, params, limit = 1000 }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .fetchManagers({ workspaceId, limit, params })
      .then(({ data }) => data);
  }
);

export const fetchOneManager = createActionThunk(
  'assets/fetch-managers',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .fetchOneManager({ workspaceId, id })
      .then(({ data }) => data);
  }
);

export const addManager = createActionThunk(
  'assets/add-manager',
  ({ getState, values }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .addManager({ workspaceId, values })
      .then(({ data }) => data);
  }
);

export const addCategory = createActionThunk(
  'assets/add-category',
  ({ getState, category }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .addCategory({ workspaceId, category })
      .then(({ data }) => data);
  }
);

export const updateCategory = createActionThunk(
  'assets/update-category',
  ({ getState, id, category }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .updateCategory({ workspaceId, id, category })
      .then(({ data }) => data);
  }
);

export const deleteCategory = createActionThunk(
  'assets/delete-category',
  ({ getState, id, newParent }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .deleteCategory({ workspaceId, id, newParent })
      .then(() => id);
  }
);

export const deleteManager = createActionThunk(
  'assets/delete-manager',
  ({ getState, id, newManager }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets.deleteManager({ workspaceId, id, newManager });
  }
);

export const changeManager = createActionThunk(
  'assets/change-manager',
  ({ getState, id, newManager }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .changeManager({ workspaceId, id, newManager })
      .then(({ data }) => data);
  }
);

// RELATIONS
export const fetchRelations = createActionThunk(
  'assets/fetch-relations',
  ({ getState, id }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.assets
      .fetchRelations({ workspaceId, id })
      .then(({ data }) => data.results);
  }
);

export const changeRelations = createActionThunk(
  'assets/change-relations',
  ({ getState, id, relations }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets.changeRelations({ workspaceId, id, relations });
  }
);

// MEMBERS (ACCESS SETTINGS)
export const fetchMembers = createActionThunk(
  'assets/fetch-members',
  ({ getState, limit, offset, id }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets
      .fetchMembers({ limit, offset, id, workspaceId })
      .then(({ data }) => data.results);
  }
);

export const fetchMembersInCategory = createActionThunk(
  'assets/fetch-members-in-category',
  ({ getState, limit, offset, id }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets
      .fetchMembersInCategory({ limit, offset, id, workspaceId })
      .then(({ data }) => data.results);
  }
);

export const changeMembers = createActionThunk(
  'assets/change-members',
  ({ getState, id, isDelete, employees }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets.members({ workspaceId, id, employees, isDelete });
  }
);

export const changeMembersInCategory = createActionThunk(
  'assets/change-members-in-category',
  ({ getState, id, isDelete, employees }) => {
    const workspaceId = getWorkspaceId(getState());

    return api.assets.changeMembersInCategory({
      workspaceId,
      id,
      employees,
      isDelete
    });
  }
);

export const changeAssetAttachments = createAction('assets/change-attachments');
export const setDefaultState = createAction('assets/set-default-state');

// FILTERS
export const clearFilter = createAction('assets/clear-filter');

export const setFilterSearch = createAction('assets/set-filter-search');

export const setFilterResponsible = createAction(
  'assets/set-filter-responsible'
);

export const setFilterCreator = createAction('assets/set-filter-creator');

export const setFilterRange = createAction('assets/set-filter-range');

export const setFilterTag = createAction('assets/set-filter-tag');

export const setFilterRelations = createAction('assets/set-filter-relations');

export const setFilterCategories = createAction('assets/set-filter-category');

export const setFilterManagers = createAction('assets/set-filter-managers');

export const clearEntries = createAction('assets/clear-entries');

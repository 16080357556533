import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import EmployeeModal from 'components/common/modals/employee';

import {
  changeOrderStatusResponsible,
  fetchOrderStatus,
  fetchOrderStatusResponsible,
  removeOrderStatusFromEntries
} from 'store/order-statuses';

import useRoutesService from 'services/routes';

const ChangingResponsibleModal = ({
  visible,
  orderStatusId,
  defaultValue,
  onClose
}) => {
  const dispatch = useDispatch();

  const routes = useRoutesService();

  const { t } = useTranslation('OrderRoleAction');

  const onSubmit = useCallback(
    async employee => {
      try {
        await dispatch(
          changeOrderStatusResponsible({
            id: orderStatusId,
            responsibleId: employee.value
          })
        );
      } catch {
        return;
      }

      try {
        await dispatch(fetchOrderStatus({ id: orderStatusId }));
        await dispatch(
          fetchOrderStatusResponsible({ responsibleId: employee.value })
        );
      } catch ({ response }) {
        if (response.status === 403 || response.status === 404) {
          dispatch(removeOrderStatusFromEntries(orderStatusId));
          routes.toOrderStatuses({ isReplace: true });
        }
      }
    },
    [dispatch, orderStatusId, routes]
  );

  return (
    <EmployeeModal
      title={t('ChangeResponsibleHeading')}
      btnText={t('SubmitBtn')}
      description={
        <Alert type="warning" message={t('ChangeResponsibleWarning')} />
      }
      selectProps={{
        label: t('Responsible')
      }}
      defaultValue={defaultValue}
      visible={visible}
      onSubmit={onSubmit}
      onClose={onClose}
    />
  );
};

ChangingResponsibleModal.propTypes = {
  visible: PropTypes.bool.isRequired,
  onClose: PropTypes.func.isRequired,
  orderStatusId: PropTypes.string.isRequired,
  defaultValue: PropTypes.shape({
    id: PropTypes.number,
    firstName: PropTypes.string
  })
};

ChangingResponsibleModal.defaultProps = {
  defaultValue: null
};

export default ChangingResponsibleModal;

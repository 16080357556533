import moment from 'moment';

import {
  STATUS_IN_WORK,
  STATUS_FOR_EXECUTION,
  STATUS_CHECK,
  STATUS_DONE,
  STATUS_PENDING
} from 'constants/tasks';
import { COMPLETED, CANCELLED } from 'constants/index';

export const getIsOutdated = date => {
  const dateNow = new Date().toISOString();

  return Date.parse(date) < Date.parse(dateNow);
};

export const getIsTaskOutdated = task => {
  if (!task) {
    return false;
  }

  if (
    [
      STATUS_IN_WORK,
      STATUS_FOR_EXECUTION,
      STATUS_CHECK,
      STATUS_PENDING
    ].includes(task.status)
  ) {
    return getIsOutdated(task.dateEnd);
  }

  if (task.status === STATUS_DONE) {
    return moment(task.completedAt).isAfter(moment(task.dateEnd));
  }

  return false;
};

export const getIsOrderStatusOutdated = orderStatus => {
  if (
    !orderStatus ||
    orderStatus.state === COMPLETED ||
    orderStatus.state === CANCELLED
  ) {
    return false;
  }

  return getIsOutdated(orderStatus.deadlineAt);
};

import React, { forwardRef } from 'react';
import { Upload, Typography, message } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import { MESSAGE_DURATION } from 'constants/index.js';

import Attachments from 'components/common/attachments';
import Icon from 'components/common/icon';

import { useFileUpload } from 'hooks';
import { EXE_TYPE, MSI } from 'hooks/common/use-file-upload/types';
import getFileListSize from 'utils/get-file-list-size';
import { ERROR_NOTICE_TYPE, showNoticeMessage } from 'services/notice';

import styles from './upload-files.module.scss';

const { Dragger } = Upload;
const { Text } = Typography;

const checkExtentionFile = file => {
  const fileName = file.name.toLowerCase();

  return fileName
    .split('.')
    .pop()
    .toLowerCase();
};

export const UploadFiles = forwardRef(
  (
    {
      className,
      multiple,
      children,
      fileList,
      labels,
      onChange,
      isInline,
      attachmetsProps,
      showAttachments,
      fullWidth,
      maxSizeFile,
      maxSizeFileList,
      ...props
    },
    ref
  ) => {
    const { t } = useTranslation('Errors', 'Common');

    const { onUpload } = useFileUpload();

    const getLabels = () => ({
      addFiles: t('AddFiles', { ns: 'Common' }),
      fileTypes: 'PNG, JPEG, PDF, DOC',
      ...labels
    });

    const localLabels = getLabels();

    const onChangeFileList = ({ fileList: _fileList }) => {
      const files = multiple
        ? _fileList
        : (_fileList.length > 0 && [_fileList[_fileList.length - 1]]) || [];

      const filteredFiles = files.filter(
        item => item.type !== EXE_TYPE && checkExtentionFile(item) !== MSI
      );

      onChange(filteredFiles);
    };

    const onDelete = file => {
      const key = file.id ? 'id' : 'uid';

      const newFiles = fileList.filter(f => f[key] !== file[key]);

      onChange(newFiles);
    };

    const onRenameFile = ({ id, name }) => {
      const newFileList = fileList.reduce((acc, curr) => {
        if (curr.response && curr.response.id === id) {
          return [...acc, { ...curr, name }];
        }

        return [...acc, curr];
      }, []);

      onChange(newFileList);
    };

    const beforeUpload = (file, uploadedFileList) => {
      const uploadedFileListSize = getFileListSize(uploadedFileList);

      if (
        maxSizeFileList &&
        getFileListSize(fileList) + uploadedFileListSize > maxSizeFileList
      ) {
        return false;
      }

      if (maxSizeFile && file.size > maxSizeFile) {
        message.error(
          t('FileSizeMustNotExceed', { mb: maxSizeFile / 1024 / 1024 }),
          MESSAGE_DURATION
        );

        return false;
      }

      if (file.type === EXE_TYPE || checkExtentionFile(file) === MSI) {
        showNoticeMessage({
          customContent: t('MsiExeNotAllowed'),
          type: ERROR_NOTICE_TYPE
        });

        return false;
      }

      return true;
    };

    return (
      <>
        <Dragger
          ref={ref}
          multiple={multiple}
          showUploadList={false}
          name="file"
          className={classnames(className, styles.root, {
            [styles.inline]: isInline,
            [styles.fullWidth]: fullWidth
          })}
          onChange={onChangeFileList}
          customRequest={onUpload}
          beforeUpload={beforeUpload}
          // TODO: the onChange method stops executing and displaying download progress, but deleting files does not work correctly without this
          // fileList={fileList}
          {...props}
        >
          {children ? (
            <>{children}</>
          ) : (
            <div className={styles.content}>
              <Icon type="plus" size={16} className={styles.icon} />
              <Text className={styles.title} strong>
                {localLabels.addFiles}
              </Text>
              <Text className={styles.description}>
                ({localLabels.fileTypes})
              </Text>
            </div>
          )}
        </Dragger>

        {showAttachments && (
          <Attachments
            fileList={fileList}
            attachmentProps={{
              onDelete,
              onRename: onRenameFile,
              size: 'small'
            }}
            {...attachmetsProps}
          />
        )}
      </>
    );
  }
);

UploadFiles.propTypes = {
  className: PropTypes.string,
  multiple: PropTypes.bool,
  name: PropTypes.string,
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  labels: PropTypes.shape({
    addFiles: PropTypes.string,
    fileTypes: PropTypes.string,
    file: PropTypes.string,
    exceedsSize: PropTypes.string,
    descriptionWarn: PropTypes.string
  }),
  fileList: PropTypes.arrayOf(
    PropTypes.shape({
      contentType: PropTypes.string,
      url: PropTypes.string,
      size: PropTypes.number.isRequired
    })
  ),
  isInline: PropTypes.bool,
  fullWidth: PropTypes.bool,
  showAttachments: PropTypes.bool,
  onChange: PropTypes.func,
  attachmetsProps: PropTypes.shape({
    allowDelete: PropTypes.bool,
    size: PropTypes.string,
    className: PropTypes.string,
    fileClassName: PropTypes.string
  }),
  maxSizeFile: PropTypes.number
};

UploadFiles.defaultProps = {
  className: undefined,
  multiple: true,
  showAttachments: true,
  isInline: false,
  fullWidth: false,
  children: undefined,
  fileList: [],
  labels: {},
  props: {},
  onChange: () => {},
  attachmetsProps: {},
  maxSizeFile: undefined
};

export default UploadFiles;

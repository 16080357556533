import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
import { Spin } from 'antd';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import {
  ASSET_CREATOR_DRAWER,
  BENCH,
  CALENDAR,
  CONTACT_CREATOR_DRAWER,
  INCOMING,
  ORDER_CREATOR_DRAWER,
  OUTGOING,
  SETTINGS_STATUS_INTEGRATIONS,
  TYPE_MEETING
} from 'constants/index';

import Typography from 'components/common/typography';
import ActionsDropdown from 'components/common/actions';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';

import {
  fetchSocialCredentials as fetchSocialCredentialsAction,
  getSocialCredentialsGoogleCalendar
} from 'store/social-credentials';
import { setVisibleDrawer } from 'store/drawers';
import { getActiveWorkspace, getUserEmployee } from 'store/workspace';

import useRoutesService from 'services/routes';

import styles from './view-info.module.scss';

const Dashboard = ({ subView }) => {
  const dispatch = useDispatch();

  const workspace = useSelector(getActiveWorkspace);
  const employee = useSelector(getUserEmployee);
  const googleCalendarCredentail = useSelector(
    getSocialCredentialsGoogleCalendar
  );

  const [taskVisible, setTaskVisible] = useState(false);
  const [isLoadingCredentials, setIsLoadingCredentials] = useState(false);
  const [defaultTaskValues, setDefaultTaskValues] = useState({});

  const routes = useRoutesService();

  const { t } = useTranslation(['Dashboard', 'Common']);

  const googleSyncBtn = {
    show: !!subView.includes(CALENDAR),
    mood: googleCalendarCredentail ? 'negative' : 'positive',
    text: googleCalendarCredentail ? t('UnsyncBtn') : t('SyncCalendarBtn')
  };

  const isBench = !!employee.roles.find(({ type }) => type === BENCH);

  const fetchSocialCredentials = useCallback(async () => {
    try {
      setIsLoadingCredentials(true);

      await dispatch(fetchSocialCredentialsAction());
    } finally {
      setIsLoadingCredentials(false);
    }
  }, [dispatch]);

  const showOrderDrawer = (data = {}) =>
    dispatch(
      setVisibleDrawer({
        drawer: ORDER_CREATOR_DRAWER,
        data
      })
    );

  // eslint-disable-next-line arrow-body-style
  const onCreateOrder = data => {
    // if (!employee.isPhoneVerified && !workspace.isNotVerified) {
    //   return setVisiblePhoneConfirmation(true);
    // }

    return showOrderDrawer(data);
  };

  const showAssetCreator = useCallback(
    () =>
      dispatch(
        setVisibleDrawer({
          drawer: ASSET_CREATOR_DRAWER,
          data: {}
        })
      ),
    [dispatch]
  );

  const showContactCreator = useCallback(
    () =>
      dispatch(
        setVisibleDrawer({
          drawer: CONTACT_CREATOR_DRAWER,
          data: { isDetailsAfterSubmit: true }
        })
      ),
    [dispatch]
  );

  const actions = [
    {
      title: t('AddOrder'),
      allow: true,
      onClick: () => onCreateOrder({ defaultKind: OUTGOING })
    },
    {
      title: t('Purchase', { ns: 'Common' }),
      allow: true,
      onClick: () => onCreateOrder({ defaultKind: INCOMING })
    },
    {
      title: t('AddTask'),
      allow: true,
      onClick: () => {
        setDefaultTaskValues({});
        setTaskVisible(true);
      }
    },
    {
      title: t('Asset'),
      allow: workspace.permissions.createAsset,
      onClick: showAssetCreator
    },
    {
      title: t('Contact', { ns: 'Common' }),
      allow: true,
      onClick: showContactCreator
    },
    {
      title: t('AddMeeting'),
      allow: true,
      onClick: () => {
        setDefaultTaskValues({
          kind: TYPE_MEETING,
          isDisabledTypeSelect: true
        });
        setTaskVisible(true);
      }
    }
  ];

  useEffect(() => {
    if (!googleCalendarCredentail) {
      fetchSocialCredentials();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.dashboard}>
        <Typography.Title level={2} className={styles.title}>
          {t('DashboardHeading')}
        </Typography.Title>

        <ActionsDropdown actions={actions}>
          <Button type="primary" disabled={isBench} className={styles.button}>
            <Icon type="plus" />
            {t('AddBtn')}
          </Button>
        </ActionsDropdown>

        {googleSyncBtn.show && (
          <Spin spinning={isLoadingCredentials} size="small">
            <Button
              type="secondary"
              mood={googleSyncBtn.mood}
              className={styles.googleSyncBtn}
              onClick={() =>
                routes.toUserProfile({ view: SETTINGS_STATUS_INTEGRATIONS })
              }
            >
              {googleSyncBtn.text}
            </Button>
          </Spin>
        )}
      </div>

      <TaskCreatorDrawer
        visible={taskVisible}
        value={defaultTaskValues}
        onClose={() => setTaskVisible(false)}
      />
    </>
  );
};

Dashboard.propTypes = {
  subView: PropTypes.string
};

Dashboard.defaultProps = {
  subView: ''
};

export default Dashboard;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';

import {
  TYPE_REPORT,
  TYPE_ASSET,
  TYPE_CHANNEL,
  TYPE_CONTACT,
  TYPE_EMPLOYEE,
  TYPE_ORDER,
  TYPE_PROJECT,
  TYPE_TASK
} from 'constants/index';

import Icon from 'components/common/icon';

import styles from './entity-icon.module.scss';

const getEntityIconName = entityType => {
  switch (entityType) {
    case TYPE_TASK:
      return 'snippets';
    case TYPE_CONTACT:
      return 'phone-and-message';
    case TYPE_ASSET:
      return 'book';
    case TYPE_ORDER:
      return 'funnel-plot';
    case TYPE_PROJECT:
      return 'project';
    case TYPE_EMPLOYEE:
      return 'big-team';
    case TYPE_REPORT:
      return 'line-chart';
    case TYPE_CHANNEL:
      return 'channel';

    default:
      return '';
  }
};

const EntityIcon = ({ entityType, isMessageReminder }) => {
  if (!entityType) {
    return null;
  }

  const iconName = getEntityIconName(entityType);

  return (
    <>
      {iconName && (
        <div
          className={classnames(styles.root, {
            [styles.isMessageReminder]: isMessageReminder
          })}
        >
          <Icon type={iconName} size={18} color="white" />
        </div>
      )}
    </>
  );
};

EntityIcon.propTypes = {
  entityType: PropTypes.oneOfType([PropTypes.string, null]),
  isMessageReminder: PropTypes.bool
};

EntityIcon.defaultProps = {
  entityType: null,
  isMessageReminder: false
};

export default EntityIcon;

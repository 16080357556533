import React, { useState, useEffect, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Skeleton } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  REQUEST_KIND_APPROVING_CANCELING_ORDER,
  REQUEST_STATE_ACCEPTED
} from 'constants/index';

import Typography from 'components/common/typography';

import { fetchOrderStatusAllRequests } from 'store/order-statuses';

import styles from './cancellation-reason.module.scss';

const CancellationReason = ({ orderStatusId }) => {
  const dispatch = useDispatch();

  const [reason, setReason] = useState(null);

  const { t } = useTranslation('Order');

  const fetchRequests = useCallback(async () => {
    const { _embedded } = await dispatch(
      fetchOrderStatusAllRequests({ id: orderStatusId })
    );

    const { reason: cancellationReason } = _embedded.requests.find(
      r =>
        r.kind === REQUEST_KIND_APPROVING_CANCELING_ORDER &&
        r.state === REQUEST_STATE_ACCEPTED
    );
    setReason(cancellationReason);
  }, [dispatch, orderStatusId]);

  useEffect(() => {
    fetchRequests();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <div className={styles.root}>
      {reason ? (
        <Typography.Text size="small" weight="semibold">
          {`${t('CancelReasonBanner')} ${reason}`}
        </Typography.Text>
      ) : (
        <Skeleton
          active
          title={false}
          paragraph={{
            width: '100%',
            rows: 1,
            className: styles.skeletonParagraph
          }}
        />
      )}
    </div>
  );
};

CancellationReason.propTypes = {
  orderStatusId: PropTypes.string.isRequired
};

export default CancellationReason;

import React, { useState } from 'react';
import { Button, Form, Radio, Typography } from 'antd';
import { useDispatch, useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import isEmpty from 'lodash/isEmpty';
import omit from 'lodash/omit';
import { useTranslation } from 'react-i18next';

import { CONTRACTOR, IS_INDIVIDUAL } from 'constants/index';

import Modal from 'components/common/modal';

import { editContact } from 'store/contacts/actions';
import { createContactsCompanyLocal } from 'store/contacts-companies';
import { editOrderStatusContractorSync } from 'store/order-statuses';
import { getActiveWorkspace } from 'store/workspace';

import { showNoticeMessage } from 'services/notice';

import CompanySelect, {
  validator as companyValidator
} from '../../company-select';

import styles from './editor-form.module.scss';

const { Text } = Typography;

const EditorForm = Form.create()(
  ({
    form,
    orderStatusId,
    onCallback,
    contactId,
    responsible,
    isIndividualContact
  }) => {
    const dispatch = useDispatch();

    const workspace = useSelector(getActiveWorkspace);

    const [isLoading, setIsLoading] = useState(false);

    const { t } = useTranslation('ChangeContractor');

    const { getFieldDecorator, getFieldValue, getFieldError } = form;

    const createContact = contact =>
      dispatch(createContactsCompanyLocal({ contact }));

    const updateContact = async (id, values) => {
      const contact = await dispatch(editContact({ id, values }));

      if (orderStatusId) {
        dispatch(
          editOrderStatusContractorSync({
            id: orderStatusId,
            values: {
              company: contact.company
            }
          })
        );
      }
    };

    const onSave = event => {
      event.preventDefault();

      form.validateFields(async (err, value) => {
        if (!err) {
          setIsLoading(true);

          try {
            const { company = {}, country } = value[CONTRACTOR] || {};

            let contactCompany;

            if (company.isNew || value[IS_INDIVIDUAL]) {
              const companyData = value[IS_INDIVIDUAL]
                ? {}
                : {
                    country: country.value,
                    ...company
                  };

              contactCompany = await createContact({
                responsible,
                isIndividual: value[IS_INDIVIDUAL],
                ...companyData
              });
            }

            await updateContact(contactId, {
              company:
                company.isNew || value[IS_INDIVIDUAL]
                  ? contactCompany.id
                  : company.id
            });

            showNoticeMessage();

            onCallback();
          } finally {
            setIsLoading(false);
          }
        }
      });
    };

    const submitDisabled =
      !getFieldValue(IS_INDIVIDUAL) &&
      (!isEmpty(getFieldError(CONTRACTOR)) ||
        isEmpty(omit(getFieldValue(CONTRACTOR), ['country'])));

    return (
      <Form
        colon={false}
        data-qa="qa-ofbe6vzkfvd62a3"
        hideRequiredMark
        onSubmit={onSave}
        style={{ display: 'flex', flexDirection: 'column' }}
      >
        <Form.Item>
          {getFieldDecorator(IS_INDIVIDUAL, {
            initialValue: isIndividualContact
          })(
            <Radio.Group>
              <Radio value={false}>{t('CompanyRepresentativeRadio')}</Radio>
              <Radio value>{t('IndividualRadio')}</Radio>
            </Radio.Group>
          )}
        </Form.Item>

        {!getFieldValue(IS_INDIVIDUAL) && (
          <Form.Item>
            {getFieldDecorator(CONTRACTOR, {
              initialValue: {
                country: {
                  value: workspace.country.id,
                  label: workspace.country
                }
              },
              rules: [{ validator: companyValidator }]
            })(<CompanySelect autoComplete="off" />)}
          </Form.Item>
        )}

        <Button
          type="primary"
          htmlType="submit"
          loading={isLoading}
          style={{ marginLeft: 'auto', paddingLeft: 24, paddingRight: 24 }}
          data-qa="qa-4rnncrwcoi1v642"
          disabled={submitDisabled}
        >
          {t('ChangeBtn')}
        </Button>
      </Form>
    );
  }
);

EditorForm.propTypes = {
  onCallback: PropTypes.func.isRequired,
  country: PropTypes.shape({
    id: PropTypes.number
  }),
  dialog: PropTypes.shape({
    id: PropTypes.number,
    contact: PropTypes.object
  }),
  isIndividualContact: PropTypes.bool
};

EditorForm.defaultProps = {
  country: undefined,
  dialog: undefined,
  isIndividualContact: false
};

export const EditorContractorModal = ({ onClose, ...props }) => {
  const { t } = useTranslation('ChangeContractor');

  return (
    <Modal
      width={610}
      title={
        <Text className={styles.title}>{t('ChangeContractorHeading')}</Text>
      }
      onClose={onClose}
      destroyOnClose
      contentStyle={{ padding: 24 }}
      {...props}
    >
      <EditorForm onCallback={onClose} {...props} />
    </Modal>
  );
};

export default EditorContractorModal;

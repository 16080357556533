import React from 'react';
import { useSelector } from 'react-redux';

import {
  ALL,
  ASSETS,
  ATTACHMENTS,
  CONTACTS,
  CRM,
  ORDERS,
  ORGSTRUCTURE,
  PROJECTS,
  REPORTS,
  TASKS,
  TEAM,
  DASHBOARD,
  TEMPLATES,
  SETTINGS,
  BILLING
} from 'constants/index';

import {
  getRouterUrlModal,
  getRouterUrlSubmenu,
  getRouterUrlView
} from 'store/router';
import { getActiveWorkspace } from 'store/workspace';
import { getIsOnlyUserProfile } from 'store/user';

import Orders from './orders';
import Contacts from './contacts';
import Tasks from './tasks';
import Projects from './projects';
import Team from './team';
import Attachments from './attachments';
import Reports from './reports';
import Handbook from './handbook';
import Dashboard from './dashboard';
import UserProfile from './user-profile';
import BillingSettings from './billing-settings';

export const ViewInfo = () => {
  const workspace = useSelector(getActiveWorkspace);
  const view = useSelector(getRouterUrlView);
  const subView = useSelector(getRouterUrlSubmenu);
  const modal = useSelector(getRouterUrlModal);

  const isOnlyUserProfile = useSelector(getIsOnlyUserProfile);

  if (isOnlyUserProfile) {
    return <UserProfile />;
  }

  if ((modal || '').includes('detail') || !workspace) {
    return null;
  }

  if (view === CRM && subView === ORDERS) {
    return <Orders />;
  }

  if (view === CONTACTS) {
    return <Contacts />;
  }

  if (view === TASKS && (subView === ALL || subView.includes(`${PROJECTS}/`))) {
    return <Tasks />;
  }

  if (view === TASKS && subView === PROJECTS) {
    return <Projects />;
  }

  if (view === TEAM && subView === ORGSTRUCTURE) {
    return <Team />;
  }

  if (view === ATTACHMENTS && subView === ALL) {
    return <Attachments />;
  }

  if (view === REPORTS && (subView === ALL || subView === TEMPLATES)) {
    return <Reports />;
  }

  if (view === ASSETS) {
    return <Handbook subView={subView} />;
  }

  if (view === DASHBOARD) {
    return <Dashboard subView={subView} />;
  }

  if (view === SETTINGS && subView.includes(`${BILLING}/${SETTINGS}`)) {
    return <BillingSettings />;
  }

  return null;
};

export default ViewInfo;

import { createSelector } from 'reselect';
import memoize from 'lodash.memoize';

import {
  OWNER,
  ADMIN,
  MANAGER,
  EMPLOYEE,
  CONTROLLER,
  BENCH
} from '../../../constants';
import { getUserEmployee } from '../../workspace';

export const getEmployees = state => state.team.employees;

export const getEmployeesEntries = createSelector(
  getEmployees,
  employees => employees.entries
);

export const getIsEmployeesLoaded = createSelector(
  getEmployees,
  employees => employees.isLoaded
);

export const getIsEmployeesLoading = createSelector(
  getEmployees,
  employees => employees.isLoading
);

export const getCurrentPage = createSelector(
  getEmployees,
  employees => employees.currentPage
);

export const getTotalItems = createSelector(
  getEmployees,
  employees => employees.totalItems
);

export const getItemsPerPage = createSelector(
  getEmployees,
  employees => employees.itemsPerPage
);

export const getTotalPages = createSelector(
  [getTotalItems, getItemsPerPage],
  (totalItems, itemsPerPage) => Math.ceil(totalItems / itemsPerPage)
);

export const getFilterEmployees = createSelector(
  getEmployees,
  employees => employees.filter
);

export const getFilterSearchEmployees = createSelector(
  getFilterEmployees,
  filter => filter.search
);

export const getFilterCategoriesEmployees = createSelector(
  getFilterEmployees,
  filter => filter.categories
);

export const getFilterRoleEmployees = createSelector(
  getFilterEmployees,
  filter => filter.role
);

export const getEmployeeFactory = createSelector(
  getEmployeesEntries,
  entries => memoize(id => entries.find(employee => employee.id === id))
);

const isThatRole = (entries, id) => role => {
  const employee = entries.find(item => item.id === id);

  if (employee) {
    return employee.roles.some(item => item.type === role);
  }

  return false;
};

export const getIsEmployeeOwner = createSelector(
  getEmployeesEntries,
  entries => memoize(id => isThatRole(entries, id)(OWNER))
);

export const getIsEmployeeAdmin = createSelector(
  getEmployeesEntries,
  entries => memoize(id => isThatRole(entries, id)(ADMIN))
);

export const getIsEmployeeController = createSelector(
  getEmployeesEntries,
  entries => memoize(id => isThatRole(entries, id)(CONTROLLER))
);

export const getIsEmployeeManager = createSelector(
  getEmployeesEntries,
  entries => memoize(id => isThatRole(entries, id)(MANAGER))
);

export const getIsEmployeeEmployee = createSelector(
  getEmployeesEntries,
  entries => memoize(id => isThatRole(entries, id)(EMPLOYEE))
);

export const getIsEmployeeBench = createSelector(
  getEmployeesEntries,
  entries => memoize(id => isThatRole(entries, id)(BENCH))
);

export const getOrdersEmployee = createSelector(
  getEmployeesEntries,
  entries =>
    memoize(id => {
      const employee = entries.find(item => item.id === id);

      if (employee) {
        return employee.orders;
      }

      return null;
    })
);

export const getEmployee = createSelector(
  getEmployeesEntries,
  entries =>
    memoize(id => {
      const employee = entries.find(item => item.id === id);

      return employee;
    })
);

export const getIsItMe = createSelector(
  [getEmployeesEntries, getUserEmployee],
  (entries, userEmployee) => {
    const employee = entries.find(item => item.userId === userEmployee.userId);

    return !!employee;
  }
);

import React from 'react';
import classnames from 'classnames';
import PropTypes from 'prop-types';
import moment from 'moment';
import isEmpty from 'lodash/isEmpty';
import { Radio, Tabs, Tooltip, Badge } from 'antd';
import { useTranslation } from 'react-i18next';

import { TIME_FORMAT } from 'constants/index';

import Typography from 'components/common/typography';
import Message from 'components/contacts-view/views/chats/contact-chat/message';
import Button from 'components/common/button';
import { SkeletonButton } from 'components/common/skeletons';
import Icon from 'components/common/icon';
import EntityIcon from 'components/dashboard-view/views/notifications/entity-icon';
import NotificationMessageChat from 'components/dashboard-view/views/notifications/notification-message-chat';
import {
  convertMessageToNode,
  hasContent
} from 'components/common/comments/converters';

import useNotificationMessage from './use-notification-message';
import useEntityLink from './use-entity-link';

import styles from './message.module.scss';

const NotificationMessage = ({ message, rootClassName, view }) => {
  const {
    entityFromNotification,
    controls,
    content,
    entity,
    checkAllowReaction,
    onClick,
    onRead,
    sourceRoomUuid,
    notificationChatDisplay,
    handleNotificationChatDisplay
  } = useNotificationMessage({ message, view });

  const { entityLink, openInNewTab } = useEntityLink({
    entityType: entityFromNotification.destinationEntityType,
    entityId: entityFromNotification.destinationEntityId
  });

  const { t } = useTranslation(['Dashboard', 'Task', 'Common']);

  const {
    createdAt,
    source,
    isRead,
    sourceChatUnreadMessagesCount,
    notificationData
  } = message;

  const {
    isMessageReminder,
    notificationComment,
    sourceMessage = {}
  } = notificationData;

  return (
    <div className={classnames(styles.root, rootClassName)}>
      <div>
        <Tooltip title={t(isRead ? 'MarkUnreadTip' : 'MarkReadTip')}>
          <Radio
            checked={!isRead}
            className={styles.radio}
            onClick={() => onRead()}
          />
        </Tooltip>
      </div>

      <div
        className={classnames(styles.message, { [styles.isRead]: isRead })}
        onClick={onClick}
      >
        <div className={styles.header}>
          <EntityIcon
            entityType={entityFromNotification.destinationEntityType}
            isMessageReminder={isMessageReminder}
          />

          {entityFromNotification.title && (
            <Typography.Paragraph
              color="brand-30"
              ellipsis={{ rows: 2 }}
              style={{ marginBottom: 0 }}
            >
              <Tooltip
                mouseEnterDelay={0.5}
                title={entityFromNotification.title}
              >
                {entityFromNotification.title}
              </Tooltip>
            </Typography.Paragraph>
          )}

          <div className={styles.headerRightSide}>
            {entityLink && (
              <Tooltip
                title={t('OpenInANewTab', { ns: 'Common' })}
                mouseEnterDelay={0.5}
              >
                <Button
                  type="text"
                  mood="none"
                  onClick={event => {
                    openInNewTab(entityLink);
                    event.stopPropagation();
                  }}
                  className={styles.openNewTabBtn}
                >
                  <Icon type="open-in-new-widnow" size={20} />
                </Button>
              </Tooltip>
            )}

            <Typography.Text color="black-55">
              {moment(createdAt).format(TIME_FORMAT)}
            </Typography.Text>

            {!isRead &&
              typeof sourceChatUnreadMessagesCount === 'number' &&
              sourceChatUnreadMessagesCount > 1 && (
                <div className={styles.badgeWrap}>
                  <Badge
                    count={sourceChatUnreadMessagesCount}
                    className={styles.badge}
                  />
                </div>
              )}
          </div>
        </div>

        {content && !isMessageReminder && (
          <Typography.Text>{content}</Typography.Text>
        )}

        {isMessageReminder && (
          <div className={styles.reminderContent}>
            {hasContent(sourceMessage.text) ? (
              <Typography.Text>
                {t('RemindAboutMessage', { ns: 'Dashboard' })}:
                {convertMessageToNode(sourceMessage.text)}
              </Typography.Text>
            ) : (
              <Typography.Text>
                {t('RemindAboutMessage', { ns: 'Dashboard' })}.
              </Typography.Text>
            )}

            {notificationComment && (
              <Typography.Text color="black-45">
                {t('Comment', { ns: 'Common' })}: {notificationComment}
              </Typography.Text>
            )}
          </div>
        )}

        {source && (
          <div className={styles.sourceWrap} onClick={e => e.stopPropagation()}>
            {!notificationChatDisplay[sourceRoomUuid] && (
              <Message
                entity={entityFromNotification}
                entityType={entityFromNotification.type}
                destination={{
                  entityType: entityFromNotification.destinationEntityType,
                  entityId: entityFromNotification.id,
                  entity: {
                    title: entityFromNotification.entityTitle
                  }
                }}
                messages={{
                  prev: message,
                  curr: source
                }}
                rootClassName={classnames({
                  [styles.sourceWithControls]:
                    notificationChatDisplay[sourceRoomUuid]
                })}
                onClickAnswer={() => handleNotificationChatDisplay(true)}
                onClickReply={values => {
                  handleNotificationChatDisplay(true);

                  controls.onClickReply(values);
                }}
                scrollToMessage={() => {}}
                checkAllowReaction={checkAllowReaction}
              />
            )}

            {notificationChatDisplay[sourceRoomUuid] && (
              <>
                {isEmpty(entity) ? (
                  <SkeletonButton className={styles.skeletonControls} />
                ) : (
                  <>
                    <Tabs
                      activeKey="messages"
                      tabBarExtraContent={
                        <Button
                          type="text"
                          onClick={() => handleNotificationChatDisplay(false)}
                        >
                          <Icon type="close" size={16} color="black-55" />
                        </Button>
                      }
                      className={styles.tabs}
                    >
                      <Tabs.TabPane
                        key="messages"
                        tab={
                          <>
                            <Icon type="comment" size={16} color="black-55" />

                            <span> {t('TaskMessagesTab', { ns: 'Task' })}</span>
                          </>
                        }
                        data-qa="qa-c5l2lti1h1c0h44"
                      />
                    </Tabs>

                    <NotificationMessageChat
                      entity={entity}
                      entityFromNotification={entityFromNotification}
                      message={message}
                      onRead={onRead}
                    />
                  </>
                )}
              </>
            )}
          </div>
        )}
      </div>
    </div>
  );
};

NotificationMessage.propTypes = {
  rootClassName: PropTypes.string,
  message: PropTypes.shape({
    sender: PropTypes.object,
    createdAt: PropTypes.string,
    text: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
    source: PropTypes.shape({}),
    notificationData: PropTypes.object
  }).isRequired,
  view: PropTypes.arrayOf(PropTypes.string).isRequired
};

NotificationMessage.defaultProps = {
  rootClassName: undefined
};

export default NotificationMessage;

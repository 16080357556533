import React, { useState } from 'react';
import PropTypes from 'prop-types';

import Seporator from './seporator';

import styles from './split-pane.module.scss';

const getWith = (min, max, width) => Math.min(max, Math.max(min, width));

export const SplitPane = ({
  children,
  minWidth,
  maxWidth,
  withNavigatorResize,
  defaultWidth,
  styleFirstPane,
  styleSecondPane,
  visisbleSeporator,
  onResizeEnd
}) => {
  const [width, setWidth] = useState(defaultWidth || 320);

  const handleResize = diff => {
    const newWidth = getWith(minWidth, maxWidth, width + diff);
    setWidth(newWidth);
  };

  const handleResizeEnd = diff => {
    const newWidth = getWith(minWidth, maxWidth, width + diff);
    onResizeEnd(newWidth);
  };

  return (
    <div className={styles.root}>
      <div
        className={styles.pane}
        style={{
          maxWidth,
          minWidth,
          width,
          flexShrink: 0,
          overflow: 'auto',
          ...styleFirstPane
        }}
      >
        {children[0]}
      </div>

      {visisbleSeporator && (
        <Seporator
          onResize={handleResize}
          onResizeEnd={handleResizeEnd}
          withNavigatorResize={withNavigatorResize}
        />
      )}

      <div
        className={styles.pane}
        style={styleSecondPane}
      >
        {children[1]}
      </div>
    </div>
  );
};

SplitPane.propTypes = {
  children: PropTypes.arrayOf(PropTypes.node).isRequired,
  minWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  maxWidth: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  defaultWidth: PropTypes.number,
  withNavigatorResize: PropTypes.bool,
  styleFirstPane: PropTypes.object,
  styleSecondPane: PropTypes.object,
  visisbleSeporator: PropTypes.bool,
  onResizeEnd: PropTypes.func
};

SplitPane.defaultProps = {
  minWidth: 200,
  maxWidth: 400,
  defaultWidth: undefined,
  withNavigatorResize: false,
  styleFirstPane: {},
  styleSecondPane: {},
  visisbleSeporator: true,
  onResizeEnd: () => {}
};

export default SplitPane;

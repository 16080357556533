import React, { useState } from 'react';
import PropTypes from 'prop-types';
// import { useSelector } from 'react-redux';
import classnames from 'classnames';

// import WorkspaceVerifyModal from 'components/common/workspace-verify-modal';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';

// import { getActiveWorkspace } from 'store/workspace';

import SelectInviteModal from './select-invite-modal';
import getIsDemoModeActive from '../../../utils/get-is-demo-mode-active';

import styles from './invite-to-register.module.scss';

export const InviteToRegister = ({ contact, style, className }) => {
  const [visibleInviteModal, setVisibleInviteModal] = useState(false);
  // const [visbleVerifyModal, setVisibleVerifyModal] = useState(false);

  const { t } = useTranslation('Contacts');

  // const workspace = useSelector(getActiveWorkspace);

  // eslint-disable-next-line arrow-body-style
  const handleClick = () => {
    // if (workspace.isNotVerified) {
    //   return setVisibleVerifyModal(true);
    // }

    return setVisibleInviteModal(true);
  };

  if (getIsDemoModeActive()) {
    return null;
  }

  return (
    <>
      <Button
        type="link"
        style={style}
        className={classnames(styles.root, className)}
        data-qa="qa-4v0z7sru9xqdskq"
        onClick={handleClick}
      >
        {t('InviteForRegistrationBtn')}
      </Button>

      {/* <WorkspaceVerifyModal
        description="Отправлять приглашения могут только компании, 
          которые прошли проверку. Чтобы сделать это - введите название компании и ее страну. Мы проверим ваш налоговый статус."
        visible={visbleVerifyModal}
        callback={() => setVisibleInviteModal(true)}
        onClose={() => setVisibleVerifyModal(false)}
        className={styles.verifyModal}
      /> */}

      <SelectInviteModal
        visible={visibleInviteModal}
        contact={contact}
        onClose={() => setVisibleInviteModal(false)}
      />
    </>
  );
};

InviteToRegister.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  style: PropTypes.object,
  className: PropTypes.string
};

InviteToRegister.defaultProps = {
  contact: {},
  style: {},
  className: undefined
};

export default InviteToRegister;

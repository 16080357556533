import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Avatar from 'components/common/avatar';
import Icon from 'components/common/icon';
import { UnknownPersonIcon } from 'components/common/icons';
import Typography from 'components/common/typography';

import getFIO, { getFullName } from 'utils/get-fio';

import styles from '../merging-contacts.module.scss';

const SHOWED_FIELDS = [
  {
    name: 'phone',
    key: 'PhoneNumber',
    isMulti: true
  },
  {
    name: 'email',
    key: 'Email',
    isMulti: true
  },
  {
    name: 'birthday',
    key: 'DateOfBirth',
    isMulti: false
  },
  {
    name: 'address',
    key: 'Address',
    isMulti: false
  }
];

const renderMultField = (contact, fieldName) => {
  const fields = Object.entries(contact).filter(
    ([key, value]) => key.includes(fieldName) && value
  );

  if (!fields.length) {
    return <Typography.Text>-</Typography.Text>;
  }

  return fields.map(([, value]) => <Typography.Text>{value}</Typography.Text>);
};

const Card = ({ contact, isDisabled, isSelected, select }) => {
  const { t } = useTranslation('СombineContacts');

  const onSelect = useCallback(
    value => {
      if (isDisabled) {
        return;
      }

      select(value);
    },
    [isDisabled, select]
  );

  return (
    <div
      className={classnames(styles.card, {
        [styles.selected]: isSelected,
        [styles.disabled]: isDisabled
      })}
      onClick={() => onSelect(contact)}
    >
      <div className={styles.header}>
        {contact.employee ? (
          <Avatar size={36} src={(contact.avatarFile || {}).url}>
            {getFIO(contact)}
          </Avatar>
        ) : (
          <Icon component={UnknownPersonIcon} size={36} color="white" />
        )}

        <Tooltip
          mouseEnterDelay={0.5}
          title={
            <>
              {getFullName(contact)}
              <br />
              {contact.company.isIndividual ? '-' : contact.company.title}
            </>
          }
        >
          <div className={styles.nameAndCompany}>
            <Typography.Text weight="semibold" ellipsis>
              {getFullName(contact)}
            </Typography.Text>

            <Typography.Text weight="semibold" ellipsis>
              {contact.company.isIndividual ? '-' : contact.company.title}
            </Typography.Text>
          </div>
        </Tooltip>

        {!isDisabled && (
          <div
            className={classnames(styles.radio, {
              [styles.selected]: isSelected
            })}
          />
        )}
      </div>

      {contact.employee && (
        <Typography.Paragraph
          className={styles.registerCheap}
          size="small"
          color="green-dark"
        >
          {t('RegisteredInUpservice')}
        </Typography.Paragraph>
      )}

      <div className={styles.info}>
        {SHOWED_FIELDS.map(field => (
          <div className={styles.field}>
            <Typography.Text color="black-45">{t(field.key)}</Typography.Text>
            {field.isMulti ? (
              renderMultField(contact, field.name)
            ) : (
              <Typography.Text>{contact[field.name] || '-'}</Typography.Text>
            )}
          </div>
        ))}
      </div>
    </div>
  );
};

// TODO
Card.propTypes = {
  contact: PropTypes.shape({}).isRequired,
  isSelected: PropTypes.bool.isRequired,
  isDisabled: PropTypes.bool.isRequired,
  select: PropTypes.func.isRequired
};

export default Card;

import {
  TYPE_ORDER,
  TYPE_TASK,
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_CHANNEL_CHAT,
  TYPE_ATTACHMENT,
  TYPE_PROJECT,
  TYPE_TASK_TEMPLATE,
  TYPE_ORDER_TEMPLATE,
  TYPE_ORDER_STATUS
} from 'constants/index';

import handleActions from 'utils/redux-actions';

import { addTag, deleteTag, fetchTagsByEntity } from './actions';

const initialState = {
  [TYPE_ORDER]: {},
  [TYPE_ORDER_STATUS]: {},
  [TYPE_TASK]: {},
  [TYPE_ASSET]: {},
  [TYPE_CONTACT]: {},
  [TYPE_CHANNEL_CHAT]: {},
  [TYPE_ATTACHMENT]: {},
  [TYPE_PROJECT]: {},
  [TYPE_TASK_TEMPLATE]: {},
  [TYPE_ORDER_TEMPLATE]: {}
};

export default handleActions(
  {
    [fetchTagsByEntity.SUCCEEDED]: (state, { args, payload }) => {
      const transfromedPayload = {};

      Object.keys(payload).forEach(entityId => {
        transfromedPayload[entityId] = (payload[entityId] || []).map(tag => ({
          ...tag,
          entityType: args.entityType,
          entityId
        }));
      });

      state[args.entityType] = {
        ...state[args.entityType],
        ...transfromedPayload
      };

      return state;
    },

    [addTag.SUCCEEDED]: (state, { args }) => {
      state[args.tag.entityType][args.tag.entityId] = [
        ...(state[args.tag.entityType][args.tag.entityId] || []),
        args.tag
      ];

      return state;
    },

    [deleteTag.SUCCEEDED]: (state, { args }) => {
      state[args.tag.entityType][args.tag.entityId] = state[
        args.tag.entityType
      ][args.tag.entityId].filter(tag => tag.id !== args.tag.id);

      return state;
    }
  },
  initialState
);

import { useDispatch, useSelector } from 'react-redux';
import React, { useCallback, useEffect, useState } from 'react';
import { Tooltip } from 'antd';
import { Trans, useTranslation } from 'react-i18next';

import {
  ACCEPTED_STATE_AGREEMENT,
  COMPLETED,
  IN_PROGRESS,
  LEADS,
  ORDER_STATUS_RESPONSIBLE,
  REVIEW,
  REQUEST_KIND_APPROVING_SPECIFICATION,
  REQUEST_KIND_APPROVING_CANCELING_ORDER,
  REQUEST_KIND_APPROVING_ACT,
  REQUEST_KIND_APPROVING_CHANGING_SPECIFICATION,
  REQUEST_KIND_APPROVING_SCHEDULING_SPECIFICATION,
  REQUEST_KIND_APPROVING_SCHEDULING_ACT,
  REQUEST_KIND_APPROVING_CHANGING_SCHEDULING_SPECIFICATION,
  AGREEMENT,
  SPEC_KIND_SYSTEM
} from 'constants/index';

import Button from 'components/common/button';
import useChangeState from 'components/orders-view/hooks/use-change-state';
import Typography from 'components/common/typography';

import { getUserEmployee } from 'store/workspace';
import {
  fetchOrderStatusAct,
  fetchOrderStatusAttachments,
  fetchOrderStatusRequest,
  fetchOrderStatusSpecification
} from 'store/order-statuses';

import { getFullName } from 'utils/get-fio';

import SchedulerTooltip from '../scheduler-tooltip';

const COMMON_BUTTON_PROPS = {
  type: 'secondary',
  size: 'large'
};

const getContractorCompanyTitle = contractor => {
  if (contractor.company.isIndividual) {
    return getFullName(contractor);
  }

  return contractor.company.title;
};

const useActions = ({ orderStatus }) => {
  const dispatch = useDispatch();

  const {
    isLoading,
    modalData,
    setIsLoading,
    acceptOrderStatus,
    changeOrderStatus,
    cancelOrderStatus,
    completeOrderStatus,
    acceptRequest,
    rejectRequest,
    cancelRequest,
    reopenOrderStatus,
    reopenAct,
    changeActSystem
  } = useChangeState();

  const [accepted, setAccepted] = useState({
    title: null,
    condition: null, // spec's condition
    documentList: null, // file ids
    acceptedDocuments: null // files to approve
  });
  const [openPopoverData, setOpenPopoverData] = useState({
    visible: false,
    data: null
  });

  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation('Order');

  const {
    responsibleId,
    contractor,
    signatoryId,
    state,
    request,
    id,
    contract,
    isLocked, // when orderStatus has online status and contractor from other side has request
    lockedKind // what request is the opposite side in the process
  } = orderStatus;

  const isResponsible = responsibleId === employee.id;
  const isSignatory = signatoryId === employee.id;

  const isLeads = state === LEADS;
  const isAgreement = state === AGREEMENT;
  const isInProgress = state === IN_PROGRESS;
  const isReview = state === REVIEW;
  const isCompleted = state === COMPLETED;

  const canCancelRequest =
    !!request &&
    isResponsible &&
    ((isLocked && isInProgress) || // for the side which initialize cancelling order from state IN_PROGRESS
      (!isLocked && request.canCancelRequest));

  const agreementAct =
    orderStatus &&
    orderStatus.acts &&
    orderStatus.acts.find(act => act.state === AGREEMENT);

  // show if order status is in progress or in review for SYSTEM specification
  const canShowChangeActButton =
    orderStatus &&
    orderStatus.acts &&
    orderStatus.specification &&
    canCancelRequest &&
    isResponsible &&
    agreementAct &&
    agreementAct.kind === SPEC_KIND_SYSTEM &&
    ((isInProgress && orderStatus.specification.scheduler) ||
      (isReview && !orderStatus.specification.scheduler));

  const checkCanAcceptOrRejectRequest = useCallback(
    orderStatusRequest => {
      if (!orderStatusRequest) {
        return false;
      }

      if (orderStatusRequest.executor === ORDER_STATUS_RESPONSIBLE) {
        return isResponsible;
      }

      return isSignatory;
    },
    [isResponsible, isSignatory]
  );

  const allActions = [
    {
      label: 'SendToSignBtn',
      ns: 'Order',
      buttonProps: COMMON_BUTTON_PROPS,
      allow: isLeads && isResponsible && !request,
      onClick: () =>
        acceptOrderStatus({ orderStatusId: id, contractId: contract })
    },
    {
      label: isReview ? 'AcceptOrderBtn' : 'ApproveBtn',
      ns: 'Order',
      buttonProps: COMMON_BUTTON_PROPS,
      allow: checkCanAcceptOrRejectRequest(request),
      onClick: () =>
        acceptRequest({
          orderStatusId: id,
          state,
          request
        })
    },
    {
      label: 'ChangeActBtn',
      ns: 'Order',
      buttonProps: COMMON_BUTTON_PROPS,
      allow: canShowChangeActButton,
      onClick: () =>
        changeActSystem({
          orderStatusId: id,
          contractId: contract,
          actId: orderStatus.acts[orderStatus.acts.length - 1].id,
          orderStatus
        })
    },
    {
      label: isReview ? 'BackOrderToWorkBtn' : 'RejectBtn',
      ns: 'Order',
      buttonProps: {
        ...COMMON_BUTTON_PROPS,
        mood: 'negative'
      },
      allow: checkCanAcceptOrRejectRequest(request),
      onClick: rejectRequest
    },
    {
      label: 'ChangeOrderConditionsBtn',
      ns: 'Order',
      buttonProps: COMMON_BUTTON_PROPS,
      allow: isInProgress && isResponsible && !request,
      onClick: () =>
        changeOrderStatus({
          orderStatusId: id,
          contractId: contract,
          orderStatus
        })
    },
    {
      label: 'OrderDoneBtn',
      ns: 'Order',
      buttonProps: COMMON_BUTTON_PROPS,
      allow: isInProgress && isResponsible && !request,
      onClick: () =>
        completeOrderStatus({ orderStatusId: id, contractId: contract })
    },
    {
      label: 'CancelOrderBtn',
      ns: 'Order',
      buttonProps: { ...COMMON_BUTTON_PROPS, mood: 'negative' },
      allow: (isLeads || isInProgress) && isResponsible && !request,
      onClick: cancelOrderStatus
    },
    {
      label: 'BackOrderToWorkBtn',
      ns: 'Order',
      buttonProps: {
        ...COMMON_BUTTON_PROPS,
        mood: 'negative'
      },
      allow: isCompleted && isResponsible,
      onClick: () => reopenOrderStatus({ orderStatusId: id })
    },
    {
      label: isInProgress ? 'RejectActBtn' : 'CancelRequestBtn',
      ns: 'Order',
      buttonProps: { ...COMMON_BUTTON_PROPS, mood: 'negative' },
      allow: canCancelRequest && isResponsible && !isSignatory,
      onClick: () =>
        cancelRequest({
          orderStatusId: id,
          state,
          contractId: contract,
          request
        })
    }
  ];

  const allowedActions = isLocked ? [] : allActions.filter(a => a.allow);

  const isEmpty =
    !allowedActions.length &&
    !accepted.title &&
    !isLoading &&
    !modalData.acceptanceContractModal.visible &&
    !modalData.changingContractModal.visible &&
    !modalData.requestRejectionModal.visible &&
    !modalData.cancellationModal.visible &&
    !modalData.completionModal.visible;

  const fetchSpecification = () =>
    dispatch(
      fetchOrderStatusSpecification({
        id,
        contractId: contract,
        specificationId: request.relationId
      })
    );

  const onVisiblePopover = () =>
    setOpenPopoverData({ visible: false, data: null });

  const onFetchSpecification = useCallback(async () => {
    try {
      setIsLoading(true);

      const specification = await fetchSpecification();

      const getTitle = () => {
        // ONLINE ORDER: if our side agreed on the request and it went to the other side
        if (isLocked) {
          return (
            <>
              {t('WaitingForContractorApproveDesc')}{' '}
              <Typography.Text weight="semibold">
                {contractor
                  ? getContractorCompanyTitle(contractor)
                  : t('ContractorDesc')}
              </Typography.Text>
            </>
          );
        }

        // ONLINE ORDER: if our side agrees the request on the status LEEDS or IN_PROGRESS
        if (!isLocked && (isLeads || isInProgress)) {
          return t('WaitingForResponsibleApproveDesc');
        }

        // ANY ORDER: if we wait approving from our signatory
        return t('NeedSignersApproveDesc');
      };

      setAccepted({
        title: getTitle(),
        condition: specification,
        documentList: specification.documentList
      });
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor, isInProgress, isLeads, isLocked, request, setIsLoading]);

  const onFetchPeriodicSpecification = useCallback(async () => {
    try {
      setIsLoading(true);

      const specification = await fetchSpecification();

      // ANY ORDER: if our side agreed on the request and it went to the other side
      const getTitle = () => {
        if (isLocked) {
          return (
            <>
              {t('WaitingForContractorApproveDesc')}{' '}
              <Typography.Text weight="semibold">
                {contractor
                  ? getContractorCompanyTitle(contractor)
                  : t('ContractorDesc')}
              </Typography.Text>
            </>
          );
        }

        // ANY ORDER: after sending the periodic specification for approval to the signer of the first party
        if (!isLocked && isAgreement && !isSignatory) {
          return (
            <>
              {t('NeedSignersApproveDescSchedule')}{' '}
              <Tooltip
                title={() => (
                  <SchedulerTooltip
                    specification={specification}
                    state={state}
                  />
                )}
                mouseEnterDelay={0.5}
              >
                <Button
                  type="link"
                  style={{ padding: 0, height: 'auto', fontWeight: 400 }}
                >
                  {t('ScheduleBtn')}
                </Button>
              </Tooltip>
            </>
          );
        }

        // ANY ORDER: output for the responsible second party, after sending by the signer of the first party
        if (!isLocked && isLeads) {
          return (
            <>
              {t('WaitingForResponsibleApproveDescSchedule')}{' '}
              <Tooltip
                title={() => (
                  <SchedulerTooltip
                    specification={specification}
                    state={state}
                  />
                )}
                mouseEnterDelay={0.5}
              >
                <Button
                  type="link"
                  style={{ padding: 0, height: 'auto', fontWeight: 400 }}
                >
                  {t('ScheduleBtn')}
                </Button>
              </Tooltip>
            </>
          );
        }

        // ANY ORDER: show the responsible of the second party if the signer of the first party agreed on the specification
        if (!isLocked && request.executor === ORDER_STATUS_RESPONSIBLE) {
          return (
            <>
              {t('WaitingForResponsibleApproveDescSchedule')}{' '}
              <Tooltip
                title={() => (
                  <SchedulerTooltip
                    specification={specification}
                    state={state}
                  />
                )}
                mouseEnterDelay={0.5}
              >
                <Button
                  type="link"
                  style={{ padding: 0, height: 'auto', fontWeight: 400 }}
                >
                  {t('ScheduleBtn')}
                </Button>
              </Tooltip>
            </>
          );
        }

        // ANY ORDER: display for the signer of the second party after the specification is agreed upon by the responsible of the second party
        return (
          <>
            {t('NeedYourSignersApproveDescSchedule')}{' '}
            <Tooltip
              title={() => (
                <SchedulerTooltip specification={specification} state={state} />
              )}
              mouseEnterDelay={0.5}
            >
              <Button
                type="link"
                style={{ padding: 0, height: 'auto', fontWeight: 400 }}
              >
                {t('ScheduleBtn')}
              </Button>
            </Tooltip>
          </>
        );
      };

      setAccepted({
        title: getTitle(),
        condition: specification,
        documentList: specification.documentList
      });
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [contractor, isInProgress, isLeads, isLocked, request, setIsLoading]);

  const onFetchAct = useCallback(async () => {
    try {
      setIsLoading(true);

      const act = await dispatch(
        fetchOrderStatusAct({
          id,
          contractId: contract,
          actId: request.relationId
        })
      );

      const getTitle = () => {
        // ONLINE ORDER: if our side agreed on the request and it went to the other side
        if (isLocked) {
          return (
            <Trans
              i18nKey="OrderDoneWaitingForContractorApproveDesc"
              ns="Order"
              values={{
                contractor: contractor
                  ? getContractorCompanyTitle(contractor)
                  : ''
              }}
              components={{
                bold: <Typography.Text weight="semibold" />
              }}
            />
          );
        }

        // ONLINE ORDER: if our side agrees the request on the status IN_POROGRESS
        if (!isLocked && isInProgress) {
          return t('OrderDoneWaitingForResponsibleApproveDesc');
        }

        // ANY ORDER: if the order status is under review and this is the person in charge
        if (
          !isLocked &&
          isReview &&
          isResponsible &&
          canCancelRequest &&
          !isSignatory
        ) {
          return t('OrderDoneActSendToSignerDesc');
        }

        // ANY ORDER: if we wait approving from our signatory
        return t('OrderDoneNeedSignersApproveDesc');
      };

      setAccepted({
        title: getTitle(),
        condition: null,
        documentList: act.documentList
      });
    } finally {
      setIsLoading(false);
    }
  }, [
    canCancelRequest,
    contract,
    contractor,
    dispatch,
    id,
    isInProgress,
    isLocked,
    isResponsible,
    isReview,
    isSignatory,
    request,
    setIsLoading,
    t
  ]);

  const onFetchPeriodicAct = useCallback(async () => {
    try {
      setIsLoading(true);

      const act = await dispatch(
        fetchOrderStatusAct({
          id,
          contractId: contract,
          actId: request.relationId
        })
      );

      const getTitle = () => {
        // ONLINE ORDER: if our side agreed on the request and it went to the other side
        if (isLocked) {
          return (
            <Trans
              i18nKey="OrderDoneWaitingForContractorApproveDesc"
              ns="Order"
              values={{
                contractor: contractor
                  ? getContractorCompanyTitle(contractor)
                  : ''
              }}
              components={{
                bold: <Typography.Text weight="semibold" />
              }}
            />
          );
        }

        // ANY ORDER: if the act is sent to the signer, by the responsible person who created the periodic specification
        if (
          !isLocked &&
          isInProgress &&
          isResponsible &&
          !isSignatory &&
          contractor &&
          canCancelRequest
        ) {
          return t('ActSentToSignerDescSchedule');
        }

        // ONLINE ORDER: if the act is approved by the responsible second party
        if (
          !isLocked &&
          isInProgress &&
          request.executor === ORDER_STATUS_RESPONSIBLE
        ) {
          return t('WaitingForResponsibleApproveDesc');
        }

        // ANY ORDER: if the act is being agreed with the second party by the signatory, responsible, or if the responsible === signatory
        if (
          !isLocked &&
          isInProgress &&
          (isSignatory ||
            (isResponsible && !canCancelRequest) ||
            (isResponsible && isSignatory && contractor))
        ) {
          return t('NeedYourSignerApproveDesc');
        }

        // ANY ORDER: if we wait approving from our signatory
        return t('OrderDoneNeedSignersApproveDesc');
      };

      setAccepted({
        title: getTitle(),
        condition: null,
        documentList: act.documentList
      });
    } finally {
      setIsLoading(false);
    }

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    contract,
    contractor,
    dispatch,
    id,
    isInProgress,
    isLocked,
    isResponsible,
    request,
    setIsLoading
  ]);

  const onFetchAcceptedDocuments = useCallback(
    async ({ fileIds, acceptedState }) => {
      const { results } = await dispatch(
        fetchOrderStatusAttachments({ params: { fileIds } })
      );

      setAccepted(prev => ({
        ...prev,
        acceptedDocuments: results.map(r => ({ ...r, acceptedState }))
      }));
    },
    [dispatch]
  );

  const onCancelRequest = useCallback(
    () => cancelRequest({ orderStatusId: id, request, state }),
    [cancelRequest, id, request, state]
  );

  const onReopenAct = useCallback(
    ({ actId }) =>
      reopenAct({
        id,
        contractId: contract,
        actId
      }),

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [reopenAct, id, contract]
  );

  const setLockedTitle = useCallback(
    (orderStatusState, orderStatusLockedKind) => {
      const getInProgressTitle = () => {
        if (
          orderStatusLockedKind ===
          REQUEST_KIND_APPROVING_CHANGING_SPECIFICATION
        ) {
          return (
            <Trans
              i18nKey="EditingOrderDesc"
              ns="Order"
              components={{
                bold: <Typography.Text weight="semibold" />
              }}
            />
          );
        }

        if (orderStatusLockedKind === REQUEST_KIND_APPROVING_SCHEDULING_ACT) {
          return (
            <Trans
              i18nKey="WaitingForApproveActDescSchedule"
              ns="Order"
              values={{
                contractor: contractor
                  ? getContractorCompanyTitle(contractor)
                  : ''
              }}
              components={{
                bold: <Typography.Text weight="semibold" />
              }}
            />
          );
        }

        if (
          orderStatusLockedKind ===
          REQUEST_KIND_APPROVING_SCHEDULING_SPECIFICATION
        ) {
          return <></>;
        }

        return t('ContractorPreparingDocsDesc');
      };

      const titleByState = {
        [LEADS]: (
          <Trans
            i18nKey="PreparingOrderDesc"
            ns="Order"
            components={{
              bold: <Typography.Text weight="semibold" />
            }}
          />
        ),
        [IN_PROGRESS]: getInProgressTitle()
      };

      setAccepted({ title: titleByState[orderStatusState] });
    },
    [contractor, t]
  );

  const setCancellingReasonTitle = useCallback(
    async ({ orderStatusId, requestId }) => {
      try {
        setIsLoading(true);

        const fetchedRequest = await dispatch(
          fetchOrderStatusRequest({ id: orderStatusId, requestId })
        );

        setAccepted({
          title: `${t('PleaseApproveCancelationDesc')} ${fetchedRequest.reason}`
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch, setIsLoading, t]
  );

  useEffect(() => {
    // ONLINE ORDER: if the other side first sent any request and it is not transmitted to us
    if (isLocked && !request) {
      setLockedTitle(state, lockedKind);
    }

    // ONLINE ORDER: for the both sides (responsible) when cancelling order from state IN_PROGRESS
    if (
      request &&
      request.kind === REQUEST_KIND_APPROVING_CANCELING_ORDER &&
      isResponsible
    ) {
      setCancellingReasonTitle({ orderStatusId: id, requestId: request.id });
    }

    // ANY ORDER: fetch specification
    if (
      request &&
      (request.kind === REQUEST_KIND_APPROVING_SPECIFICATION ||
        request.kind === REQUEST_KIND_APPROVING_CHANGING_SPECIFICATION) &&
      contractor
    ) {
      onFetchSpecification();
    }

    // ANY ORDER: fetch act
    if (request && request.kind === REQUEST_KIND_APPROVING_ACT && contractor) {
      onFetchAct();
    }

    // ANY ORDER: fetch periodic specification
    if (
      request &&
      (request.kind === REQUEST_KIND_APPROVING_SCHEDULING_SPECIFICATION ||
        request.kind ===
          REQUEST_KIND_APPROVING_CHANGING_SCHEDULING_SPECIFICATION ||
        lockedKind === REQUEST_KIND_APPROVING_SCHEDULING_SPECIFICATION)
    ) {
      onFetchPeriodicSpecification();
    }

    // ANY ORDER: fetch periodic act
    if (
      request &&
      request.kind === REQUEST_KIND_APPROVING_SCHEDULING_ACT &&
      contractor
    ) {
      onFetchPeriodicAct();
    }

    // ANY ORDER: if the act is one-time and is shown only from the signatory
    if (
      isSignatory &&
      responsibleId !== signatoryId &&
      (isLeads || isInProgress) &&
      lockedKind !== REQUEST_KIND_APPROVING_SCHEDULING_ACT
    ) {
      setAccepted({
        title: t('WaitingForResponsibleToActDescSchedule')
      });
    }

    return () =>
      setAccepted({
        title: null,
        condition: null,
        documentList: null,
        acceptedDocuments: null
      });
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isLocked, request, state, contractor]);

  useEffect(() => {
    if (!!(accepted.documentList || []).length && !accepted.acceptedDocuments) {
      onFetchAcceptedDocuments({
        fileIds: accepted.documentList,
        acceptedState: ACCEPTED_STATE_AGREEMENT
      });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [accepted]);

  return {
    actions: allowedActions,
    canCancelRequest,
    isEmpty,
    isLoading,
    modalData,
    accepted,
    cancelRequest: onCancelRequest,
    openPopoverData,
    onVisiblePopover,
    isResponsible,
    reopenAct: onReopenAct
  };
};

export default useActions;

import React, {
  useState,
  useRef,
  useEffect,
  forwardRef,
  useCallback,
  Suspense
} from 'react';
import { Popover, Input, Spin, Checkbox } from 'antd';
import { useSelector } from 'react-redux';
import PropTypes from 'prop-types';
import InfiniteScroll from 'react-infinite-scroller';
import classnames from 'classnames';
import debounce from 'lodash/debounce';
import isEqual from 'lodash/isEqual';
import { Translation, useTranslation } from 'react-i18next';
import i18n from 'i18n-config';

import Icon from 'components/common/icon';
import Button from 'components/common/button';

import { getSelectedId } from 'store/workspace';

import usePrevious from 'hooks/common/use-previous';
import { OLD_REQUEST_CANCELED } from 'utils/make-cancalable-request';

import CommonOption from './option';
import CommonContent from './content';
import Creatable from './creatable-option';

import styles from './filter-select.module.scss';

const debounceSearch = debounce((setValue, value) => {
  setValue(value);
}, 300);

const filterHidden = (value, hiddenItems) =>
  value.filter(v => !hiddenItems.find(item => v.value === item));

/**
 * @typedef {Object} Props
 * @property {RefType} ref
 * @property {(({ search: string, items: array }) => boolean) | undefined} showCreatable Указывает будет ли показана кнопка "Создать"
 * @property {boolean} newValueOnChange Перезаписывать value при onChange
 * @property {array} filterFields Массив ключей по которым фильтровать (если не isAsync)
 * @property {boolean} isHideSelectedValue Не выводить выбранные значения внутри инпута
 */

/**
 * @type {React.FC<Props>}
 */
const CustomSelect = forwardRef(
  (
    {
      children,
      value,
      isHideSelectedValue,
      defaultValue,
      valueText,
      notFoundContent,
      onChange,
      params,
      options,
      label,
      placeholder,
      rootClassName,
      className,
      contentClassName,
      popoverOverlayClassName,
      filterFields,
      Option,
      CreatableOption,
      Content,
      CustomValue,
      maxListHeight,
      fetchData,
      isClearable,
      isAsync,
      isCursorPagination,
      isSearchable,
      showCreatable,
      isMulti,
      isLabelInside,
      withHasValueBadge,
      isDisabled,
      visibleDropdownMenu,
      allowSelectAll,
      hiddenItems,
      newValueOnChange,
      closeMenuOnSelect,
      reloadAfterOpen,
      contentProps,
      optionProps,
      inputProps,
      popoverProps,
      notSelectedOption,
      firstOption,
      getPopupContainer,
      renderContent,
      renderContentTop,
      afterVisibleCallback,
      size,
      style,
      hidePosition,
      isLinkToElement,
      selectType,
      showSelectedOptionSeparately,
      hasRelatedProject,
      addEntityButtonData,
      dataTestId
    },
    ref
  ) => {
    const [items, setItems] = useState(options);
    const [allItems, setAllItems] = useState(options); // Для синхронного поиска
    const [totalItems, setTotalItems] = useState(undefined);
    const [cursor, setCursor] = useState(undefined);
    const [search, setSearch] = useState('');
    const [hasMore, setHasMore] = useState(true);
    const [visible, setVisible] = useState(visibleDropdownMenu);
    const [isLoading, setIsLoading] = useState(false);
    const [isFirstLoading, setIsFirstLoading] = useState(true);
    const [widthPopap, setWidthPopap] = useState('100%');
    const [isAllSelected, setIsAllSelected] = useState(false);

    const workspaceId = useSelector(getSelectedId);

    const popoverRef = useRef(null);
    const inputRef = useRef(null);

    const prevParams = usePrevious(params);

    const { t } = useTranslation(['Common', 'Filters']);

    const getCommonValue = useCallback(() => {
      if (isMulti) {
        return ((value || []).length ? value : defaultValue) || [];
      }

      return value || defaultValue || {};
    }, [defaultValue, isMulti, value]);

    const commonValue = getCommonValue();

    const hasValue = isMulti
      ? !!commonValue.length
      : !!Object.keys(commonValue).length;

    const handleChange = ({ option, isCheck, isClickOnAll }) => {
      if (isMulti && !newValueOnChange) {
        if (isClickOnAll) {
          const changedValue = isCheck ? filterHidden(option, hiddenItems) : [];

          onChange(changedValue);
        } else {
          const changedValue = isCheck
            ? [...commonValue, option]
            : commonValue.filter(v => v.value !== option.value);

          onChange(changedValue);
        }
      } else {
        onChange(option);
      }
      onClearSearch();
    };

    const onClearSearch = useCallback(() => {
      if (search.length) {
        setSearch('');
      }

      if (closeMenuOnSelect) {
        // Чтобы срабатывал запрос на получение списка
        setTimeout(() => {
          setVisible(false);
        }, 50);
      }
    }, [closeMenuOnSelect, search.length]);

    const onClearValue = useCallback(
      option => {
        const emptyDefaultValue =
          !isMulti && (defaultValue || {}).isEmpty ? {} : defaultValue;
        if (!option || !Object.keys(option).length) {
          onChange(isMulti ? defaultValue || [] : emptyDefaultValue);
        } else {
          const resultValue = isMulti
            ? commonValue.filter(v => v.value !== option.value)
            : emptyDefaultValue;

          onChange(resultValue);
        }
      },
      [commonValue, defaultValue, isMulti, onChange]
    );

    const filterItems = useCallback(
      searchValue => {
        if (!searchValue.length) {
          setItems(allItems);
        } else {
          const filtredItems = allItems.filter(item => {
            if (typeof item.label === 'string') {
              return item.label
                .toLowerCase()
                .includes(searchValue.toLowerCase());
            }

            return filterFields.some(field =>
              (item.label[field] || '')
                .toString()
                .toLowerCase()
                .includes(searchValue.toLowerCase())
            );
          });

          setItems(filtredItems);
        }
      },
      [allItems, filterFields]
    );

    const focusInput = () => inputRef.current && inputRef.current.focus();

    useEffect(() => {
      if (workspaceId) {
        setItems([]);
        setTotalItems(undefined);
        setCursor(undefined);
        setSearch('');
        setHasMore(true);
        setIsFirstLoading(true);
      }
    }, [workspaceId]);

    useEffect(() => {
      focusInput();
    });

    useEffect(() => {
      setItems(options);
      setAllItems(options);
    }, [options]);

    useEffect(() => {
      if (visible) {
        if (isAsync) {
          debounceSearch(loadData, { isEmpty: true });
        } else {
          filterItems(search);
        }
      }

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [search, isAsync]);

    useEffect(() => {
      if (!isEqual(prevParams, params)) setIsFirstLoading(true);
    }, [params, prevParams]);

    useEffect(() => {
      const mustReload = visible && (isFirstLoading || reloadAfterOpen);

      if (mustReload) {
        loadData({ isEmpty: true });
        setIsFirstLoading(false);
      }

      afterVisibleCallback(visible);

      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [visible, reloadAfterOpen]);

    useEffect(() => {
      setVisible(visibleDropdownMenu);
    }, [visibleDropdownMenu]);

    const loadData = useCallback(
      async ({ isEmpty = false, loadAll = false } = {}) => {
        if (isAsync) {
          setIsLoading(true);

          try {
            const query = {
              search,
              offset: isEmpty ? 0 : items.length,
              next: isCursorPagination ? cursor : undefined,
              ...params
            };

            if (loadAll) {
              query.limit = totalItems - items.length;
            }

            const { entries, totalItems: _totalItems, next } = await fetchData(
              query
            );
            const results = isEmpty ? entries : [...items, ...entries];

            if (isCursorPagination) {
              setCursor(next);
            } else {
              setTotalItems(_totalItems);
            }

            setItems(results);

            setHasMore(
              isCursorPagination ? next : results.length < _totalItems
            );

            setIsLoading(false);
          } catch (e) {
            setIsLoading(e.type === OLD_REQUEST_CANCELED);
          }
        }
      },
      [
        cursor,
        fetchData,
        isAsync,
        isCursorPagination,
        items,
        params,
        search,
        totalItems
      ]
    );

    const handleSearch = useCallback(event => {
      setSearch(event.target.value);
      setIsLoading(true);
    }, []);
    const onVisibleChange = useCallback(
      vis => setVisible(isDisabled ? false : vis),
      [isDisabled]
    );

    const isCheckedOption = useCallback(
      option =>
        isMulti
          ? commonValue.some(v => v.value === option.value)
          : commonValue.value === option.value,
      [commonValue, isMulti]
    );

    useEffect(() => {
      if (allowSelectAll && isAllSelected) {
        if (isAsync && hasMore) {
          loadData({ isEmpty: false, loadAll: true }).then(option =>
            handleChange({
              option,
              isCheck: isAllSelected,
              isClickOnAll: true
            })
          );
        } else {
          handleChange({
            option: items,
            isCheck: isAllSelected,
            isClickOnAll: true
          });
        }
      }
      //  eslint-disable-next-line
    }, [isAllSelected]);

    const onCheckAllChange = e => setIsAllSelected(e.target.checked);

    const optionList = [
      firstOption,
      ...(notSelectedOption && !search ? [notSelectedOption] : []),
      ...items
    ].filter(Boolean);

    const renderNotFoundContent = useCallback(() => {
      if (items.length === 0 && !isLoading) {
        if (showCreatable && !!search.length) {
          return null;
        }

        if (search.length) {
          return <span className={styles.empty}>{t('NotFound')}</span>;
        }

        return (isFirstLoading || notFoundContent ? (
          <span className={styles.empty}>
            {isFirstLoading ? <Spin /> : notFoundContent}
          </span>
        ) : null);
      }

      return null;
    }, [
      items.length,
      isLoading,
      showCreatable,
      search.length,
      isFirstLoading,
      notFoundContent,
      t
    ]);

    const content = (
      <Content
        className={classnames(styles.content, contentClassName)}
        value={commonValue}
        onChange={handleChange}
        {...contentProps}
      >
        <Suspense fallback={<Spin />}>
          {isSearchable && (
            <Input
              autoFocus
              allowClear
              placeholder={placeholder}
              ref={inputRef}
              className={styles.search}
              prefix={<Icon type="search" size={20} className={styles.icon} />}
              value={search}
              onChange={handleSearch}
              {...inputProps}
            />
          )}

          {renderContentTop()}

          {addEntityButtonData && (
            <Button
              type="link"
              className={styles.addButton}
              onClick={() => {
                setVisible(false);
                addEntityButtonData.onClick();
              }}
            >
              <Icon type="plus-circle" size={20} />
              {addEntityButtonData.title}
            </Button>
          )}

          {showCreatable && showCreatable({ search, items }) && (
            <CreatableOption
              onClick={() =>
                handleChange({
                  option: {
                    label: search,
                    value: search,
                    isNew: true
                  },
                  isCheck: true
                })
              }
            >
              {search}
            </CreatableOption>
          )}

          {renderNotFoundContent()}

          <div style={{ maxHeight: maxListHeight, overflow: 'auto' }}>
            <InfiniteScroll
              loadMore={() => loadData()}
              hasMore={hasMore && !isLoading && isAsync}
              useWindow={false}
              initialLoad={false}
              className={styles.list}
            >
              {allowSelectAll && (
                <div className={styles.list}>
                  <Checkbox
                    onChange={onCheckAllChange}
                    checked={isAllSelected}
                    className={classnames(styles.option, 'small', {
                      [styles.multiple]: isMulti
                    })}
                  >
                    <span style={{ marginLeft: 8 }}>
                      {t('ChooseAllChckbx', { ns: 'Filters' })}
                    </span>
                  </Checkbox>
                </div>
              )}
              {optionList
                .filter(i => !hiddenItems.find(item => i.value === item))
                .map((option, i) => (
                  <Checkbox
                    key={`${option.value}-${i}`}
                    onChange={event =>
                      handleChange({ option, isCheck: event.target.checked })
                    }
                    className={classnames(styles.option, 'small', {
                      [styles.multiple]: isMulti,
                      [styles.withPosition]: option.label.position,
                      [styles.disabled]: option.label.isDisabled
                    })}
                    checked={isCheckedOption(option)}
                    tabIndex={-1}
                    style={hidePosition ? { alignItems: 'center' } : null}
                    disabled={option.label.isDisabled}
                  >
                    <Option
                      option={option.label}
                      {...optionProps}
                      hidePosition={hidePosition}
                      isLinkToElement={false}
                      selectType={selectType}
                      hasRelatedProject={hasRelatedProject}
                    >
                      {(option.icon || option.iconType) && (
                        <Icon
                          color="black-55"
                          style={{ marginRight: 8 }}
                          type={option.iconType}
                          component={option.icon}
                        />
                      )}
                      {option.ns ? (
                        <Translation ns={option.ns}>
                          {translate => translate(option.label)}
                        </Translation>
                      ) : (
                        option.label
                      )}
                    </Option>
                  </Checkbox>
                ))}
            </InfiniteScroll>
          </div>

          {isLoading && isAsync && <Spin />}
        </Suspense>
      </Content>
    );

    const renderValueText = (
      <span
        style={{
          marginRight: 5,
          overflow: 'hidden',
          whiteSpace: 'nowrap',
          textOverflow: 'ellipsis'
        }}
      >
        {valueText}
      </span>
    );

    const renderValue = useCallback(() => {
      if (!hasValue || isHideSelectedValue) {
        return renderValueText;
      }

      if (CustomValue) {
        return (
          <CustomValue
            option={commonValue.label}
            isSelectedOption
            isClearable={isClearable}
            onRemove={() => onClearValue()}
          />
        );
      }

      if (isMulti) {
        return (
          <div className={styles.optionList}>
            {commonValue.map(option => (
              <Option
                key={option.value}
                option={option.label}
                isDisabled={isDisabled}
                isClearable
                isTag
                isSmall
                onRemove={() => onClearValue(option)}
                className={classnames(styles.optionItem, {
                  [styles.optionSeparately]: showSelectedOptionSeparately
                })}
                hidePosition
                withAllName={false}
                isLinkToElement={isLinkToElement}
                optionId={option.value}
                selectType={selectType}
                objectId={option.objectId}
                hasRelatedProject={hasRelatedProject}
                {...optionProps}
              >
                {(option.icon || option.iconType) && (
                  <Icon
                    color="black-55"
                    type={option.iconType}
                    component={option.icon}
                    style={{ marginRight: 8 }}
                  />
                )}

                {option.ns ? (
                  <Translation ns={option.ns}>
                    {translate => translate(option.label)}
                  </Translation>
                ) : (
                  option.label
                )}
              </Option>
            ))}
          </div>
        );
      }

      return (
        <Option
          option={commonValue.label}
          isClearable={isClearable}
          isDisabled={isDisabled}
          hidePosition
          isInInput
          style={{ paddingLeft: 0 }}
          onRemove={() => onClearValue(commonValue)}
          isLinkToElement={isLinkToElement}
          optionId={commonValue.value}
          selectType={selectType}
          objectId={commonValue.objectId}
          hasRelatedProject={hasRelatedProject}
          className={classnames({
            [styles.optionSeparately]: showSelectedOptionSeparately
          })}
          {...optionProps}
        >
          {(commonValue.icon || commonValue.iconType) && (
            <Icon
              color="black-55"
              type={commonValue.iconType}
              component={commonValue.icon}
              style={{ marginRight: 8 }}
            />
          )}

          {commonValue.ns ? (
            <Translation ns={commonValue.ns}>
              {translate => translate(commonValue.label)}
            </Translation>
          ) : (
            commonValue.label
          )}
        </Option>
      );
    }, [
      CustomValue,
      commonValue,
      hasRelatedProject,
      hasValue,
      isClearable,
      isDisabled,
      isHideSelectedValue,
      isLinkToElement,
      isMulti,
      onClearValue,
      optionProps,
      renderValueText,
      selectType,
      showSelectedOptionSeparately
    ]);

    const renderLabel = useCallback(
      colon => (
        <span className={styles.label}>
          {label}
          {colon ? ': ' : ''}
        </span>
      ),
      [label]
    );

    return (
      <div
        ref={ref}
        className={classnames(styles.rootWrap, rootClassName)}
        style={style}
      >
        {label && !isLabelInside && renderLabel()}

        <Popover
          trigger="click"
          content={renderContent || content}
          ref={popoverRef}
          placement="bottomLeft"
          getPopupContainer={trigger => {
            setWidthPopap(trigger.getBoundingClientRect().width);

            if (getPopupContainer) {
              return getPopupContainer(trigger);
            }

            return trigger.parentNode;
          }}
          overlayClassName={classnames(
            styles.popoverOverlay,
            popoverOverlayClassName
          )}
          visible={visible}
          onVisibleChange={onVisibleChange}
          {...popoverProps}
          onClick={e => {
            if (popoverProps.onClick) {
              popoverProps.onClick(e);
            }
          }}
          overlayStyle={{
            width: widthPopap,
            ...popoverProps.overlayStyle
          }}
        >
          <div
            className={classnames(styles.root, className, {
              [styles.disabled]: isDisabled,
              [styles[size]]: size,
              [styles.badge]: hasValue && withHasValueBadge
            })}
            data-error
            data-testid={dataTestId}
          >
            {label && isLabelInside && renderLabel(hasValue)}

            {children ? (
              <>{children}</>
            ) : !showSelectedOptionSeparately ? (
              renderValue()
            ) : (
              renderValueText
            )}

            {!children && (
              <Icon
                type="arrow"
                side={visible ? 'left' : 'default'}
                color="black-55"
                size={20}
              />
            )}
          </div>

          {showSelectedOptionSeparately && hasValue && (
            <div className={styles.showSelectedOptionSeparatelyList}>
              {renderValue()}
            </div>
          )}
        </Popover>
      </div>
    );
  }
);

CustomSelect.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  options: PropTypes.array,
  value: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.any
      })
    )
  ]),
  defaultValue: PropTypes.oneOfType([
    PropTypes.shape({
      value: PropTypes.any,
      label: PropTypes.any,
      isEmpty: PropTypes.bool
    }),
    PropTypes.arrayOf(
      PropTypes.shape({
        value: PropTypes.any,
        label: PropTypes.any
      })
    )
  ]),
  hiddenItems: PropTypes.arrayOf(PropTypes.number),
  valueText: PropTypes.string,
  notFoundContent: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  label: PropTypes.string,
  placeholder: PropTypes.string,
  rootClassName: PropTypes.string,
  className: PropTypes.string,
  contentClassName: PropTypes.string,
  popoverOverlayClassName: PropTypes.string,
  filterFields: PropTypes.arrayOf(PropTypes.string),
  Option: PropTypes.any,
  CreatableOption: PropTypes.any,
  Content: PropTypes.any,
  CustomValue: PropTypes.any,
  maxListHeight: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
  isSearchable: PropTypes.bool,
  isClearable: PropTypes.bool,
  isAsync: PropTypes.bool,
  isCursorPagination: PropTypes.bool,
  isMulti: PropTypes.bool,
  isDisabled: PropTypes.bool,
  isLabelInside: PropTypes.bool,
  newValueOnChange: PropTypes.bool,
  closeMenuOnSelect: PropTypes.bool,
  reloadAfterOpen: PropTypes.bool,
  allowSelectAll: PropTypes.bool,
  onChange: PropTypes.func,
  fetchData: PropTypes.func, // res -> ({ totalItems, entries })
  contentProps: PropTypes.object,
  popoverProps: PropTypes.object,
  inputProps: PropTypes.object,
  optionProps: PropTypes.object,
  firstOption: PropTypes.shape({
    value: PropTypes.oneOfType([PropTypes.string, PropTypes.number]),
    label: PropTypes.string
  }),
  style: PropTypes.object,
  size: PropTypes.string,
  renderContent: PropTypes.any,
  renderContentTop: PropTypes.func,
  getPopupContainer: PropTypes.func,
  afterVisibleCallback: PropTypes.func,
  showCreatable: PropTypes.func,
  hidePosition: PropTypes.bool,
  isLinkToElement: PropTypes.bool,
  selectType: PropTypes.string,
  showSelectedOptionSeparately: PropTypes.bool,
  hasRelatedProject: PropTypes.bool,
  withHasValueBadge: PropTypes.bool,
  addEntityButtonData: PropTypes.shape({
    title: PropTypes.string,
    onClick: PropTypes.func
  })
};

CustomSelect.defaultProps = {
  hidePosition: false,
  children: undefined,
  options: [],
  value: undefined,
  hiddenItems: [],
  defaultValue: undefined,
  valueText: <Translation ns="Common">{t => t('All')}</Translation>,
  notFoundContent: <Translation ns="Common">{t => t('NotFound')}</Translation>,
  label: undefined,
  placeholder: i18n.t('Search', { ns: 'Common' }),
  rootClassName: undefined,
  className: undefined,
  contentClassName: undefined,
  popoverOverlayClassName: undefined,
  filterFields: ['title'],
  Option: CommonOption,
  CreatableOption: Creatable,
  Content: CommonContent,
  CustomValue: undefined,
  maxListHeight: 240,
  isSearchable: false,
  isClearable: false,
  isAsync: false,
  isCursorPagination: false,
  isMulti: false,
  isDisabled: false,
  isLabelInside: false,
  newValueOnChange: false,
  closeMenuOnSelect: true,
  reloadAfterOpen: false,
  allowSelectAll: false,
  firstOption: null,
  renderContent: undefined,
  renderContentTop: () => {},
  getPopupContainer: undefined,
  onChange: () => {},
  fetchData: () => {},
  contentProps: {},
  popoverProps: {},
  inputProps: {},
  optionProps: {},
  style: {},
  size: 'default',
  afterVisibleCallback: visible => visible,
  showCreatable: undefined,
  isLinkToElement: false,
  selectType: '',
  showSelectedOptionSeparately: false,
  hasRelatedProject: false,
  withHasValueBadge: false,
  addEntityButtonData: null
};

export default CustomSelect;

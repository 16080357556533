import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';

import { FormInput, FormLocationInput } from 'components/common/hook-form';
import FormNewEditor from 'components/common/hook-form/markdown';

import styles from './custom-specification.module.scss';

const TYPE_LOCATION = 'location';
const TYPE_CONTENT = 'content';

const useAdditionalFields = ({ defaultValues }) => {
  const [activeFields, setActiveFields] = useState([]);

  const { t } = useTranslation('OrderConditions');

  const onAddField = type =>
    setActiveFields(prev => [...prev, allFields.find(f => f.type === type)]);

  const allFields = [
    {
      type: TYPE_LOCATION,
      title: t('AddressHeadingShort'),
      ns: 'OrderConditions',
      icon: 'environment',
      allow: true,
      onClick: () => onAddField(TYPE_LOCATION),
      component: (
        <div className={styles.location}>
          <FormLocationInput
            label={t('MainAddress')}
            name="location"
            placeholder={t('EnterAddress')}
          />

          <FormInput label={t('AdditionalAddress')} name="locationExact" />
        </div>
      )
    },
    {
      type: TYPE_CONTENT,
      title: t('OrderDescShort'),
      ns: 'OrderConditions',
      icon: 'container',
      allow: true,
      onClick: () => onAddField(TYPE_CONTENT),
      component: (
        <FormNewEditor
          label={t('OrderDescShort')}
          name="content"
          placeholder={t('OrderDescPlchldr')}
          resizeInput
        />
      )
    }
  ];

  const allowedFields = allFields.filter(
    f => f.allow && !activeFields.find(({ type }) => type === f.type)
  );

  const checkIsActiveFeild = value => {
    if (Array.isArray(value) && !value.length) {
      return false;
    }

    return !!value;
  };

  useEffect(() => {
    setActiveFields(
      allFields.filter(
        f => checkIsActiveFeild(defaultValues[f.type]) && f.allow
      )
    );
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [defaultValues]);

  return { activeFields, allowedFields };
};

export default useAdditionalFields;

import { combineActions } from 'redux-actions';
import { LOCATION_CHANGE } from 'connected-react-router';

import handleActions from '../../utils/redux-actions';
import {
  fetchAttachments,
  fetchAttachment,
  clearError,
  restoreAttachment,
  renameAttachment,
  setCurrentPage,
  setFilterSearch,
  clearFilter,
  setSorting,
  setFilterOrders,
  setFilterProjects,
  setFilterTasks,
  setFilterContacts,
  setFilterCreators,
  setFilterCreatedAt,
  toTrashAttachment,
  deleteAttachment,
  setFilterAsset,
  setFilterTag
} from './actions';
import { makeSubscribeLocationChange } from '../router';
import {
  ITEMS_PER_PAGE,
  ATTACHMENTS,
  CONTAINS_ANY_OPTION
} from '../../constants';
import { setActiveId } from '../workspace';
import { getInitialValueFilterStorage } from '../../hooks/common/use-filter-storage';

export const initialFilter = {
  search: '',
  orders: [],
  projects: [],
  tasks: [],
  contacts: [],
  asset: [],
  sorting: {
    sortField: '',
    sortDirection: undefined
  },
  departments: [],
  creators: [],
  createdAt: {},
  tag: {
    ids: [],
    condition: CONTAINS_ANY_OPTION
  }
};

const initialState = {
  isLoading: false,
  isLoaded: false,
  error: null,

  isTrash: false,

  filter: {
    ...initialFilter,
    ...getInitialValueFilterStorage(ATTACHMENTS, initialFilter)
  },

  currentPage: 1,
  totalItems: 0,
  itemsPerPage: ITEMS_PER_PAGE * 2,

  entries: []
};

export default handleActions(
  {
    [LOCATION_CHANGE]: makeSubscribeLocationChange(ATTACHMENTS),

    [setActiveId]: () => initialState,

    [combineActions(
      setFilterSearch,
      setFilterOrders,
      setFilterProjects,
      setFilterTasks,
      setFilterContacts,
      setFilterCreators,
      setFilterCreatedAt,
      setFilterAsset,
      setFilterTag
    )]: state => {
      state.currentPage = 1;

      return state;
    },

    [setCurrentPage]: (state, { payload }) => {
      state.currentPage = payload;

      return state;
    },

    [setFilterSearch]: (state, { payload }) => {
      state.filter.search = payload;

      return state;
    },

    [setFilterOrders]: (state, { payload }) => {
      state.filter.orders = payload;

      return state;
    },

    [setFilterAsset]: (state, { payload }) => {
      state.filter.asset = payload;

      return state;
    },

    [setFilterProjects]: (state, { payload }) => {
      state.filter.projects = payload;

      return state;
    },

    [setFilterTasks]: (state, { payload }) => {
      state.filter.tasks = payload;

      return state;
    },

    [setFilterContacts]: (state, { payload }) => {
      state.filter.contacts = payload;

      return state;
    },

    [setFilterCreators]: (state, { payload }) => {
      state.filter.creators = payload;

      return state;
    },

    [setFilterCreatedAt]: (state, { payload }) => {
      state.filter.createdAt = payload;

      return state;
    },

    [setFilterTag]: (state, { payload }) => {
      state.filter.tag = payload;

      return state;
    },

    [setSorting]: (state, { payload }) => {
      state.filter.sorting = payload;

      return state;
    },

    [clearFilter]: state => {
      state.filter = initialFilter;

      return state;
    },

    [fetchAttachments.START]: (state, { args }) => {
      state.isLoading = true;
      state.isLoaded = false;

      // При переходите из всех фалов в корзину (обратно), очищаем пагинацию
      if (state.isTrash !== args.isTrash) {
        state.isTrash = args.isTrash;
        state.currentPage = 1;
      }

      return state;
    },

    [fetchAttachments.ENDED]: state => {
      state.isLoading = false;

      return state;
    },

    [fetchAttachments.FAILED]: (state, { payload }) => {
      state.error = payload;

      return state;
    },

    [fetchAttachments.SUCCEEDED]: (state, { payload }) => {
      if (state.filter.search !== payload.search) {
        return state;
      }

      state.isLoading = false;
      state.isLoaded = true;
      state.totalItems = payload.totalItems;
      state.entries = payload.entries;

      return state;
    },

    [fetchAttachment.SUCCEEDED]: (state, { payload }) => {
      state.entries = [...state.entries, payload];

      return state;
    },

    [clearError]: state => {
      state.error = null;

      return state;
    },

    [combineActions(
      toTrashAttachment.SUCCEEDED,
      restoreAttachment.SUCCEEDED,
      deleteAttachment.SUCCEEDED
    )]: (state, { payload }) => {
      state.entries = state.entries.filter(a => a.fileId !== payload);
      state.totalItems -= 1;

      return state;
    },

    [renameAttachment.SUCCEEDED]: (state, { payload }) => {
      const index = state.entries.findIndex(a => a.fileId === payload.fileId);

      if (state.entries[index]) {
        state.entries[index] = payload;
      }

      return state;
    }
  },
  initialState
);

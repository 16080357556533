import React from 'react';
import PropTypes from 'prop-types';
import { Tooltip } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Button from 'components/common/button';

import styles from '../components.module.scss';

const Submit = ({ isLoading, isDisabled, className, onSubmit }) => {
  const { t } = useTranslation('CommonChat');

  return (
    <Tooltip title={t('SendBtnTip')} mouseEnterDelay={0.5} placement="topRight">
      <Button
        type="link"
        onClick={onSubmit}
        className={classnames(styles.toolbarSubmit, className)}
        data-qa="qa-3uw8sxus4w0l5r5"
        loading={isLoading}
        disabled={isDisabled}
      >
        {t('SendTip')}
      </Button>
    </Tooltip>
  );
};

Submit.propTypes = {
  isLoading: PropTypes.bool,
  isDisabled: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired
};

Submit.defaultProps = {
  isLoading: false,
  isDisabled: false
};

export default Submit;

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';

import { REQUEST_KIND_APPROVING_SCHEDULING_ACT } from 'constants/orders';

import RejectionModal from 'components/common/modals/rejection';

import {
  fetchOrderStatus,
  fetchOrderStatusAllActs,
  rejectOrderStatusRequest
} from 'store/order-statuses';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const OrderRequestRejectionModal = ({
  visible,
  orderStatus,
  onClose,
  resetReordering
}) => {
  const dispatch = useDispatch();

  const onSubmit = useCallback(
    async ({ reason, fileList }) => {
      const { state, id, request, contract } = orderStatus;

      await dispatch(
        rejectOrderStatusRequest({
          id,
          requestId: request.id,
          reason,
          fileList
        })
      );
      const fetchedOrderStatus = await dispatch(fetchOrderStatus({ id }));

      if (request.kind === REQUEST_KIND_APPROVING_SCHEDULING_ACT) {
        await dispatch(
          fetchOrderStatusAllActs({
            id,
            contractId: contract
          })
        );

        showNoticeMessage({ number: NOTICE_NUMBER.orderStatusCancelAct });
        return;
      }

      showNoticeMessage({
        number:
          fetchedOrderStatus.state === state
            ? NOTICE_NUMBER.orderStatusUpdated
            : NOTICE_NUMBER.orderStatusStateChanged
      });
    },
    [dispatch, orderStatus]
  );

  const closeModal = () => {
    onClose();
    resetReordering();
  };

  return (
    <RejectionModal
      visible={visible}
      onSubmit={onSubmit}
      onClose={closeModal}
      callback={onClose}
      dataTestId="orders-request-rejection-modal"
    />
  );
};

OrderRequestRejectionModal.propTypes = {
  visible: PropTypes.bool,
  orderStatus: PropTypes.shape({
    id: PropTypes.string,
    request: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  onClose: PropTypes.func.isRequired,
  resetReordering: PropTypes.func
};

OrderRequestRejectionModal.defaultProps = {
  orderStatus: {},
  visible: false,
  resetReordering: () => {}
};

export default OrderRequestRejectionModal;

import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import { Alert } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  FormContactSelect,
  FormCustomSelect,
  FormDatePicker,
  FormEmployeeSelect
} from 'components/common/hook-form';
import Button from 'components/common/button';

import useMinMaxTime from 'hooks/common/use-min-max-time';

import User from './user';

import styles from './access-settings.module.scss';

const READER = 'reader';
const EDITOR = 'editor';
const COMMENTATOR = 'commentator';

export const OPTIONS = [
  { label: 'ReaderAccessAction', ns: 'MyDriveAccessSettings', value: READER },
  { label: 'EditorAccessAction', ns: 'MyDriveAccessSettings', value: EDITOR },
  {
    label: 'CommentatorAccessAction',
    ns: 'MyDriveAccessSettings',
    value: COMMENTATOR
  }
];

const Form = ({
  isEditableFile,
  employees,
  contacts,
  isLoading,
  onDeleteAccess,
  onEditAccess,
  onSubmit
}) => {
  const methods = useForm({
    defaultValues: {
      employee: {
        entities: [],
        role: OPTIONS[0]
      },
      contact: {
        entities: [],
        role: OPTIONS[0],
        to: null
      }
    }
  });

  const { t } = useTranslation(['MyDriveAccessSettings', 'Errors', 'Common']);

  const contactEntitiesWatch = useWatch({
    name: 'contact.entities',
    control: methods.control
  });
  const contactToWatch = useWatch({
    name: 'contact.to',
    control: methods.control
  });

  const [minTimeStart, maxTimeStart] = useMinMaxTime({
    startDate: contactToWatch || new Date()
  });

  const excludeEmployees = employees.default.map(({ id }) => id);
  const excludeContacts = contacts.default.map(({ id }) => id);

  const handleSubmit = async values => {
    const errors = await onSubmit(values);

    if (!errors.employees.length && !errors.contacts.length) {
      methods.reset();

      return;
    }

    methods.setValue('employee.entities', errors.employees);
    methods.setValue('contact.entities', errors.contacts);
  };

  return (
    <FormProvider {...methods}>
      <form
        className={styles.form}
        onSubmit={methods.handleSubmit(handleSubmit)}
      >
        <Alert
          message={t('AccessSettingsWarning')}
          type="warning"
          className={styles.alert}
        />

        <div className={styles.employeeSelectsWrap}>
          <FormEmployeeSelect
            label={t('Employee')}
            name="employee.entities"
            isMulti
            allowSetYourself={false}
            params={{ exclude: excludeEmployees, excludeSelf: true }}
            className={styles.employeeSelect}
          />

          <FormCustomSelect
            label={t('Rights')}
            name="employee.role"
            options={OPTIONS}
            isDisabled={!isEditableFile}
          />
        </div>

        <div>
          {employees.current.map(e => (
            <User
              key={e.id}
              data={e}
              onDeleteAccess={onDeleteAccess}
              onEditAccess={onEditAccess}
              filtredOptions={
                !isEditableFile
                  ? OPTIONS.filter(o => o.value === READER)
                  : OPTIONS
              }
            />
          ))}
        </div>

        {isEditableFile && (
          <>
            <div className={styles.contactSelectsWrap}>
              <FormContactSelect
                label={t('Contact')}
                valueText={t('ChooseContact')}
                name="contact.entities"
                isMulti
                closeMenuOnSelect={false}
                params={{ exclude: excludeContacts }}
              />

              <FormCustomSelect
                label={t('Rights')}
                name="contact.role"
                options={OPTIONS}
              />

              <FormDatePicker
                label={t('CloseAccess')}
                name="contact.to"
                minDate={new Date()}
                minTime={minTimeStart}
                maxTime={maxTimeStart}
                placeholderText={t('ChooseDate', { ns: 'Common' })}
                wrapperClassname={styles.datePicker}
                rules={{
                  required: {
                    value: !!(contactEntitiesWatch || []).length,
                    message: t('RequiredField', { ns: 'Errors' })
                  }
                }}
              />
            </div>

            <div>
              {contacts.current.map(c => (
                <User
                  key={c.id}
                  data={c}
                  isContact
                  onDeleteAccess={onDeleteAccess}
                  onEditAccess={onEditAccess}
                  filtredOptions={OPTIONS}
                />
              ))}
            </div>
          </>
        )}

        <Button
          type="primary"
          htmlType="submit"
          size="large"
          loading={isLoading}
          className={styles.button}
        >
          {t('SaveSettingsBtn')}
        </Button>
      </form>
    </FormProvider>
  );
};

Form.propTypes = {
  employees: PropTypes.shape({
    default: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    ),
    current: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    )
  }).isRequired,
  contacts: PropTypes.shape({
    default: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    ),
    current: PropTypes.arrayOf(
      PropTypes.shape({
        id: PropTypes.number
      })
    )
  }).isRequired,
  isLoading: PropTypes.bool,
  onDeleteAccess: PropTypes.func.isRequired,
  onEditAccess: PropTypes.func.isRequired,
  onSubmit: PropTypes.func.isRequired,
  isEditableFile: PropTypes.bool
};

Form.defaultProps = {
  isLoading: false,
  isEditableFile: false
};

export default Form;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Divider, Input, notification, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';
import { EMAIL, PHONE } from 'components/contacts-view/utils';

import { fetchLinkInviteLocal } from 'store/contacts';

import { useAmplitude } from 'hooks/amplitude/use-amplitude';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import SendInviteModal from '../../send-invite-modal';

import styles from '../invite-to-register.module.scss';

const { Paragraph } = Typography;

export const SelectInviteModal = ({ contact, visible, onClose, ...props }) => {
  const dispatch = useDispatch();
  const amplitude = useAmplitude();

  const [link, setLink] = useState('');
  const [visibleInvite, setVisibleInvite] = useState();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation(['InviteForRegistration', 'Toast']);

  const onClick = key => {
    const data = [];

    const inviteType = {
      [PHONE]: 'sms',
      [EMAIL]: EMAIL
    };

    Object.keys(contact).forEach(k => {
      if (k.includes(key) && contact[k]) {
        data.push(contact[k]);
      }
    });

    if (data.length) {
      setVisibleInvite({
        ...contact,
        [`${key}s`]: data,
        inviteType: inviteType[key]
      });
      onClose();
    } else {
      notification.warning({
        message: t(key === EMAIL ? 'ContactEmailEmpty' : 'ContactPhoneEmpty', {
          ns: 'Toast'
        })
      });
    }
  };

  const getInviteLink = async () => {
    try {
      setIsLoading(true);

      const _link = await dispatch(fetchLinkInviteLocal({ id: contact.id }));
      setLink(_link);
    } finally {
      setIsLoading(false);
    }
  };

  const onCopy = () => {
    navigator.clipboard.writeText(link);
    amplitude.contactInvitationEvent({ value: contact, type: 'link' });
    showNoticeMessage({ number: NOTICE_NUMBER.linkCopied });
  };

  useEffect(() => {
    if (visible) {
      getInviteLink();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [visible]);

  return (
    <>
      <Modal
        title={t('SendInvitationHeading')}
        data-qa="qa-onrhhtvzc6kascy"
        width={600}
        centered
        visible={visible}
        contentStyle={{ padding: 24 }}
        onClose={onClose}
        {...props}
      >
        <Paragraph className={styles.text}>{t('SendInvitationDesc')}</Paragraph>

        <div className={styles.btnWrap} data-qa="qa-w8h6qodtjgtnu8o">
          <Button
            type="link"
            className={styles.btn}
            data-qa="qa-c7bm3cfkmbxgauf"
            onClick={() => onClick(PHONE)}
          >
            {t('SendSMSBtn')}
          </Button>

          <Button
            type="link"
            className={styles.btn}
            data-qa="qa-4lxktitkb52v0sc"
            onClick={() => onClick(EMAIL)}
          >
            {t('SendEmailBtn')}
          </Button>
        </div>

        <Divider style={{ margin: '24px 0' }} />

        <Spin spinning={isLoading}>
          <Input
            readOnly
            value={link}
            className={styles.input}
            data-qa="qa-qij5gz9eq7e9qw2"
            suffix={
              <Button
                type="link"
                className={styles.btn}
                data-qa="qa-lqwwu7kp5qp0tea"
                onClick={() => onCopy()}
              >
                {t('CopyLinkBtn')}
              </Button>
            }
          />
        </Spin>
      </Modal>

      <SendInviteModal
        visible={visibleInvite !== undefined}
        contact={visibleInvite}
        onClose={() => setVisibleInvite(undefined)}
      />
    </>
  );
};

SelectInviteModal.propTypes = {
  contact: PropTypes.shape({
    id: PropTypes.oneOfType([PropTypes.number, PropTypes.string]),
    phone: PropTypes.string,
    email: PropTypes.string
  }),
  visible: PropTypes.bool,
  onClose: PropTypes.func
};

SelectInviteModal.defaultProps = {
  contact: {},
  visible: false,
  onClose: () => {}
};

export default SelectInviteModal;

import React, { useState, useEffect, useMemo } from 'react';
import PropTypes from 'prop-types';
import { useLocation } from 'react-router-dom';

import { BACKLOG, SPRINTS, TASKS } from 'constants/index';

export const CreatorDrawerContext = React.createContext({});

export const CreatorDrawerProvider = ({
  visible,
  children,
  value,
  ideaId,
  onClose,
  onSubmit,
  onTasksCreated,
  initialPage: initialPageProp,
  withShowDetails
}) => {
  const { state = {}, pathname } = useLocation();
  const { initialData } = state;
  const resultValue = initialData || value || {};

  const [visibleCalender, setVisibleCandar] = useState(
    resultValue.defaultVisibleCalendar
  );

  const isBacklog = pathname.includes(`/${BACKLOG}`);
  const isSprints = pathname.includes(`/${SPRINTS}`);

  const initialPage = useMemo(() => {
    if (typeof initialPageProp === 'number') return initialPageProp;
    if (isBacklog) return 0;
    if (resultValue.kind) return 1;

    return 0;
  }, [isBacklog, resultValue, initialPageProp]);

  const [currentPage, setCurrentPage] = useState(() => initialPage);

  const isFirstPage = initialPage >= currentPage;

  const [currentTaskType, setCurrentTaskType] = useState(resultValue.kind);
  const [currentTaskTemplate, setCurrentTaskTemplate] = useState();
  const [isLoading, setIsLoading] = useState(false);
  const [activeTab, setActiveTab] = useState(TASKS);

  useEffect(() => {
    if (visible) {
      setCurrentPage(initialPage);
      setCurrentTaskType(resultValue.kind);
    } else {
      setVisibleCandar(false);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [initialData, isBacklog, visible]);

  return (
    <CreatorDrawerContext.Provider
      value={{
        initialPage,
        isFirstPage,
        currentTaskType,
        setCurrentTaskType,
        currentTaskTemplate,
        setCurrentTaskTemplate,
        isLoading,
        setIsLoading,
        currentPage,
        setCurrentPage,
        isBacklog,
        isSprints,
        value: resultValue,
        ideaId,
        onSubmit,
        onClose,
        activeTab,
        setActiveTab,
        onTasksCreated,
        withShowDetails,
        visibleCalender,
        setVisibleCandar
      }}
    >
      {children}
    </CreatorDrawerContext.Provider>
  );
};

CreatorDrawerProvider.propTypes = {
  visible: PropTypes.bool,
  children: PropTypes.node.isRequired,
  value: PropTypes.object,
  ideaId: PropTypes.number,
  onSubmit: PropTypes.func,
  onClose: PropTypes.func,
  withShowDetails: PropTypes.bool
};

CreatorDrawerProvider.defaultProps = {
  visible: false,
  value: undefined,
  ideaId: undefined,
  onSubmit: undefined,
  onClose: () => {},
  withShowDetails: true
};

export default CreatorDrawerProvider;

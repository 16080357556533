import React from 'react';
import PropTypes from 'prop-types';
import { Link, useLocation } from 'react-router-dom';
import classnames from 'classnames';

import Typography from 'components/common/typography';
import { useViewFile } from 'components/attachments-view/hooks';
import { getFileLocation } from 'components/app/open-file-provider/utils';

import { getIsEditableFile } from 'hooks/common/use-file-upload/types';

import styles from './file-title.module.scss';

const { Text } = Typography;

const FileTitle = ({
  isDisabled,
  file,
  target,
  actionsDeps,
  isOpenViaLink
}) => {
  const location = useLocation();
  const { viewFile } = useViewFile(actionsDeps);

  const { title, highlight, fileId } = file;

  const { fileName = [] } = highlight || {};

  if (isDisabled) {
    return <Text>{title}</Text>;
  }

  if (getIsEditableFile(file) || !isOpenViaLink) {
    return (
      <Text
        className={classnames(styles.link, styles.withHighlight)}
        onClick={() => viewFile(file)}
      >
        {fileName.length ? (
          <span
            dangerouslySetInnerHTML={{
              __html: fileName.join(' ')
            }}
          />
        ) : (
          title
        )}
      </Text>
    );
  }

  return (
    <Text>
      <Link
        to={getFileLocation(location, fileId)}
        replace
        target={target}
        className={styles.withHighlight}
      >
        {fileName.length ? (
          <span
            dangerouslySetInnerHTML={{
              __html: fileName.join(' ')
            }}
          />
        ) : (
          title
        )}
      </Link>
    </Text>
  );
};

FileTitle.propTypes = {
  isDisabled: PropTypes.bool,
  file: PropTypes.object.isRequired,
  target: PropTypes.string,
  actionsDeps: PropTypes.object,
  isOpenViaLink: PropTypes.bool
};

FileTitle.defaultProps = {
  isDisabled: false,
  target: '_self',
  actionsDeps: {},
  isOpenViaLink: true
};

export default FileTitle;

import React, { forwardRef, useState, useRef, useEffect } from 'react';
import { Button } from 'antd';
import { Editor as EditorDraft } from 'react-draft-wysiwyg';
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css';
import { EditorState, convertToRaw, convertFromRaw } from 'draft-js';
import { draftToMarkdown, markdownToDraft } from 'markdown-draft-js';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { replace } from 'lodash';
import marked from 'marked';

import AttachmentsControl from 'components/common/attachments-control';
import Attachments from 'components/common/attachments';
import Icon from 'components/common/icon';

import useFilePaste from 'hooks/common/use-file-paste';

import toolbar from './toolbar';
import { extractLinksToEntities, getLinks } from './utils';

import styles from './styles.module.scss';

const CustomUpdload = ({
  fileList,
  onChangeFiles,
  uploadFileServer,
  children,
  uploadText,
  isDisabled,
  actionsDeps,
  entityType,
  ...props
}) => (
  <AttachmentsControl
    fileList={fileList}
    onChange={onChangeFiles}
    uploadFileServer={uploadFileServer}
    actionsDeps={actionsDeps}
    entityType={entityType}
  >
    {children || (
      <Button
        type="text"
        disabled={isDisabled}
        style={{ fontWeight: 600 }}
        {...props}
      >
        <Icon type="paper-clip" size={16} />
        {!!uploadText && <span>{uploadText}</span>}
      </Button>
    )}
  </AttachmentsControl>
);

export const Editor = forwardRef(
  (
    {
      fileList,
      onChangeFileList,
      uploadProps,
      isDisabled,
      attachmetsProps,
      toolbarHidden,
      uploadText,
      allowAttach,
      actionsDeps,
      entityType,
      ...props
    },
    ref
  ) => {
    const editorRef = useRef(null);

    const onDeleteFile = file => {
      const newFiles = fileList.filter(({ id, uid }) =>
        id ? id !== file.id : uid !== file.uid
      );

      onChangeFileList(newFiles);
    };
    const onRenameFile = ({ id, name }) => {
      const newFileList = fileList.reduce((acc, curr) => {
        if (curr.response && curr.response.id === id) {
          return [...acc, { ...curr, name }];
        }

        return [...acc, curr];
      }, []);

      onChangeFileList(newFileList);
    };

    useFilePaste({
      target: editorRef.current,
      fileList,
      onChange: onChangeFileList,
      uploadFileServer: (uploadProps || {}).uploadFileServer
    });

    return (
      <div ref={editorRef} className={styles.root}>
        <div className={classnames(styles.editorWrap, 'editor-wrap')}>
          <EditorDraft
            editorRef={ref}
            toolbarClassName={classnames(styles.toolbarClassName, {
              [styles.withoutAttach]: !allowAttach
            })}
            editorClassName={styles.editorClassName}
            locale="ru"
            toolbar={
              toolbarHidden
                ? {
                    options: []
                  }
                : toolbar
            }
            toolbarStyle={{
              padding: toolbarHidden ? 12 : '6px 12px',
              display: toolbarHidden && !allowAttach ? 'none' : 'flex'
            }}
            toolbarCustomButtons={
              allowAttach && [
                <CustomUpdload
                  className={classnames(
                    styles.upload,
                    (uploadProps || {}).className
                  )}
                  fileList={fileList}
                  onChangeFiles={onChangeFileList}
                  uploadText={uploadText}
                  isDisabled={isDisabled}
                  actionsDeps={actionsDeps}
                  entityType={entityType}
                  {...uploadProps}
                />
              ]
            }
            stripPastedStyles
            {...props}
          />
        </div>

        {allowAttach && (
          <Attachments
            fileList={fileList}
            className={styles.attachmets}
            {...attachmetsProps}
            attachmentProps={{
              size: 'small',
              onDelete: onDeleteFile,
              onRename: onRenameFile,
              isDisabled,
              fileLinkTarget: '_blank',
              ...(attachmetsProps.attachmentProps || {})
            }}
            actionsDeps={actionsDeps}
          />
        )}
      </div>
    );
  }
);

Editor.propTypes = {
  fileList: PropTypes.array,
  onChangeFileList: PropTypes.func,
  uploadProps: PropTypes.object,
  attachmetsProps: PropTypes.object,
  toolbarHidden: PropTypes.bool,
  isDisabled: PropTypes.bool,
  allowAttach: PropTypes.bool
};

Editor.defaultProps = {
  fileList: undefined,
  onChangeFileList: () => {},
  uploadProps: undefined,
  attachmetsProps: {},
  toolbarHidden: false,
  isDisabled: false,
  allowAttach: true
};

const replacements = [
  {
    // маркдаун сам добавляет эти два символа (\n \u200B),
    // когда строка пустая и из-за этого ломается валидация
    from: /[\n\u200B]+$/,
    to: ''
  },
  {
    // https://www.markdownguide.org/basic-syntax/
    // при конвертации в MD нескольких нумерованных списков каждый новый список
    // продолжает нумерацию предыдущего, а не начинает с начала.
    // поэтому заменяем все номера на единицы чтобы при рендере MD нумеровал их сам
    pattern: /([0-9]\.\s.+(\n|$)){2,}/gim,
    from: /^[0-9]\.\s/gm,
    to: '1. '
  }
];

const normalize = str =>
  replacements.reduce((acc, { pattern, from, to }) => {
    if (!pattern) {
      return acc.replace(from, to);
    }

    return replace(acc, pattern, match => match.replace(from, to));
  }, str);

// Принимает value = { description, fileList, isFromEditor}
// isFromEditor - используется для очищения значения, должен быть false
export const MarkdownEditor = forwardRef(
  ({ value, onChange, isHtml, ...editorProps }, ref) => {
    const [state, setState] = useState(
      EditorState.createWithContent(
        convertFromRaw(markdownToDraft(value.description || ''))
      )
    );

    const onEditorStateChange = newState => {
      const withLinkEntities = extractLinksToEntities(newState);

      const mdData = convertToRaw(withLinkEntities.getCurrentContent());

      const links = getLinks((mdData || {}).entityMap);

      const descriptionMd = normalize(
        draftToMarkdown(convertToRaw(newState.getCurrentContent()))
      );

      onChange({
        ...value,
        links,
        description: isHtml ? marked(descriptionMd) : descriptionMd,
        isFromEditor: true
      });

      setState(newState);
    };

    const onChangeFileList = files =>
      onChange({ ...value, fileList: files, isFromEditor: true });

    useEffect(() => {
      if (!value.description && !value.isFromEditor) {
        setState(EditorState.createEmpty());
      }
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [value.description]);

    return (
      <Editor
        editorState={state}
        onEditorStateChange={onEditorStateChange}
        ref={ref}
        fileList={value.fileList}
        onChangeFileList={onChangeFileList}
        {...editorProps}
      />
    );
  }
);

export default MarkdownEditor;

import React from 'react';

export const AssetIcon = ({ style = { width: 150, height: 150 } }) => (
  <svg
    width="150"
    height="150"
    viewBox="0 0 150 150"
    fill="none"
    xmlns="http://www.w3.org/2000/svg"
    style={style}
  >
    <g style={{ mixBlendMode: 'multiply' }}>
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M81.6265 17.1505C99.1866 18.3565 117.261 22.9717 127.65 37.1871C139.244 53.0588 145.097 74.7035 136.703 92.4888C128.84 109.358 107.307 111.094 89.7955 117.462C70.5951 124.457 51.3786 139.043 32.9339 130.23C12.5596 120.485 -1.07687 97.2327 0.289992 74.6714C1.52821 54.2649 22.2885 42.8958 38.9803 31.0925C51.8127 21.9908 65.9316 16.057 81.6265 17.1505Z"
        fill="#EBF8F8"
      />
    </g>
    <path
      d="M5.01769 55.9535L12.6721 108.682C12.9719 110.728 13.9111 112.626 15.3554 114.105C16.7997 115.584 18.6749 116.568 20.7125 116.916C21.7354 117.077 22.7772 117.077 23.8 116.916L66.607 110.628C67.6377 110.481 68.6373 110.166 69.5658 109.695C71.4136 108.777 72.9236 107.298 73.8797 105.47C74.8358 103.642 75.1889 101.558 74.8886 99.5163L67.1216 46.8196C66.744 44.2501 65.3612 41.9358 63.2774 40.3857C61.1935 38.8357 58.5793 38.1768 56.0098 38.5541L13.2028 44.8256C10.6448 45.2221 8.34776 46.6153 6.81396 48.7005C5.28017 50.7858 4.63435 53.3935 5.01769 55.9535Z"
      fill="#F9716C"
    />
    <path
      d="M19.239 72.5592L58.2033 66.8402C60.9185 66.4417 62.7966 63.9176 62.398 61.2024L60.8708 50.797C60.4723 48.0819 57.9481 46.2038 55.2329 46.6024L16.2686 52.3214C13.5534 52.7199 11.6754 55.244 12.0739 57.9592L13.6012 68.3646C13.9997 71.0797 16.5239 72.9578 19.239 72.5592Z"
      fill="#2B2A36"
    />
    <path
      d="M17.7149 87.4442L24.3495 86.4704C25.0612 86.3659 25.5535 85.7043 25.449 84.9925L24.4752 78.3579C24.3708 77.6462 23.7091 77.1539 22.9973 77.2583L16.3628 78.2321C15.651 78.3366 15.1587 78.9983 15.2632 79.71L16.237 86.3446C16.3414 87.0564 17.0031 87.5487 17.7149 87.4442Z"
      fill="#2B2A36"
    />
    <path
      d="M32.5933 85.3555L39.232 84.3497C39.9441 84.2418 40.434 83.577 40.3261 82.8648L39.3203 76.2262C39.2123 75.514 38.5475 75.0241 37.8354 75.132L31.1967 76.1379C30.4846 76.2458 29.9947 76.9106 30.1026 77.6228L31.1085 84.2614C31.2164 84.9736 31.8812 85.4634 32.5933 85.3555Z"
      fill="#2B2A36"
    />
    <path
      d="M34.7124 97.6478L41.347 96.674C42.0588 96.5696 42.5511 95.9079 42.4466 95.1961L41.4728 88.5615C41.3683 87.8498 40.7066 87.3575 39.9949 87.462L33.3603 88.4358C32.6486 88.5402 32.1563 89.2019 32.2607 89.9136L33.2345 96.5482C33.339 97.26 34.0007 97.7523 34.7124 97.6478Z"
      fill="#2B2A36"
    />
    <path
      d="M36.1212 110.225L42.7598 109.219C43.472 109.111 43.9618 108.446 43.8539 107.734L42.8481 101.095C42.7402 100.383 42.0754 99.8933 41.3632 100.001L34.7246 101.007C34.0124 101.115 33.5225 101.78 33.6304 102.492L34.6363 109.131C34.7442 109.843 35.409 110.333 36.1212 110.225Z"
      fill="#2B2A36"
    />
    <path
      d="M48.2119 82.9667L54.8465 81.9929C55.5583 81.8884 56.0506 81.2267 55.9461 80.515L54.9723 73.8804C54.8678 73.1686 54.2062 72.6763 53.4944 72.7808L46.8598 73.7546C46.1481 73.8591 45.6558 74.5207 45.7602 75.2325L46.734 81.8671C46.8385 82.5788 47.5002 83.0711 48.2119 82.9667Z"
      fill="#2B2A36"
    />
    <path
      d="M50.0278 95.3929L56.6624 94.4191C57.3742 94.3147 57.8665 93.653 57.762 92.9412L56.7882 86.3067C56.6838 85.5949 56.0221 85.1026 55.3103 85.2071L48.6757 86.1809C47.964 86.2853 47.4717 86.947 47.5762 87.6588L48.55 94.2934C48.6544 95.0051 49.3161 95.4974 50.0278 95.3929Z"
      fill="#2B2A36"
    />
    <path
      d="M51.4381 108.06L58.0767 107.054C58.7889 106.946 59.2787 106.282 59.1708 105.569L58.165 98.9308C58.0571 98.2186 57.3923 97.7287 56.6801 97.8366L50.0415 98.8425C49.3293 98.9504 48.8394 99.6152 48.9473 100.327L49.9532 106.966C50.0611 107.678 50.7259 108.168 51.4381 108.06Z"
      fill="#2B2A36"
    />
    <path
      d="M112.694 63.0127L116.972 32.5236L57.1674 24.1455L52.7291 55.7924L52.7465 55.7861L45.7661 105.466L94.2013 112.397C97.2123 112.819 100.267 112.027 102.695 110.197C105.123 108.366 106.724 105.647 107.146 102.636L112.66 62.9998L112.694 63.0127Z"
      fill="white"
    />
    <path
      d="M21.4629 112.289L27.8748 111.348C28.6481 111.234 29.1829 110.515 29.0694 109.742L26.3045 90.9042C26.191 90.1309 25.4721 89.5961 24.6989 89.7096L18.287 90.6507C17.5138 90.7642 16.9789 91.483 17.0924 92.2563L19.8573 111.094C19.9708 111.867 20.6897 112.402 21.4629 112.289Z"
      fill="#2B2A36"
    />
    <path
      d="M22.9843 55.7048L18.259 56.3984C17.3363 56.5338 16.6982 57.3915 16.8336 58.3141L18.0596 66.667C18.195 67.5897 19.0527 68.2278 19.9754 68.0924L24.7007 67.3989C25.6234 67.2634 26.2615 66.4057 26.1261 65.4831L24.9001 57.1302C24.7647 56.2075 23.907 55.5694 22.9843 55.7048Z"
      stroke="white"
      strokeWidth="1.12565"
      strokeMiterlimit="10"
    />
    <path
      d="M107.372 100.963L107.147 102.571C106.724 105.582 105.123 108.302 102.696 110.132C100.268 111.963 97.2128 112.754 94.2019 112.332L45.7666 105.466C42.7776 105.035 40.0793 103.442 38.2577 101.033C36.4361 98.6248 35.6384 95.5947 36.0378 92.6013L36.681 88.1147L92.4169 95.9139L92.0149 98.8085C91.7291 100.845 92.2641 102.912 93.502 104.554C94.74 106.196 96.5796 107.279 98.616 107.564C100.653 107.85 102.719 107.315 104.361 106.077C106.003 104.839 107.086 103 107.372 100.963Z"
      fill="#D9D9D9"
    />
    <path
      d="M83.0097 48.7816L60.8182 45.6619C60.3963 45.6059 60.0137 45.3848 59.7545 45.0472C59.4953 44.7096 59.3805 44.283 59.4353 43.8609C59.4625 43.6456 59.5331 43.4382 59.6427 43.2509C59.7523 43.0637 59.8986 42.9006 60.0729 42.7714C60.2471 42.6422 60.4457 42.5496 60.6567 42.4991C60.8677 42.4487 61.0868 42.4415 61.3006 42.4779L83.4921 45.5976C83.9186 45.6552 84.3047 45.8798 84.5656 46.2221C84.8264 46.5644 84.9406 46.9963 84.8831 47.4228C84.8255 47.8492 84.6008 48.2354 84.2586 48.4963C83.9163 48.7571 83.4844 48.8713 83.0579 48.8137L83.0097 48.7816Z"
      fill="#2D9EA3"
    />
    <path
      d="M105.747 44.0695L61.8952 37.9267C61.684 37.8971 61.4808 37.8262 61.297 37.7181C61.1132 37.61 60.9525 37.4667 60.8241 37.2965C60.6957 37.1262 60.602 36.9324 60.5485 36.726C60.495 36.5196 60.4827 36.3046 60.5123 36.0935C60.5418 35.8823 60.6127 35.679 60.7208 35.4952C60.829 35.3114 60.9722 35.1507 61.1425 35.0223C61.3127 34.8939 61.5065 34.8003 61.713 34.7468C61.9194 34.6933 62.1343 34.6809 62.3455 34.7105L106.198 40.8534C106.624 40.9131 107.009 41.1397 107.269 41.4835C107.528 41.8273 107.64 42.2601 107.581 42.6866C107.521 43.113 107.294 43.4984 106.95 43.7577C106.607 44.0171 106.174 44.1292 105.747 44.0695Z"
      fill="#2D9EA3"
    />
    <path
      d="M101.989 70.5221L58.1369 64.3793C57.9257 64.3497 57.7225 64.2789 57.5387 64.1707C57.3549 64.0626 57.1942 63.9193 57.0658 63.7491C56.9374 63.5789 56.8437 63.385 56.7902 63.1786C56.7367 62.9722 56.7244 62.7573 56.754 62.5461C56.7835 62.3349 56.8544 62.1316 56.9625 61.9478C57.0707 61.7641 57.2139 61.6034 57.3842 61.4749C57.5544 61.3465 57.7482 61.2529 57.9547 61.1994C58.1611 61.1459 58.376 61.1336 58.5872 61.1631L102.439 67.306C102.866 67.3657 103.251 67.5924 103.511 67.9362C103.77 68.28 103.882 68.7127 103.822 69.1392C103.763 69.5657 103.536 69.951 103.192 70.2103C102.848 70.4697 102.416 70.5819 101.989 70.5221Z"
      fill="#D9D9D9"
    />
    <path
      d="M100.184 51.1934L91.9665 50.0356C91.54 49.9758 91.1547 49.7492 90.8954 49.4054C90.636 49.0616 90.5239 48.6288 90.5836 48.2023C90.6433 47.7759 90.87 47.3906 91.2137 47.1312C91.5575 46.8718 91.9903 46.7597 92.4168 46.8194L100.618 47.9611C101.044 48.0187 101.431 48.2433 101.691 48.5856C101.952 48.9279 102.067 49.3598 102.009 49.7863C101.951 50.2128 101.727 50.5989 101.384 50.8598C101.042 51.1207 100.61 51.2349 100.184 51.1773V51.1934Z"
      fill="#2D9EA3"
    />
    <path
      d="M71.7208 78.9165C71.4954 80.5458 70.7946 82.0724 69.7059 83.3053C68.6173 84.5383 67.1892 85.4228 65.6004 85.8482C64.0116 86.2736 62.3327 86.221 60.7736 85.697C59.2145 85.1729 57.8446 84.2008 56.8353 82.9021C55.826 81.6035 55.2221 80.036 55.0991 78.3958C54.9761 76.7557 55.3396 75.1157 56.144 73.681C56.9484 72.2464 58.1581 71.0809 59.6216 70.3302C61.0851 69.5796 62.7374 69.2773 64.3719 69.461L63.7447 73.9154C62.9934 73.8565 62.2412 74.0189 61.581 74.3825C60.9209 74.7462 60.3816 75.2952 60.0299 75.9617C59.6781 76.6283 59.5292 77.3833 59.6016 78.1335C59.6739 78.8837 59.9643 79.5963 60.437 80.1834C60.9097 80.7704 61.5439 81.2062 62.2614 81.437C62.9788 81.6678 63.7482 81.6834 64.4745 81.482C65.2008 81.2806 65.8522 80.8709 66.3483 80.3036C66.8445 79.7362 67.1636 79.036 67.2664 78.2894C67.3746 77.4632 67.2169 76.624 66.8162 75.8934L70.4343 73.1757C71.5444 74.8703 72.0015 76.9103 71.7208 78.9165Z"
      fill="#FFE181"
    />
    <path
      d="M70.4342 73.1756L66.8161 75.8933C66.5309 75.3643 66.1241 74.9105 65.6293 74.5694C65.1344 74.2284 64.5656 74.0097 63.9698 73.9314C63.8956 73.9153 63.8188 73.9153 63.7446 73.9314L64.3718 69.4771H64.5969C65.7773 69.6419 66.9088 70.0573 67.9155 70.6951C68.9223 71.333 69.781 72.1787 70.4342 73.1756Z"
      fill="#F9716C"
    />
    <path
      d="M67.2663 78.2895C67.1662 79.0412 66.8478 79.7469 66.3505 80.3194C65.8532 80.8918 65.1989 81.3058 64.4686 81.51C63.7384 81.7143 62.9643 81.6998 62.2422 81.4684C61.5201 81.237 60.8817 80.7989 60.4062 80.2082C59.9306 79.6176 59.6388 78.9005 59.5668 78.1456C59.4948 77.3908 59.6459 76.6314 60.0013 75.9615C60.3567 75.2917 60.9007 74.7408 61.5661 74.3771C62.2315 74.0135 62.9889 73.853 63.7446 73.9156C63.8188 73.8995 63.8956 73.8995 63.9698 73.9156C64.5656 73.9938 65.1344 74.2125 65.6293 74.5536C66.1241 74.8947 66.5309 75.3484 66.8161 75.8774C67.2202 76.6126 67.378 77.458 67.2663 78.2895Z"
      fill="#FDA402"
    />
    <path
      d="M100.361 80.4282L78.153 77.3085C77.9418 77.2789 77.7386 77.2081 77.5548 77.0999C77.371 76.9918 77.2103 76.8485 77.0819 76.6783C76.9535 76.5081 76.8598 76.3142 76.8063 76.1078C76.7528 75.9014 76.7405 75.6865 76.7701 75.4753C76.7996 75.2641 76.8705 75.0608 76.9786 74.877C77.0868 74.6933 77.23 74.5326 77.4003 74.4041C77.5705 74.2757 77.7644 74.1821 77.9708 74.1286C78.1772 74.0751 78.3921 74.0628 78.6033 74.0923L100.795 77.212C101.214 77.2719 101.592 77.4946 101.848 77.8318C102.104 78.1689 102.217 78.5934 102.162 79.0131C102.138 79.2248 102.072 79.4298 101.968 79.6159C101.864 79.8021 101.725 79.9657 101.557 80.0974C101.39 80.2291 101.197 80.3261 100.992 80.3829C100.787 80.4397 100.572 80.4551 100.361 80.4282Z"
      fill="#D9D9D9"
    />
    <path
      d="M99.219 88.5171L77.0275 85.4135C76.8164 85.3839 76.6131 85.313 76.4293 85.2049C76.2455 85.0968 76.0848 84.9535 75.9564 84.7833C75.828 84.6131 75.7343 84.4192 75.6808 84.2128C75.6273 84.0064 75.615 83.7914 75.6446 83.5803C75.6742 83.3691 75.745 83.1658 75.8532 82.982C75.9613 82.7982 76.1046 82.6375 76.2748 82.5091C76.445 82.3807 76.6389 82.2871 76.8453 82.2336C77.0517 82.1801 77.2666 82.1678 77.4778 82.1973L99.6692 85.3009C100.091 85.3607 100.473 85.5857 100.729 85.9264C100.985 86.2672 101.096 86.6958 101.036 87.118C101.01 87.3299 100.943 87.5346 100.837 87.7201C100.731 87.9056 100.59 88.0682 100.421 88.1984C100.252 88.3286 100.058 88.4239 99.8519 88.4786C99.6456 88.5333 99.4304 88.5464 99.219 88.5171Z"
      fill="#D9D9D9"
    />
    <path
      d="M67.2663 78.2895C67.1662 79.0412 66.8478 79.7469 66.3505 80.3194C65.8532 80.8918 65.1989 81.3058 64.4686 81.51C63.7384 81.7143 62.9643 81.6998 62.2422 81.4684C61.5201 81.237 60.8817 80.7989 60.4062 80.2082C59.9306 79.6176 59.6388 78.9005 59.5668 78.1456C59.4948 77.3908 59.6459 76.6314 60.0013 75.9615C60.3567 75.2917 60.9007 74.7408 61.5661 74.3771C62.2315 74.0135 62.9889 73.853 63.7446 73.9156C63.8188 73.8995 63.8956 73.8995 63.9698 73.9156C64.5656 73.9938 65.1344 74.2125 65.6293 74.5536C66.1241 74.8947 66.5309 75.3484 66.8161 75.8774C67.2202 76.6126 67.378 77.458 67.2663 78.2895Z"
      fill="white"
    />
    <ellipse
      cx="136.875"
      cy="87.6395"
      rx="11.5179"
      ry="12.6395"
      fill="#FEC458"
    />
    <ellipse
      cx="127.983"
      cy="48.9985"
      rx="8.59703"
      ry="9.43427"
      transform="rotate(-14.2317 127.983 48.9985)"
      fill="#FEC458"
    />
    <ellipse cx="136.875" cy="87.3396" rx="7.511" ry="8.24247" fill="#FFE181" />
    <ellipse
      cx="127.928"
      cy="48.7815"
      rx="5.60628"
      ry="6.15225"
      transform="rotate(-14.2317 127.928 48.7815)"
      fill="#FFE181"
    />
  </svg>
);

export default AssetIcon;

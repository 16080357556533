import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import { Divider, Spin, Tooltip } from 'antd';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';

import { SELECTED_ASSET, TYPE_ASSET, CALENDAR, VIDEOS } from 'constants/index';
import { HEADER_HEIGHT } from 'constants/styles';

import Drawer from 'components/common/drawer';
import Typography from 'components/common/typography';
import useAsset from 'components/assets-view/hooks/use-asset';
import BackBtn from 'components/common/back-btn';
import Actions from 'components/assets-view/actions';
import { renderDescriptions } from 'components/common/comments/converters';
import UserRole from 'components/tasks-view/view/drawers/details/user-role';
import EmployeeModal from 'components/common/modals/employee';
import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Empty from 'components/assets-view/views/all/list/empty';
import { TagList } from 'components/common/tags';
import VideoBtn from 'components/common/video-btn';
import Chat from 'components/common/chat';
import useAssetChat from 'components/common/chat/entity-hooks/use-asset-chat';
import Reminder from 'components/common/reminder';

import { partialUpdate } from 'store/assets';

import useRoutesService from 'services/routes';
import { useQueryParam } from 'hooks';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import InfoButtons from './info-buttons';
import CategoryChangeModal from '../../modals/category-change';

import styles from './details.module.scss';

const { Paragraph, Title, Text } = Typography;

const AssetDetailsDrawer = ({ visible, onClose }) => {
  const dispatch = useDispatch();

  const { t } = useTranslation([
    'Asset',
    'Assets',
    'Common',
    'AddAsset',
    'ChangeAssetResponsible'
  ]);

  const routes = useRoutesService();
  const routesWithUrl = useRoutesService({ returnUrl: true });

  const [afterVisibleChange, setAfterVisibleChange] = useState(false);
  const [visibleResponsibleEditor, setVisibleResponsibleEditor] = useState(
    false
  );
  const [visibleCategoryModal, setVisibleCategoryModal] = useState(false);

  const id = +useQueryParam(SELECTED_ASSET);

  const { asset, hasError, isLoading } = useAsset({
    id,
    afterVisibleChange
  });

  const {
    renderedMessages,
    messagesRef,
    hasMessages,
    isLoading: isLoadingMessages,
    toBottom,
    renderedControls,
    isJoined,
    hasMore,
    loadMore
  } = useAssetChat({ asset });

  const changeResponsible = async responsible => {
    await dispatch(
      partialUpdate({
        id,
        asset: {
          responsible: responsible.value
        }
      })
    );

    showNoticeMessage({ number: NOTICE_NUMBER.changeOfResponsible });
  };

  if (!visible) {
    return <div />;
  }

  return (
    <>
      <Drawer
        visible={visible}
        isContent
        fullWidth
        style={{
          height: `calc(100% - ${HEADER_HEIGHT}px)`
        }}
        bodyStyle={{ flexDirection: 'row' }}
        documentTitleProps={{
          title: asset.title,
          prefix: ''
        }}
        destroyOnClose
        afterVisibleChange={setAfterVisibleChange}
        onClose={onClose}
      >
        {isLoading || !afterVisibleChange ? (
          <Spin />
        ) : (
          <>
            <div
              style={{
                flexShrink: 0,
                width: 417,
                padding: '24px 16px 24px 32px',
                overflow: 'auto',
                boxShadow: '1.5px 0px 0px #d8d8d8'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  marginBottom: 20
                }}
              >
                <BackBtn style={{ paddingLeft: 0 }}>{t('BackBtn')}</BackBtn>

                <div
                  style={{
                    marginLeft: 'auto',
                    display: 'flex',
                    alignItems: 'center'
                  }}
                >
                  <Button
                    type="link"
                    style={{
                      hight: 'auto',
                      padding: 0
                    }}
                    onClick={() => routes.toAssets({ view: CALENDAR, asset })}
                  >
                    <Icon type="schedule" />
                  </Button>

                  <Actions
                    isFromDetails
                    asset={asset}
                    style={{ marginLeft: 16 }}
                  />
                </div>
              </div>

              <Title level={2} className={styles.title}>
                {asset.title}
              </Title>

              {asset.fullPath && (
                <Text size="small" color="black-55">
                  {asset.fullPath}
                </Text>
              )}

              {(asset.permissions || {}).viewAssetComment && (
                <div className={styles.infoButtonsWrap}>
                  <InfoButtons asset={asset} />
                </div>
              )}

              {asset.category && (
                <div className={styles.handbook}>
                  <div className={styles.wrapper}>
                    <Icon type="book" color="black-55" size={16} />

                    <Typography.Text
                      color="black-55"
                      style={{ marginLeft: 8, whiteSpace: 'pre-wrap' }}
                    >
                      {t('Journal')}{' '}
                    </Typography.Text>

                    <Link
                      to={routesWithUrl.toCategory({ id: asset.category.id })}
                      className={styles.link}
                    >
                      <Typography.Text
                        color="brand"
                        style={{
                          marginRight: 8,
                          whiteSpace: 'nowrap',
                          display: 'block'
                        }}
                        ellipsis
                      >
                        <Tooltip
                          title={asset.category.title}
                          mouseEnterDelay={0.5}
                        >
                          {asset.category.title}
                        </Tooltip>
                      </Typography.Text>
                    </Link>
                    {asset.permissions.changeAssetCategory && (
                      <Button
                        type="link"
                        style={{
                          padding: 0,
                          fontWeight: 400,
                          marginLeft: 'auto'
                        }}
                        onClick={() => setVisibleCategoryModal(true)}
                        data-testid="project-edit-btn"
                      >
                        {t('Edit', { ns: 'Common' })}
                      </Button>
                    )}
                  </div>
                </div>
              )}

              <div className={styles.reminder}>
                <Reminder
                  entityType={TYPE_ASSET}
                  entityId={id}
                  textReminder={asset.title}
                />
              </div>

              {!!(asset.description || []).length && (
                <Paragraph style={{ marginBottom: 20 }}>
                  {renderDescriptions(asset.description)}
                </Paragraph>
              )}

              <Divider />

              <UserRole
                label={t('Responsible')}
                labelProps={{
                  color: 'black-55',
                  style: {
                    marginBottom: 8,
                    textTransform: 'uppercase',
                    fontWeight: 700
                  }
                }}
                user={asset.responsible}
                stylesButton={{ fontWeight: 400 }}
                allowChange={(asset.permissions || {}).changeAssetResponsible}
                onClickEditor={() => setVisibleResponsibleEditor(true)}
              />

              <Divider />

              <div className={styles.tagsHeader}>
                <Paragraph color="black-55" className={styles.tagsTitle}>
                  {t('Tags')}
                </Paragraph>

                <VideoBtn slug={VIDEOS.workWithTags} />
              </div>

              <TagList
                entityType={TYPE_ASSET}
                entityId={asset.id}
                needFetchTags
                className={styles.tagList}
              />

              {/* TODO: create common component */}

              {asset.dateEnd && (
                <div style={{ marginBottom: 20 }}>
                  <Paragraph
                    color="black-55"
                    style={{
                      marginBottom: 4,
                      textTransform: 'uppercase',
                      fontWeight: 700
                    }}
                  >
                    {t('ExpirationDate')}
                  </Paragraph>
                  <Text>{asset.dateEnd}</Text>
                </div>
              )}

              {asset.inventoryNumber && (
                <div style={{ marginBottom: 20 }}>
                  <Paragraph
                    color="black-55"
                    style={{
                      marginBottom: 4,
                      textTransform: 'uppercase',
                      fontWeight: 700
                    }}
                  >
                    {t('Number')}
                  </Paragraph>
                  <Text>{asset.inventoryNumber}</Text>
                </div>
              )}

              {asset.location && (
                <div>
                  <Paragraph
                    color="black-55"
                    style={{
                      marginBottom: 4,
                      textTransform: 'uppercase',
                      fontWeight: 700
                    }}
                  >
                    {t('Address')}
                  </Paragraph>
                  <Text>{asset.location}</Text>
                </div>
              )}
            </div>

            <div className={styles.chat}>
              {asset.chats && (asset.permissions || {}).viewAssetComment ? (
                <Chat
                  messagesRef={messagesRef}
                  isLoading={isLoadingMessages}
                  hasMessages={hasMessages}
                  messages={renderedMessages}
                  toBottom={toBottom}
                  controls={renderedControls}
                  isEntityLoading={isLoading || !isJoined}
                  hasMore={hasMore}
                  loadMore={loadMore}
                />
              ) : (
                <Title
                  level={2}
                  style={{
                    display: 'flex',
                    alignItems: 'center',
                    justifyContent: 'center',
                    height: '100%'
                  }}
                >
                  {t('NoPermsForViewMessages', { ns: 'Common' })}
                </Title>
              )}
            </div>

            {hasError && (
              <div
                style={{
                  position: 'absolute',
                  display: 'flex',
                  justifyContent: 'center',
                  width: '100%',
                  height: '100%',
                  backgroundColor: 'white',
                  zIndex: 2
                }}
              >
                <Empty description={t('AssetNotFound', { ns: 'Assets' })} />
              </div>
            )}
          </>
        )}
      </Drawer>

      <EmployeeModal
        title={t('ChangeAssetResponsibleHeading', {
          ns: 'ChangeAssetResponsible'
        })}
        btnText={t('SaveBtn', { ns: 'AddAsset' })}
        visible={visibleResponsibleEditor}
        defaultValue={asset.responsible}
        onSubmit={changeResponsible}
        onClose={() => setVisibleResponsibleEditor(false)}
      />

      <CategoryChangeModal
        onClose={() => setVisibleCategoryModal(false)}
        visible={visibleCategoryModal}
        isEditor
        values={asset}
      />
    </>
  );
};

export default AssetDetailsDrawer;

import { useSelector } from 'react-redux';
import moment from 'moment';

import {
  INCOMING,
  ORDER_STATUS_STATES,
  LEADS,
  DATE_TIME_FORMAT
} from 'constants/index';

import { getWorkspaceId } from 'store/workspace';

import useRoutesService from 'services/routes';

export const useLinkByUser = ({ user, contact }) => {
  const routes = useRoutesService({ returnUrl: true });
  const currentWorkspaceId = useSelector(getWorkspaceId);

  if (currentWorkspaceId === (user || {}).workspaceId) {
    return routes.toEmployee((user || {}).id);
  }

  if ((contact || {}).id) {
    return routes.toContact({ id: contact.id });
  }

  return undefined;
};

export const getContact = (contact, isToMarket) =>
  !isToMarket && !contact.vatNumber ? contact.id : null;

export const getWorkspace = (contact, isToMarket) =>
  !isToMarket && contact.vatNumber ? contact.id : undefined;

// V2

export const getOrderStatusStates = (kind, states = ORDER_STATUS_STATES) => {
  const result = new Map(states);

  if (kind === INCOMING) {
    result.set(LEADS, {
      ...result.get(LEADS),
      label: 'NegotiationsOrderStatus'
    });
  }

  return result;
};

export const getFormattedDate = date => moment(date).format(DATE_TIME_FORMAT);

export const getOrderStatusesInitialPageData = () => {
  const result = {};

  ORDER_STATUS_STATES.forEach((_, key) => {
    result[key] = {
      number: 0,
      totalElements: 0,
      totalPages: 0
    };
  });

  return result;
};

export default useLinkByUser;

import axios from 'axios';
import { LANGUAGE } from 'i18n-config';

import { TOKEN_FILES } from 'constants/api';

import { config } from 'config';

import commonApi from '../../../api';
import { parseValueStorage } from '../use-local-storage';

export const API_ROOT = `https://${config.REACT_APP_API_URL_FILES}`;
export const FILE_ID = 'file_id';

export const api = axios.create({ baseURL: API_ROOT });

const { CancelToken } = axios;
let source = CancelToken.source();

api.interceptors.request.use(async config => {
  const token = parseValueStorage(localStorage.getItem(TOKEN_FILES));
  const language = localStorage.getItem(LANGUAGE);

  // eslint-disable-next-line
  config.headers.authorization = `Bearer ${token}`;

  if (language) {
    config.headers['Accept-Language'] = language;
  }

  return config;
});

const getPercent = (loadedSize, fileSize) => (loadedSize / fileSize) * 100;

export const useApi = () => {
  const getPassword = workspaceId => {
    resetCancledToke();

    return commonApi.attachments.password(workspaceId);
  };

  const auth = async (workspaceId, userId) => {
    const { password } = await getPassword(workspaceId);

    const { data } = await api.post('/api/v1/auth', {
      user_id: userId,
      password
    });

    return data.access_token;
  };

  const splitFile = async ({
    file,
    initialOffset = 0,
    upload,
    onSuccess,
    onProgress
  }) => {
    const chunkSize = 1024 * 1024; // 1MB Chunk size
    const fileSize = file.size;
    let offset = initialOffset;
    let loadedSize = 0;
    let res;

    const reader = new FileReader();
    reader.onload = async e => {
      const blob = new Blob([new Uint8Array(e.target.result)], {
        type: file.type
      });

      while (offset < fileSize) {
        const currentFilePart = blob.slice(offset, offset + chunkSize);
        // eslint-disable-next-line no-await-in-loop
        res = await upload({ part: currentFilePart, offset, chunkSize });
        loadedSize += currentFilePart.size;
        onProgress({ percent: getPercent(loadedSize, fileSize) }, file);
        offset += chunkSize;
      }
      onSuccess({ id: res }, file);
    };
    reader.readAsArrayBuffer(file);
  };

  const uploadFile = file =>
    api
      .post('/api/v1/upload', {
        title: file.name,
        size: file.size,
        mimetype: file.type
      })
      .then(({ data }) => data);

  const uploadPart = ({ file_id: fileId, offset, part }) =>
    api
      .post(`/api/v1/upload/${fileId}/${offset}`, part, {
        cancelToken: source.token
      })
      .then(({ data }) => data[FILE_ID]);

  const cancelUpload = (file, callback = () => {}) => {
    if (file.percent !== undefined && file.percent !== 100) {
      source.cancel('Operation canceled by the user.');
    }

    callback(file);
  };

  const createFile = async ({ title, type }) =>
    api
      .post('/api/v1/files/create/', {
        title,
        type
      })
      .then(({ data }) => data);

  const renameFile = async ({ uuid, name }) =>
    api
      .put(`/api/v1/files/${uuid}/rename`, {
        name
      })
      .then(({ data }) => data);

  const resetCancledToke = () => {
    source = CancelToken.source();
  };

  return {
    getPassword,
    auth,
    splitFile,
    uploadFile,
    uploadPart,
    cancelUpload,
    createFile,
    renameFile
  };
};

export default useApi;

import React, { useCallback, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { useDispatch } from 'react-redux';
import axios from 'axios';
import JSZip from 'jszip';

import Button from 'components/common/button';

import { getFileUrls } from 'store/attachments';

import downloadFile from 'hooks/common/use-file-upload/download-file';

import styles from './download-all-files.module.scss';

const DownloadAllFiles = ({ fileList }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Common');

  const download = useCallback(async () => {
    try {
      setIsLoading(true);

      const filesIds = fileList.map(f => f.fileId);

      const { urls } = await dispatch(getFileUrls({ filesIds }));

      const files = await Promise.all(
        urls.map(({ url, title }) =>
          axios
            .get(url, { responseType: 'blob' })
            .then(({ data }) => ({ data, title }))
        )
      );

      const zip = new JSZip();
      files.forEach(({ title, data }) => {
        zip.file(title, data);
      });

      const zipFile = await zip.generateAsync({
        type: 'blob',
        streamFiles: true
      });
      const zipFileName = `${t('Archive')}.zip`;

      downloadFile({
        url: URL.createObjectURL(zipFile),
        fileName: zipFileName
      });
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, fileList, t]);

  return (
    <Button
      type="link"
      loading={isLoading}
      className={styles.root}
      onClick={download}
    >
      {t('DownloadAllFiles')}
    </Button>
  );
};

export default DownloadAllFiles;

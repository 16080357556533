import axios from 'axios';
import { LANGUAGE } from 'i18n-config';

import { config } from 'config';

const API_MESSENGER = `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_SOCKET_API_DIALOGUES_URL}`;

const api = axios.create({
  baseURL: API_MESSENGER
});

api.interceptors.request.use(
  configuration => {
    const language = localStorage.getItem(LANGUAGE);

    if (language) {
      configuration.headers['Accept-Language'] = language;
    }

    return configuration;
  },
  error => Promise.reject(error)
);

// data: [{entityid, entityType, chats: [{appId, roomUuid}], limit, offset}]
export default () => ({
  operatorChats: ({ data, params }) =>
    api.post(
      `/operator/chats`,
      {
        data
      },
      {
        params: {
          access_token: params.accessToken,
          channel_id: params.onlineChannelUuid,
          readable: params.readable,
          with_source: params.withSource,
          with_parent: params.withParent,
          with_aggregation: params.withAggregation
        }
      }
    ),

  fetchUnreadedNotificationsCount: ({ roomUuids, params }) =>
    api.post(
      `/operator/chats/unread-notifications-count`,
      {
        data: roomUuids
      },
      {
        params: {
          access_token: params.accessToken
        }
      }
    )
});

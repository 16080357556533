import React from 'react';
import { Typography } from 'antd';
import classnames from 'classnames';
import PropTypes from 'prop-types';

import Avatar from 'components/common/avatar';

import { getFIO } from 'utils/get-fio';

import Option from '../option';

import styles from './workspace-option.module.scss';

const { Paragraph } = Typography;

export const WorkspaceOption = ({
  option,
  className,
  withWatNumber,
  ...props
}) => {
  const vatNumber = !option.vatNumber
    ? option.creatorFullName
    : option.vatNumber || '';
  const title = withWatNumber ? `${option.title} ${vatNumber}` : option.title;

  return (
    <Option
      className={classnames(className, styles.root)}
      contentClassName={styles.contentWrap}
      title={title}
      {...props}
    >
      <Avatar
        src={(option.logoFile || {}).url}
        size={23}
        style={{ marginRight: 10, flexShrink: 0 }}
      >
        {getFIO({ lastName: option.title })}
      </Avatar>

      <div className={styles.content}>
        <Paragraph ellipsis={{ rows: 1 }} style={{ marginBottom: 0 }}>
          {option.title}
        </Paragraph>

        {withWatNumber && (
          <Paragraph
            ellipsis={{ rows: 1 }}
            style={{ marginBottom: 0 }}
            className={styles.extra}
          >
            {vatNumber}
          </Paragraph>
        )}
      </div>
    </Option>
  );
};

WorkspaceOption.propTypes = {
  option: PropTypes.shape({
    avatarFile: PropTypes.object,
    firstName: PropTypes.string,
    lastName: PropTypes.string
  }).isRequired,
  withWatNumber: PropTypes.bool
};

WorkspaceOption.defaultProps = {
  withWatNumber: true
};

export default WorkspaceOption;

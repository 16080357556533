import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import CancellationModal from 'components/common/modals/cancellation';

import { fetchOrderStatus, cancelOrderStatus } from 'store/order-statuses';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const PRICE = 'WrongCostRadio';
const TERM = 'WrongDeadlinesRadio';
const CONDITION = 'ViolationOfTermsRadio';
const NON_TARGETED = 'NotTargetRadio';
const IDLE = 'NoConnectRadio';
const SPAM = 'SpamRadio';

const OPTION_NAMESPACE = 'CancelOrder';

const OPTIONS = [
  {
    label: PRICE,
    ns: OPTION_NAMESPACE,
    value: PRICE
  },
  {
    label: TERM,
    ns: OPTION_NAMESPACE,
    value: TERM
  },
  {
    label: CONDITION,
    ns: OPTION_NAMESPACE,
    value: CONDITION
  },
  {
    label: NON_TARGETED,
    ns: OPTION_NAMESPACE,
    value: NON_TARGETED
  },
  {
    label: IDLE,
    ns: OPTION_NAMESPACE,
    value: IDLE
  },
  {
    label: SPAM,
    ns: OPTION_NAMESPACE,
    value: SPAM
  }
];

const OrderCancellationModal = ({
  visible,
  orderStatusId,
  onClose,
  resetReordering
}) => {
  const dispatch = useDispatch();

  const { t } = useTranslation('CancelOrder');

  const onSubmit = useCallback(
    async reason => {
      await dispatch(cancelOrderStatus({ id: orderStatusId, reason }));
      const fetchedOrderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      showNoticeMessage({
        number: fetchedOrderStatus.isLocked
          ? NOTICE_NUMBER.orderStatusRequestToContractorSended
          : NOTICE_NUMBER.orderStatusStateChanged
      });
    },
    [dispatch, orderStatusId]
  );

  const closeModal = () => {
    onClose();
    resetReordering();
  };

  return (
    <CancellationModal
      description={t('CancelOrderReasonDesc')}
      visible={visible}
      options={OPTIONS}
      onSubmit={onSubmit}
      onClose={closeModal}
      callback={onClose}
      dataTestId="orders-cancellation-modal"
    />
  );
};

OrderCancellationModal.propTypes = {
  visible: PropTypes.bool,
  orderStatusId: PropTypes.string,
  onClose: PropTypes.func.isRequired,
  resetReordering: PropTypes.func
};

OrderCancellationModal.defaultProps = {
  visible: false,
  orderStatusId: null,
  resetReordering: () => {}
};

export default OrderCancellationModal;

import React from 'react';
import PropTypes from 'prop-types';
import { Alert, Spin } from 'antd';
import classnames from 'classnames';
import { Translation } from 'react-i18next';

import Button from 'components/common/button';
import Typography from 'components/common/typography';
import Attachments from 'components/common/attachments';
import { AttachmentSkeleton } from 'components/common/skeletons';
import StateChangingModalsProvider from 'components/orders-view/providers/state-changing-modals';

import useActions from './use-actions';
import Condition from './condition';

import styles from './status-actions.module.scss';

const StatusActions = ({ orderStatus }) => {
  const { actions, isLoading, accepted, isEmpty, modalData } = useActions({
    orderStatus
  });

  if (isEmpty) {
    return null;
  }

  return (
    <StateChangingModalsProvider
      orderStatus={orderStatus}
      modalData={modalData}
    >
      <Spin spinning={isLoading} wrapperClassName={styles.root}>
        {accepted.title && (
          <Alert
            type="warning"
            className={styles.alert}
            message={
              <>
                <div className={styles.head}>
                  <Typography.Text>{accepted.title}</Typography.Text>

                  {accepted.condition && (
                    <Condition data={accepted.condition} />
                  )}
                </div>

                {!!(accepted.documentList || []).length && (
                  <>
                    {(accepted.acceptedDocuments || []).length ? (
                      <div className={styles.documents}>
                        <Attachments
                          fileList={accepted.acceptedDocuments}
                          showDownloadAllButton
                        />
                      </div>
                    ) : (
                      <div className={styles.documents}>
                        {accepted.documentList.map(doc => (
                          <AttachmentSkeleton
                            key={`attachment-skeleton-${doc}`}
                          />
                        ))}
                      </div>
                    )}
                  </>
                )}
              </>
            }
          />
        )}

        {!!actions.length && (
          <div
            className={classnames(styles.buttons, {
              [styles.odd]: actions.length % 2 !== 0
            })}
          >
            {actions.map((a, index) => (
              <Button
                key={`action-button-${a.label}-${index}`}
                className={styles.button}
                {...a.buttonProps}
                onClick={a.onClick}
              >
                <Translation ns={a.ns}>{t => t(a.label)}</Translation>
              </Button>
            ))}
          </div>
        )}
      </Spin>
    </StateChangingModalsProvider>
  );
};

StatusActions.propTypes = {
  orderStatus: PropTypes.shape({})
};

StatusActions.defaultProps = {
  orderStatus: {}
};

export default StatusActions;

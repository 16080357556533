import React from 'react';
import PropTypes from 'prop-types';
import { FormProvider, useForm, useWatch } from 'react-hook-form';
import moment from 'moment';
import { useSelector } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { SPEC_KIND_CUSTOM } from 'constants/index';

import {
  FormAttachDocuments,
  FormCurrencySelect,
  FormDatePicker,
  FormInput,
  FormInputNumber
} from 'components/common/hook-form';
import Button from 'components/common/button';
import ActionsDropdown from 'components/common/actions';
import Icon from 'components/common/icon';

import { getUserEmployee } from 'store/workspace';

import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';
import useUploadingFiles from 'hooks/common/use-file-upload/use-uploading-files';
import useMinMaxTime from 'hooks/common/use-min-max-time';

import useAdditionalFields from './use-additional-fields';

import styles from './custom-specification.module.scss';

const CustomSpecificationForm = ({
  defaultValues,
  isLoading,
  onSubmit,
  specButtonProps
}) => {
  const employee = useSelector(getUserEmployee);

  const { t } = useTranslation(['OrderConditions', 'Common', 'Errors']);

  const methods = useForm({
    defaultValues: {
      title: defaultValues.title,
      content: {
        description: defaultValues.content,
        fileList: (defaultValues.documentList || []).filter(file =>
          defaultValues.fileList.includes(file.fileId)
        )
      },
      location: defaultValues.location,
      locationExact: defaultValues.locationExact,
      price:
        defaultValues.price === null ? undefined : `${defaultValues.price}`,
      currency: {
        label: defaultValues.currency || employee.currency,
        value: defaultValues.currency || employee.currency
      },
      deadlineAt: moment(defaultValues.deadlineAt).toDate(),
      startAt: defaultValues.startAt
        ? moment(defaultValues.startAt).toDate()
        : null,
      documentList: defaultValues.documentList
    }
  });

  const fieldValues = useWatch({ control: methods.control });
  const {
    startAt: startAtField,
    deadlineAt: deadlineAtField,
    documentList: documentListField
  } = fieldValues;

  const isUploadingFiles = useUploadingFiles(documentListField);

  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate: startAtField,
    endDate: deadlineAtField
  });

  const { activeFields, allowedFields } = useAdditionalFields({
    defaultValues
  });

  const transformSubmitedValues = values => ({
    ...values,
    items: [],
    withTax: false,
    content: values.content.description,
    fileList: getFileIdsV2(values.content.fileList),
    currency: values.currency.value,
    measurementUnit: '',
    documentList: getFileIdsV2(values.documentList),
    kind: SPEC_KIND_CUSTOM,
    contractDate: defaultValues.createdAt,
    contractNumber: defaultValues.orderId
  });

  return (
    <FormProvider {...methods}>
      <form
        className={styles.root}
        onSubmit={methods.handleSubmit(values =>
          onSubmit(transformSubmitedValues(values))
        )}
      >
        <FormInput
          label={t('ServiceName')}
          placeholder={t('EnterServiceName')}
          name="title"
          rules={{ required: t('RequiredField', { ns: 'Errors' }) }}
        />

        <FormAttachDocuments label={t('AttachDocsBtn')} name="documentList" />

        <div className={styles.priceWrap}>
          <FormInputNumber
            name="price"
            label={t('TotalOrder')}
            placeholder="000"
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              min: {
                value: 1,
                message: t('NotNullPrice', { ns: 'Errors' })
              }
            }}
          />

          <FormCurrencySelect name="currency" label={t('Currency')} />
        </div>

        <div className={styles.dateWrap}>
          <FormDatePicker
            name="startAt"
            label={t('OrderStartDate')}
            wrapperClassname={styles.datePicker}
            minTime={minTimeStart}
            maxTime={maxTimeStart}
            minDate={new Date()}
            maxDate={deadlineAtField}
            rules={{
              required: t('RequiredField', { ns: 'Errors' })
            }}
            placeholderText={t('ChooseDate')}
          />

          <FormDatePicker
            name="deadlineAt"
            label={t('OrderDueDate')}
            minDate={startAtField || new Date()}
            minTime={minTimeEnd}
            maxTime={maxTimeEnd}
            wrapperClassname={styles.datePicker}
            rules={{
              required: t('RequiredField', { ns: 'Errors' }),
              validate: value =>
                !moment(startAtField).isAfter(value) ||
                t('DateStartCannotBeAfterDateEnd', { ns: 'Errors' })
            }}
            placeholderText={t('ChooseDate')}
          />
        </div>

        {activeFields.map(f => f.component)}

        {!!allowedFields.length && (
          <div className={styles.btnWithPlus}>
            <ActionsDropdown actions={allowedFields} placement="bottomLeft">
              <Button type="link" size="large">
                <Icon type="plus" />
                {t('ShowAllFieldsBtn')}
              </Button>
            </ActionsDropdown>
          </div>
        )}

        <div className={styles.submitWrap}>
          {specButtonProps.allow && (
            <Button
              type="link"
              className={styles.systemSpecBtn}
              onClick={() =>
                specButtonProps.onClick({
                  ...transformSubmitedValues(fieldValues),
                  withTax: true,
                  contractNumber: undefined,
                  contractDate: undefined,
                  fileList: fieldValues.content.fileList,
                  documentList: fieldValues.documentList
                })
              }
            >
              {t('UseUpserviceSpecBtn')}
            </Button>
          )}

          <Button
            type="primary"
            htmlType="submit"
            loading={isLoading || isUploadingFiles}
          >
            {`${t('SendBtn')} ${
              isUploadingFiles ? t('FileLoading', { ns: 'Common' }) : ''
            }`}
          </Button>
        </div>
      </form>
    </FormProvider>
  );
};

CustomSpecificationForm.propTypes = {
  defaultValues: PropTypes.shape({}),
  isLoading: PropTypes.bool,
  onSubmit: PropTypes.func.isRequired,
  specButtonProps: PropTypes.shape({
    allow: PropTypes.bool,
    onClick: PropTypes.func
  }).isRequired
};

CustomSpecificationForm.defaultProps = {
  isLoading: false,
  defaultValues: {}
};

export default CustomSpecificationForm;

export const NOTIFICATIONS = 'notifications';

export const FILTER_ALL = 'all';
export const FILTER_COMMAND = 'action';
export const FILTER_TASK = 'task';
export const FILTER_ORDER = 'order';
export const FILTER_CONTACT = 'contact';

export const EMPLOYEE_DISMISS = 'employee-dismiss';

const OWNER_CHANGE = 'owner-change';
const EMPLOYEE_BENCH = 'employee-bench';
const ADMIN_CHANGE = 'admin-change';
const OWNER_BECOME = 'owner-become';
const EMPLOYEE_JOIN = 'employee-join';
export const WORKSPACE_JOIN = 'workspace_join';

export const CHANGING_PROCESSES = [
  OWNER_CHANGE,
  EMPLOYEE_BENCH,
  ADMIN_CHANGE,
  OWNER_BECOME,
  EMPLOYEE_JOIN
];

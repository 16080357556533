import React, { useEffect, useState } from 'react';
import { useDispatch, useSelector, shallowEqual } from 'react-redux';
import { Divider, Button, Alert } from 'antd';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import moment from 'moment';
import { useTranslation } from 'react-i18next';
import isEmpty from 'lodash/isEmpty';

import {
  STATUS_BACKLOG,
  STATUS_DONE,
  STATUS_FOR_EXECUTION,
  STATUS_IN_WORK,
  STATUS_CHECK,
  TYPE_ACQUAINTANCE,
  TYPE_LETTER,
  LETTER_KINDS,
  TYPE_MEETING,
  TASK_STATUSES,
  ATTACHMENT_TYPE_ENTITY,
  TYPE_AGREEMENT,
  STATUS_PENDING,
  TYPE_TASK,
  TYPE_ASSET,
  STATUS_CANCELLED,
  VIDEOS,
  ASSIGNMENTS_CHANGE_DRAWER,
  RELATIONS_DRAWER,
  CLICK_TASK_ATTACHMENT_EVENT,
  TASK_FIELD_TITLE,
  TASK_FIELD_FULL_LOCATION,
  TASK_FIELD_LOCATION,
  TASK_FIELD_LOCATION_EXACT,
  TASK_FIELD_COMPLETE_REQUIRE,
  TASK_FIELD_DESCRIPTION,
  FILE_GENERATION_PROCESS_ALERTS
} from 'constants/index';

import Typography from 'components/common/typography';
import Attachments from 'components/common/attachments';
import AgreementSheetDrawer from 'components/attachments-view/drawers/agreement-sheet';
import Location from 'components/common/location';
import { renderDescriptions } from 'components/common/comments/converters';
import { TagList } from 'components/common/tags';
import VideoBtn from 'components/common/video-btn';
import AssetList from 'components/common/assets/list';
import GoogleMeetButton from 'components/common/google-meet-button';
import Reminder from 'components/common/reminder';
import TaskFieldEditorProvider from 'components/tasks-view/providers/task-field-editor';
import {
  checkAllowCancelTask,
  getTaskDescriptionText
} from 'components/tasks-view/utils';

import {
  fetchAttachments,
  setTaskViewed,
  setTaskViewedApproving,
  fetchRelations,
  fetchAssignments,
  changeAssignments,
  changeRelations,
  fetchTask,
  fetchTaskLocal
} from 'store/tasks';
import { getUser } from 'store/user';
import { getUserEmployee } from 'store/workspace';
import {
  fetchAssetTaskBookingData,
  fetchEmployeeRecordsLocal
} from 'store/calendar';
import { setVisibleDrawer } from 'store/drawers';

import { FieldEditorItem, useFieldEditorContext } from 'providers/field-editor';
import transformTask from 'utils/transform-task';
import useWorklogModalData from 'hooks/tasks/useWorklogModal';
import { getIsTaskOutdated } from 'utils/get-is-outdated';
import { NOTICE_NUMBER } from 'services/notice';
import useAmplitude from 'hooks/amplitude/use-amplitude';
import getHasOwnerRole from 'utils/get-has-owner-role';

import SprintSelectModal from '../sprint-select-modal';
import SprintCreatorModal from '../sprint-creator';
import Scheduler from '../components/scheduler';
import EditorWorklogModal from './editor-worklog-modal';
import Statuses from './statuses';
import UserRole from './user-role';
import EditorResponsibleModal from './editor-responsible-modal';
import EditorControllerModal from './editor-controller-modal';
import EditorApprovingManagerModal from './editor-approving-manager-modal';
import AcquaintenceSheetDrawer from './acquaintence-sheet-drawer';
import ProgressBar from '../components/progress-bar';
import SubtaskList from '../components/subtask-list';
import Dates from '../components/dates';
import Header from '../components/header';
import { UploadsDrawer, RelationsBar } from '../../../components';
import CompleteRequire from './complete-require';
import ProjectInfo from './project-info';

import styles from './details.module.scss';

const { Title, Paragraph, Text } = Typography;

const AttachmentsWrapper = ({ children }) => {
  const { checkField } = useFieldEditorContext();

  if (checkField(TASK_FIELD_DESCRIPTION)) {
    return null;
  }

  return children;
};

export const Details = ({ task, className }) => {
  const dispatch = useDispatch();

  const amplitdue = useAmplitude();

  const currentUser = useSelector(getUser, shallowEqual);
  const userEmployee = useSelector(getUserEmployee);

  const [transformedTask, setTransformedTask] = useState(task);
  const [visibleResponsible, setVisibleResponsible] = useState(false);
  const [visibleController, setVisibleController] = useState(false);
  const [visibleApproving, setVisibleApproving] = useState(false);
  const [visibleSprintSelect, setVisibleSprintSelect] = useState(undefined);
  const [creatingSprint, setCreatingSprint] = useState(false);
  const [isShowUploadsEditor, setIsShowUploadsEditor] = useState(false);
  const [agreementSheetId, setAgreementSheetId] = useState(null);
  const [visibleAcquaintenceDrawer, setVisibleAcquaintenceDrawer] = useState(
    false
  );

  const [parentTask, setParentTask] = useState(null);
  const [employeeTaskRecord, setEmployeeTaskRecord] = useState(null);

  const { t } = useTranslation([
    'TaskCoexecutors',
    'TaskLinks',
    'Task',
    'AddTask'
  ]);

  const editAssignments = async ({ added, deleted }) => {
    if (deleted.length) {
      await dispatch(
        changeAssignments({
          id: task.id,
          employees: deleted,
          isDelete: true
        })
      );
    }

    if (added.length) {
      await dispatch(
        changeAssignments({
          id: task.id,
          employees: added,
          isDelete: false
        })
      );
    }
  };

  const editRelations = async ({ added, deleted }) => {
    const result = [
      ...deleted.map(d => ({ ...d, isDelete: true })),
      ...added.map(d => ({ ...d, isDelete: false }))
    ];

    if (result.length) {
      await dispatch(
        changeRelations({
          id: task.id,
          relations: result
        })
      );

      await dispatch(fetchRelations({ id: task.id }));
    }
  };

  const showAssignmentsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: ASSIGNMENTS_CHANGE_DRAWER,
        data: {
          title: t('CoexecutorsHeading', { ns: 'TaskCoexecutors' }),
          videoSlug: VIDEOS.taskMembers,
          description: t('TaskCoexecutorsDesc', { ns: 'TaskCoexecutors' }),
          employeeListTitle: t('TaskCoexecutorsListText', {
            ns: 'TaskCoexecutors'
          }),
          selectProps: {
            label: t('Coexecutors', { ns: 'TaskCoexecutors' })
          },
          submitText: t('SaveBtn', { ns: 'TaskCoexecutors' }),
          noticeNumber: NOTICE_NUMBER.memberListSaved,
          permissions: {
            allowAdd: permissions.addTaskMember,
            allowDelete: permissions.deleteTaskMember
          },
          fetch: () =>
            dispatch(
              fetchAssignments({ directMembersOnly: true, id: task.id })
            ),
          submit: editAssignments
        }
      })
    );

  const showRelationsDrawer = () =>
    dispatch(
      setVisibleDrawer({
        drawer: RELATIONS_DRAWER,
        data: {
          videoSlug: VIDEOS.taskRelations,
          title: t('TaskLinksHeading', { ns: 'TaskLinks' }),
          description: t('TaskLinksDesc', { ns: 'TaskLinks' }),
          fetch: () => dispatch(fetchRelations({ id: task.id })),
          submit: editRelations
        }
      })
    );

  const {
    visibleWorklog,
    setVisibleWorklog,
    checkIsWorklog,
    onSaveWorklog
  } = useWorklogModalData(transformedTask);

  const {
    id,
    title,
    permissions,
    relations = [],
    bookings,
    additionalData
  } = task;

  const isBacklog = task.status === STATUS_BACKLOG;
  const isStatusPending = task.status === STATUS_PENDING;
  const isStatusForExecution = task.status === STATUS_FOR_EXECUTION;
  const isStatusInWork = task.status === STATUS_IN_WORK;
  const isStatusDone = task.status === STATUS_DONE;
  const isStatusCheck = task.status === STATUS_CHECK;
  const isStatusCancelled = task.status === STATUS_CANCELLED;
  const isOutdated = getIsTaskOutdated(task);

  const isTypeAcquaintence = task.kind === TYPE_ACQUAINTANCE;
  const isTypeLetter = task.kind === TYPE_LETTER;
  const isTypeMeeting = task.kind === TYPE_MEETING;
  const isTypeAgreement = task.kind === TYPE_AGREEMENT;
  const isTypeTask = task.kind === TYPE_TASK;

  const isTopTask = !(task || {}).parent;
  const isChildAgreementTask =
    isTypeAgreement &&
    !isTopTask &&
    parentTask &&
    parentTask.kind !== TYPE_TASK;
  const isChildAcquaintenceTask =
    isTypeAcquaintence &&
    !isTopTask &&
    parentTask &&
    parentTask.kind !== TYPE_TASK;
  const isChildMeetingTask =
    isTypeMeeting && !isTopTask && parentTask && parentTask.kind !== TYPE_TASK;

  const isResponsible = (task.responsible || {}).id === userEmployee.id;
  const isController = (task.controller || {}).id === userEmployee.id;
  const isAuthor = (task.author || {}).id === userEmployee.id;
  const isApprovingManager =
    (task.approvingManager || {}).id === userEmployee.id;
  const isProjectManager = (task.project || {}).managerId === userEmployee.id;
  const isTrueResponsible = (task.trueResponsible || {}).id === userEmployee.id;
  const isOwner = getHasOwnerRole(userEmployee.roles);

  const hasController = !!task.controller;

  const allowDeleteUpload = (task.author || {}).userId === currentUser.id;
  const allowUpdateEstimate = permissions.updateTaskEstimate;
  const allowAddWorklog = permissions.addTaskWorklog;
  const allowChangeResponsible =
    permissions.changeTaskResponsible && !isStatusCheck;

  const allowChangeController = permissions.changeTaskController;
  const allowChangeStatus = permissions.changeTaskStatus;
  const allowSwitchComplete = isResponsible && hasController;

  const allowEdit =
    permissions.updateTask &&
    !isStatusCancelled &&
    !isStatusDone &&
    !isChildAgreementTask &&
    !isChildAcquaintenceTask &&
    !isChildMeetingTask;

  const allowChangeApprovingManager =
    permissions.changeTaskApprovingManager && isStatusPending;

  const allowChangeStatusByRole =
    (isStatusForExecution && allowChangeStatus && isResponsible) ||
    (isStatusInWork && isResponsible) ||
    ((isStatusCheck || isStatusDone) && isController) ||
    (isStatusDone && isChildMeetingTask && isAuthor) ||
    (isStatusDone && allowChangeStatus && !isTypeMeeting) ||
    (isStatusDone &&
      (isAuthor || isResponsible || isController) &&
      !isChildAgreementTask &&
      !isChildMeetingTask &&
      !isChildAcquaintenceTask) ||
    (isStatusCancelled &&
      (isApprovingManager ||
        isAuthor ||
        isProjectManager ||
        isOwner ||
        (isTypeTask && (isResponsible || isTrueResponsible))) &&
      !isTypeAgreement &&
      !isTypeMeeting) ||
    (isStatusPending && isApprovingManager);

  const hideResponsibleField =
    (isTypeAgreement || isTypeAcquaintence || isTypeMeeting) &&
    (!task.parent || (parentTask && parentTask.kind.includes(TYPE_TASK)));
  const hideControllerField =
    isTypeAgreement || isTypeAcquaintence || isTypeMeeting;

  const hideStatuses =
    isTypeAcquaintence &&
    (!task.parent || (parentTask && parentTask.kind.includes(TYPE_TASK)));

  const isShowAlertGeneratedFiles =
    !isEmpty(additionalData) &&
    additionalData.filesProcessingCompleted !== undefined &&
    !additionalData.filesProcessingCompleted;

  const isShowAlertNonReturnedAgreementTask =
    !isEmpty(additionalData) && additionalData.agreementWasReopened;

  const fetchParentTask = async () => {
    const fetchedParentTask = await dispatch(
      fetchTaskLocal({ id: task.parent })
    );

    setParentTask(fetchedParentTask);
  };

  const fetchEmployeeTaskRecord = async () => {
    const { results } = await dispatch(
      fetchEmployeeRecordsLocal({
        params: {
          objectId: task.id,
          dateStart: task.dateStart,
          dateEnd: task.dateEnd
        }
      })
    );

    setEmployeeTaskRecord(results[0]);
  };

  // TODO: избавиться, так как происходят непредвиденные баги.
  // Если подписываться на значения transformedTask, то они происходят асинхронно
  // что приводит к неправильному выполнению функционала
  useEffect(() => {
    setTransformedTask(transformTask(task));
  }, [task]);

  useEffect(() => {
    // Получение вложенных файлов в задачу
    dispatch(fetchAttachments({ id: task.id, source: ATTACHMENT_TYPE_ENTITY }));

    dispatch(fetchRelations({ id: task.id }));

    if (task.parent) {
      fetchParentTask();
    }

    if (task.id && isTypeMeeting) {
      fetchEmployeeTaskRecord();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.id]);

  useEffect(() => {
    if (task.id) {
      dispatch(fetchAssetTaskBookingData({ id: task.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.relations, task.id, task.dateEnd, task.dateStart]);

  // fetch task data if status changed from backlog to todo
  useEffect(() => {
    if (task.id && task.isNeedToFetch) {
      dispatch(fetchTask({ id: task.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [task.id, task.isNeedToFetch]);

  useEffect(() => {
    if (isResponsible && task.needToLog) {
      dispatch(setTaskViewed({ id: task.id }));
    }

    if (isApprovingManager && task.needToLogApprovingManager) {
      dispatch(setTaskViewedApproving({ id: task.id }));
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [
    task.id,
    task.needToLog,
    task.needToLogApprovingManager,
    isResponsible,
    isApprovingManager
  ]);

  const showEditor = (setIsShow, currentValue) => setIsShow(!currentValue);

  const getLetterKindNames = values =>
    (values || []).reduce((acc, cur) => {
      const current = LETTER_KINDS.find(kind => kind.value === cur);
      if (current) {
        acc.push(t(current.label, { ns: current.ns }));
      }

      return acc;
    }, []);

  const activeStatus = TASK_STATUSES.find(s => s.id === task.status);

  const allowCancel = checkAllowCancelTask(task);

  const getCancelAction = () => {
    let label = 'CancelBtn';

    if (isStatusPending) {
      label = 'RejectTaskBtn';
    }

    if (isTypeMeeting) {
      if (
        task.parent &&
        (((isAuthor || isResponsible || isProjectManager || isOwner) &&
          isStatusForExecution) ||
          (isStatusInWork && !isResponsible))
      ) {
        label = 'DeclineTaskBtn';
      }

      if (task.parent && isStatusInWork && isResponsible) {
        label = 'NotParticipatedBtn';
      }
    }

    return {
      label,
      ns: 'Task',
      status: STATUS_CANCELLED,
      mood: 'negative'
    };
  };

  const getActions = () => {
    let { actions } = activeStatus;

    if (
      isTypeMeeting &&
      (isStatusCancelled ||
        isStatusDone ||
        moment(new Date()).isAfter(task.dateEnd))
    ) {
      actions = [];
    }

    if (isTypeMeeting && (isStatusForExecution || isStatusInWork)) {
      if (!task.parent && (!isOwner || !isAuthor || !isProjectManager)) {
        actions = [];
      } else {
        actions = activeStatus.actionsMeeting;
      }
    }

    if (isTypeAcquaintence && isStatusInWork) {
      actions = activeStatus.actionsAcquaintence;
    }

    if (isTypeAgreement && isStatusInWork && !isChildAgreementTask) {
      actions = [];
    }

    const cancelAction = getCancelAction();

    return allowCancel ? [...actions, cancelAction] : actions;
  };

  const showAgreementSheet = sheetId => setAgreementSheetId(sheetId);

  return (
    <TaskFieldEditorProvider task={task} parentTask={parentTask}>
      <div
        className={classnames(styles.content, className)}
        data-qa="qa-s0759pkw2wfbmb5"
      >
        <Header
          task={transformedTask}
          parentTask={parentTask}
          isBacklog={isBacklog}
          setVisibleSprintSelect={setVisibleSprintSelect}
          className={styles.header}
          data-qa="qa-csl8bpolbk9dubw"
        />

        <div className={styles.flexAuto} data-qa="qa-e2tds619mpj9u6p">
          <div className={styles.flexOverflow} data-qa="qa-mwwehcml7huyzq1">
            <FieldEditorItem
              field={TASK_FIELD_TITLE}
              defaultValues={{
                [TASK_FIELD_TITLE]: task.title
              }}
              className={styles.titleWrap}
            >
              <Title
                level={3}
                className={classnames(styles.title, {
                  [styles.outdated]: isOutdated
                })}
                data-qa="qa-i40nc4497k0sqic"
              >
                {task.title}
              </Title>
            </FieldEditorItem>

            <Dates
              taskValues={task}
              isStyledOutdated={!isStatusCancelled}
              className={styles.dates}
              data-qa="qa-gjjrsypckuc9bvc"
            />

            <ProjectInfo task={task} />

            <FieldEditorItem
              field={TASK_FIELD_FULL_LOCATION}
              defaultValues={{
                [TASK_FIELD_LOCATION]: task.location,
                [TASK_FIELD_LOCATION_EXACT]: task.locationExact
              }}
              className={styles.location}
            >
              {task.location && (
                <Location
                  location={task.location}
                  locationExact={task.locationExact}
                  data-qa="qa-aa4erj1j0t3a5wb"
                />
              )}
            </FieldEditorItem>

            <FieldEditorItem
              field={TASK_FIELD_COMPLETE_REQUIRE}
              defaultValues={{
                [TASK_FIELD_COMPLETE_REQUIRE]: task.completeRequire
              }}
              className={styles.completeRequire}
            >
              {task.completeRequire && <CompleteRequire task={task} />}
            </FieldEditorItem>

            {task.templateScheduler && (
              <Scheduler task={task} isAuthor={isAuthor} />
            )}

            {task.status !== STATUS_DONE &&
              task.status !== STATUS_CANCELLED && (
                <Reminder
                  entityId={task.id}
                  entityType={TYPE_TASK}
                  deadlineAt={task.dateEnd}
                  isOutdated={!isOutdated}
                />
              )}

            {isShowAlertNonReturnedAgreementTask && (
              <Alert
                type="warning"
                message={t('NonReturnedAgreementTaskAlert', { ns: 'Task' })}
                className={styles.alert}
              />
            )}

            <FieldEditorItem
              field={TASK_FIELD_DESCRIPTION}
              defaultValues={{
                [TASK_FIELD_DESCRIPTION]: {
                  description: getTaskDescriptionText(task.description),
                  fileList: task.fileList
                }
              }}
              className={styles.description}
            >
              {!!(task.description[0] && task.description[0].text) && (
                <Paragraph style={{ margin: 0 }}>
                  {renderDescriptions(task.description)}
                </Paragraph>
              )}
            </FieldEditorItem>

            {isTypeLetter && (
              <div className={styles.flex} data-qa="qa-gc2xh9kcbn4vmx3">
                <Text
                  color="black-55"
                  className={styles.label}
                  data-qa="qa-3nf9xlpra63nz0e"
                >
                  {t('WhatToSend', { ns: 'Task' })}{' '}
                </Text>

                <Text>{getLetterKindNames(task.letterKind).join(', ')}</Text>
              </div>
            )}

            {isShowAlertGeneratedFiles && (
              <Alert
                type="warning"
                message={t(FILE_GENERATION_PROCESS_ALERTS[task.kind], {
                  ns: 'Task'
                })}
                className={styles.alert}
              />
            )}

            <AttachmentsWrapper>
              <Attachments
                fileList={task.fileList}
                actionsDeps={{
                  taskId: task.id,
                  sendCopyToComment: false,
                  allowDeleteFrom: !isTypeAgreement && !isTypeAcquaintence
                }}
                showDownloadAllButton
              />
            </AttachmentsWrapper>

            {isTypeAcquaintence && (
              <Button
                type="link"
                className={styles.link}
                data-qa="qa-kz2wdzwmscgonsk"
                onClick={() => setVisibleAcquaintenceDrawer(true)}
              >
                {t('IntroductionListBtn', { ns: 'Task' })}
              </Button>
            )}

            {isTypeAgreement && (
              <Button
                type="link"
                className={styles.link}
                data-qa="qa-y2ssmzoo6rz2qlf"
                onClick={() => showAgreementSheet(task.agreementSheet)}
              >
                {t('ApprovingListBtn', { ns: 'Task' })}
              </Button>
            )}

            {isTypeMeeting &&
              employeeTaskRecord &&
              employeeTaskRecord.gEventUrl && (
                <GoogleMeetButton link={employeeTaskRecord.gEventUrl} />
              )}

            <Divider />

            <RelationsBar
              buttons={{
                relations: {
                  allow: true,
                  click: showRelationsDrawer
                },
                members: {
                  allow: true,
                  click: showAssignmentsDrawer
                },
                uploads: {
                  allow: true,
                  click: () => {
                    showEditor(setIsShowUploadsEditor, isShowUploadsEditor);
                    amplitdue.logEvent({ event: CLICK_TASK_ATTACHMENT_EVENT });
                  }
                }
              }}
            />

            <UserRole
              label={t('TaskAuthor', { ns: 'Task' })}
              data-qa="qa-kgbjzor0c25pvp2"
              user={task.author}
            />

            {!hideResponsibleField && (
              <UserRole
                label={t('TaskResponsible', { ns: 'Task' })}
                data-qa="qa-2yuaypqt47yro5s"
                previousResponsible={task.trueResponsible}
                user={task.responsible}
                allowChange={allowChangeResponsible}
                onClickEditor={() =>
                  checkIsWorklog(setVisibleResponsible)({
                    changedRole: 'responsible'
                  })
                }
              />
            )}

            {!hideControllerField && (
              <UserRole
                label={t('TaskController', { ns: 'Task' })}
                data-qa="qa-oy3h2qmpuq03ehx"
                addLabel={t('AddControllerBtn', { ns: 'Task' })}
                user={task.controller}
                allowChange={allowChangeController}
                onClickEditor={() =>
                  checkIsWorklog(setVisibleController)({
                    changedRole: 'controller'
                  })
                }
              />
            )}

            {task.approvingManager && (
              <UserRole
                label={t('TaskApproving', { ns: 'Task' })}
                data-qa="qa-5z6mzpfi17jixq1"
                user={task.approvingManager}
                approvingStatus={task.isApproving}
                allowChange={allowChangeApprovingManager}
                onClickEditor={() =>
                  checkIsWorklog(setVisibleApproving)({
                    changedRole: 'approvingManager'
                  })
                }
              />
            )}

            <Divider />

            <ProgressBar
              task={task}
              allowUpdateEstimate={allowUpdateEstimate}
              allowAddWorklog={allowAddWorklog}
            />

            <Divider />

            <div className={styles.tagsHeader}>
              <Title level={3} className={styles.tagsTitle}>
                {t('TagsHeading', { ns: 'Task' })}
              </Title>

              <VideoBtn
                slug={VIDEOS.workWithTags}
                className={styles.videoBtn}
              />
            </div>

            <TagList
              entityType={TYPE_TASK}
              entityId={task.id}
              needFetchTags
              className={styles.tagList}
            />

            <Divider />

            <SubtaskList task={task} />

            <AssetList
              assets={relations.filter(r => r.type === TYPE_ASSET)}
              entityData={{
                id,
                title,
                type: TYPE_TASK,
                allowEditDates: allowEdit,
                allowBooking: !isStatusCancelled,
                dateStart: task.dateStart,
                dateEnd: task.dateEnd
              }}
              bookings={bookings}
            />
          </div>
        </div>

        {!hideStatuses && (
          <Statuses
            task={task}
            allowChange={allowChangeStatusByRole}
            isResponsible={isResponsible}
            isController={isController}
            allowSwitchComplete={allowSwitchComplete}
            actions={getActions()}
            checkIsWorklog={checkIsWorklog}
          />
        )}

        <UploadsDrawer
          object={task}
          allowDelete={allowDeleteUpload}
          visible={isShowUploadsEditor}
          onClose={() => showEditor(setIsShowUploadsEditor, { value: false })}
        />

        <AgreementSheetDrawer
          task={task}
          sheetId={agreementSheetId}
          visible={!!agreementSheetId}
          onClose={() => setAgreementSheetId(null)}
        />

        <AcquaintenceSheetDrawer
          task={task}
          visible={visibleAcquaintenceDrawer}
          onClose={() => setVisibleAcquaintenceDrawer(false)}
        />

        <EditorResponsibleModal
          visible={visibleResponsible}
          task={task}
          onClose={() => setVisibleResponsible(false)}
        />

        <EditorControllerModal
          visible={visibleController}
          task={task}
          onClose={() => setVisibleController(false)}
        />

        <EditorApprovingManagerModal
          visible={visibleApproving}
          task={task}
          onClose={() => setVisibleApproving(false)}
        />

        <EditorWorklogModal
          task={task}
          visible={visibleWorklog}
          worklog={task.workLog}
          handleSubmit={onSaveWorklog}
          onClose={() => setVisibleWorklog(false)}
        />

        <SprintSelectModal
          visible={visibleSprintSelect !== undefined}
          onClose={() => setVisibleSprintSelect(undefined)}
          data={visibleSprintSelect}
          setCreatingSprint={setCreatingSprint}
        />

        <SprintCreatorModal
          setIsModalVisible={setCreatingSprint}
          isModalVisible={creatingSprint}
          projectId={(task.project || {}).id}
        />
      </div>
    </TaskFieldEditorProvider>
  );
};

Details.propTypes = {
  task: PropTypes.object.isRequired
};

export default Details;

import axios from 'axios';
import { Spin, notification } from 'antd';
import React, { Suspense } from 'react';
import { Translation } from 'react-i18next';

import { config } from 'config';

import { errorResponseHandler, requestInterception } from '../utils';
import marketplaceApi from './marketplace';
import ordersApi from './orders';
import orderStatusesApi from './order-statuses';
import citiesApi from './cities';
import countriesApi from './countries';
import commentsApi from './comments';
import chatStatusesApi from './chat-statuses';
import userApi from './user';
import filesApi from './files';
import actionsApi from './actions';
import workspacesApi from './workspaces';
import dealFeedbackApi from './deals-feedback';
import notificationsApi from './notifications';
import contactsApi from './contacts';
import contactsCompaniesApi from './contacts-companies';
import searchApi from './search';
import invitationApi from './invitation';
import employeesApi from './employees';
import departmentsApi from './departments';
import workspaceUserCategoryApi from './workspace-user-category';
import calendarApi from './calendar';
import wokrspaceSocialCredentialsApi from './workspace-social-credentials';
import guidesApi from './guides';
import categoriesApi from './categories';
import ordersControllersApi from './orders-controllers';
import projectsApi from './projects';
import ideasApi from './ideas';
import tasksApi from './tasks';
import taskTemplatesApi from './task-templates';
import regionsApi from './regions';
import subscriptionsApi from './subscriptions';
import currencyApi from './currency';
import attachmentsApi from './attachments';
import worklogsReportsApi from './worklogs-reports';
import agreementApi from './agreement';
import supportApi from './support';
import videosApi from './videos';
import sprintsApi from './sprints';
import promoApi from './promo';
import channelsApi from './channels';
import dialoguesApi from './dialogues';
import devicesApi from './devices';
import redashApi from './redash';
import assetsApi from './assets';
import tagsApi from './tags';
import releaseApi from './release';
import reportsApi from './reports';
import messengerApi from './messenger';
import geolocationApi from './geolocation';
import messageTemplatesApi from './message-templates';
import remindersApi from './reminders';
import websiteFormApi from './website-form';
import feedbacksApi from './feedbacks';
import billingApi from './billing';
import gptApi from './gpt';

export const API_ROOT = `${process.env.REACT_APP_API_PROTOCOL}://${config.REACT_APP_API_URL}`;

export const api = axios.create({
  baseURL: API_ROOT,
  retryCount: 3
});

const fieldMap = {
  non_field_errors: '',
  nonFieldErrors: '',
  error: ''
};

export const handle400 = response => {
  if (response.status === 409) {
    return null;
  }

  const { data } = response;

  const notificationMessage = (
    <Suspense fallback={<Spin size="small" />}>
      <Translation ns="Errors">{t => t('SomethingWentWrong')}</Translation>
    </Suspense>
  );

  return Object.keys(data).forEach(field => {
    let errors;
    if (Array.isArray(data[field])) {
      errors = data[field];
    } else {
      errors = [data[field]];
    }

    errors.forEach(error => {
      if (typeof error === 'string') {
        const fieldName =
          fieldMap[field] !== undefined ? fieldMap[field] : field;

        const prefix =
          fieldName && Number.isNaN(+fieldName) ? `${fieldName}:` : '';
        const description = `${prefix} ${error}`;

        return notification.warn({
          message: notificationMessage,
          description
        });
      }

      if (Array.isArray(error)) {
        return notification.warn({
          message: notificationMessage,
          description: error.join(' ')
        });
      }

      if (typeof error === 'object') {
        return Object.keys(error).forEach(key => {
          const message = Array.isArray(error[key])
            ? error[key].join(' ')
            : error[key];
          const description = `${key}: ${message}`;
          notification.warn({
            message: notificationMessage,
            description
          });
        });
      }
    });
  });
};

api.interceptors.request.use(requestInterception, error =>
  Promise.reject(error)
);

api.interceptors.response.use(
  response => response,
  error => errorResponseHandler(error, api, () => handle400(error.response))
);

export default {
  user: userApi(api),
  comments: commentsApi(api),
  chatStatuses: chatStatusesApi(api),
  marketplace: marketplaceApi(api),
  orders: ordersApi(api),
  orderStatuses: orderStatusesApi(api),
  cities: citiesApi(api),
  countries: countriesApi(api),
  workspaces: workspacesApi(api),
  notifications: notificationsApi(api),
  dealFeedbacks: dealFeedbackApi(api),
  contacts: contactsApi(api),
  contactsCompanies: contactsCompaniesApi(api),
  search: searchApi(api),
  invitation: invitationApi(api),
  employees: employeesApi(api),
  departments: departmentsApi(api),
  employeeCategory: workspaceUserCategoryApi(api),
  calendar: calendarApi(api),
  socialCredentials: wokrspaceSocialCredentialsApi(api),
  actions: actionsApi(api),
  guides: guidesApi(api),
  categories: categoriesApi(api),
  ordersControllers: ordersControllersApi(api),
  projects: projectsApi(api),
  ideas: ideasApi(api),
  tasks: tasksApi(api),
  taskTemplates: taskTemplatesApi(api),
  regions: regionsApi(api),
  subscriptions: subscriptionsApi(api),
  currency: currencyApi(api),
  attachments: attachmentsApi(api),
  worklogsReports: worklogsReportsApi(api),
  agreement: agreementApi(api),
  support: supportApi(api),
  videos: videosApi(api),
  sprints: sprintsApi(api),
  promo: promoApi(api),
  channels: channelsApi(api),
  dialogues: dialoguesApi(api),
  devices: devicesApi(api),
  redash: redashApi(),
  files: filesApi(api),
  assets: assetsApi(api),
  tags: tagsApi(),
  release: releaseApi(api),
  reports: reportsApi(api),
  messenger: messengerApi(),
  geolocation: geolocationApi(),
  messageTemplates: messageTemplatesApi(api),
  reminders: remindersApi(api),
  websiteForm: websiteFormApi(),
  feedbacks: feedbacksApi(api),
  billing: billingApi(api),
  gpt: gptApi()
};

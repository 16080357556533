import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';
import moment from 'moment';

import {
  ATTACHMENT_TYPE_ENTITY,
  TASK_FIELD_COMPLETE_REQUIRE,
  TASK_FIELD_DATE_END,
  TASK_FIELD_DATE_START,
  TASK_FIELD_DESCRIPTION,
  TASK_FIELD_FILE_LIST,
  TASK_FIELD_FULL_LOCATION,
  TASK_FIELD_LOCATION,
  TASK_FIELD_LOCATION_EXACT,
  TASK_FIELD_PROJECT,
  TASK_FIELD_SPRINT,
  TASK_FIELD_TITLE,
  TYPE_TASK
} from 'constants/index';

import {
  FormCompleteRequireTaskSelect,
  FormDatePicker,
  FormInput,
  FormLocationInput,
  FormProjectSelect,
  FormSprintSelect,
  validateMinLength,
  validateRequired
} from 'components/common/hook-form';
import FormNewEditor from 'components/common/hook-form/markdown';

import { fetchAttachments, partialTaskUpdate } from 'store/tasks';

import { FieldEditorProvider } from 'providers';
import useMinMaxTime from 'hooks/common/use-min-max-time';
import getFileIds from 'hooks/common/use-file-upload/get-file-ids';
import useUploadingFiles from 'hooks/common/use-file-upload/use-uploading-files';

import {
  checkAllowEditTask,
  checkIsTaskKindAcquaintence,
  checkIsTaskKindAgreement,
  checkIsTaskKindMeeting,
  checkIsTaskStatusBacklog,
  checkIsTaskStatusCancelled,
  checkIsTaskStatusDone,
  checkIsTopTask,
  getTaskDescriptionText
} from '../../utils';

import styles from './task-field-editor.module.scss';

const TaskFieldEditorProvider = ({ children, task, parentTask }) => {
  const dispatch = useDispatch();

  const [currentValues, setCurrentValues] = useState({});

  const { t } = useTranslation([
    'Task',
    'Tasks',
    'Common',
    'ChooseEntityActions',
    'AddTask'
  ]);

  const {
    id,
    kind,
    status,
    project,
    permissions,
    taskInfo,
    dateStart,
    dateEnd,
    sprint,
    location,
    completeRequire,
    description,
    fileList
  } = task;

  const [currentTaskId, setCurrentTaskId] = useState(id);
  const [isEditingCancelled, setIsEditingCancelled] = useState(false);
  const [minTimeStart, maxTimeStart, minTimeEnd, maxTimeEnd] = useMinMaxTime({
    startDate: currentValues.dateStart,
    endDate: currentValues.dateEnd
  });

  const isUploadingFiles = useUploadingFiles(
    (currentValues.description || {}).fileList
  );

  const isOutdated = moment(new Date()).isAfter(currentValues.dateEnd);
  const isTopTask = checkIsTopTask(task);
  const isSlaTask = !!Object.values(taskInfo || {}).length;

  const isKindAgreement = checkIsTaskKindAgreement(kind);
  const isKindAcquaintence = checkIsTaskKindAcquaintence(kind);
  const isKindMeeting = checkIsTaskKindMeeting(kind);

  const isStatusBacklog = checkIsTaskStatusBacklog(status);
  const isStatusDone = checkIsTaskStatusDone(status);
  const isStatusCancelled = checkIsTaskStatusCancelled(status);

  const allowEditTask = checkAllowEditTask({ task, parentTask });

  const allowEditProject = isTopTask && permissions.updateTask;
  const allowDeleteProject = allowEditProject && !isStatusBacklog;
  const allowEditSprint =
    isTopTask && permissions.updateTask && !isStatusDone && !isStatusCancelled;
  const allowEditDescription =
    allowEditTask && !isKindAgreement && !isKindAcquaintence;

  const isDateEndRequired = isKindAgreement || !project || !isStatusBacklog;

  const changeCurrentValues = values =>
    setCurrentValues(prev => ({ ...prev, ...values }));

  const update = async ({ field, values }) => {
    let resultValues = values;

    if (field === TASK_FIELD_PROJECT) {
      resultValues = {
        [field]: values[field] && values[field].value,
        [TASK_FIELD_SPRINT]:
          (values[field] || {}).value !== (project || {}).id ? null : undefined
      };
    }

    if (field === TASK_FIELD_SPRINT) {
      resultValues = {
        [field]: values[field] && values[field].value
      };
    }

    if (field === TASK_FIELD_FULL_LOCATION && values[field] === null) {
      resultValues = {
        [TASK_FIELD_LOCATION]: '',
        [TASK_FIELD_LOCATION_EXACT]: ''
      };
    }

    if (field === TASK_FIELD_DESCRIPTION) {
      resultValues = {
        [field]: [{ text: values[field] ? values[field].description : '' }],
        [TASK_FIELD_FILE_LIST]: values[field]
          ? getFileIds(values[field].fileList)
          : undefined
      };
    }

    await dispatch(partialTaskUpdate({ id, values: resultValues }));

    if (
      field === TASK_FIELD_DESCRIPTION &&
      values[field] &&
      values[field].fileList.length !== fileList.length
    ) {
      await dispatch(fetchAttachments({ id, source: ATTACHMENT_TYPE_ENTITY }));
    }
  };

  const fieldMap = {
    [TASK_FIELD_TITLE]: {
      formItems: (
        <FormInput
          name={TASK_FIELD_TITLE}
          rules={{
            required: validateRequired(),
            minLength: validateMinLength(2)
          }}
          autoFocus
        />
      ),
      allowDoubleClickEdit: true,
      permissions: {
        canRemove: false,
        canEdit: allowEditTask && !isKindAgreement && !isKindAcquaintence
      }
    },
    [TASK_FIELD_DATE_START]: {
      formItems: (
        <FormDatePicker
          name={TASK_FIELD_DATE_START}
          label={t('TaskStartDate', { ns: 'Tasks' })}
          rules={{
            required: isKindMeeting && validateRequired()
          }}
          wrapperClassname={styles.datePickerWrap}
          minDate={new Date()}
          maxDate={isOutdated ? undefined : currentValues.dateEnd}
          minTime={minTimeStart}
          maxTime={maxTimeStart}
        />
      ),
      allowDoubleClickEdit: false,
      editButtonText: t('EditTaskAction'),
      permissions: {
        canRemove: !isKindMeeting && dateStart,
        canEdit: allowEditTask
      }
    },
    [TASK_FIELD_DATE_END]: {
      formItems: (
        <FormDatePicker
          name={TASK_FIELD_DATE_END}
          label={t('TaskDueDate')}
          rules={{
            required: isDateEndRequired && validateRequired()
          }}
          wrapperClassname={styles.datePickerWrap}
          minDate={currentValues.dateStart || new Date()}
          minTime={minTimeEnd}
          maxTime={maxTimeEnd}
        />
      ),
      allowDoubleClickEdit: false,
      editButtonText: t('EditTaskAction'),
      permissions: {
        canRemove: !isDateEndRequired && dateEnd,
        canEdit: allowEditTask && !isSlaTask
      }
    },
    [TASK_FIELD_PROJECT]: {
      formItems: (
        <FormProjectSelect
          name={TASK_FIELD_PROJECT}
          label={t('ProjectName', { ns: 'Common' })}
          valueText={t('ChooseProject', { ns: 'Common' })}
          isClearable={false}
          params={{
            isActive: true
          }}
        />
      ),
      editButtonText: t('EditProjectBtn'),
      permissions: {
        canRemove: allowDeleteProject && project,
        canEdit: allowEditTask && isTopTask
      }
    },
    [TASK_FIELD_SPRINT]: {
      formItems: (
        <FormSprintSelect
          name={TASK_FIELD_SPRINT}
          label={t('SprintName', { ns: 'ChooseEntityActions' })}
          valueText={t('ChooseSprint', { ns: 'ChooseEntityActions' })}
          params={{
            project: (project || {}).id,
            status: ['active', 'planned'],
            isLag: false
          }}
        />
      ),
      editButtonText: t('EditProjectBtn'),
      permissions: {
        canRemove: allowEditSprint && sprint,
        canEdit: allowEditSprint
      }
    },
    [TASK_FIELD_FULL_LOCATION]: {
      formItems: (
        <div>
          <FormLocationInput
            label={t('MainAddress', { ns: 'AddTask' })}
            name={TASK_FIELD_LOCATION}
            placeholder={t('EnterAddress', { ns: 'AddTask' })}
          />

          <FormInput
            label={t('AdditionalAddress', { ns: 'AddTask' })}
            name={TASK_FIELD_LOCATION_EXACT}
          />
        </div>
      ),
      editButtonText: t('EditTaskAction'),
      permissions: {
        canRemove: allowEditTask && location,
        canEdit: allowEditTask
      }
    },
    [TASK_FIELD_COMPLETE_REQUIRE]: {
      formItems: (
        <FormCompleteRequireTaskSelect
          label={t('TaskResultHeading', { ns: 'AddTask' })}
          name={TASK_FIELD_COMPLETE_REQUIRE}
        />
      ),
      editButtonText: t('EditTaskAction'),
      permissions: {
        canRemove: allowEditTask && completeRequire,
        canEdit: allowEditTask
      }
    },
    [TASK_FIELD_DESCRIPTION]: {
      formItems: (
        <FormNewEditor
          label={t('TaskDescription', { ns: 'AddTask' })}
          name={TASK_FIELD_DESCRIPTION}
          actionsDeps={{
            taskId: id,
            sendCopyToComment: false
          }}
          destination={{
            entityId: id,
            entityType: TYPE_TASK
          }}
          resizeInput
        />
      ),
      allowDoubleClickEdit: true,
      isDisabledSaveButton: isUploadingFiles,
      permissions: {
        canRemove:
          allowEditDescription && !!getTaskDescriptionText(description),
        canEdit: allowEditDescription
      }
    }
  };

  useEffect(() => {
    if (currentTaskId !== id) {
      setCurrentTaskId(id);
      setIsEditingCancelled(true);
    }
  }, [currentTaskId, id]);

  useEffect(() => {
    if (isEditingCancelled) {
      setIsEditingCancelled(false);
    }
  }, [currentTaskId, isEditingCancelled]);

  return (
    <FieldEditorProvider
      fieldMap={fieldMap}
      update={update}
      changeCurrentValues={changeCurrentValues}
      isEditingCancelled={isEditingCancelled}
    >
      {children}
    </FieldEditorProvider>
  );
};

export default TaskFieldEditorProvider;

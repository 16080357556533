import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useForm, FormProvider, useWatch } from 'react-hook-form';
import classnames from 'classnames';
import { useDispatch } from 'react-redux';
import { Alert, Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import FormAttachments from 'components/common/hook-form/attachments';
import Button from 'components/common/button';

import { completeOrderStatus, fetchOrderStatus } from 'store/order-statuses';

import { getFileIdsV2 } from 'hooks/common/use-file-upload/get-file-ids';
import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';
import useUploadingFiles from 'hooks/common/use-file-upload/use-uploading-files';

import styles from './completion.module.scss';

const Form = ({ orderStatus, isLoading, onSubmit }) => {
  const { t } = useTranslation(['Order', 'Errors', 'Common']);

  const methods = useForm({ defaultValues: { documentList: [] } });

  const documentListField = useWatch({
    name: 'documentList',
    control: methods.control
  });

  const isUploadingFiles = useUploadingFiles(documentListField);

  return (
    <FormProvider {...methods}>
      <form className={styles.form} onSubmit={methods.handleSubmit(onSubmit)}>
        <FormAttachments
          name="documentList"
          rules={{
            validate: value =>
              value.length || t('RequiredField', { ns: 'Errors' })
          }}
          statusId={orderStatus.id}
          isChangeAfterSelect
          filterPaneClassName={styles.filterPane}
          attachmentsTableClassName={styles.attachmentsTable}
          itemProps={{
            className: styles.attachmentsFormItem
          }}
        />

        <Spin wrapperClassName={styles.spin} spinning={isLoading}>
          <div className={styles.buttonsWrap}>
            <Button
              type="secondary"
              width="expanded"
              htmlType="submit"
              onClick={e => {
                e.preventDefault();
                e.stopPropagation();

                onSubmit({ documentList: [] });
              }}
            >
              {t('SkipBtn')}
            </Button>

            <Button
              type="primary"
              htmlType="submit"
              width="expanded"
              loading={isUploadingFiles}
            >
              {`${t('SendBtn')} ${
                isUploadingFiles ? t('FileLoading', { ns: 'Common' }) : ''
              }`}
            </Button>
          </div>
        </Spin>
      </form>
    </FormProvider>
  );
};

export const OrderCompletionModal = ({
  visible,
  onClose,
  orderStatus,
  resetReordering
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('Order');

  const onSubmit = useCallback(
    async ({ documentList }) => {
      try {
        setIsLoading(true);

        await dispatch(
          completeOrderStatus({
            id: orderStatus.id,
            documentList: getFileIdsV2(documentList)
          })
        );
        const fetchedOrderStatus = await dispatch(
          fetchOrderStatus({ id: orderStatus.id })
        );

        showNoticeMessage({
          number: fetchedOrderStatus.isLocked
            ? NOTICE_NUMBER.orderStatusRequestToContractorSended
            : NOTICE_NUMBER.orderStatusStateChanged
        });

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch, orderStatus.id]
  );

  const closeModal = () => {
    onClose();
    resetReordering();
  };

  return (
    <Modal
      centered
      title={t('OrderResultHeading')}
      visible={visible}
      width={1170}
      contentClassName={classnames(styles.root)}
      destroyOnClose
      onClose={closeModal}
    >
      <Alert
        type="warning"
        message={t('OrderResultWarning')}
        className={styles.alert}
      />

      <Form
        isLoading={isLoading}
        orderStatus={orderStatus}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

OrderCompletionModal.propTypes = {
  visible: PropTypes.bool,
  orderStatus: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  resetReordering: PropTypes.func
};

OrderCompletionModal.defaultProps = {
  visible: false,
  orderStatus: {},
  resetReordering: () => {}
};

export default OrderCompletionModal;

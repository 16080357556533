import React, { useEffect, useState, useCallback, useMemo } from 'react';
import { useDispatch } from 'react-redux';
import { Tooltip, Tree, Spin, Progress } from 'antd';
import moment from 'moment';
import { useTranslation } from 'react-i18next';

import {
  STATUS_DONE,
  STATUS_CANCELLED,
  TYPE_ORDER_STATUS
} from 'constants/index';

import CreatRelatedTaskBtn from 'components/common/create-task-btn';
import Icon from 'components/common/icon';
import SubtaskCard from 'components/tasks-view/view/drawers/components/subtask-card';
import CustomCollapse from 'components/tasks-view/view/drawers/components/collapse';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';

import { fetchTasksLocal } from 'store/tasks';

import styles from './tasks.module.scss';

const Tasks = ({ orderStatus, canCreateTask }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [tasks, setTasks] = useState([]);

  const { t } = useTranslation(['Order', 'Common']);

  const {
    id,
    orderId,
    title,
    content,
    deadlineAt,
    startAt,
    location,
    locationExact
  } = orderStatus;

  const initialTaskValue = useMemo(
    () => ({
      title: `${t('CompleteOrder', { ns: 'Common' })} ID ${orderId} ${title}`,
      description: content,
      dateStart: startAt && moment(startAt).toDate(),
      dateEnd: moment(deadlineAt).toDate(),
      location,
      locationExact,
      relations: transformValueToRelation(
        [
          {
            value: id,
            label: orderStatus
          }
        ],
        TYPE_ORDER_STATUS
      )
    }),
    [
      content,
      deadlineAt,
      id,
      location,
      locationExact,
      orderId,
      orderStatus,
      startAt,
      t,
      title
    ]
  );

  const fetchData = useCallback(async () => {
    try {
      setIsLoading(true);

      const { results } = await dispatch(
        fetchTasksLocal({ limit: 1000, offset: 0, orderStatus: id })
      );

      setTasks(results);
    } finally {
      setIsLoading(false);
    }
  }, [dispatch, id]);

  useEffect(() => {
    if (orderStatus.relations) {
      fetchData();
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [orderStatus.id, orderStatus.relations]);

  const getCountByStatus = useCallback(
    status => tasks.filter(subtask => subtask.status === status).length,
    [tasks]
  );

  const getDonePercent = useCallback(() => {
    const totalCount = tasks.length;
    const doneCount = getCountByStatus(STATUS_DONE);
    const cancelledCount = getCountByStatus(STATUS_CANCELLED);

    return Math.round((doneCount / (totalCount - cancelledCount)) * 100);
  }, [getCountByStatus, tasks]);

  const renderTitle = useCallback(
    () => (
      <div className={styles.title} data-qa="qa-csg1plobnfyhusg">
        <span style={{ marginRight: 5 }} data-qa="qa-b9d81ddsoopj21x">
          {t('TasksForOrderHeading')}
        </span>

        <Spin spinning={isLoading} size="small" />
      </div>
    ),
    [isLoading, t]
  );

  const renderStatistic = useCallback(
    () => (
      <div className={styles.statistic} data-qa="qa-3txluc5r9wp9i2k">
        <div className={styles.left} data-qa="qa-veiylyezt51sff9">
          <div>
            {t('SubtasksTotal')} <strong>{tasks.length}</strong>
          </div>

          <div className={styles.text} data-qa="qa-0d1zg1z25kwkyr6">
            {t('SubtasksDeclined')} {getCountByStatus(STATUS_CANCELLED)}
          </div>
        </div>

        <div className={styles.right} data-qa="qa-y24c6zdt9e92q8p">
          <Progress
            strokeLinecap="square"
            strokeWidth={5}
            percent={getDonePercent()}
            style={{ lineHeight: 1.2 }}
            data-qa="qa-6kw5nwuu1iufivd"
          />

          <span className={styles.text} data-qa="qa-co1b8ik5h6rindo">
            {t('SubtasksDone')} {getCountByStatus(STATUS_DONE)}
          </span>
        </div>
      </div>
    ),
    [getCountByStatus, getDonePercent, t, tasks.length]
  );

  const getTransformTask = useCallback(
    subtask => ({
      title: (
        <SubtaskCard
          {...subtask}
          className={styles.option}
          data-qa="qa-u2jjqqi1qwpgdsa"
        />
      ),
      key: subtask.id,
      children: (subtask.subTasks || []).map(getTransformTask)
    }),
    []
  );

  const treeData = tasks.map(getTransformTask);

  return (
    <div className={styles.root} data-qa="qa-w3cdhrqerodsilt">
      {canCreateTask && (
        <Tooltip placement="topRight">
          <CreatRelatedTaskBtn
            type="text"
            mood="none"
            className={styles.btn}
            data-qa="qa-30si9mk0kanq229"
            initialValue={initialTaskValue}
            createWithUrl={false}
          >
            <Icon type="plus" color="black-55" data-qa="qa-2rugt0vbszunkcs" />
          </CreatRelatedTaskBtn>
        </Tooltip>
      )}

      {tasks.length ? (
        <CustomCollapse defaultActiveKey={['tasks']}>
          <CustomCollapse.Panel
            header={renderTitle()}
            key="tasks"
            showArrow={tasks.length > 0}
          >
            {renderStatistic()}

            <Tree treeData={treeData} selectable={false} />
          </CustomCollapse.Panel>
        </CustomCollapse>
      ) : (
        <>{renderTitle()}</>
      )}
    </div>
  );
};

export default Tasks;

import { createSelector } from 'reselect';

export const getTags = state => state.tags;

export const getTagsByEntity = createSelector(
  getTags,
  tags => ({ entityId, entityType }) => {
    if (!tags[entityType]) {
      return undefined;
    }

    return tags[entityType][entityId];
  }
);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';
import { Spin, Tooltip } from 'antd';
import classnames from 'classnames';
import { Translation } from 'react-i18next';

import { ONLINE, OFFLINE, TESTID_ORDERS_ICON_ID } from 'constants/index';

import { OfflineIcon, OnlineIcon } from 'components/common/icons';
import Typography from 'components/common/typography';
import Icon from 'components/common/icon';

import styles from './order-icon-id.module.scss';

const TYPES = {
  [ONLINE]: {
    icon: OnlineIcon,
    tooltip: (
      <>
        <Translation ns="Order">{t => t('OnlineOrderTip')}</Translation>
        <br />(
        <Translation ns="Order">{t => t('OnlinerOrderDescTip')}</Translation>)
      </>
    )
  },
  [OFFLINE]: {
    icon: OfflineIcon,
    tooltip: (
      <>
        <Translation ns="Order">{t => t('OfflineOrderTip')}</Translation>
        <br />(
        <Translation ns="Order">{t => t('OfflineOrderDescTip')}</Translation>)
      </>
    )
  }
};

export const IconId = ({ orderId, contractor }) => {
  const getType = useCallback(isOnline => (isOnline ? ONLINE : OFFLINE), []);

  const type = getType(!!(contractor || {}).employee);

  return (
    <div className={classnames(styles.root)}>
      {contractor ? (
        <Tooltip
          title={TYPES[type].tooltip}
          mouseEnterDelay={0.5}
          placement="right"
        >
          <Icon
            component={TYPES[type].icon}
            size={16}
            className={styles.typeIcon}
            data-testid={TESTID_ORDERS_ICON_ID}
          />
        </Tooltip>
      ) : (
        <Spin size="small" />
      )}

      <Typography.Text>ID {orderId}</Typography.Text>
    </div>
  );
};

IconId.propTypes = {
  orderId: PropTypes.number.isRequired,
  contractor: PropTypes.shape({
    employee: PropTypes.number
  })
};

IconId.defaultProps = {
  contractor: null
};

export default IconId;

import React, { useState, useCallback } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { useTranslation } from 'react-i18next';

import { CONTRACT_DRAWER, TESTID_ORDERS_CONTRACT_MODAL } from 'constants/index';

import Modal from 'components/common/modal';
import CustomSpecificationForm from 'components/orders-view/forms/custom-specification';

import { fetchOrderStatus } from 'store/order-statuses';
import { setVisibleDrawer } from 'store/drawers';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

import styles from './contract.module.scss';

const OrderContractModal = ({
  visible,
  orderStatus,
  isChanging,
  previousData,
  onClose,
  onSubmit,
  resetReordering
}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);

  const { t } = useTranslation('OrderConditions');

  const handleSubmit = useCallback(
    async values => {
      try {
        setIsLoading(true);

        const withoutChangingState =
          isChanging && orderStatus.responsibleId === orderStatus.signatoryId;

        await onSubmit({ id: orderStatus.id, values });
        const fetchedOrderStatus = await dispatch(
          fetchOrderStatus({ id: orderStatus.id })
        );

        const getNoticeNumber = () => {
          if (fetchedOrderStatus.isLocked) {
            return NOTICE_NUMBER.orderStatusRequestToContractorSended;
          }

          return withoutChangingState
            ? NOTICE_NUMBER.orderStatusUpdated
            : NOTICE_NUMBER.orderStatusStateChanged;
        };

        showNoticeMessage({
          number: getNoticeNumber()
        });

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    [
      dispatch,
      isChanging,
      onClose,
      onSubmit,
      orderStatus.id,
      orderStatus.responsibleId,
      orderStatus.signatoryId
    ]
  );

  const closeModal = () => {
    onClose();
    resetReordering();
  };

  const defaultValues = {
    ...orderStatus,
    ...previousData,
    documentList: orderStatus.documentList
  };

  const specButtonProps = {
    allow: true,
    onClick: values => {
      dispatch(
        setVisibleDrawer({
          drawer: CONTRACT_DRAWER,
          data: {
            orderStatus,
            specificationData: values,
            isFromCustomSpecification: true,
            onSubmit: handleSubmit,
            resetReordering
          }
        })
      );
      onClose();
    }
  };

  return (
    <Modal
      title={t('OrderConditionsHeading')}
      width={576}
      visible={visible}
      contentClassName={styles.root}
      onClose={closeModal}
      destroyOnClose
      centered
      dataTestId={TESTID_ORDERS_CONTRACT_MODAL}
    >
      <CustomSpecificationForm
        defaultValues={defaultValues}
        isLoading={isLoading}
        specButtonProps={specButtonProps}
        onSubmit={handleSubmit}
      />
    </Modal>
  );
};

OrderContractModal.propTypes = {
  visible: PropTypes.bool,
  orderStatus: PropTypes.shape({}),
  isChanging: PropTypes.bool,
  previousData: PropTypes.shape({}),
  onClose: PropTypes.func.isRequired,
  resetReordering: PropTypes.func,
  onSubmit: PropTypes.func.isRequired
};

OrderContractModal.defaultProps = {
  visible: false,
  previousData: {},
  isChanging: false,
  orderStatus: {},
  resetReordering: () => {}
};

export default OrderContractModal;

import React from 'react';
import PropTypes from 'prop-types';
import classnames from 'classnames';
import { Tooltip } from 'antd';

import Typography from 'components/common/typography';

import ValueRemove from '../value-remove';

import styles from './option.module.scss';

export const Option = ({
  children,
  className,
  style,
  contentClassName,
  isClearable,
  isDisabled,
  isTag,
  isSmall,
  withTooltip,
  tooltipPlacement,
  square,
  onRemove,
  ellipsis,
  option
}) => {
  const hasDelete = typeof onRemove === 'function';

  return (
    <Tooltip
      title={typeof option === 'string' && withTooltip ? option : undefined}
      placement={tooltipPlacement}
      mouseEnterDelay={0.5}
    >
      <div
        className={classnames(className, styles.root, {
          [styles.withBtn]: hasDelete,
          [styles.tag]: isTag,
          [styles.small]: isSmall,
          [styles.square]: square
        })}
        style={style}
      >
        <Typography.Paragraph
          ellipsis={ellipsis}
          style={{
            marginBottom: 0
          }}
          className={contentClassName}
        >
          {children}
        </Typography.Paragraph>

        {hasDelete && isClearable && !isDisabled && (
          <ValueRemove
            onClick={onRemove}
            className={classnames(styles.btnRemove, {
              [styles.squareRemove]: square
            })}
          />
        )}
      </div>
    </Tooltip>
  );
};

Option.propTypes = {
  children: PropTypes.oneOfType([
    PropTypes.arrayOf(PropTypes.node),
    PropTypes.node
  ]),
  className: PropTypes.string,
  style: PropTypes.object,
  isClearable: PropTypes.bool,
  isDisabled: PropTypes.bool,
  // option выбранный в value
  isTag: PropTypes.bool,
  isSmall: PropTypes.bool,
  square: PropTypes.bool,
  onRemove: PropTypes.func,
  ellipsis: PropTypes.bool
};

Option.defaultProps = {
  children: undefined,
  className: undefined,
  style: {},
  isClearable: false,
  isDisabled: false,
  isTag: false,
  isSmall: false,
  square: false,
  onRemove: undefined,
  ellipsis: true
};

export default Option;

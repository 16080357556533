import { useEffect, useState } from 'react';
import { useSelector } from 'react-redux';

import { DETAIL_ORDER, MODAL, SELECTED_STATUS } from 'constants/index';

import { getRouterUrlQuery } from 'store/router';

export const useVisibleOrderDetails = (defaultState = false) => {
  const [visible, setVisible] = useState(defaultState);

  const query = useSelector(getRouterUrlQuery);
  const modal = query[MODAL];
  const id = query[SELECTED_STATUS];

  useEffect(() => {
    setVisible(!!(modal === DETAIL_ORDER && id));
  }, [id, modal]);

  return visible;
};

export default useVisibleOrderDetails;

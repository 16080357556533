import React, { useCallback, useEffect, useState } from 'react';
import { useDispatch, useSelector } from 'react-redux';
// import { Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import {
  BENCH,
  CHANNELS_CREATOR_DRAWER,
  CONTACT_CREATOR_DRAWER,
  // WRITE_FIRST_DRAWER,
  INTEGRATIONS
} from 'constants/index';

import Button from 'components/common/button';
import Icon from 'components/common/icon';
import Typography from 'components/common/typography';
import ActionsDropdown from 'components/common/actions';
import ImportContactsModal from 'components/contacts-view/import-contacts/modals/import-contacts';

import { setVisibleDrawer } from 'store/drawers';
import { getUserEmployee, getWorkspacePermissions } from 'store/workspace';
import {
  fetchEmailChannels,
  fetchWhatsAppChannels
  // getEmailAndWhatsAppChannels
} from 'store/channels';
import { getRouterUrlSubmenu } from 'store/router';

import styles from './view-info.module.scss';

const Contacts = () => {
  const dispatch = useDispatch();

  const [visibleContactsImportModal, setVisibleContactsImportModal] = useState(
    false
  );

  const employee = useSelector(getUserEmployee);
  // const { emailChannels, whatsAppChannels } = useSelector(
  //   getEmailAndWhatsAppChannels
  // );

  const subview = useSelector(getRouterUrlSubmenu);
  const permissions = useSelector(getWorkspacePermissions);

  const { t } = useTranslation('Contacts');

  const isIntegrationsSubview = subview === INTEGRATIONS;
  const isBench = !!employee.roles.find(({ type }) => type === BENCH);

  // const isActiveChannels = !![...emailChannels, ...whatsAppChannels].find(
  //   ({ isActive }) => isActive
  // );

  const showCreatorDrawer = useCallback(
    () =>
      dispatch(
        setVisibleDrawer({ drawer: CONTACT_CREATOR_DRAWER, data: true })
      ),
    [dispatch]
  );

  // const showWriteFirstDrawer = useCallback(
  //   () =>
  //     dispatch(
  //       setVisibleDrawer({
  //         drawer: WRITE_FIRST_DRAWER,
  //         data: true
  //       })
  //     ),
  //   [dispatch]
  // );

  const actions = [
    {
      title: 'AddContactManuallyBtn',
      ns: 'Contacts',
      allow: true,
      onClick: showCreatorDrawer
    },
    {
      title: 'AddContactImportBtn',
      ns: 'Contacts',
      allow: true,
      onClick: () => setVisibleContactsImportModal(true)
    }
  ];

  const onFetchChannels = async () => {
    await Promise.all([
      dispatch(
        fetchEmailChannels({
          hasChannelRole: employee.id,
          limit: 2
        })
      ),
      dispatch(
        fetchWhatsAppChannels({
          hasChannelRole: employee.id,
          limit: 2
        })
      )
    ]);
  };

  useEffect(() => {
    onFetchChannels();

    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);

  return (
    <>
      <div className={styles.contacts}>
        <Typography.Title level={2} className={styles.title}>
          {t('ContactsHeading')}
        </Typography.Title>

        {!isBench && !isIntegrationsSubview && (
          <ActionsDropdown
            actions={actions}
            overlayClassName={styles.dropdownContacts}
            btnStyle={{ padding: 0 }}
            visible
          >
            <Button type="primary" disabled={isBench} className={styles.button}>
              <Icon type="plus" />
              {t('AddContactBtn')}
            </Button>
          </ActionsDropdown>
        )}

        {isIntegrationsSubview && (
          <Button
            type="primary"
            className={styles.button}
            onClick={() =>
              dispatch(
                setVisibleDrawer({ drawer: CHANNELS_CREATOR_DRAWER, data: {} })
              )
            }
            disabled={!permissions.createChannel}
          >
            {t('ConnectChannelBtn')}
          </Button>
        )}

        {/* {isActiveChannels && !isIntegrationsSubview && ( */}
        {/*  <Tooltip title={t('WriteFirstTip')}> */}
        {/*    <Button */}
        {/*      type="secondary" */}
        {/*      onClick={showWriteFirstDrawer} */}
        {/*      className={styles.mailButton} */}
        {/*    > */}
        {/*      <Icon type="form" size={24} /> */}
        {/*    </Button> */}
        {/*  </Tooltip> */}
        {/* )} */}
      </div>

      <ImportContactsModal
        onClose={() => setVisibleContactsImportModal(false)}
        visible={visibleContactsImportModal}
        setVisible={setVisibleContactsImportModal}
      />
    </>
  );
};

export default Contacts;

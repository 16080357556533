import React, { useCallback, useState } from 'react';
import PropTypes from 'prop-types';
import { useDispatch } from 'react-redux';
import { Translation } from 'react-i18next';

import {
  LEADS,
  ORDER_CREATOR_DRAWER,
  TESTID_ORDERS_CREATOR_MODAL,
  TITLE_BY_KIND
} from 'constants/index';

import Modal from 'components/common/modal';
import ShortForm from 'components/orders-view/forms/short';

import { setVisibleDrawer } from 'store/drawers';
import {
  clearOrderStatusesEntries,
  createOrderStatus,
  fetchOrderStatuses
} from 'store/order-statuses';

import useModalsService from 'services/modals';

import styles from './creator.module.scss';

const OrderCreatorModal = ({ visible, data, onClose }) => {
  const dispatch = useDispatch();
  const modals = useModalsService();

  const [isLoading, setIsLoading] = useState(false);

  const onSubmit = useCallback(
    async values => {
      try {
        setIsLoading(true);

        const { identifier } = await dispatch(
          createOrderStatus({ values: { ...values, kind: data.defaultKind } })
        );

        modals.orders.showDetails({
          orderStatusId: identifier
        });

        dispatch(clearOrderStatusesEntries(LEADS));
        dispatch(fetchOrderStatuses({ statuses: LEADS }));

        onClose();
      } finally {
        setIsLoading(false);
      }
    },
    [data.defaultKind, dispatch, modals.orders, onClose]
  );

  const showCreatorDrawer = useCallback(
    values => {
      dispatch(
        setVisibleDrawer({
          drawer: ORDER_CREATOR_DRAWER,
          data: { ...data, defaultValues: values }
        })
      );
      onClose();
    },
    [data, dispatch, onClose]
  );

  return (
    <Modal
      title={
        <Translation ns={(TITLE_BY_KIND[data.defaultKind] || {}).ns}>
          {t => t((TITLE_BY_KIND[data.defaultKind] || {}).label)}
        </Translation>
      }
      visible={visible}
      onClose={onClose}
      destroyOnClose
      centered
      contentClassName={styles.root}
      width={576}
      dataTestId={TESTID_ORDERS_CREATOR_MODAL}
    >
      <ShortForm
        isLoading={isLoading}
        defaultValues={data.defaultValues}
        showMainForm={showCreatorDrawer}
        onSubmit={onSubmit}
      />
    </Modal>
  );
};

OrderCreatorModal.propTypes = {
  visible: PropTypes.bool,
  data: PropTypes.object,
  onClose: PropTypes.func.isRequired
};

OrderCreatorModal.defaultProps = {
  visible: false,
  data: {}
};

export default OrderCreatorModal;

import { useDispatch, useSelector } from 'react-redux';
import { useCallback, useEffect, useRef, useState } from 'react';

import { DASHBOARD, ITEMS_PER_PAGE, TYPE_EMPLOYEE } from 'constants/index';

import { getUserEmployee } from 'store/workspace';
import {
  changeNotificationChatDisplay,
  setEntityChatRoomUuids
} from 'store/operator';
import { getRouterUrlView } from 'store/router';

import useChat, { transformChatsToJoinedGroups } from '../use-chat';

const sortByIsRead = (a, b) => {
  if (a.isRead === b.isRead) {
    return 0;
  }

  return a.isRead ? -1 : 1;
};

const useEmployeeNotificationsChat = ({ afterVisibleChange, view }) => {
  const dispatch = useDispatch();

  const employee = useSelector(getUserEmployee);
  const urlView = useSelector(getRouterUrlView);

  const [fetchedSourceChatUuids, setFetchedSourceChatUuids] = useState([]);

  const messagesRef = useRef();

  const {
    hasMessages,
    isLoading,
    messages,
    hasMore,
    isReady,
    fetchMessages,
    joinChannels
  } = useChat({
    entity: employee,
    entityType: TYPE_EMPLOYEE,
    needJoin: false,
    needLeave: false,
    defaultNeedAutoScroll: false,
    customMessagesSort: sortByIsRead
  });

  const fetch = useCallback(
    async ({ offset, isRefetch }) => {
      const chat = await fetchMessages({
        readable: true,
        offset,
        isRefetch,
        limit: ITEMS_PER_PAGE,
        withAggregation: true
      });

      if (!isRefetch) {
        const sourceChats = chat.messages.reduce((acc, curr) => {
          if (curr.source) {
            return [...acc, { uuid: curr.source.roomUuid }];
          }

          return acc;
        }, []);

        const chatUuids = [
          ...new Set(transformChatsToJoinedGroups(sourceChats, TYPE_EMPLOYEE))
        ];

        setFetchedSourceChatUuids(chatUuids);
      }
    },
    [fetchMessages]
  );

  const loadMore = useCallback(() => fetch({ offset: messages.length }), [
    fetch,
    messages.length
  ]);

  useEffect(() => {
    if (fetchedSourceChatUuids.length && isReady) {
      joinChannels(fetchedSourceChatUuids, false);

      dispatch(
        setEntityChatRoomUuids([
          {
            entityId: employee.id,
            entityType: TYPE_EMPLOYEE,
            chats: fetchedSourceChatUuids.map(uuid => ({ roomUuid: uuid }))
          }
        ])
      );

      setFetchedSourceChatUuids([]);
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isReady, fetchedSourceChatUuids]);

  useEffect(() => {
    if (
      view === DASHBOARD ||
      (afterVisibleChange && (!messages.length || urlView !== DASHBOARD))
    ) {
      fetch({ offset: 0, isRefetch: !!messages.length });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [employee.id, afterVisibleChange]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  useEffect(() => () => dispatch(changeNotificationChatDisplay({ view })), []);

  return {
    hasMessages,
    isLoading,
    messages: [...messages].reverse(),
    hasMore,
    messagesRef,
    loadMore
  };
};

export default useEmployeeNotificationsChat;

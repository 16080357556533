import qs from 'qs';

export default api => ({
  create: ({ workspaceId, values }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/`, values),

  edit: ({ workspaceId, id, values }) =>
    api.put(`/v2/${workspaceId}/orders/order-statuses/${id}/`, values),

  fetch: ({ workspaceId, params, axiosCancelTokenSource }) =>
    api.get(`/v2/${workspaceId}/orders/order-statuses/`, {
      params,
      cancelToken: axiosCancelTokenSource && axiosCancelTokenSource.token,
      paramsSerializer(p) {
        return qs.stringify(p, { arrayFormat: 'comma' });
      }
    }),

  fetchOne: ({ workspaceId, id }) =>
    api.get(`/v2/${workspaceId}/orders/order-statuses/${id}/`, {
      show400: false
    }),

  fetchByIds: ({ workspaceId, ids }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/system/`, {
      ids
    }),

  accept: ({ workspaceId, id, values }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/${id}/accept/`, values),

  cancel: ({ workspaceId, id, reason }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/${id}/cancel/`, {
      reason
    }),

  complete: ({ workspaceId, id, documentList }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/${id}/complete/`, {
      documentList
    }),

  change: ({ workspaceId, id, values }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/${id}/change/`, values),

  reopen: ({ workspaceId, id }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/${id}/reopen/`, {}),

  cancelRequest: ({ workspaceId, id, requestId, reason, fileList }) =>
    api.post(
      `/v2/${workspaceId}/orders/order-statuses/${id}/requests/${requestId}/cancel/`,
      { reason, fileList }
    ),

  acceptRequest: ({ workspaceId, id, requestId }) =>
    api.post(
      `/v2/${workspaceId}/orders/order-statuses/${id}/requests/${requestId}/accept/`,
      {}
    ),

  rejectRequest: ({ workspaceId, id, requestId, reason, fileList }) =>
    api.post(
      `/v2/${workspaceId}/orders/order-statuses/${id}/requests/${requestId}/reject/`,
      { reason, fileList }
    ),

  fetchRequest: ({ workspaceId, id, requestId }) =>
    api.get(
      `/v2/${workspaceId}/orders/order-statuses/${id}/requests/${requestId}/`
    ),

  fetchAllRequests: ({ workspaceId, id }) =>
    api.get(`/v2/${workspaceId}/orders/order-statuses/${id}/requests/`),

  fetchContract: ({ workspaceId, id, contractId }) =>
    api.get(
      `/v2/${workspaceId}/orders/order-statuses/${id}/contracts/${contractId}/`
    ),

  fetchAllSpecifications: ({ workspaceId, id, contractId }) =>
    api.get(
      `/v2/${workspaceId}/orders/order-statuses/${id}/contracts/${contractId}/specifications/`
    ),

  fetchSpecification: ({ workspaceId, id, contractId, specificationId }) =>
    api.get(
      `/v2/${workspaceId}/orders/order-statuses/${id}/contracts/${contractId}/specifications/${specificationId}/`
    ),

  fetchAllActs: ({ workspaceId, id, contractId }) =>
    api.get(
      `/v2/${workspaceId}/orders/order-statuses/${id}/contracts/${contractId}/acts/`
    ),

  fetchAct: ({ workspaceId, id, contractId, actId }) =>
    api.get(
      `/v2/${workspaceId}/orders/order-statuses/${id}/contracts/${contractId}/acts/${actId}/`
    ),

  changeResponsible: ({ workspaceId, id, responsibleId }) =>
    api.put(`/v2/${workspaceId}/orders/order-statuses/${id}/responsible/`, {
      responsibleId
    }),

  changeSignatory: ({ workspaceId, id, signatoryId }) =>
    api.put(`/v2/${workspaceId}/orders/order-statuses/${id}/signatory/`, {
      signatoryId
    }),

  changeMembers: ({ employees, id, isDelete, workspaceId }) =>
    api.put(`/v2/${workspaceId}/orders/order-statuses/${id}/members/`, {
      employees,
      delete: isDelete
    }),

  fetchRelations: ({ workspaceId, id }) =>
    api.get(`/v2/${workspaceId}/orders/order-statuses/${id}/relations/`),

  changeRelations: ({ workspaceId, id, relations }) =>
    api.post(
      `/v2/${workspaceId}/orders/order-statuses/${id}/relations/bulk_update/`,
      {
        relations
      }
    ),

  fetchSchedulerOrderNextDate: ({
    workspaceId,
    startAt,
    interval,
    frequency,
    until
  }) =>
    api.post(`/v2/${workspaceId}/orders/order-statuses/scheduler-preview/`, {
      startAt,
      interval,
      frequency,
      until
    }),

  reopenAct: ({ workspaceId, id, contractId, actId }) =>
    api.post(
      `/v2/${workspaceId}/orders/order-statuses/${id}/contracts/${contractId}/acts/${actId}/reopen/`,
      {}
    ),

  changeAct: ({ workspaceId, id, contractId, actId, values }) =>
    api.put(
      `v2/${workspaceId}/orders/order-statuses/${id}/contracts/${contractId}/acts/${actId}/`,
      values
    )
});

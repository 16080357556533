import moment from 'moment';

import { DATE_TIME_FORMAT_FM } from 'constants/calendar';

import { getIsTaskOutdated } from './get-is-outdated';

export const transformTask = task => {
  if (!task) {
    return null;
  }

  const formatDate = date => moment(date).format(DATE_TIME_FORMAT_FM);

  const isOutdated = getIsTaskOutdated(task);

  return {
    ...task,
    fileList: task.fileList || [],
    members: task.assignments,
    relations: task.relations || [],
    dateStart: task.dateStart && formatDate(task.dateStart),
    dateEnd: task.dateEnd && formatDate(task.dateEnd),
    isOutdated
  };
};

export default transformTask;

import React, { useState, useEffect } from 'react';
import PropTypes from 'prop-types';
import { shallowEqual, useSelector } from 'react-redux';
import { Form, Input, Checkbox } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import PhoneInput from 'components/common/controls/phone-input';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import { getUserEmail, getUserPhone } from 'store/user';

import {
  validateIsRequire,
  validateIsPhone,
  validateMaxLength
} from 'utils/validators';

import styles from './styles.module.scss';

export const CreatorForm = Form.create()(
  ({
    form,
    className,
    btnClassName,
    values,
    isLoading,
    onSubmit,
    allowedFields
  }) => {
    const userEmail = useSelector(getUserEmail, shallowEqual);
    const userPhone = useSelector(getUserPhone, shallowEqual);

    const [isChecked, setIsChecked] = useState(false);

    const { t } = useTranslation('FiilEmployeeProfile');

    const onCheckboxChange = () => {
      if (isChecked) {
        form.setFieldsValue({ email: userEmail, phone: userPhone });
      } else {
        form.resetFields(['email', 'phone']);
      }
    };

    useEffect(() => {
      onCheckboxChange();
      // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isChecked]);

    const { getFieldDecorator, validateFields } = form;

    const handleSubmit = event => {
      event.preventDefault();
      validateFields((err, newValues) => {
        if (!err) {
          onSubmit({
            ...newValues,
            ...newValues.about
          });
        }
      });
    };

    return (
      <Form
        className={className}
        hideRequiredMark
        onSubmit={handleSubmit}
        colon={false}
      >
        {allowedFields.textEmployee && (
          <Typography.Paragraph>
            {t('FiilEmployeeProfileDesc')}
          </Typography.Paragraph>
        )}

        {allowedFields.phone && (
          <Form.Item label={t('CorporatePhone')}>
            {getFieldDecorator('phone', {
              initialValue: values.phone,
              rules: [
                validateIsRequire(),
                {
                  validator: validateIsPhone
                }
              ]
            })(<PhoneInput autoFocus />)}
          </Form.Item>
        )}

        {allowedFields.email && (
          <Form.Item label={t('CorporateEmail')}>
            {form.getFieldDecorator('email', {
              initialValue: values.email,
              rules: [validateIsRequire()]
            })(<Input type="email" />)}
          </Form.Item>
        )}

        {allowedFields.position && (
          <Form.Item label={t('YourPosition')}>
            {form.getFieldDecorator('position', {
              initialValue: values.position,
              rules: [validateIsRequire(), validateMaxLength(500)]
            })(<Input placeholder={t('YourPosition')} />)}
          </Form.Item>
        )}

        {allowedFields.registrationDataSelect && (
          <Form.Item>
            <Checkbox
              type="checkbox"
              onChange={() => setIsChecked(!isChecked)}
              checked={isChecked}
              className={classnames(styles.checkbox, 'small')}
            >
              {t('UseSignInInfoChckbx')}
            </Checkbox>
          </Form.Item>
        )}

        <Button
          type="primary"
          size="large"
          width="expanded"
          htmlType="submit"
          className={btnClassName}
          loading={isLoading}
        >
          {t('SaveBtn')}
        </Button>
      </Form>
    );
  }
);

CreatorForm.propTypes = {
  className: PropTypes.string,
  btnClassName: PropTypes.string,
  values: PropTypes.shape({
    name: PropTypes.string,
    owner: PropTypes.oneOf([PropTypes.string, PropTypes.number])
  }),
  onSubmit: PropTypes.func.isRequired,
  allowedFields: PropTypes.shape({
    phone: PropTypes.bool,
    email: PropTypes.bool,
    controller: PropTypes.bool,
    registrationDataSelect: PropTypes.bool
  }),
  isLoading: PropTypes.bool
};

CreatorForm.defaultProps = {
  className: undefined,
  btnClassName: undefined,
  values: {},
  isLoading: false
};

export default CreatorForm;

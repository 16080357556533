import { useReducer, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';

import { getWorkspaceId } from 'store/workspace';
import { fetchAttachmentsLocal } from 'store/attachments';
import { ITEMS_PER_PAGE } from 'constants/index';

const actionTypes = {
  setTotalItems: 'TOTAL',
  setIsLoading: 'LOADING',
  setHasError: 'ERROR',
  setEntries: 'ENTRIES'
};

const initialState = {
  totalItems: 0,
  isLoading: false,
  hasError: undefined,

  entries: []
};

const dataReducer = (state, { type, payload }) => {
  switch (type) {
    case actionTypes.setTotalItems: {
      return { ...state, totalItems: payload };
    }

    case actionTypes.setHasError: {
      return { ...state, hasError: payload };
    }

    case actionTypes.setIsLoading: {
      return { ...state, isLoading: payload };
    }

    case actionTypes.setEntries: {
      return { ...state, entries: payload };
    }

    default: {
      throw new Error(type);
    }
  }
};

export const useData = ({ reducer = dataReducer, filters } = {}) => {
  const [data, localDispatch] = useReducer(reducer, initialState);
  const workspaceId = useSelector(getWorkspaceId);
  const dispatch = useDispatch();
  const limit = ITEMS_PER_PAGE * 2;

  const setHasError = payload =>
    localDispatch({ type: actionTypes.setHasError, payload });
  const setTotalItems = payload =>
    localDispatch({ type: actionTypes.setTotalItems, payload });
  const setIsLoading = payload =>
    localDispatch({ type: actionTypes.setIsLoading, payload });

  const setEntries = payload =>
    localDispatch({ type: actionTypes.setEntries, payload });

  const fetchData = async () => {
    try {
      setIsLoading(true);
      setHasError(false);

      const offset = limit * (filters.currentPage - 1);

      const { entries, totalItems } = await dispatch(
        fetchAttachmentsLocal({ ...filters, isTrash: false, offset, limit })
      );

      setEntries(entries);
      setTotalItems(totalItems);
    } catch {
      setHasError(true);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (!data.isLoading && !data.hasError) {
      fetchData();
    }

    // eslint-disable-next-line
  }, [workspaceId, filters]);

  return {
    data,

    setHasError,
    setTotalItems,
    setIsLoading,
    setEntries,
    fetchData
  };
};

export default useData;

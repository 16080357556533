import api from 'api';

import createActionThunk from '../actions-thunk';
import { getWorkspaceId } from '../workspace';

export const fetchTagsLocal = createActionThunk(
  'tags/fetch',
  ({ getState, search, limit, offset }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tags
      .fetch({ workspaceId, search, limit, offset })
      .then(({ data }) => data);
  }
);

export const fetchTagsByEntity = createActionThunk(
  'tags/fetch-by-entity',
  ({ getState, entityType, entityIds }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tags
      .fetchByEntity({ workspaceId, entityType, entityIds })
      .then(({ data }) => data.data);
  }
);

export const createTag = createActionThunk(
  'tags/create-tag',
  ({ getState, tag }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tags.create({ workspaceId, tag }).then(({ data }) => data.data);
  }
);

export const addTag = createActionThunk('tags/add-tag', ({ getState, tag }) => {
  const state = getState();
  const workspaceId = getWorkspaceId(state);

  return api.tags.add({ workspaceId, tag });
});

export const deleteTag = createActionThunk(
  'tags/delete-tag',
  ({ getState, tag }) => {
    const state = getState();
    const workspaceId = getWorkspaceId(state);

    return api.tags.delete({ workspaceId, tag }).then(({ data }) => data);
  }
);

import React, { useCallback } from 'react';
import PropTypes from 'prop-types';

import ContactEditorForm from 'components/contacts-view/contact-view/editor-form';
import RightSidePanel from 'components/common/right-side-panel';

import { useCollapsedContractorPanel } from './use-collapsed-contractor-panel';
import useSmallListOpened from '../small-list/use-small-list-opened';

import styles from './contractor-panel.module.scss';

export const ContractorPanel = ({ orderStatusId, contractor }) => {
  const [, setOpenedSmallList] = useSmallListOpened();
  const collpasedCallback = useCallback(
    collapsed => {
      if (!collapsed) {
        setOpenedSmallList(false);
      }
    },
    [setOpenedSmallList]
  );

  const [
    collapsedContractor,
    setCollapsedContractor
  ] = useCollapsedContractorPanel(collpasedCallback);

  return (
    <RightSidePanel
      width={330}
      collapsed={collapsedContractor}
      setCollapsed={setCollapsedContractor}
    >
      <ContactEditorForm
        contact={contractor}
        orderStatusId={orderStatusId}
        className={styles.contactEditor}
      />
    </RightSidePanel>
  );
};

ContractorPanel.propTypes = {
  contractor: PropTypes.shape({}).isRequired
};

export default ContractorPanel;

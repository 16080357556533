export const CREATOR_DRAWER_WIDTH = 600;

export const CONTACT_CREATOR_DRAWER = 'contactCreator';
export const CONTACTS_IMPORT_DRAWER = 'contactsImport';
export const CONTACTS_IMPORT_RESULTS_DRAWER = 'contactsImportResults';

export const ORDER_CREATOR_DRAWER = 'orderCreator';
export const ORDER_COPY_DRAWER = 'orderCopy';
export const ORDER_EDIT_DRAWER = 'orderEdit';

export const CHANNELS_CREATOR_DRAWER = 'channelsCreator';
export const CHANNELS_EDITOR_DRAWER = 'channelsEditor';

export const ASSIGNMENTS_CHANGE_DRAWER = 'assignmentsChange';

export const RELATIONS_DRAWER = 'relationsDrawer';

export const CONTRACT_DRAWER = 'contractDrawer';

export const ACT_EDITOR_DRAWER = 'actEditorDrawer';

export const WRITE_FIRST_DRAWER = 'writeFirstDrawer';

export const SAVE_TO_DISK_DRAWER = 'saveToDisk';

export const INVITE_EMPLOYEE_DRAWER = 'inviteEmployee';

export const MESSAGE_TEMPLATES_DRAWER = 'messageTemplates';

export const ASSET_CREATOR_DRAWER = 'assetCreator';

export const TASK_CREATOR_DRAWER = 'taskCreator';

export const CREATE_DEPARTMENT_DRAWER = 'createDepartment';

export const MOVE_DEPARTMENT_DRAWER = 'moveDepartment';

import React, { useState } from 'react';
import { push } from 'connected-react-router';
import { useLocation } from 'react-router-dom';
import { useSelector, useDispatch, shallowEqual } from 'react-redux';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { CONTROLLER, SELECTED_EMPLOYEE, OWNER } from 'constants/index';

import Drawer from 'components/common/drawer';
import OnboardingModal from 'components/common/onboarding/modal';
// import PhoneConfirmationModal from 'components/common/phone-confirmation-modal';

import { getEmployeeFactory, updateEmployee } from 'store/team/employees';
import { getRouterState } from 'store/router';
import {
  updateController,
  getDepartmentEmployeeFactory,
  updateDepartmentEmployee
} from 'store/team/departments';
import {
  getWorkspacePermissions,
  getIsIndividual,
  getUserEmployee,
  updateWorkspaceUser,
  updateWorkspace,
  getActiveWorkspace
} from 'store/workspace';

import useQueryParam from 'hooks/common/use-query-param';
import { useModalsService } from 'services/modals';
import { useFileUpload } from 'hooks';
import { reconnectSockets } from 'socket';
import { showNoticeMessage } from 'services/notice';

import CreatorForm from './form';

import styles from '../styles.module.scss';

export const EditorDrawer = ({ className, title, ...drawerProps }) => {
  const dispatch = useDispatch();
  const location = useLocation();
  const modals = useModalsService();
  const { getFileIds } = useFileUpload();

  const id = Number(useQueryParam(SELECTED_EMPLOYEE));
  const { departmentId, employeeId, isFromDepartment } = useSelector(
    getRouterState
  );

  const departmentEmployee = useSelector(state =>
    getDepartmentEmployeeFactory(state)(departmentId, employeeId)
  );

  const selectedEmployee = useSelector(state =>
    getEmployeeFactory(state)(employeeId)
  );

  const employee =
    (isFromDepartment ? departmentEmployee : selectedEmployee) || {};

  const workspace = useSelector(getActiveWorkspace, shallowEqual);
  const userEmployee = useSelector(getUserEmployee);
  const isIndividual = useSelector(getIsIndividual);

  const isThatRole = (roles, role) =>
    (roles || []).some(item => item.type === role);

  const isController = isThatRole(employee.roles, CONTROLLER);
  const isOwner = isThatRole(userEmployee.roles, OWNER);

  const perms = useSelector(getWorkspacePermissions);

  const [isLoading, setIsLoading] = useState(false);
  const [visibleModal, setVisibleModal] = useState(false);

  const { t } = useTranslation('FiilEmployeeProfile');

  // const [visiblePhoneConfirmation, setVisiblePhoneConfirmation] = useState(
  //   false
  // );

  // const [submitData, setSubmitData] = useState({});

  const { isAfterRegister, isCreateTeam } = location.state || {};

  const getAllowFields = () => {
    // Если выбрано редактирование сотрудника
    if (id) {
      if (!employee) {
        return {};
      }

      return {
        email: (employee.permissions || {}).editEmployee,
        phone: (employee.permissions || {}).editEmployee,
        position: (employee.permissions || {}).editEmployee && !isIndividual,
        controllerType: isController
          ? perms.editWorkspaceController
          : undefined,
        postCode: isIndividual,
        description: isIndividual,
        textEmployee: !isIndividual
      };
    }

    // Принудительная форма заполнения
    return {
      email: userEmployee.permissions.editEmployee,
      phone: userEmployee.permissions.editEmployee,
      position: userEmployee.permissions.editEmployee && !isIndividual,
      textEmployee: !isIndividual,
      registrationDataSelect: true
    };
  };

  const getValues = () => {
    if (id && employee) {
      if (isIndividual) {
        return {
          email: employee.email,
          phone: employee.phone,
          controllerType: (employee.controller || {}).type,
          postCode: workspace.postCode,
          description: workspace.description,
          fileList: workspace.fileList
        };
      }

      return {
        email: employee.email,
        phone: employee.phone,
        position: employee.position,
        controllerType: (employee.controller || {}).type
      };
    }

    return {
      email: userEmployee.email,
      phone: userEmployee.phone,
      position: userEmployee.position
    };
  };

  const onSubmit = async newValues => {
    try {
      setIsLoading(true);

      if (id) {
        await dispatch(
          updateEmployee({
            employee: {
              id,
              email: newValues.email,
              phone: newValues.phone,
              position: newValues.position
            },
            departmentId: employee.departmentId
          })
        ).then(data => dispatch(updateDepartmentEmployee(data)));

        if (id === userEmployee.id) {
          await dispatch(
            updateWorkspaceUser({
              user: {
                id,
                email: newValues.email,
                phone: newValues.phone,
                position: newValues.position
              }
            })
          );
        }

        const workspaceData = {
          id: employee.workspaceId,
          postCode: newValues.postCode,
          description: newValues.description,
          fileList: getFileIds(newValues.fileList)
        };

        if (isIndividual) {
          await dispatch(updateWorkspace({ workspace: workspaceData }));

          if (newValues.controllerType) {
            await dispatch(
              updateController({
                employee: id,
                type: newValues.controllerType
              })
            );
          }
        }
      }

      if (!id && userEmployee) {
        await dispatch(
          updateWorkspaceUser({
            user: {
              id: userEmployee.id,
              email: newValues.email,
              phone: newValues.phone,
              position: newValues.position,
              afterRegistration: isAfterRegister
            }
          })
        );
      }

      showNoticeMessage();

      modals.close(location.state);

      if (isAfterRegister) {
        reconnectSockets();
      }

      if (isAfterRegister && isCreateTeam && isOwner) {
        setVisibleModal(true);
      }
    } finally {
      setIsLoading(false);
    }
  };

  // eslint-disable-next-line arrow-body-style
  const handleSubmit = newValues => {
    // if (
    //   !workspace.isNotVerified &&
    //   employee.isPhoneVerified &&
    //   newValues.phone.replace(/[- )(]/g, '') !== employee.phone &&
    //   id
    // ) {
    //   setSubmitData(newValues);
    //   return setVisiblePhoneConfirmation(true);
    // }

    return onSubmit(newValues);
  };

  const getTitle = () => {
    if (title) {
      return title;
    }

    return t('EditEmployeeProfile');
  };

  return (
    <>
      <Drawer
        {...drawerProps}
        width={480}
        title={<Drawer.Title>{getTitle()}</Drawer.Title>}
        destroyOnClose
        className={className}
      >
        <CreatorForm
          allowedFields={getAllowFields()}
          values={getValues()}
          onSubmit={handleSubmit}
          isLoading={isLoading}
          className={styles.form}
          btnClassName={styles.btn}
        />
      </Drawer>

      <OnboardingModal
        visible={visibleModal}
        onClose={() => {
          setVisibleModal(false);
          dispatch(push({ isAfterRegister: false }));
        }}
      />

      {/* <PhoneConfirmationModal
        visible={visiblePhoneConfirmation}
        employee={{ ...userEmployee, ...submitData }}
        onClose={() => setVisiblePhoneConfirmation(false)}
        callback={() => onSubmit(submitData)}
      /> */}
    </>
  );
};

EditorDrawer.propTypes = {
  className: PropTypes.string,
  title: PropTypes.string
};

EditorDrawer.defaultProps = {
  className: undefined,
  title: undefined
};

export default EditorDrawer;

import React, { createContext, useState, useEffect } from 'react';
import PropTypes from 'prop-types';

import { showNoticeMessage } from 'services/notice';

export const FieldEditorContext = createContext({
  editableFields: [],
  fieldMap: {},
  cancel: () => {},
  save: () => {},
  edit: () => {},
  remove: () => {},
  checkField: () => {},
  isEditingCancelled: false
});

export const FieldEditorProvider = ({
  children,
  update,
  changeCurrentValues,
  fieldMap,
  isEditingCancelled
}) => {
  const [editableFields, setEditableFields] = useState([]);

  const cancel = ({ field } = {}) => {
    if (field) {
      setEditableFields(prev => prev.filter(p => p !== field));
    } else {
      setEditableFields([]);
    }
  };

  const save = async ({ field, values }) => {
    await update({ field, values });

    cancel({ field });

    showNoticeMessage();
  };

  const edit = ({ field }) => setEditableFields(prev => [...prev, field]);

  const remove = ({ field }) => save({ field, values: { [field]: null } });

  const checkField = field => editableFields.includes(field);

  useEffect(() => {
    if (isEditingCancelled) {
      cancel();
    }
  }, [isEditingCancelled]);

  return (
    <FieldEditorContext.Provider
      value={{
        fieldMap,
        editableFields,
        changeCurrentValues,
        cancel,
        save,
        edit,
        remove,
        checkField
      }}
    >
      {children}
    </FieldEditorContext.Provider>
  );
};

FieldEditorProvider.propTypes = {
  update: PropTypes.func.isRequired,
  changeCurrentValues: PropTypes.func,
  fieldMap: PropTypes.object.isRequired,
  isEditingCancelled: PropTypes.bool
};

FieldEditorProvider.defaultProps = {
  changeCurrentValues: () => {},
  isEditingCancelled: false
};

export default FieldEditorProvider;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import { ORDER_CREATOR_DRAWER } from 'constants/index';

// import PhoneConfirmationModal from 'components/common/phone-confirmation-modal';
import Button from 'components/common/button';
import OrderCreatorModal from 'components/orders-view/modals/creator';

// import { getActiveWorkspace, getUserEmployee } from 'store/workspace';
import { setVisibleDrawer } from 'store/drawers';

export const CreateOrderBtn = ({
  children,
  withCreatorModal,
  data,
  onClick,
  ...props
}) => {
  const dispatch = useDispatch();

  // const [visiblePhoneConfirmation, setVisiblePhoneConfirmation] = useState(
  //   false
  // );
  const [visibleCreatorModal, setVisibleCreatorModal] = useState(false);

  // const employee = useSelector(getUserEmployee);
  // const workspace = useSelector(getActiveWorkspace);

  const openDrawer = () => {
    if (withCreatorModal) {
      return setVisibleCreatorModal(true);
    }

    return dispatch(setVisibleDrawer({ drawer: ORDER_CREATOR_DRAWER, data }));
  };

  const handleClick = ({ event } = {}) => {
    if (event) {
      event.stopPropagation();
    }

    // if (!employee.isPhoneVerified && !workspace.isNotVerified) {
    //   return setVisiblePhoneConfirmation(true);
    // }

    if (onClick) {
      return onClick();
    }

    return openDrawer();
  };

  return (
    <>
      <Button {...props} onClick={e => handleClick({ event: e })}>
        {children}
      </Button>

      <OrderCreatorModal
        visible={visibleCreatorModal}
        onClose={() => setVisibleCreatorModal(false)}
        data={data}
      />

      {/* <PhoneConfirmationModal
        visible={visiblePhoneConfirmation}
        employee={employee}
        description={
          <>
            Подтверждение номера телефона необходимо <br /> для обеспечения
            безопасной работы наших <br /> пользователей
          </>
        }
        onClose={() => setVisiblePhoneConfirmation(false)}
        callback={onClick || openDrawer}
      /> */}
    </>
  );
};

CreateOrderBtn.propTypes = {
  onClick: PropTypes.func,
  data: PropTypes.object,
  withCreatorModal: PropTypes.bool
};

CreateOrderBtn.defaultProps = {
  onClick: undefined,
  data: {},
  withCreatorModal: false
};

export default CreateOrderBtn;

import React, { useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';

import {
  TYPE_ORDER,
  ORDER_COPY_DRAWER,
  ORDER_EDIT_DRAWER,
  LEADS
} from 'constants/index';

import ActionsDropdown from 'components/common/actions';
import TaskCreatorDrawer from 'components/tasks-view/view/drawers/creator';
import { transformValueToRelation } from 'components/common/controls/custom-select/relations-select/utils';

import { setVisibleDrawer } from 'store/drawers';

import styles from './actions.module.scss';

export const Actions = ({ orderStatus, ...props }) => {
  const dispatch = useDispatch();

  // const updateTags = useUpdateTags();

  // const tags = useSelector(state =>
  //   getTagsByEntity(state)({
  //     entityType: TYPE_ORDER,
  //     entityId: selectedStatus || (orderStatus || {}).id
  //   })
  // );

  const [taskVisible, setTaskVisible] = useState(false);

  const { id, permissions, state } = orderStatus;

  const canShowEditAction =
    permissions && permissions.canUpdateOrder && state === LEADS;

  const copy = () =>
    dispatch(
      setVisibleDrawer({
        drawer: ORDER_COPY_DRAWER,
        data: {
          id
        }
      })
    );

  const edit = () =>
    dispatch(
      setVisibleDrawer({
        drawer: ORDER_EDIT_DRAWER,
        data: orderStatus
      })
    );

  // const onSaveAsTemplate = async () => {
  //   const toWorkspace = getWorkspace(contact, order.isMarketplace);
  //   const contactId = getContact(contact, order.isMarketplace);

  //   const templateOrder = {
  //     ...order,
  //     relations: [],
  //     isCustomer: order.isMarketplace ? null : isCustomer,
  //     workspace: toWorkspace,
  //     contact: contactId || undefined,
  //     controller: (controller || {}).id
  //   };

  //   const template = await dispatch(createTemplate({ order: templateOrder }));

  //   updateTags({
  //     entityType: TYPE_ORDER_TEMPLATE,
  //     entityId: template.id,
  //     newTags: (tags || []).map(t => ({ value: t.id, label: t }))
  //   });

  //   message.success('TemplateSaved', MESSAGE_DURATION);
  // };

  const actions = [
    // {
    //   title: 'SaveAsTemplate',
    //   onClick: onSaveAsTemplate,
    //   allow: true
    // },
    {
      title: 'CopyOrderAction',
      ns: 'Order',
      onClick: copy,
      allow: true
    },
    {
      title: 'EditOrderAction',
      ns: 'Order',
      onClick: edit,
      allow: canShowEditAction
    }
    // {
    //   title: 'CreateTask',
    //   onClick: () => setTaskVisible(true),
    //   allow: true
    // }
  ];

  if (!orderStatus) {
    return null;
  }

  return (
    <>
      <ActionsDropdown
        actions={actions}
        {...props}
        overlayClassName={styles.overlayClassName}
        data-qa="qa-1twxp88j0id1avw"
      />

      <TaskCreatorDrawer
        value={{
          relations: transformValueToRelation(
            [
              {
                value: id,
                label: orderStatus
              }
            ],
            TYPE_ORDER
          )
        }}
        visible={taskVisible}
        onClose={() => setTaskVisible(false)}
      />
    </>
  );
};

Actions.propTypes = {
  orderStatus: PropTypes.shape({
    id: PropTypes.string
  })
};

Actions.defaultProps = {
  orderStatus: {}
};

export default Actions;

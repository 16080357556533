import React, { useEffect, useState } from 'react';
import { useDispatch } from 'react-redux';
import PropTypes from 'prop-types';
import { Spin, Empty } from 'antd';
import classnames from 'classnames';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import { MyDriveIcon } from 'components/common/icons/multicolor';
import Attachments from 'components/common/attachments';

import {
  fetchOrderStatusAllActsLocal,
  fetchOrderStatusAllSpecifications,
  fetchOrderStatusAttachments
} from 'store/order-statuses';

import styles from './list.module.scss';

export const List = ({ orderStatus, isAfterVisible }) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [documents, setDocuments] = useState([]);

  const { t } = useTranslation('Common');

  const { id, contract } = orderStatus;

  const fetch = async () => {
    try {
      setIsLoading(true);

      const { results: documentList } = await dispatch(
        fetchOrderStatusAttachments({
          params: {
            relatedOrderStatuses: id
          }
        })
      );

      const results = documentList;

      if (contract) {
        const [
          { _embedded: specificationsEmbedded = {} },
          { _embedded: actsEmbedded = {} }
        ] = await Promise.all([
          dispatch(
            fetchOrderStatusAllSpecifications({ id, contractId: contract })
          ),
          dispatch(fetchOrderStatusAllActsLocal({ id, contractId: contract }))
        ]);

        const resultEmbedded = [
          ...(specificationsEmbedded.specifications || []),
          ...(actsEmbedded.acts || [])
        ];

        resultEmbedded.forEach(specOrAct => {
          const docsWithState = results.filter(result =>
            specOrAct.documentList.includes(result.fileId)
          );

          docsWithState.forEach(doc => {
            const index = results.findIndex(
              result => result.fileId === doc.fileId
            );

            if (index !== -1) {
              results[index] = {
                ...results[index],
                acceptedState: specOrAct.state
              };
            }
          });
        });
      }

      setDocuments(results);
    } finally {
      setIsLoading(false);
    }
  };

  useEffect(() => {
    if (isAfterVisible) {
      fetch();
    }

    return () => setDocuments([]);
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [isAfterVisible]);

  return (
    <Spin
      spinning={isLoading}
      wrapperClassName={classnames(styles.root, {
        [styles.empty]: !documents.length
      })}
    >
      <Attachments
        fileList={documents}
        actionsDeps={{
          statusId: id,
          sendCopyToComment: false,
          allowDeleteFrom: false
        }}
        showDownloadAllButton
      />

      {!documents.length && !isLoading && isAfterVisible && (
        <Empty
          image={<Icon component={MyDriveIcon} />}
          imageStyle={{ height: 'auto', marginBottom: 10 }}
          className={styles.emptyContainer}
          description={<Typography.Text>{t('EmptyList')}</Typography.Text>}
        />
      )}
    </Spin>
  );
};

List.propTypes = {
  orderStatus: PropTypes.shape({}),
  isAfterVisible: PropTypes.bool
};

List.defaultProps = {
  orderStatus: {},
  isAfterVisible: false
};

export default List;

import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import Chat from 'components/common/chat';
import useNotificationMessageChat from 'components/common/chat/entity-hooks/use-notification-message-chat';

import { showNoticeMessage } from 'services/notice';

import styles from './notification-message-chat.module.scss';

const NotificationMessageChat = ({
  entity,
  entityFromNotification,
  message,
  onRead
}) => {
  const { t } = useTranslation(['Contacts']);

  const { isRead } = message;

  const sendMessageCallback = () => {
    showNoticeMessage({
      customContent: t('MessageSentTip')
    });

    if (!isRead) {
      onRead();
    }
  };

  const {
    renderedMessages,
    renderedControls,
    messagesRef,
    hasMessages,
    isLoading,
    toBottom,
    isJoined,
    hasMore,
    loadMore: fetchMessages
  } = useNotificationMessageChat({
    entity,
    entityFromNotification,
    sendMessageCallback
  });

  return (
    <Chat
      messagesRef={messagesRef}
      isLoading={isLoading}
      hasMessages={hasMessages}
      messages={renderedMessages}
      toBottom={toBottom}
      controls={renderedControls}
      isEntityLoading={!isJoined}
      hasMore={hasMore}
      loadMore={fetchMessages}
      infiniteClassName={styles.infiniteChat}
      className={styles.rootChat}
    />
  );
};

NotificationMessageChat.propTypes = {
  entity: PropTypes.object.isRequired,
  entityFromNotification: PropTypes.object.isRequired,
  message: PropTypes.shape({
    sender: PropTypes.object,
    createdAt: PropTypes.string,
    text: PropTypes.arrayOf(PropTypes.shape({ text: PropTypes.string })),
    source: PropTypes.shape({}),
    notificationData: PropTypes.object
  }).isRequired,
  onRead: PropTypes.func.isRequired
};

export default NotificationMessageChat;

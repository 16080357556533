import React from 'react';
import PropTypes from 'prop-types';
import { Spin } from 'antd';
import { useTranslation } from 'react-i18next';

import Modal from 'components/common/modal';
import Typography from 'components/common/typography';
import Button from 'components/common/button';

import useModalsService from 'services/modals';

import styles from './agreement.module.scss';

const OrderAgreementModal = ({
  visible,
  orderStatus,
  onClose,
  isLoading,
  onAccept,
  onReject,
  resetDraggable,
  resetReordering
}) => {
  const modals = useModalsService();

  const { t } = useTranslation('DocumentsApprovalBoard');

  const handleOnAccept = async () => {
    await onAccept({
      orderStatusId: orderStatus.id,
      requestId: orderStatus.request.id,
      state: orderStatus.state
    });
    resetDraggable();
    onClose();
  };

  const handleOnReject = () => {
    onReject();
    onClose();
  };

  const closeModal = () => {
    onClose();
    resetDraggable();
    resetReordering();
  };

  const showDetails = () => {
    closeModal();
    modals.orders.showDetails({ orderStatusId: orderStatus.id });
  };

  return (
    <Modal
      width={508}
      title={t('DocumentsApprovalBoardHeading')}
      visible={visible}
      destroyOnClose
      centered
      contentClassName={styles.root}
      onClose={closeModal}
    >
      <Typography.Text>{t('DocumentsApprovalBoardDesc')}</Typography.Text>

      <Spin spinning={isLoading}>
        <div className={styles.buttons}>
          <Button
            type="secondary"
            className={styles.button}
            onClick={handleOnAccept}
          >
            {t('ApproveBtn')}
          </Button>

          <Button
            type="secondary"
            mood="negative"
            className={styles.button}
            onClick={handleOnReject}
          >
            {t('RejectBtn')}
          </Button>
        </div>
      </Spin>

      <Button type="link" className={styles.link} onClick={showDetails}>
        {t('ShowOrderDetailsBtn')}
      </Button>
    </Modal>
  );
};

OrderAgreementModal.propTypes = {
  visible: PropTypes.bool,
  orderStatus: PropTypes.shape({
    id: PropTypes.string,
    request: PropTypes.shape({
      id: PropTypes.string
    })
  }),
  onClose: PropTypes.func.isRequired,
  isLoading: PropTypes.bool,
  onAccept: PropTypes.func.isRequired,
  onReject: PropTypes.func.isRequired,
  resetDraggable: PropTypes.func.isRequired,
  resetReordering: PropTypes.func.isRequired
};

OrderAgreementModal.defaultProps = {
  visible: false,
  isLoading: false,
  orderStatus: {}
};

export default OrderAgreementModal;

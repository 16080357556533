import moment from 'moment';
import { useState, useCallback } from 'react';
import { useDispatch } from 'react-redux';
import maxBy from 'lodash/maxBy';

import {
  CONTRACT_DRAWER,
  REQUEST_KIND_APPROVING_SCHEDULING_ACT,
  SPEC_KIND_SYSTEM,
  ACT_EDITOR_DRAWER
} from 'constants/index';

import {
  acceptOrderStatusLead,
  acceptOrderStatusRequest,
  cancelOrderStatusRequest,
  fetchOrderStatus,
  fetchOrderStatusAllSpecifications,
  changeOrderStatus as changeOrderStatusAction,
  reopenOrderStatus as reopenOrderStatusAction,
  fetchOrderStatusContract,
  completeOrderStatus as completeOrderStatusAction,
  reopenOrderStatusAct,
  fetchOrderStatusAllActs,
  changeAct
} from 'store/order-statuses';
import { setVisibleDrawer } from 'store/drawers';

import { NOTICE_NUMBER, showNoticeMessage } from 'services/notice';

const useChangeState = ({
  resetDraggable = () => {},
  resetReordering = () => {}
} = {}) => {
  const dispatch = useDispatch();

  const [isLoading, setIsLoading] = useState(false);
  const [previousData, setPreviousData] = useState({});

  const [
    visibleAcceptanceContractModal,
    setVisibleAcceptanceContractModal
  ] = useState(false);
  const [
    visibleChangingContractModal,
    setVisibleChangingContractModal
  ] = useState(false);
  const [visibleRejectionModal, setVisibleRejectionModal] = useState(false);
  const [visibleCancellationModal, setVisibleCancellationModal] = useState(
    false
  );
  const [visibleCompletionModal, setVisibleCompletionModal] = useState(false);
  const [visibleAgreementModal, setVisibleAgreementModal] = useState(false);
  const [visibleCancelActModal, setVisibleCancelActModal] = useState(false);

  const fetchPreviousSpecification = useCallback(
    async ({ orderStatusId, contractId }) => {
      try {
        setIsLoading(true);

        const { _embedded } = await dispatch(
          fetchOrderStatusAllSpecifications({ id: orderStatusId, contractId })
        );

        let latestSpecification = {};

        if (_embedded && _embedded.specifications.length) {
          latestSpecification = maxBy(_embedded.specifications, spec =>
            moment(spec.createdAt).valueOf()
          );
        }

        // let specifications = [];

        // if ((latestSpecification.documentList || []).length) {
        //   const { results } = await dispatch(
        //     fetchOrderStatusAttachments({
        //       params: { fileIds: latestSpecification.documentList }
        //     })
        //   );

        //   specifications = results;
        // }

        const result = {
          ...latestSpecification
          // specifications
        };

        setPreviousData(result);
        return result;
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const acceptOrderStatus = useCallback(
    async ({ orderStatusId, contractId }) => {
      setPreviousData({});

      if (contractId) {
        await fetchPreviousSpecification({
          orderStatusId,
          contractId
        });
      }

      setVisibleAcceptanceContractModal(true);
    },
    [fetchPreviousSpecification]
  );

  const submitChanginContract = useCallback(
    ({ id, values }) => dispatch(changeOrderStatusAction({ id, values })),
    [dispatch]
  );

  const submitChangingSystemContract = useCallback(
    async ({ orderStatusId, values }) => {
      await submitChanginContract({ id: orderStatusId, values });
      const fetchedOrderStatus = await dispatch(
        fetchOrderStatus({ id: orderStatusId })
      );

      const getNoticeNumber = () => {
        if (fetchedOrderStatus.isLocked) {
          return NOTICE_NUMBER.orderStatusRequestToContractorSended;
        }

        const withoutChangingState =
          fetchedOrderStatus.responsibleId === fetchedOrderStatus.signatoryId;

        return withoutChangingState
          ? NOTICE_NUMBER.orderStatusUpdated
          : NOTICE_NUMBER.orderStatusStateChanged;
      };

      showNoticeMessage({
        number: getNoticeNumber()
      });
    },
    [dispatch, submitChanginContract]
  );

  const changeOrderStatus = useCallback(
    async ({ orderStatusId, contractId, orderStatus }) => {
      setPreviousData({});

      const previousSpecification = await fetchPreviousSpecification({
        orderStatusId,
        contractId
      });

      if (previousSpecification.kind === SPEC_KIND_SYSTEM) {
        const contract = await dispatch(
          fetchOrderStatusContract({ id: orderStatusId, contractId })
        );

        dispatch(
          setVisibleDrawer({
            drawer: CONTRACT_DRAWER,
            data: {
              orderStatus,
              specificationData: {
                ...previousSpecification,
                contractDate: contract.contractDate,
                contractNumber: contract.contractNumber
              },
              onSubmit: values =>
                submitChangingSystemContract({ orderStatusId, values }),
              resetReordering
            }
          })
        );
      } else {
        setVisibleChangingContractModal(true);
      }
    },
    [
      dispatch,
      fetchPreviousSpecification,
      resetReordering,
      submitChangingSystemContract
    ]
  );

  const submitChangingAct = useCallback(
    async ({ orderStatusId, contractId, actId, values }) => {
      await dispatch(
        changeAct({ id: orderStatusId, contractId, actId, values })
      );

      await dispatch(fetchOrderStatus({ id: orderStatusId }));

      await dispatch(
        fetchOrderStatusAllActs({ id: orderStatusId, contractId })
      );
    },
    [dispatch]
  );

  const changeActSystem = useCallback(
    async ({ orderStatusId, contractId, actId, orderStatus }) => {
      setPreviousData({});

      const previousSpecification = await fetchPreviousSpecification({
        orderStatusId,
        contractId
      });

      const contract = await dispatch(
        fetchOrderStatusContract({ id: orderStatusId, contractId })
      );

      dispatch(
        setVisibleDrawer({
          drawer: ACT_EDITOR_DRAWER,
          data: {
            orderStatus,
            specificationData: {
              ...previousSpecification,
              contractDate: contract.contractDate,
              contractNumber: contract.contractNumber
            },
            onSubmit: values =>
              submitChangingAct({ orderStatusId, contractId, actId, values }),
            resetReordering
          }
        })
      );
    },
    [dispatch, fetchPreviousSpecification, resetReordering, submitChangingAct]
  );

  const cancelOrderStatus = () => setVisibleCancellationModal(true);

  const submitAcceptanceContractModal = useCallback(
    ({ id, values }) => dispatch(acceptOrderStatusLead({ id, values })),
    [dispatch]
  );

  const acceptRequest = useCallback(
    async ({ orderStatusId, state, request }) => {
      try {
        setIsLoading(true);

        await dispatch(
          acceptOrderStatusRequest({ id: orderStatusId, requestId: request.id })
        );

        const fetchedOrderStatus = await dispatch(
          fetchOrderStatus({ id: orderStatusId })
        );

        const getNoticeNumber = () => {
          if (request.kind === REQUEST_KIND_APPROVING_SCHEDULING_ACT) {
            return NOTICE_NUMBER.orderStatusActAgreed;
          }

          if (fetchedOrderStatus.isLocked) {
            return NOTICE_NUMBER.orderStatusRequestToContractorSended;
          }

          return state === fetchedOrderStatus.state
            ? NOTICE_NUMBER.orderStatusUpdated
            : NOTICE_NUMBER.orderStatusStateChanged;
        };

        showNoticeMessage({
          number: getNoticeNumber()
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const rejectRequest = () => setVisibleRejectionModal(true);

  const cancelRequest = useCallback(
    async ({ orderStatusId, state, contractId, request }) => {
      if (request.kind === REQUEST_KIND_APPROVING_SCHEDULING_ACT) {
        setVisibleCancelActModal(true);
        return;
      }

      try {
        setIsLoading(true);

        await dispatch(
          cancelOrderStatusRequest({
            id: orderStatusId,
            requestId: request.id,
            fileList: [],
            reason: null
          })
        );

        const fetchedOrderStatus = await dispatch(
          fetchOrderStatus({ id: orderStatusId })
        );

        if (contractId) {
          await dispatch(
            fetchOrderStatusAllActs({ id: orderStatusId, contractId })
          );
        }

        showNoticeMessage({
          number:
            state === fetchedOrderStatus.state
              ? NOTICE_NUMBER.requestCancelled
              : NOTICE_NUMBER.orderStatusStateChanged
        });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const reopenOrderStatus = useCallback(
    async ({ orderStatusId }) => {
      try {
        setIsLoading(true);

        await dispatch(reopenOrderStatusAction({ id: orderStatusId }));
        await dispatch(fetchOrderStatus({ id: orderStatusId }));

        showNoticeMessage({ number: NOTICE_NUMBER.orderStatusStateChanged });
      } finally {
        setIsLoading(false);
      }
    },
    [dispatch]
  );

  const completeOrderStatus = useCallback(
    async ({ orderStatusId, contractId }) => {
      const previousSpecification = await fetchPreviousSpecification({
        orderStatusId,
        contractId
      });

      if (previousSpecification.kind === SPEC_KIND_SYSTEM) {
        try {
          setIsLoading(true);

          await dispatch(
            completeOrderStatusAction({
              id: orderStatusId,
              documentList: []
            })
          );

          const fetchedOrderStatus = await dispatch(
            fetchOrderStatus({ id: orderStatusId })
          );

          await dispatch(
            fetchOrderStatusAllActs({ id: orderStatusId, contractId })
          );

          return showNoticeMessage({
            number: fetchedOrderStatus.isLocked
              ? NOTICE_NUMBER.orderStatusRequestToContractorSended
              : NOTICE_NUMBER.orderStatusStateChanged
          });
        } finally {
          resetDraggable();
          setIsLoading(false);
        }
      }

      return setVisibleCompletionModal(true);
    },
    [dispatch, fetchPreviousSpecification, resetDraggable]
  );

  const reopenAct = useCallback(
    async ({ id, contractId, actId }) => {
      try {
        setIsLoading(true);

        await dispatch(reopenOrderStatusAct({ id, contractId, actId }));

        await dispatch(fetchOrderStatus({ id }));

        await dispatch(fetchOrderStatusAllActs({ id, contractId }));

        showNoticeMessage({ number: NOTICE_NUMBER.orderStatusStateChanged });
      } finally {
        setIsLoading(false);
      }
    },

    // eslint-disable-next-line react-hooks/exhaustive-deps
    [dispatch]
  );

  const approveDocuments = () => setVisibleAgreementModal(true);

  return {
    isLoading,
    modalData: {
      acceptanceContractModal: {
        visible: visibleAcceptanceContractModal,
        previousData,
        onClose: () => {
          setVisibleAcceptanceContractModal(false);
          resetDraggable();
        },
        onSubmit: submitAcceptanceContractModal
      },
      changingContractModal: {
        visible: visibleChangingContractModal,
        previousData,
        onClose: () => {
          setVisibleChangingContractModal(false);
          resetDraggable();
        },
        onSubmit: submitChanginContract
      },
      requestRejectionModal: {
        visible: visibleRejectionModal,
        onClose: () => {
          setVisibleRejectionModal(false);
          resetDraggable();
        }
      },
      cancellationModal: {
        visible: visibleCancellationModal,
        onClose: () => {
          setVisibleCancellationModal(false);
          resetDraggable();
        }
      },
      completionModal: {
        visible: visibleCompletionModal,
        onClose: () => {
          setVisibleCompletionModal(false);
          resetDraggable();
        }
      },
      agreementModal: {
        visible: visibleAgreementModal,
        resetDraggable,
        isLoading,
        onAccept: acceptRequest,
        onReject: rejectRequest,
        onClose: () => setVisibleAgreementModal(false)
      },
      cancelActModal: {
        visible: visibleCancelActModal,
        onClose: () => {
          setVisibleCancelActModal(false);
        }
      }
    },
    setIsLoading,
    acceptOrderStatus,
    changeOrderStatus,
    cancelOrderStatus,
    acceptRequest,
    rejectRequest,
    cancelRequest,
    completeOrderStatus,
    approveDocuments,
    reopenOrderStatus,
    reopenAct,
    changeActSystem
  };
};

export default useChangeState;

import moment from 'moment/moment';
import React from 'react';
import PropTypes from 'prop-types';
import { useTranslation } from 'react-i18next';

import { DATE_TIME_FORMAT, REVIEW } from 'constants/index';

import FrequencyLabel from '../status-actions/frequency-label';

export const SchedulerTooltip = ({ specification, state }) => {
  const { t } = useTranslation('Order');

  return (
    <>
      <div>
        {t('FirstActBePostedDesc')}{' '}
        {moment(specification.scheduler.startAt).format(DATE_TIME_FORMAT)}.
      </div>

      <div>
        {t('ActRecurrence')} {specification.scheduler.interval}{' '}
        <FrequencyLabel
          count={specification.scheduler.interval}
          frequency={specification.scheduler.frequency}
        />
      </div>

      {state !== REVIEW && (
        <div>
          {t('ClosestActBePostedDesc')}{' '}
          {specification && specification.scheduler.nextDate
            ? moment(specification.scheduler.nextDate).format(DATE_TIME_FORMAT)
            : '-'}
          .
        </div>
      )}

      <div>
        {t('LastActBePostedDesc')}{' '}
        {moment(specification.scheduler.until).format(DATE_TIME_FORMAT)}.
      </div>
    </>
  );
};

SchedulerTooltip.propTypes = {
  specification: PropTypes.object,
  state: PropTypes.string
};

SchedulerTooltip.defaultProps = {
  specification: {},
  state: ''
};

export default SchedulerTooltip;

import React, { useMemo } from 'react';
import PropTypes from 'prop-types';
import { Link } from 'react-router-dom';
import isEmpty from 'lodash/isEmpty';
import { Skeleton, Tooltip } from 'antd';
import { useTranslation } from 'react-i18next';

import Typography from 'components/common/typography';
import Icon from 'components/common/icon';
import VerifiedPhone from 'components/common/verified-phone';
import Button from 'components/common/button';
import ContactAvatar from 'components/common/avatar/contact';
import EmployeeAvatar from 'components/common/avatar/employee';

import { getFullName } from 'utils/get-fio';

import styles from './user-role.module.scss';

const UserRole = ({
  label,
  labelTooltip,
  user,
  link,
  isContractor,
  allowChange,
  onClickChanging
}) => {
  const { t } = useTranslation('Order');

  const workspaceTitle = useMemo(() => {
    if (isEmpty(user) || !isContractor) {
      return null;
    }

    return user.company.title;
  }, [isContractor, user]);

  if (isEmpty(user)) {
    return (
      <>
        <Skeleton active title={{ width: '20%' }} paragraph={false} />

        <Skeleton
          active
          avatar={{ size: 24, shape: 'circle' }}
          title={{ width: '100%' }}
          paragraph={false}
        />
      </>
    );
  }

  return (
    <div className={styles.root}>
      <div className={styles.labelWrap}>
        <Typography.Text color="black-35" className={styles.label}>
          {label}
        </Typography.Text>

        {labelTooltip && (
          <Tooltip title={labelTooltip} mouseEnterDelay={0.5}>
            <Icon
              type="question-circle"
              size={15}
              theme="filled"
              color="black-55"
            />
          </Tooltip>
        )}
      </div>

      <div className={styles.info}>
        {isContractor ? (
          <Link to={link}>
            <ContactAvatar size={24} contact={user} />
          </Link>
        ) : (
          <VerifiedPhone isVerified={user.isPhoneVerified}>
            <EmployeeAvatar
              employee={user}
              tooltip={{ isHide: user.isActive }}
            />
          </VerifiedPhone>
        )}

        <Typography.Text ellipsis>{`${getFullName(user)} ${
          workspaceTitle ? `(${workspaceTitle})` : ''
        }`}</Typography.Text>

        {allowChange && (
          <Button
            type="link"
            className={styles.changingBtn}
            onClick={onClickChanging}
          >
            {t('ChangeBtn')}
          </Button>
        )}
      </div>
      <div />
    </div>
  );
};

UserRole.propTypes = {
  label: PropTypes.string.isRequired,
  labelTooltip: PropTypes.oneOfType([PropTypes.string, PropTypes.node]),
  user: PropTypes.shape({}),
  link: PropTypes.string,
  isContractor: PropTypes.bool,
  allowChange: PropTypes.bool,
  onClickChanging: PropTypes.func
};

UserRole.defaultProps = {
  user: {},
  labelTooltip: null,
  isContractor: false,
  allowChange: false,
  onClickChanging: () => {},
  link: undefined
};

export default UserRole;

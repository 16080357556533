import {
  TYPE_ASSET,
  TYPE_CONTACT,
  TYPE_EMPLOYEE,
  TYPE_ORDER,
  TYPE_PROJECT,
  TYPE_TASK
} from 'constants/index';

import useRoutesService from 'services/routes';
import useModalsService from 'services/modals';

const useEntityLink = ({ entityType, entityId }) => {
  const routes = useRoutesService({ returnUrl: true });
  const modals = useModalsService({ returnUrl: true });

  const createLink = (query, isOrigin) => {
    const url = `${
      isOrigin ? window.location.origin : window.location.href
    }${query}`;

    return url;
  };

  const openInNewTab = url => {
    window.open(url, '_blank');
  };

  const getLink = () => {
    switch (entityType) {
      case TYPE_TASK:
        return createLink(
          modals.tasks.showDetails({
            id: entityId
          })
        );
      case TYPE_CONTACT:
        return createLink(routes.toContact({ id: entityId }), true);
      case TYPE_ASSET:
        return createLink(
          modals.assets.showDetails({
            id: entityId
          })
        );
      case TYPE_ORDER:
        return createLink(
          modals.orders.showDetails({
            orderStatusId: entityId
          })
        );
      case TYPE_PROJECT:
        return createLink(routes.toProject(entityId), true);
      case TYPE_EMPLOYEE:
        return createLink(routes.toEmployee(entityId), true);
      default:
        return null;
    }
  };

  return { entityLink: getLink(), openInNewTab };
};

export default useEntityLink;

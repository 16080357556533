import React, { forwardRef, useMemo } from 'react';
import PropTypes from 'prop-types';
import { Virtuoso } from 'react-virtuoso';
import { useTranslation } from 'react-i18next';

import useEmployeeNotificationsChat from 'components/common/chat/entity-hooks/use-employee-notifications-chat';
import Chat from 'components/common/chat';
import TimerAndCalendarIcon from 'components/common/icons/multicolor/timer-and-calendar';
import DateList from 'components/common/date-list';
import SkeletonChat from 'components/common/chat/skeleton';

import NotificationMessage from './message';
import ReadAllNotifications from '../read-all';

import styles from './list.module.scss';

const NotificationList = ({ afterVisibleChange, view }) => {
  const { t } = useTranslation('Dashboard');

  const {
    isLoading,
    hasMessages,
    messages,
    hasMore,
    messagesRef,
    loadMore
  } = useEmployeeNotificationsChat({
    afterVisibleChange,
    view
  });

  const components = useMemo(
    () => ({
      List: forwardRef((props, ref) => {
        const { children, ...restProps } = props;

        return (
          <div {...restProps} ref={ref}>
            <DateList
              selector={item => item.children.props.message.createdAt}
              className={styles.date}
              firstDateAdditionalComponent={<ReadAllNotifications />}
            >
              {children}
            </DateList>
          </div>
        );
      }),
      Item: ({ item, ...props }) => (
        <div {...props} className={styles.messageWrap} />
      ),
      Footer: () => {
        if (!isLoading) {
          return null;
        }

        return <SkeletonChat className={styles.skeleton} isIncoming />;
      }
    }),
    [isLoading]
  );

  const renderedMessages = useMemo(() => {
    if (!messages.length) {
      return null;
    }

    return (
      <Virtuoso
        style={{ height: '100%' }}
        data={messages}
        components={components}
        itemContent={(_, message) => (
          <NotificationMessage message={message} view={view} />
        )}
        customScrollParent={messagesRef.current}
        overscan={{
          reverse: 1500,
          main: 1500
        }}
      />
    );
  }, [components, messages, messagesRef, view]);

  return (
    <Chat
      isLoading={!messages.length && isLoading}
      hasMessages={hasMessages}
      messages={renderedMessages}
      messagesRef={messagesRef}
      className={styles.root}
      messagesClassName={styles.messages}
      empty={{
        icon: TimerAndCalendarIcon,
        description: t('NoNotificationsYet')
      }}
      skeletonProps={{
        className: styles.skeleton,
        isIncoming: true
      }}
      isReverse={false}
      hasMore={hasMore}
      loadMore={loadMore}
    />
  );
};

NotificationList.propTypes = {
  afterVisibleChange: PropTypes.bool,
  view: PropTypes.arrayOf(PropTypes.string).isRequired
};

NotificationList.defaultProps = {
  afterVisibleChange: false
};

export default NotificationList;

import React, { forwardRef } from 'react';
import PropTypes from 'prop-types';
import { Badge } from 'antd';
import classnames from 'classnames';
import isEmpty from 'lodash/isEmpty';

import { UPSERVICE_SENDER } from 'constants/index';

import Icon from 'components/common/icon';
import Avatar from 'components/common/avatar';
import ChatMessage from 'components/common/chat/message';
import Typography from 'components/common/typography';
import ContactAvatar from 'components/common/avatar/contact';
import { LogoCircleIcon } from 'components/common/icons';

import getFIO from 'utils/get-fio';

import useMessage from './use-message';
import Header from './header';
import Content from './content';
import ChatActions from './chat-actions';
import Actions from './actions';
import Parent from './parent';
import Reactions from './reactions';

import styles from './message.module.scss';

const Message = forwardRef(
  (
    {
      entity,
      messages,
      onClickReply,
      scrollToMessage,
      entityType,
      rootClassName,
      onClickAnswer,
      checkAllowReaction,
      destination
    },
    ref
  ) => {
    const {
      ChannelIcon,
      sender,
      text,
      contactChat,
      createdAt,
      fileList,
      isMy,
      isLog,
      isComment,
      isFirstGroupMessage,
      isGroupMessage,
      isLastGroupMessage,
      isFromOnlineChat,
      subject,
      isFrom,
      textHtml,
      isHelloMessage,
      chatActions,
      leads,
      recipients,
      parent,
      actions,
      visibleEmojiPopover,
      visibleControlDropdown,
      reactions,
      messageUuid,
      messageReaction,
      logContent,
      isPrivate,
      isDraft,
      isNotSended,
      showMessageActions,
      isNotificationSource,
      getSourceInfoLabel,
      getIconStatusMessage,
      getStatusTextNotDeliveryMessage,
      isSending,
      isGptMessage,
      attachmentActionsDeps,
      changeFileList
    } = useMessage({
      messages,
      entity,
      onClickReply,
      entityType,
      onClickAnswer,
      checkAllowReaction,
      destination
    });

    if (isEmpty(contactChat)) {
      return null;
    }

    return (
      <div
        ref={ref}
        className={classnames(
          styles.root,
          {
            [styles.firstGroupMessage]: isFirstGroupMessage,
            [styles.groupMessage]: isGroupMessage,
            [styles.lastGroupMessage]: isLastGroupMessage,
            [styles.source]: isNotificationSource
          },
          rootClassName
        )}
      >
        <div
          className={classnames(styles.message, {
            [styles.my]: isMy || isHelloMessage
          })}
        >
          {!isNotificationSource && (
            <>
              {(sender.fullName.toLowerCase() || '') === UPSERVICE_SENDER && (
                <Icon
                  component={LogoCircleIcon}
                  style={{ width: 36, height: 36 }}
                />
              )}

              {!isMy &&
                !isHelloMessage &&
                (sender.fullName.toLowerCase() || '') !== UPSERVICE_SENDER && (
                  <Badge
                    offset={[0, 20]}
                    count={
                      ChannelIcon ? (
                        <Icon
                          component={ChannelIcon}
                          size={16}
                          className={styles.channelIcon}
                        />
                      ) : null
                    }
                  >
                    {sender.isContact ? (
                      <ContactAvatar contact={entity} size={36} />
                    ) : (
                      <Avatar size={36} src={sender.avatarUrl}>
                        {getFIO({ firstName: sender.fullName })}
                      </Avatar>
                    )}
                  </Badge>
                )}
            </>
          )}

          <ChatMessage
            entityType={entityType}
            header={
              isNotificationSource ? null : (
                <Header
                  sender={sender}
                  entityType={entityType}
                  leads={leads}
                  recipients={recipients}
                  isComment={isComment || isPrivate}
                  isFrom={isFrom}
                  getSourceInfoLabel={getSourceInfoLabel}
                />
              )
            }
            content={
              <Content
                sender={sender}
                content={text}
                htmlContent={textHtml}
                logContent={logContent}
                subject={subject}
                isFrom={isFrom}
                isLog={isLog}
                isComment={isComment || isPrivate}
                isGptMessage={isGptMessage}
                fileList={fileList}
                isNotificationSource={isNotificationSource}
              />
            }
            createdAt={createdAt}
            isDraft={isDraft}
            isNotSended={isNotSended}
            isNotificationSource={isNotificationSource}
            fileList={fileList}
            parent={
              parent && (
                <Parent
                  data={parent}
                  onClick={uuid => scrollToMessage(uuid, 'smooth')}
                  isMessageContent
                />
              )
            }
            reactions={
              <Reactions
                messageUuid={messageUuid}
                reactions={reactions}
                onClick={messageReaction}
              />
            }
            className={classnames(styles.contentWrap, {
              [styles.my]: isMy || isHelloMessage,
              [styles.comment]: isComment || isPrivate,
              [styles.online]: isFromOnlineChat && !isMy
            })}
            getIconStatusMessage={getIconStatusMessage}
            getStatusTextNotDeliveryMessage={getStatusTextNotDeliveryMessage}
            isSending={isSending}
            attachmentActionsDeps={attachmentActionsDeps}
            changeFileList={changeFileList}
          />

          {showMessageActions && (
            <Actions
              messageUuid={messageUuid}
              actions={actions}
              className={classnames(styles.messageActions, {
                [styles.visible]:
                  visibleEmojiPopover || visibleControlDropdown || isNotSended
              })}
            />
          )}
        </div>

        {isLastGroupMessage && (
          <div className={styles.groupInfo}>
            <Typography.Text ellipsis color="black-55">
              {contactChat.channelName}
            </Typography.Text>

            <ChatActions actions={chatActions} />
          </div>
        )}
      </div>
    );
  }
);

// TODO
Message.propTypes = {
  entity: PropTypes.shape({}).isRequired,
  messages: PropTypes.shape({
    prev: PropTypes.shape({}),
    curr: PropTypes.shape({}),
    next: PropTypes.shape({})
  }).isRequired,
  destination: PropTypes.shape({
    entityType: PropTypes.string,
    entityId: PropTypes.string
  }).isRequired,
  rootClassName: PropTypes.string
};

Message.defaultProps = {
  rootClassName: undefined
};

export default Message;

import React, { useRef, useState } from 'react';
import { useLexicalComposerContext } from '@lexical/react/LexicalComposerContext';
import classnames from 'classnames';

import Emoji from 'components/common/new-editor/components/emoji';
import Mention from 'components/common/new-editor/components/mention';
import UploadFilesDescription from 'components/common/new-editor/components/upload-files-description';
import useToolbarControls from 'components/common/new-editor/plugins/hooks/use-toolbar-controls';
import { MESSAGE_TEMPLATES_LIST } from 'components/common/drawers/message-templates/constants';
import MessageTemplatesDrawer from 'components/common/drawers/message-templates';

import MessageTemplates from '../../components/message-templates';

import styles from '../../plugins/toolbar.module.scss';

export const DescriptionToolbar = ({
  onChangeFileList,
  fileList,
  uploadProps,
  showItems,
  actionsDeps,
  isDisabled,
  resizeInput,
  value
}) => {
  const [
    visibleMessageTemplatesDrawer,
    setVisibleMessageTemplatesDrawer
  ] = useState(false);

  const toolbarRef = useRef(null);

  const [editor] = useLexicalComposerContext();

  const { addMention, addEmoji, addTextToEditor } = useToolbarControls({
    editor,
    value,
    descriptionValue: value.description
  });

  return (
    <>
      <div
        className={classnames(styles.bottomToolbar, {
          [styles.isDisabledToolbar]: isDisabled,
          [styles.resizeInput]: resizeInput
        })}
        ref={toolbarRef}
      >
        {showItems.upload && (
          <UploadFilesDescription
            onChangeFiles={onChangeFileList}
            fileList={fileList}
            actionsDeps={actionsDeps}
            disabled={isDisabled}
            {...uploadProps}
          />
        )}

        {showItems.mention && (
          <Mention onClick={addMention} disabled={isDisabled} />
        )}

        {showItems.emoji && (
          <Emoji onSelect={addEmoji} isDisabled={isDisabled} />
        )}

        {showItems.messageTemplates && (
          <MessageTemplates
            disabled={isDisabled}
            onClick={() => setVisibleMessageTemplatesDrawer(true)}
          />
        )}
      </div>

      <MessageTemplatesDrawer
        visible={visibleMessageTemplatesDrawer}
        onClose={() => setVisibleMessageTemplatesDrawer(false)}
        data={{ component: MESSAGE_TEMPLATES_LIST, addTextToEditor }}
      />
    </>
  );
};

export default DescriptionToolbar;
